import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

/**
 * Props :
 *    - filter
 *    - isSelected, base state
 *    - onSelecting(boolean, filter), callback when the filter is selected
 */

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: this.props.isSelected }
  }

  select() {
    this.props.onSelecting(!this.state.selected, this.props.filter)
    this.setState({
      selected: !this.state.selected,
    })
  }

  render() {
    return (
      <Button
        className="m-1"
        variant="contained"
        onClick={() => this.select()}
        style={
          this.state.selected
            ? {
                backgroundColor: 'rgb(230,126,7)',
                color: 'white',
                borderRadius: '16px',
                textTransform: 'none',
              }
            : {
                backgroundColor: 'rgb(109, 118, 125)',
                color: 'white',
                borderRadius: '16px',
                textTransform: 'none',
              }
        }
      >
        {this.props.filter.name}
      </Button>
    )
  }
}

export default Filter
