import styled from 'styled-components'
import Card from '@material-ui/core/Card'

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 25px;
  border-witdh: 1;
`

export default StyledCard
