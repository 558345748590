import React from 'react'
import { Helmet } from 'react-helmet'
import { checkUserAdminOrGestPrivileges, getUser } from '../../helpers'
import Header from '../../components/Header'
import Container from '@material-ui/core/Container'
import { DatePicker } from '@material-ui/pickers'
import { MonthDetails, MonthRecap } from './components'
import { getConsums, getSimpleEnabledUsers } from '../../reducers'
import { fetchConsumsByUserIdAndDates } from '../../actions/requests/consumsActions'
import { connect } from 'react-redux'
import { PageSpinner, UsersListContainer } from '../../components'
import { fetchSimpleUsers } from '../../actions/requests/usersActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { userListButtonAction } from '../../constants/global'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'

class Consums extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      consumsLoading: true,
      currentUser: getUser(),
      dates: Array.from({ length: moment().daysInMonth() }, (x, i) =>
        moment().startOf('month').add(i, 'days').toDate()
      ),
      isLoading: true,
      openDialog: false,
      selectedDate: new Date(),
      selectedUser: getUser(),
    }
  }

  componentDidMount() {
    const startMonth = moment(this.state.dates[0]).format('YYYY-MM-DD')
    const endMonth = moment(
      this.state.dates[this.state.dates.length - 1]
    ).format('YYYY-MM-DD')

    let requests = [
      this.props.fetchConsumsByUserIdAndDates(this.state.selectedUser.id, [
        { key: 'startDate', value: startMonth },
        { key: 'endDate', value: endMonth },
      ]),
    ]
    if (checkUserAdminOrGestPrivileges(this.state.currentUser))
      requests = [...requests, this.props.fetchSimpleUsers()]

    Promise.all(requests).then(() => {
      this.setState({ consumsLoading: false, isLoading: false })
    })
  }

  handleSelectedDateChange = (value) => {
    const date = value.toDate()
    const dates = Array.from({ length: moment(date).daysInMonth() }, (x, i) =>
      moment(date).startOf('month').add(i, 'days').toDate()
    )

    this.setState({
      consumsLoading: true,
      dates: dates,
      selectedDate: date,
    })

    const startMonth = moment(dates[0]).format('YYYY-MM-DD')
    const endMonth = moment(dates[dates.length - 1]).format('YYYY-MM-DD')
    const datesObj = [
      { key: 'startDate', value: startMonth },
      { key: 'endDate', value: endMonth },
    ]

    Promise.all([
      this.props.fetchConsumsByUserIdAndDates(
        this.state.selectedUser.id,
        datesObj
      ),
    ]).then(() => {
      this.setState({ consumsLoading: false })
    })
  }

  handleSelectedUserChange = (user) => {
    this.setState({
      consumsLoading: true,
      selectedUser: user,
      openDialog: false,
    })

    const startMonth = moment(this.state.dates[0]).format('YYYY-MM-DD')
    const endMonth = moment(
      this.state.dates[this.state.dates.length - 1]
    ).format('YYYY-MM-DD')
    const dates = [
      { key: 'startDate', value: startMonth },
      { key: 'endDate', value: endMonth },
    ]

    Promise.all([this.props.fetchConsumsByUserIdAndDates(user.id, dates)]).then(
      () => {
        this.setState({ consumsLoading: false })
      }
    )
  }

  render() {
    if (this.state.isLoading) return <PageSpinner />

    const consums = this.props.consums.filter(
      (c) => c.user.id === this.state.selectedUser.id
    )

    const title =
      this.state.selectedUser.id === this.state.currentUser.id
        ? 'Mes saisies'
        : `Saisies de ${this.state.selectedUser.firstname} ${this.state.selectedUser.lastname}`

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Saisies - Attiprod </title>
        </Helmet>

        <Header title={title} user={this.state.currentUser} />

        <Container className={'p-3'}>
          <div className={'d-flex'}>
            <DatePicker
              inputVariant={'outlined'}
              helperText="Mois à visualiser"
              openTo={'month'}
              views={['year', 'month']}
              value={this.state.selectedDate}
              onChange={this.handleSelectedDateChange}
              cancelLabel="annuler"
              InputProps={{
                style: { backgroundColor: 'white', height: '45px' },
              }}
              autoOk
            />

            {checkUserAdminOrGestPrivileges(this.state.currentUser) && (
              <>
                <div>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => this.setState({ openDialog: true })}
                    style={{ margin: '4px 0 0 8px' }}
                  >
                    Sélectionner la ressource
                  </Button>
                </div>

                {this.state.selectedUser.profile_pic &&
                this.state.selectedUser.profile_pic !== '' ? (
                  <Avatar
                    alt="user photo"
                    src={this.state.selectedUser.profile_pic}
                    style={{ marginLeft: '8px' }}
                  />
                ) : (
                  <Avatar
                    style={{
                      marginLeft: '8px',
                      backgroundColor: 'rgb(148,139,150,1)',
                    }}
                  >
                    {this.state.selectedUser.firstname[0]}
                    {this.state.selectedUser.lastname[0]}
                  </Avatar>
                )}
              </>
            )}
          </div>

          <Dialog
            aria-labelledby="select-user-dialog"
            open={this.state.openDialog}
            onClose={() => this.setState({ openDialog: false })}
          >
            <DialogTitle id="select-user-dialog">
              Selectionner un utilisateur
            </DialogTitle>
            <DialogContent>
              <UsersListContainer
                buttonAction={userListButtonAction.SELECTION}
                omitted={[this.state.selectedUser]}
                users={this.props.users}
                onAdd={(user) => this.handleSelectedUserChange(user)}
              />
            </DialogContent>
          </Dialog>
        </Container>

        <Container className={'p-3'}>
          <MonthRecap
            consums={consums}
            dates={this.state.dates}
            isLoading={this.state.consumsLoading}
          />
        </Container>

        <Container className={'p-3'}>
          <MonthDetails
            consums={consums}
            dates={this.state.dates}
            isLoading={this.state.consumsLoading}
          />
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  consums: getConsums(state),
  users: getSimpleEnabledUsers(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchConsumsByUserIdAndDates: (id, dates) =>
    dispatch(fetchConsumsByUserIdAndDates(id, dates)),
  fetchSimpleUsers: () => dispatch(fetchSimpleUsers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Consums)
