import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import ColorButton from '../../../../components/ColorButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Dropzone from 'react-dropzone'
import Typography from '@material-ui/core/Typography'
import StyledDropZoneContainer from './styles/StyledDropZoneContainer'
import { updateProjectByImport } from '../../../../actions/requests/projectsActions'
import { fetchFiltersByProjectId } from '../../../../actions/requests/filtersActions'
import { fetchSRPricesByProjectId } from '../../../../actions/requests/srPricesActions'
import { ImportDialogTable } from './components'

const styles = {
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: {},
}

class ImportDialog extends React.Component {
  fileReader = new FileReader()

  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      file: null,
      filename: '',
      hasErrors: false,
      hasWarnings: false,
      open: false,
      savePrices: true,
      saveTasks: true,
      warnings: [],
    }

    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleOptionChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }

  handleFileChange = (files) => {
    const [file] = files
    this.fileReader.readAsBinaryString(file)
    this.fileReader.onloadend = () => {
      this.setState({
        filename: file.name,
        file:
          typeof this.fileReader.result === 'string' &&
          btoa(this.fileReader.result),
      })
    }
  }

  uploadFile = () => {
    if (!this.state.saveTasks && !this.state.savePrices) {
      this.props.addNotification(
        'Opération échouée',
        'Veuillez sélectionner au moins une option',
        'danger'
      )

      this.setState({
        open: false,
      })

      return
    }

    const notificationMessage = 'Importation du fichier terminée'
    this.props.updateProjectByImport(
      this.props.project.id,
      {
        project: this.props.project.id,
        file: this.state.file,
        filename: this.state.filename,
        options: {
          tasks: this.state.saveTasks,
          srPrices: this.state.savePrices,
        },
      },
      (id, response) => {
        const requests = [
          this.props.fetchProjectFilters(this.props.project.id),
          this.props.fetchSRPricesByProjectId(this.props.project.id),
        ]

        Promise.all(requests).then(() => {
          this.setState({
            errors: null,
            file: null,
            filename: '',
            hasErrors: false,
            hasWarnings: response.data.warnings.length !== 0,
            open: response.data.warnings.length !== 0,
            warnings: response.data.warnings,
          })

          this.props.successNotification(id, response, notificationMessage)
        })
      },
      (id, error) => {
        if (error.response && error.response.status === 400) {
          this.setState({
            errors: error.response.data.errors,
            hasErrors: true,
            hasWarnings: false,
          })
        }

        this.props.failureNotificationApi(id, error)
      }
    )
  }

  render() {
    return (
      <>
        <ColorButton
          aria-label="Importer"
          variant={'outlined'}
          className="mt-3"
          style={{ width: '14rem' }}
          color={'primary'}
          endIcon={<Icon>publish</Icon>}
          onClick={this.handleClickOpen}
        >
          Importer un fichier
        </ColorButton>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={'sm'}
          aria-labelledby={'import-excel-title'}
          aria-describedby={''}
        >
          <DialogTitle id={'import-excel-title'}>
            Importer un fichier Excel
          </DialogTitle>

          <DialogContent>
            <TextField
              disabled
              id={'import-dialog-filename-input'}
              label={'Nom du fichier'}
              variant={'outlined'}
              fullWidth={true}
              value={this.state.filename}
              InputProps={{
                classes: {
                  root: this.props.classes.inputRoot,
                  disabled: this.props.classes.disabled,
                },
              }}
            />

            <Typography style={{ marginTop: '1rem', fontWeight: 'bold' }}>
              Options
            </Typography>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'saveTasks'}
                    color={'primary'}
                    checked={this.state.saveTasks}
                    onChange={this.handleOptionChange}
                  />
                }
                label={'Importer les tâches'}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name={'savePrices'}
                    color={'primary'}
                    checked={this.state.savePrices}
                    onChange={this.handleOptionChange}
                  />
                }
                label={'Importer les tarifications'}
              />
            </FormGroup>

            <Dropzone onDrop={this.handleFileChange}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isFileDialogActive,
                isFocused,
              }) => (
                <StyledDropZoneContainer
                  {...getRootProps({
                    className: 'dropzone',
                    isDragActive,
                    isFileDialogActive,
                    isFocused,
                  })}
                >
                  <input {...getInputProps()} />
                  <Typography>
                    Glissez votre fichier ou cliquez dans le cadre pour charger
                    votre fichier
                  </Typography>
                </StyledDropZoneContainer>
              )}
            </Dropzone>

            {this.state.hasErrors && (
              <ImportDialogTable data={this.state.errors} type={'error'} />
            )}

            {this.state.hasWarnings && (
              <ImportDialogTable data={this.state.warnings} type={'warning'} />
            )}
          </DialogContent>

          <DialogActions className={'justify-content-center'}>
            <Button onClick={this.handleClose}>Fermer</Button>
            <Button
              color={'primary'}
              disabled={!this.state.file}
              onClick={this.uploadFile}
            >
              Importer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  //Fetch
  fetchProjectFilters: (id) => dispatch(fetchFiltersByProjectId(id)),
  fetchSRPricesByProjectId: (id) => dispatch(fetchSRPricesByProjectId(id)),
  //Update actions
  updateProjectByImport: (id, body, successCallback, failureCallback) =>
    dispatch(updateProjectByImport(id, body, successCallback, failureCallback)),
  //Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ImportDialog))
