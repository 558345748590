import React from 'react'
import StyledPic from './styles/StyledPic'
import { randomizeColor } from '../../helpers'
import { sizes } from '../../constants/global'
import { connect } from 'react-redux'
import { fetchPicByUserId } from '../../actions/requests/userPicsActions'
import Avatar from '@material-ui/core/Avatar'

class UserAvatar extends React.Component {
  render() {
    if (!this.props.user) return <StyledPic />
    const size = this.props.size ? this.props.size : 'medium'
    let userProfilePic = this.props.user.profile_pic

    let customProfilPic

    if (!userProfilePic) {
      customProfilPic = false
    } else {
      let img = new Image()
      img.src = userProfilePic
      customProfilPic = img.height !== 0
    }

    if (!customProfilPic) {
      return (
        <Avatar
          aria-label="user"
          className={this.props.className}
          style={{
            backgroundColor: this.props.color
              ? this.props.color
              : randomizeColor(
                  this.props.user.firstname,
                  this.props.user.lastname,
                  this.props.user.email
                ),
            height: sizes[size],
            width: sizes[size],
            fontSize: size === 'xlarge' ? 'xx-large' : size,
          }}
          sizes={size}
        >
          <div>
            {this.props.user.firstname[0] + this.props.user.lastname[0]}
          </div>
        </Avatar>
      )
    } else {
      return (
        <StyledPic
          className={this.props.className}
          src={userProfilePic}
          size={sizes[size]}
          alt={this.props.user.firstname + ' ' + this.props.user.lastname}
        />
      )
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfilePic: (id) => dispatch(fetchPicByUserId(id)),
})

export default connect(null, mapDispatchToProps)(UserAvatar)
