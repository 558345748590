import React from 'react'
import { Spinner } from 'reactstrap'

/**
 * Embbed of a Spinner reactstrap componnent
 *
 * Props:
 *
 *  - style : the "style" props has the CSS style to give to the Spinner componnent
 *
 */
const ButtonSpinner = (props) => (
  <Spinner
    style={
      props.style
        ? props.style
        : {
            width: '1.25rem',
            height: '1.25rem',
            margin: '0 auto',
            color: '#f29400',
          }
    }
  />
)

export default ButtonSpinner
