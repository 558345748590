import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  LittleSpinner,
  PageSpinner,
  Section,
  StyledAttiContainer,
} from '../../components'
import {
  BillsSection,
  ImportDialog,
  PricesSection,
  ProjectInfosSection,
  UsersSection,
} from './components'
import {
  findItem,
  isFetching,
  retrieveRequests,
  retrieveWaitingRequests,
} from '../../reducers'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { fetchSkills } from '../../actions/requests/skillsActions'
import { fetchExperiences } from '../../actions/requests/experiencesActions'
import { fetchSecondaryRoles } from '../../actions/requests/secondaryRolesActions'
import { FiltersListContainer } from '../../containers'
import { fetchTasksByProjectId } from '../../actions/requests/tasksActions'
import {
  fetchSimpleUsers,
  fetchUsersByProjectId,
} from '../../actions/requests/usersActions'
import { itemTypes } from '../../actions/itemTypes'
import { applyRequest } from '../../actions/globalActions'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import { fetchBillStatuses } from '../../actions/requests/billStatusesActions'
import { fetchBillsByProjectId } from '../../actions/requests/billsActions'
import { fetchSRPricesByProjectId } from '../../actions/requests/srPricesActions'
import { fetchUsersNeededByProjectId } from '../../actions/requests/userNeededActions'
import { fetchFiltersByProjectId } from '../../actions/requests/filtersActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Header from '../../components/Header'
import CircularProgress from '@material-ui/core/CircularProgress'
import ColorButton from '../../components/ColorButton'
import { Helmet } from 'react-helmet'
import { getUser } from '../../helpers'
import { Tooltip } from '@material-ui/core'
import { isForfaitProjectType } from '../../constants/global'
import axios from 'axios'
import {
  failureNotificationApi,
  successNotification,
} from '../../actions/temporaries/notificationActions'
import { fetchProjectById } from '../../actions/requests/projectsActions'
import { fetchSprintsByProjectId } from '../../actions/requests/sprintsActions'

class EditProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFirstRender: true,
      loading: false,
      infoChanged: false,
    }

    this.toggleLoading = this.toggleLoading.bind(this)
    this.handleInfoChange = this.handleInfoChange.bind(this)

    if (
      this.props.match.params.id === null ||
      this.props.match.params.id === undefined
    ) {
      this.props.redirectToDashboardPage()
    }
  }

  componentDidMount() {
    let promises = [
      this.props.fetchProjectStatuses(),
      this.props.fetchBillStatuses(),
      this.props.fetchSkills(),
      this.props.fetchExperiences(),
      this.props.fetchSecondaryRoles(),
      this.props.fetchSimpleUsers(),
      this.props.fetchUsersByProjectId(this.props.match.params.id),
      this.props.fetchProjectFilters(this.props.match.params.id),
      this.props.fetchProjectById(this.props.match.params.id),
      this.props.fetchUsersNeededByProjectId(this.props.match.params.id),
      this.props.fetchBillsByProjectId(this.props.match.params.id),
      this.props.fetchSRPricesByProjectId(this.props.match.params.id),
      this.props.fetchSprintsByProjectId(this.props.match.params.id),
    ]

    Promise.all(promises).then(() => {
      this.setState({
        isFirstRender: false,
        finished: this.props.project.finished,
      })
    })

    this.props
      .fetchTasksByProjectId(this.props.match.params.id)
      .then((resp) => {
        this.setState({
          tasks: resp.data,
        })
      })
  }

  toggleLoading() {
    this.setState((prevState) => ({
      loading: !prevState.loading,
    }))
  }

  saveData() {
    this.toggleLoading()
    for (let request of this.props.requests) this.props.applyRequest(request)
    this.setState({ infoChanged: false })
    this.toggleLoading()
  }

  handleInfoChange(value) {
    this.setState({
      infoChanged: value,
    })
  }

  haveChangedToSave() {
    return (
      this.state.infoChanged ||
      (this.props.requests.length > 0 &&
        this.props.waitingRequests.length === 0)
    )
  }

  checkUserToTask() {
    if (this.state.tasks === undefined) return undefined
    return this.state.tasks.find(
      (task) =>
        task.board.project.id === this.props.project.id && task.user === null
    )
  }

  downloadFile = () => {
    const notificationMessage = 'Template exporté'
    axios({
      url: `${routes.apiUrl}/projects/${this.props.project.id}/download`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const { client_name, project_name } = this.props.project
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute(
          'download',
          `${client_name} - ${project_name} - Devis.xlsx`
        )
        document.body.appendChild(link)
        link.click()

        return this.props.successNotification(
          this.props.project.id,
          response,
          notificationMessage
        )
      })
      .catch((error) => {
        return this.props.failureNotificationApi(this.props.project.id, error)
      })
  }

  render() {
    if (this.state.isFirstRender) return <PageSpinner />
    if (!this.props.project) {
      this.props.redirectToDashboardPage()
      return null
    }

    let finished = this.props.project.finished
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Edition d'un projet - Attiprod </title>
        </Helmet>

        {!this.state.isFirstRender && this.props.isFetching && (
          <LittleSpinner />
        )}

        <Header
          title={this.props.project.project_name + ' - Paramètres'}
          endIcon={
            <div>
              <Tooltip title="Accéder aux boards">
                <IconButton
                  className="m-0"
                  aria-label="accéder aux boards"
                  onClick={() => {
                    this.props.redirectToProjectTasksPage(
                      this.props.match.params.id
                    )
                  }}
                >
                  <Icon>assignment</Icon>
                </IconButton>
              </Tooltip>

              {
                <Tooltip title="Accéder aux statistiques">
                  <IconButton
                    className="m-0"
                    aria-label="accéder aux statistiques"
                    style={{ color: 'rgb(148,139,150,1)' }}
                    onClick={() => {
                      this.props.redirectToStatisticsPage(
                        this.props.match.params.id
                      )
                    }}
                  >
                    <Icon>pie_chart</Icon>
                  </IconButton>
                </Tooltip>
              }

              <Tooltip title="Accéder aux saisies">
                <IconButton
                  aria-label="accéder aux saisies"
                  style={{ color: 'rgb(148,139,150,1)' }}
                  onClick={() => {
                    this.props.redirectToProjectConsumsPage(
                      this.props.match.params.id
                    )
                  }}
                >
                  <Icon>timer</Icon>
                </IconButton>
              </Tooltip>
            </div>
          }
          user={this.props.currentUser}
        />

        <StyledAttiContainer>
          <Container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  marginBottom: isForfaitProjectType(
                    this.props.project.project_type
                  )
                    ? '0'
                    : '2rem',
                }}
              >
                <ColorButton
                  aria-label="Enregistrer"
                  variant="outlined"
                  className="mt-3"
                  onClick={() => this.saveData()}
                  disabled={!this.haveChangedToSave()}
                  style={{ width: '12rem' }}
                >
                  Enregistrer
                </ColorButton>

                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    style={{
                      position: 'absolute',
                      bottom: '12%',
                      left: '42%',
                    }}
                  />
                )}
              </div>

              {isForfaitProjectType(this.props.project.project_type) && (
                <div style={{ marginBottom: '2rem' }}>
                  <ImportDialog project={this.props.project} />
                  <ColorButton
                    aria-label="Exporter"
                    variant={'outlined'}
                    className="mt-3"
                    style={{ width: '14rem', marginLeft: '0.5rem' }}
                    color={'primary'}
                    endIcon={<Icon>get_app</Icon>}
                    onClick={this.downloadFile}
                  >
                    Télécharger le template
                  </ColorButton>
                </div>
              )}
            </Grid>

            <Grid container spacing={3} className="justify-content-center">
              <Grid item xs={12} sm={12} md={6}>
                <ProjectInfosSection
                  project={this.props.project}
                  disabled={finished}
                  onInfoChange={this.handleInfoChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <UsersSection
                  project={this.props.project}
                  disabled={finished}
                  tasksUser={this.state.tasks}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <BillsSection
                  disabled={finished}
                  project={this.props.project}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <PricesSection
                  disabled={finished}
                  project={this.props.project}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="justify-content-center"
              >
                <Section title={'Filtres'} width="300">
                  <FiltersListContainer
                    disabled={finished}
                    project={this.props.project}
                    additionalInfos={{ project: this.props.project }}
                  />
                </Section>
              </Grid>
            </Grid>
          </Container>
        </StyledAttiContainer>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state),
  project: findItem(state, itemTypes.projects, ownProps.match.params.id),
  requests: retrieveRequests(state),
  waitingRequests: retrieveWaitingRequests(state),
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  //Fetchs actions
  fetchProjectFilters: (id) => dispatch(fetchFiltersByProjectId(id)),
  fetchProjectById: (id) => dispatch(fetchProjectById(id)),
  fetchUsersByProjectId: (id) => dispatch(fetchUsersByProjectId(id)),
  fetchUsersNeededByProjectId: (id) =>
    dispatch(fetchUsersNeededByProjectId(id)),
  fetchBillsByProjectId: (id) => dispatch(fetchBillsByProjectId(id)),
  fetchSRPricesByProjectId: (id) => dispatch(fetchSRPricesByProjectId(id)),
  fetchSprintsByProjectId: (id) => dispatch(fetchSprintsByProjectId(id)),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
  fetchBillStatuses: () => dispatch(fetchBillStatuses()),
  fetchSkills: () => dispatch(fetchSkills()),
  fetchExperiences: () => dispatch(fetchExperiences()),
  fetchSecondaryRoles: () => dispatch(fetchSecondaryRoles()),
  fetchSimpleUsers: () => dispatch(fetchSimpleUsers()),
  fetchTasksByProjectId: (id) => dispatch(fetchTasksByProjectId(id)),
  //Redirects
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
  redirectToProjectConsumsPage: (id) =>
    dispatch(push(routes.projectConsums + '/' + id)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
  redirectToStatisticsPage: (id) =>
    dispatch(push(routes.statistics + '/' + id)),
  //Notifications
  applyRequest: (req) => dispatch(applyRequest(req)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProject)
