import * as React from 'react'
import { connect } from 'react-redux'
import { deleteTask } from '../../../../actions/requests/tasksActions'
import { ButtonSpinner } from '../../../../components'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { Delete } from '@material-ui/icons'

/**
 * Props :
 *    - click(taskId), optionnal, callback
 *    - task
 */
class DeleteTask extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dialog: false,
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  deleteData() {
    if (this.props.task.length) {
      this.setState({
        isLoading: true,
      })
      for (let i = 0; i < this.props.task.length; i++) {
        this.props.click && this.props.click(this.props[i].task.id)

        if (this.props.task[i].consums.length !== 0) {
          this.props.addNotification(
            'Opération échouée',
            `La consommation de la tâche ${this.props.task[i].name} n'est pas à 0`,
            'danger'
          )
        } else {
          const notificationMessage = 'Tâche supprimée'
          this.props.deleteItem(
            this.props.task[i].id,
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
        }
      }
      this.setState({
        isLoading: false,
      })
    } else {
      this.props.click && this.props.click(this.props.task.id)
      this.setState({
        isLoading: true,
      })
      if (this.props.task.consums.length !== 0) {
        this.props.addNotification(
          'Opération échouée',
          "La consommation n'est pas à 0",
          'danger'
        )
        this.setState({
          isLoading: false,
        })
      } else {
        const notificationMessage = 'Tâche supprimée'
        this.props
          .deleteItem(
            this.props.task.id,
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
          .then(() => {
            this.setState({
              isLoading: false,
            })
          })
      }
    }
    if (!!this.props.onDeleteTask) {
      this.props.onDeleteTask()
    }
    this.handleCloseDialog()
  }

  render() {
    return (
      <>
        <IconButton
          aria-label="supprimer cette tâche"
          size={this.props.small ? 'small' : 'medium'}
          onClick={this.handleOpenDialog}
          disabled={this.state.isLoading}
        >
          {this.state.isLoading ? (
            <ButtonSpinner />
          ) : (
            <Delete
              htmlColor={'red'}
              fontSize={this.props.small ? 'small' : 'default'}
            />
          )}
        </IconButton>
        <Dialog open={this.state.dialog} onClose={() => this.closeAction()}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr(e) de vouloir supprimer la(les) tâche(s) ?
            </DialogContentText>
            <DialogActions>
              <Button color="secondary" onClick={this.handleCloseDialog}>
                Fermer
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.deleteData()
                  this.handleCloseDialog()
                }}
              >
                Supprimer
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteTask(id, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(DeleteTask)
