import React from 'react'
import { push } from 'connected-react-router'
import routes from '../../../../config/routes'
import { connect } from 'react-redux'
import StyledDiv from './styles/StyledDiv'
import StyledDiv2 from './styles/StyledDiv2'
import {
  deleteBoard,
  updateBoard,
} from '../../../../actions/requests/boardsActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AddTask from '../AddTask'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { checkUserPrivileges } from '../../../../helpers'

/**
 * Props :
 *    - board
 *    - boards, the existing boards
 */
class EditBoard extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)

    let tasks = []

    this.props.boards.forEach((b) => {
      b.tasks.forEach((t) => {
        tasks = [...tasks, t]
      })
    })

    this.state = {
      isLoading: false,
      modal: false,
      previous_board_name: props.board.name,
      board_name: props.board.name,
      formOpen: false,
      anchorEl: null,
      dialog: false,
      allTasks: tasks,
    }

    if (this.props.board === null || this.props.board === undefined) {
      this.props.redirectToDashboardPage()
    }

    this.toggle = this.toggle.bind(this)
    this.handleBoardNameChange = this.handleBoardNameChange.bind(this)
    this.handleOpenForm = this.handleOpenForm.bind(this)
    this.handleCloseForm = this.handleCloseForm.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  handleBoardNameChange(e) {
    this.setState({
      board_name: e.target.value,
    })
  }

  handleOpenForm() {
    this.setState({
      formOpen: true,
    })
  }
  handleCloseFormRename() {
    if (this.state.board_name === '') {
      this.setState({
        board_name: this.state.previous_board_name,
        formOpen: false,
      })
    } else {
      this.saveData()
      this.setState({
        formOpen: false,
        previous_board_name: this.state.board_name,
      })
    }
  }
  handleCloseForm() {
    this.setState({
      formOpen: false,
    })
  }

  handleMenuClick(e) {
    this.setState({
      anchorEl: e.currentTarget,
    })
  }

  handleMenuClose() {
    this.setState({
      anchorEl: null,
    })
  }

  isFormInvalid() {
    return this.state.board_name.length === 0
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  saveData() {
    if (this.state.board_name.match(/^ *$/) !== null) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du board ne peut pas être vide',
        'danger'
      )
      this.setState({
        board_name: this.props.board.name,
      })
      return
    } else if (
      this.props.boards.some((board) => board.name.trimEnd() === this.state.board_name.trimEnd())
    ) {
      this.props.addNotification(
        'Opération échouée',
        `Le board ${this.state.board_name} existe déjà`,
        'danger'
      )
      this.setState({
        board_name: this.props.board.name,
      })
      return
    }

    let body = {
      name: this.state.board_name,
    }
    this.setState({
      isLoading: true,
    })

    const notificationMessage = 'Board modifié'
    this.props.editItem(
      this.props.board.id,
      body,
      this.props.project.id,
      (id, response) => {
        if (response && response.data) {
          this.props.successNotification(id, response, notificationMessage)
        } else {
          console.error(
            'Erreur lors de la modification du board : la réponse ne contient pas de données.'
          )
        }
      },
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  render() {
    return (
      <>
        {
          <div {...this.props.handleProps}>
            <StyledDiv>
              <StyledDiv2>
                {this.state.formOpen ? (
                  <ClickAwayListener
                    onClickAway={() => this.handleCloseFormRename()}
                  >
                    <form
                      className="flex w-full"
                      onSubmit={() => {
                        this.saveData()
                        this.handleCloseForm()
                      }}
                    >
                      <TextField
                        name="title"
                        value={this.state.board_name}
                        onChange={this.handleBoardNameChange}
                        variant="outlined"
                        margin="none"
                        autoFocus
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                type="submit"
                                disabled={this.isFormInvalid()}
                              >
                                <Icon>check</Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </ClickAwayListener>
                ) : (
                  <Typography
                    onClick={() => this.handleOpenForm()}
                    style={{
                      Text: 16,
                      Font: 600,
                      Cursor: 'pointer',
                    }}
                  >
                    {this.state.board_name}
                  </Typography>
                )}
              </StyledDiv2>
              <div>
                <IconButton
                  aria-label="Menu du board"
                  aria-owns={this.state.anchorEl ? 'actions-menu' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenuClick}
                  variant="outlined"
                  size="small"
                >
                  <Icon style={{ Text: 20 }}>more_vert</Icon>
                </IconButton>
                <Menu
                  id="actions-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                >
                  {checkUserPrivileges(this.props.user, this.props.project) ? (
                    <div>
                      {(this.props.board.tasks == null ||
                        this.props.board.tasks.length === 0) && (
                        <MenuItem
                          onClick={() => {
                            this.state.dialog
                              ? this.handleCloseDialog()
                              : this.handleOpenDialog()
                          }}
                        >
                          <ListItemIcon style={{ minWidth: 40 }}>
                            <Icon>delete</Icon>
                            <Dialog
                              open={this.state.dialog}
                              onClose={this.handleCloseDialog}
                            >
                              <DialogTitle>
                                Confirmation de suppression
                              </DialogTitle>
                              <DialogContent>
                                Êtes-vous sûr(e) de vouloir supprimer le board "
                                {this.state.board_name}" ?
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  aria-label="Fermer la modal"
                                  color="secondary"
                                  onClick={() => {
                                    this.handleMenuClose()
                                    this.handleCloseDialog()
                                  }}
                                >
                                  Fermer
                                </Button>
                                <Button
                                  aria-label="Supprimer le board"
                                  color="primary"
                                  onClick={() => {
                                    this.closeAction = () =>
                                      this.props.click &&
                                      this.props.click(this.props.board.id)
                                    this.setState({
                                      isLoading: true,
                                    })
                                    const notificationMessage = 'Board supprimé'
                                    this.props
                                      .deleteItem(
                                        this.props.board.id,
                                        this.props.project.id,
                                        (id, response) =>
                                          this.props.successNotification(
                                            id,
                                            response,
                                            notificationMessage
                                          ),
                                        (id, error) =>
                                          this.props.failureNotificationApi(
                                            id,
                                            error
                                          )
                                      )
                                      .then(() => {
                                        this.setState({ isLoading: false })
                                      })
                                    this.handleMenuClose()
                                    this.handleCloseDialog()
                                  }}
                                >
                                  Supprimer
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </ListItemIcon>
                          <ListItemText primary="Supprimer le board" />
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          this.handleOpenForm()
                          this.handleMenuClose()
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 40 }}>
                          <Icon>edit</Icon>
                        </ListItemIcon>
                        <ListItemText primary="Renommer le board" />
                      </MenuItem>
                    </div>
                  ) : (
                    this.props.board !== this.props.firstBoard && (
                      <MenuItem>
                        <Icon style={{ color: 'red' }}>block</Icon>
                      </MenuItem>
                    )
                  )}

                  {this.props.firstBoard.name === this.props.board.name && (
                    <AddTask
                      board={this.props.board}
                      filters={this.props.filters}
                      doClose={this.handleMenuClose}
                    />
                  )}
                </Menu>
              </div>
            </StyledDiv>
          </div>
        }
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteItem: (id, projectId, successCallback, failureCallback) =>
    dispatch(deleteBoard(id, projectId, successCallback, failureCallback)),
  editItem: (id, body, projectId, successCallback, failureCallback) =>
    dispatch(
      updateBoard(id, body, projectId, successCallback, failureCallback)
    ),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default connect(null, mapDispatchToProps)(EditBoard)
