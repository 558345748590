import React, { useCallback, useEffect, useState } from 'react'
import Theme from './components/Theme'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import {
  Admin,
  Consums,
  Dashboard,
  Login,
  NewProject,
  NotFoundPage,
  EditProject,
  EditTask,
  ProjectTasks,
  Projects,
  Profile,
  Statistics,
  Roadmap,
  ProjectConsums,
} from './pages'
import routes from './config/routes'
import { PrivateRoute } from './components'
import { Toaster } from './components'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import 'moment/locale/fr'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getUserInfos } from './helpers/connexion'
import { history } from './store'
import { InteractionStatus } from '@azure/msal-browser'
import { WindowSpinner } from './components'

moment.locale('fr')

const App = () => {
  const location = useLocation()
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()
  const [userInfos, setUserInfos] = useState(null)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  // Create a callback for redirection to logout page
  // Can be modify for login page perhaps ?
  const logoutCallback = useCallback(() => {
    dispatch(push(routes.logout))
  }, [dispatch])
  // const logoutCallback = () => {
  //   dispatch(push(routes.logout))
  // }

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true)
      getUserInfos({ onLogoutCallback: logoutCallback })
        .then((infos) => {
          setUserInfos(infos)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }, [isAuthenticated, logoutCallback])

  useEffect(() => {
    if (
      !isAuthenticated &&
      location.pathname !== routes.login &&
      inProgress === InteractionStatus.None
    ) {
      history.push(routes.login)
    }
  }, [location.pathname, inProgress, isAuthenticated])
  // inProgress provoque un déclenchement du hook en cascade (3 fois). ça ne devrait pas arriver.
  // Le supprimer des dependencies est la solution ? Il faudrait voir l'impact sur l'history store
  // Si ça ne gère que l'ajout d'un event dans l'history. On peut bypasser ça et le retravailler plus tard.
  // Je sépare les deux useEffect pour plus de propreté

  return isLoading ? (
    <WindowSpinner />
  ) : (
    <ThemeProvider theme={Theme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={'fr'}
      >
        <Toaster />
        {userInfos ? (
          <Switch>
            <Route
              exact
              path={routes.home}
              component={() => (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                  }}
                />
              )}
            />
            <PrivateRoute path={routes.dashboard} component={Dashboard} />
            <PrivateRoute path={routes.admin} component={Admin} />
            <PrivateRoute path={routes.consums} component={Consums} />
            <PrivateRoute
              path={routes.newProject + '/:projecttype(forfait|agile|tma)'}
              component={NewProject}
            />
            <PrivateRoute
              path={routes.projectConsums + '/:id'}
              component={ProjectConsums}
            />
            <PrivateRoute
              path={routes.projectTasks + '/:id'}
              component={ProjectTasks}
            />
            <PrivateRoute
              path={routes.editProject + '/:id'}
              component={EditProject}
            />
            <PrivateRoute
              path={routes.editTask + '/:id'}
              component={EditTask}
            />
            <PrivateRoute path={routes.projects} component={Projects} />
            <PrivateRoute path={routes.profile} component={Profile} />
            <PrivateRoute
              path={routes.statistics + '/:id'}
              component={Statistics}
            />
            <PrivateRoute path={routes.roadmap} component={Roadmap} />
            <PrivateRoute
              path={routes.gitlabAPI + '/:id'}
              component={Roadmap}
            />
            <PrivateRoute
              path={routes.gitlabFront + '/:id'}
              component={Roadmap}
            />
            <Route path={routes.login} component={Login} />
            <Route component={NotFoundPage} />
          </Switch>
        ) : (
          <Route path={routes.login} component={Login} />
        )}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
