import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { findItem, getBillStatuses, isFetching } from '../../../../reducers'
import { itemTypes } from '../../../../actions/itemTypes'
import {
  deleteFormEntity,
  postFormEntity,
  updateFormEntity,
} from '../../../../actions/globalActions'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import StyledCheckbox from './styles/StyledCheckbox'

/**
 * Props :
 *    - bill, optionnal, the edited bill
 *    - project, the project of the bill
 *    - disabled, optionnal, is this modal disabled
 *
 * Store :
 *    - Bills
 *    - BillStatuses
 */
class BillsDialog extends React.Component {
  closeAction = () => {}

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModal: false,
      closeAll: false,
      selectedStatus: this.props.bill && this.props.bill.bill_status.id,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
    this.handleSelectedStatus = this.handleSelectedStatus.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleDelete() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: false,
    })
  }

  toggleAll() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: true,
    })
  }

  handleSelectedStatus(event) {
    this.setState({
      selectedStatus: event.target.value,
    })
  }

  handleValidSubmit(bill_status, amount, billing_check) {
    let errorMessages = []

    let correctAmount = amount && amount > 0
    let temp = parseFloat(amount)
    amount = temp.toString()
    if (!correctAmount) errorMessages.push('Veuillez entrer un montant positif')

    if (correctAmount) {
      if (!this.props.bill) {
        this.closeAction = () => {
          const notificationMessage = 'Facture ajoutée'
          this.props.addBill(
            {
              project: this.props.project,
              bill_status,
              amount,
              billing_check,
            },
            this.props.project.id,
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
        }
      } else {
        this.closeAction = () => {
          const notificationMessage = 'Facture modifiée'
          this.props.updateBill(
            this.props.bill.id,
            this.props.project.id,
            {
              bill_status,
              amount,
              billing_check,
            },
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
        }
      }
      this.toggle()
    } else {
      for (let message of errorMessages)
        this.props.addNotification('Opération échouée', message, 'danger')
    }
  }

  getText() {
    return this.props.bill ? editText : addText
  }

  render() {
    return (
      <>
        <IconButton
          disabled={this.props.disabled}
          aria-label="ajouter"
          onClick={this.toggle}
          size="small"
        >
          {this.props.bill ? <Icon>edit</Icon> : <Icon>add</Icon>}
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          centered
          onExit={() => this.closeAction()}
        >
          <DialogTitle toggle={this.toggle}>
            {this.getText().header}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel for="statusInput" sm={3}>
                  Statut
                </FormLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="statusInput"
                  id="statusInput"
                  value={this.state.selectedStatus}
                  onChange={this.handleSelectedStatus}
                  style={{ width: '100%' }}
                >
                  {this.props.billStatuses.map((billStatus) => (
                    <MenuItem value={billStatus.id} key={billStatus.id}>
                      {billStatus.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <FormLabel for="amountInput" sm={3}>
                  Montant
                </FormLabel>
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="number"
                  name="amountInput"
                  id="amountInput"
                  min={0}
                  defaultValue={this.props.bill && this.props.bill.amount}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel for="toChargeInput" sm={3}>
                  A facturer
                </FormLabel>
              </Grid>
              <Grid item xs={6}>
                <StyledCheckbox name="toChargeInput" id="toChargeInput" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="justify-content-center">
            <Button
              color="secondary"
              onClick={() => {
                this.closeAction = () => {}
                this.toggle()
                this.setState({
                  selectedStatus:
                    this.props.bill && this.props.bill.bill_status.id,
                })
              }}
            >
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.handleValidSubmit(
                  this.props.findBillStatus(this.state.selectedStatus),
                  document.getElementById('amountInput').value,
                  document.getElementById('toChargeInput').checked
                )
              }}
            >
              {this.getText().footer}
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.bill && (
          <>
            <IconButton
              aria-label="supprimer cette facture"
              onClick={this.toggleDelete}
              size="small"
            >
              <Icon>delete</Icon>
            </IconButton>
            <Dialog
              open={this.state.deleteModal}
              toggle={this.toggleDelete}
              onExit={() => this.closeAction()}
              centered
            >
              <DialogTitle>Confirmation de suppression</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes-vous sûr(e) de vouloir supprimer cette facture ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={this.toggleDelete}>
                  Annuler
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.closeAction = () => {
                      const notificationMessage = 'Facture supprimée'
                      this.props.deleteBill(
                        this.props.bill.id,
                        this.props.project.id,
                        (id, response) =>
                          this.props.successNotification(
                            id,
                            response,
                            notificationMessage
                          ),
                        (id, error) =>
                          this.props.failureNotificationApi(id, error)
                      )
                    }
                    this.toggleDelete()
                  }}
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    )
  }
}

const addText = {
  header: "Ajout d'une facture",
  footer: 'Ajouter',
}

const editText = {
  header: "Edition d'une facture",
  footer: 'Enregistrer',
}

const mapStateToProps = (state) => ({
  findBillStatus: (id) => findItem(state, itemTypes.billStatuses, id),
  billStatuses: getBillStatuses(state),
  isFetching: isFetching(state),
})

const mapDispatchToProps = (dispatch) => ({
  addBill: (bill, projectId, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.bills,
        bill,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  updateBill: (id, projectId, bill, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.bills,
        id,
        bill,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  deleteBill: (id, projectId, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.bills,
        id,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BillsDialog)
