import {
  ACTION_ADD_NOTIFICATION,
  ACTION_CLEAR_NOTIFICATIONS,
} from '../../actions/actionTypes'
import { status } from '../../constants/global'

const initialState = {
  items: [],
}

export const notificationsReducer = (state = initialState, action) => {
  if (action.status === status.IS_FETCHING || action.status === status.FAILURE)
    return state
  switch (action.type) {
    case ACTION_ADD_NOTIFICATION:
      return Object.assign({}, state, { items: [...state.items, action.data] })
    case ACTION_CLEAR_NOTIFICATIONS:
      return Object.assign({}, state, { items: [] })
    default:
      return state
  }
}

export const getNotifications = (state) => {
  return state.items
}
