import React from 'react'
import StyledP from './styles/StyledP'
import StyledDiv from './styles/StyledDiv'
import StyledBadge from './styles/StyledBadge'
import { withTheme } from '@material-ui/core'

class BadgeCounter extends React.Component {
  render() {
    const red = this.props.theme.palette.badge.red
    const green = this.props.theme.palette.badge.green
    const color = this.props.colored
      ? Math.sign(this.props.number) === 1
        ? green
        : Math.sign(this.props.number) === -1
        ? red
        : null
      : null

    return (
      <StyledDiv>
        <StyledP>{this.props.title}</StyledP>
        <StyledBadge color={color}>{this.props.number}</StyledBadge>
      </StyledDiv>
    )
  }
}

export default withTheme(BadgeCounter)
