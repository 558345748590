import * as React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class DeleteReferenceDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isProcessing: false,
    }
  }

  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleDeleteReference = () => {
    this.props.onDelete()
    this.handleClose()
  }

  render() {
    return (
      <>
        <IconButton
          size="small"
          aria-label="supprimer la référence"
          onClick={this.handleOpen}
        >
          <Icon>delete</Icon>
        </IconButton>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby={'delete-ref-dialog-title'}
          aria-describedby={'delete-ref-dialog-description'}
        >
          <DialogTitle id={'delete-ref-dialog-title'}>
            Confirmation de suppression
          </DialogTitle>

          <DialogContent>
            <DialogContentText id={'delete-ref-dialog-description'}>
              Êtes-vous sûr(e) de vouloir supprimer la référence vers la tâche "
              {this.props.task.name}" ?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={this.handleClose}>
              Fermer
            </Button>

            <Button color="primary" onClick={this.handleDeleteReference}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default DeleteReferenceDialog
