import React from 'react'
import StyledDiv from './styles/StyledDiv'
import { UserThumbnail } from '..'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import UserPreview from '../../pages/ProjectTasks/components/UserPreview'
import {
  isAgileProjectType,
  isTMAProjectType,
  USERS_CARROUSEL_NB_ITEMS,
  userThumbnailTypes,
} from '../../constants/global'
import {
  checkUserAdminPrivileges,
  checkUserPrivileges,
  getUser,
} from '../../helpers'
import Icon from '@material-ui/core/Icon'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { fetchProjectById } from '../../actions/requests/projectsActions'

function getColors() {
  return {
    tma: '#A2CF6E',
    forfait: '#33C9DC',
    agile: '#FFCD38',
  }
}

function getUserColor() {
  return {
    ROLE_DEV: '#F08303',
    ROLE_CP: '#22DD8D',
    ROLE_DP: '#9819D9',
  }
}

class PreviewProject extends React.Component {
  visibleSlides = USERS_CARROUSEL_NB_ITEMS
  slideNaturalWidth = 40

  constructor(props) {
    super(props)

    this.state = {
      currentUser: getUser(),
      currentSprint: {},
      startDate: null,
      endDate: null,
    }
  }

  getCurrentSprint() {
    if (!isAgileProjectType(this.props.project.project_type)) return null
    let sprints = []
    for (let sprint of this.props.project.sprints) {
      sprints = [...sprints, sprint.number]
    }

    let currentSprint = this.props.project.sprints.find(
      (sprint) => parseInt(sprint.number) === Math.max(...sprints)
    )
    if (currentSprint.start && currentSprint.end) {
      const currentDate = new Date().getTime()
      const startDate = new Date(currentSprint.start).getTime()
      const endDate = new Date(currentSprint.end).getTime()

      if (currentDate < startDate) return { ...currentSprint, progress: 0 }
      if (currentDate > endDate) return { ...currentSprint, progress: 100 }

      let progress
      const sprintDuration = endDate - startDate
      progress =
        ((sprintDuration - (endDate - currentDate)) / sprintDuration) * 100

      return { ...currentSprint, progress }
    }

    return { ...currentSprint, progress: 0 }
  }

  render() {
    if (!this.props.project) return null

    let canEdit = checkUserAdminPrivileges(this.state.currentUser)
    let sliced_project_name = this.props.project.project_name
    let sliced_client_name = this.props.project.client_name

    if (this.props.project.project_name && sliced_project_name.length > 15)
      sliced_project_name = `${sliced_project_name.slice(0, 15)}...`

    if (this.props.project.client_name && sliced_client_name.length > 50)
      sliced_client_name = `${sliced_client_name.slice(0, 47)}...`

    const currentSprint = this.getCurrentSprint()
    const users = this.props.project.users

    return (
      <StyledDiv className={this.props.className}>
        <Card elevation={1}>
          <div
            style={{
              background: getColors()[this.props.project.project_type.codename],
              padding: '0 10px 5px',
            }}
          >
            <table style={{ width: '100%', tableLayout: 'auto' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ textAlign: 'left' }}
                      noWrap
                    >
                      {this.props.project.finished &&
                      !checkUserPrivileges(
                        this.state.currentUser,
                        this.props.project
                      ) ? (
                        <div>{sliced_project_name} </div>
                      ) : this.props.project.enabled &&
                        !this.props.project.finished ? (
                        <button
                          aria-label={`aller au projet ${this.props.project.project_name}`}
                          className="invisible-button"
                          onClick={this.props.click}
                        >
                          {sliced_project_name}
                        </button>
                      ) : (
                        <button
                          aria-label={`aller au projet ${this.props.project.project_name}`}
                          className="invisible-button"
                          onClick={this.props.click}
                        >
                          {sliced_project_name} (clôturé)
                        </button>
                      )}
                    </Typography>
                  </td>
                  <td align={'right'}>
                    <Typography variant="button" color="textSecondary">
                      <div>
                        {this.props.project.project_type.codename.toUpperCase()}
                      </div>
                    </Typography>
                  </td>
                </tr>
                <tr align="right">
                  <td colSpan="2" align={'right'}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      style={{ textAlign: 'right' }}
                      noWrap
                    >
                      {sliced_client_name}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <CardContent>
            <CarouselProvider
              visibleSlides={
                this.visibleSlides >= users.length
                  ? this.visibleSlides
                  : this.visibleSlides + 0.2
              }
              totalSlides={users.length}
              step={this.visibleSlides}
              naturalSlideWidth={this.slideNaturalWidth}
              naturalSlideHeight={40}
              dragEnabled={false}
              className="w-100"
            >
              <div
                className="position-relative m-auto"
                style={{ maxWidth: `${this.slideNaturalWidth * 4.4}px` }}
              >
                <Slider
                  style={{
                    maxWidth: `${this.slideNaturalWidth * 4.4}px`,
                    height: '3rem',
                  }}
                >
                  {users.map((userSR, key) => {
                    return canEdit ? (
                      <Slide index={key} key={key}>
                        <UserPreview
                          key={userSR.id}
                          color={
                            userSR.user.enabled
                              ? getUserColor()[userSR.secondaryRole.codename]
                              : 'grey'
                          }
                          project={this.props.project}
                          user_sr={userSR}
                          onUpdate={() =>
                            this.props.fetchProjectById(this.props.project.id)
                          }
                          isDeletable={this.props.isDeletable}
                        />
                      </Slide>
                    ) : (
                      <Slide index={key} key={key}>
                        <UserThumbnail
                          key={userSR.id}
                          type={userThumbnailTypes.USER_SR}
                          data={userSR}
                          color={getUserColor()[userSR.secondaryRole.codename]}
                        />
                      </Slide>
                    )
                  })}
                </Slider>
                {users.length > this.visibleSlides && (
                  <>
                    <ButtonBack
                      aria-label="Utilisateurs précédents"
                      className="position-absolute p-0 button-arrow-sm"
                      style={{
                        top: '50%',
                        left: '-23px',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Icon>chevron_left</Icon>
                    </ButtonBack>
                    <ButtonNext
                      aria-label="Utilisateurs suivants"
                      className="position-absolute p-0 button-arrow-sm"
                      style={{
                        top: '50%',
                        right: '-20px',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Icon>chevron_right</Icon>
                    </ButtonNext>
                  </>
                )}
              </div>
            </CarouselProvider>
          </CardContent>

          <CardActions
            className="justify-center"
            style={{
              minHeight: '5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!isTMAProjectType(this.props.project.project_type) && (
              <Button
                onClick={this.props.click}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  color: 'rgb(37,36,36)',
                }}
              >
                {isAgileProjectType(this.props.project.project_type)
                  ? `Sprint ${currentSprint.number}`
                  : this.props.project.project_status.name}
              </Button>
            )}
          </CardActions>

          {!isTMAProjectType(this.props.project.project_type) &&
            (this.props.project.project_type.codename === 'agile' ? (
              <LinearProgress
                variant="determinate"
                value={currentSprint.progress}
                color="primary"
              />
            ) : (
              <LinearProgress
                variant="determinate"
                value={
                  (this.props.project.project_status.status_order * 100) /
                  this.props.statuses.filter((status) => !status.is_sprint)
                    .length
                }
                color="primary"
              />
            ))}
        </Card>
      </StyledDiv>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProjectById: (id) => dispatch(fetchProjectById(id)),
})

export default connect(null, mapDispatchToProps)(PreviewProject)
