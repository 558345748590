import React from 'react'
import { connect } from 'react-redux'
import {
  getOpenedProjects,
  getProjects,
  getProjectStatuses,
  getUserTasks,
  isFetching,
} from '../../reducers'
import {
  PageSpinner,
  PreviewProject,
  Section,
  StyledAttiContainer,
} from '../../components'
import { NewProjectMenu, UserTasksTable } from './components'
import attiphantWhite from '../../assets/img/attiphant-white.png'
import { checkUserPrivileges, getUser } from '../../helpers'
import { fetchTasksByUserId } from '../../actions/requests/tasksActions'
import { fetchProjectsByUserId } from '../../actions/requests/projectsActions'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { DASHBOARD_CARROUSEL_NB_ITEMS } from '../../constants/global'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import { fetchSecondaryRoles } from '../../actions/requests/secondaryRolesActions'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import { TextField, withWidth } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Search } from '@material-ui/icons'
import { fetchProjectTypes } from '../../actions/requests/projectTypesActions'

const AttiSwitch = withStyles({
  switchBase: {
    color: 'rgb(230,126,7)',
    '&$checked': {
      color: 'rgb(230,126,7)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgb(230,126,7)',
    },
  },
  checked: {},
  track: {},
})(Switch)

const styles = {
  trayWrapper: {
    textAlign: 'center',
  },
}

class Dashboard extends React.Component {
  slideNaturalWidth = 300
  slideNaturalHeight = 212

  constructor(props) {
    super(props)

    const allowedKeys = {
      ArrowLeft: 'left',
      ArrowUp: 'up',
      ArrowRight: 'right',
      ArrowDown: 'down',
      a: 'a',
      b: 'b',
    }
    const konamiCode = [
      'up',
      'up',
      'down',
      'down',
      'left',
      'right',
      'left',
      'right',
      'b',
      'a',
    ]

    this.allowedKeys = allowedKeys
    this.konamiCode = konamiCode
    this.konamiCodePosition = 0

    this.state = {
      isFirstRender: true,
      showClosedProjects: false,
      showAttiphant: false,
      isFetchingConsums: true,
      searchValue: '',
      usersLoading: true,
      showChargeClient: false
    }
  }

  componentDidMount() {
    Promise.all([
      this.props.fetchProjectTypes(),
      this.props.fetchProjectStatuses(),
      this.props.fetchSecondaryRoles(),
    ])

    this.props.fetchUserTasks(this.props.currentUser.id).then(() => {
      this.setState({
        isFetchingConsums: false,
      })
    })

    this.props
      .fetchUserProjects(this.props.currentUser.id)
      .then((response) => {
        this.setState({
          isFirstRender: false
        })
        return response
      })
      .catch((error) => error)

    let that = this
    document.addEventListener('keydown', function (e) {
      const key = that.allowedKeys[e.key]
      const requiredKey = that.konamiCode[that.konamiCodePosition]
      if (key === requiredKey) {
        that.konamiCodePosition++
        if (that.konamiCodePosition === that.konamiCode.length) {
          that.setState(
            {
              showAttiphant: true,
            },
            () => console.log('(∩ ͡° ͜ʖ ͡°)⊃━☆ﾟ. * ATTIPHANT !!!')
          )
          that.konamiCodePosition = 0
        }
      } else {
        that.konamiCodePosition = 0
      }
    })
  }

  getNbVisibleSlides = (maxSlides) => {
    switch (this.props.width) {
      case 'xs':
        return 1
      case 'sm':
        return 2
      default:
        return Math.min(DASHBOARD_CARROUSEL_NB_ITEMS, maxSlides)
    }
  }

  getProjects = () => {
    const { userProjects, currentUser } = this.props

    // Si le bouton d'affichage des projets terminés est coché
    if (this.state.showClosedProjects) {
      return userProjects.filter(
        // On garde seulement les projets auxquels le currentUser participe
        (p) => p.users.find((u) => u.user.id === currentUser.id) !== undefined
      )
    }
    // Si le bouton n'est pas coché
    return userProjects.filter(
      // Idem ici
      (p) => p.users.find((u) => u.user.id === currentUser.id) !== undefined && !p.finished
    )
  }

  render() {
    if (this.props.isFetching && this.state.isFirstRender)
      return <PageSpinner />

    const { classes } = this.props

    const projects = this.getProjects()
    const searchValue = this.state.searchValue.toLowerCase()
    const filteredProjects = projects.filter((p) =>
      p.project_name.toLowerCase().includes(searchValue)
    )
    const userTasks = this.props.userTasks.filter(
      (t) =>
        t.board.project.enabled &&
        !t.board.project.finished &&
        t.remaining_workload !== 0
    )

    const totalSlides = filteredProjects.length
    const nbVisibleSlides = this.getNbVisibleSlides(totalSlides)
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tableau de bord - Attiprod </title>
        </Helmet>
        {this.state.showAttiphant && (
          <div>
            <div className="slide-far-right">
              <img
                src={attiphantWhite}
                className="elephant-animation"
                alt="Logo AttiProd"
              />
            </div>
          </div>
        )}
        <Header title="Tableau de bord" user={this.props.currentUser} />

        <Container className="d-flex justify-content-center">
          <Grid
            container
            className="mt-2 mb-2 d-flex justify-content-between btn-sm w-100"
          >
            <Grid item className="d-inline-block content-left">
              <Typography variant="h6">Mes projets</Typography>
            </Grid>
            {checkUserPrivileges(this.props.currentUser) && (
              <Grid item className="d-flex align-items-center">
                <NewProjectMenu className="btn-sm" />
              </Grid>
            )}
            <Grid item className="d-flex align-items-center">
              <TextField
                variant={'outlined'}
                size={'small'}
                placeholder={'Nom du projet'}
                value={this.state.searchValue}
                onChange={(e) => this.setState({ searchValue: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: '#f2f2f2' },
                }}
                style={{ marginRight: '16px' }}
              />

              <FormControlLabel
                control={
                  <AttiSwitch
                    checked={this.state.showClosedProjects}
                    onChange={(e) => {
                      this.setState({
                        showClosedProjects: e.target.checked,
                      })
                    }}
                    name="switchProjects"
                    id="switchProjects"
                    color="primary"
                  />
                }
                label="Projets terminés"
                style={{ marginBottom: 0 }}
              />
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Grid container>
            <Grid item xs={12} className={'d-flex justify-content-center'}>
              {/*TODO: Checker si on peut mettre un bout de diapo avec pure-react-carousel*/}
              <CarouselProvider
                isIntrinsicHeight={true}
                naturalSlideWidth={this.slideNaturalWidth}
                naturalSlideHeight={this.slideNaturalHeight}
                totalSlides={totalSlides}
                step={nbVisibleSlides}
                visibleSlides={nbVisibleSlides}
                dragEnabled={false}
                currentSlide={totalSlides <= nbVisibleSlides && 0}
                style={{
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Slider classNameTrayWrap={classes.trayWrapper}>
                  {filteredProjects &&
                    filteredProjects.map((project, index) => (
                      <Slide index={index} key={index}>
                        <PreviewProject
                          click={() => {
                            this.props.redirectToProjectTasksPage(project.id)
                          }}
                          className="preview-project"
                          key={project.id}
                          project={project}
                          statuses={this.props.statuses}
                          usersLoading={this.state.usersLoading}
                          onLoaded={() =>
                            this.setState({ usersLoading: false })
                          }
                          isDeletable={false}
                        />
                      </Slide>
                    ))}
                </Slider>
                {totalSlides > nbVisibleSlides && (
                  <>
                    <ButtonBack
                      className="position-absolute button-arrow"
                      style={{
                        top: '50%',
                        left: '-30px',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Icon fontSize="large">chevron_left</Icon>
                    </ButtonBack>
                    <ButtonNext
                      className="position-absolute button-arrow"
                      style={{
                        top: '50%',
                        right: '-40px',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Icon fontSize="large">chevron_right</Icon>
                    </ButtonNext>
                  </>
                )}
              </CarouselProvider>
            </Grid>
          </Grid>
        </Container>

        <StyledAttiContainer>
          <Section title={'Mes tâches'}>
            <Container>
              {this.state.isFetchingConsums ? (
                <div className={'d-flex justify-content-center'}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {userTasks.length === 0 ? (
                    <div className={'d-flex justify-content-center'}>
                      <Typography>Aucune tâche à afficher</Typography>
                    </div>
                  ) : (
                    <UserTasksTable
                      onProjectClick={(id) => {
                        localStorage.setItem('showChargeClient', this.state.showChargeClient + "")
                        this.props.redirectToProjectTasksPage(id)
                      }}
                      onTaskClick={(id) =>
                        this.props.redirectToEditTaskPage(id)
                      }
                      tasks={userTasks}
                    />
                  )}
                </>
              )}
            </Container>
          </Section>
        </StyledAttiContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
  currentUser: getUser(),
  userProjects: getProjects(state),
  userOpenedProjects: getOpenedProjects(state),
  userTasks: getUserTasks(state, getUser().id),
  statuses: getProjectStatuses(state),
})

const mapDispatchToProps = (dispatch) => ({
  // FETCHES
  fetchSecondaryRoles: () => dispatch(fetchSecondaryRoles()),
  fetchProjectTypes: () => dispatch(fetchProjectTypes()),
  fetchUserProjects: (id) => dispatch(fetchProjectsByUserId(id)),
  fetchUserTasks: (id) => dispatch(fetchTasksByUserId(id)),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
  // REDIRECTS
  redirectToEditTaskPage: (id) => dispatch(push(routes.editTask + '/' + id)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(Dashboard)))
