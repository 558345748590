import {
  clearErrors,
  deleteEntity,
  fetchAllEntities,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchBills = () => {
  return fetchAllEntities(itemTypes.bills)
}

export const updateBill = (id, body) => {
  return updateEntity(itemTypes.bills, id, body)
}

export const deleteBill = (id) => {
  return deleteEntity(itemTypes.bills, id)
}

export const postBill = (body) => {
  return postEntity(itemTypes.bills, body)
}

export const fetchBillsByProjectId = (id) => {
  return fetchEntity(otherURLs.projectBills, id, id)
}

export const clearErrorBills = () => clearErrors(itemTypes.bills)
