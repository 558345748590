import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../../../../components'
import { getSecondaryRolesPrices } from '../../../../reducers'
import { PricesDialog } from '..'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

/**
 * Props :
 *    - project, the edited project
 *    - disabled
 * Store :
 *    - SRPrices
 */
class PricesSection extends Component {
  render() {
    return (
      <Section title={'Tarif'}>
        <TableContainer style={{ height: '15rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledCell>Profil</StyledCell>
                <StyledCell>Montant (€)</StyledCell>
                <StyledCell>
                  <PricesDialog
                    disabled={this.props.disabled}
                    project={this.props.project}
                    srPrices={this.props.srPrices}
                  />
                </StyledCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.srPrices.map((sr_price) => (
                <TableRow key={sr_price.id}>
                  <StyledCell>{sr_price.secondaryRole.name}</StyledCell>
                  <StyledCell>{sr_price.price}</StyledCell>
                  <StyledCell>
                    <PricesDialog
                      disabled={this.props.disabled}
                      project={this.props.project}
                      price={sr_price}
                      srPrices={this.props.srPrices}
                    />
                  </StyledCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  srPrices: getSecondaryRolesPrices(state, ownProps.project.id),
})

export default connect(mapStateToProps)(PricesSection)
