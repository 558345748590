import * as React from 'react'
import { connect } from 'react-redux'
import {
  failureNotificationApi,
  successNotification,
} from '../../../actions/temporaries/notificationActions'
import { deleteUserSkills } from '../../../actions/requests/userSkillsActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

/**
 * Props :
 *    - skillExp, the edited skillExp
 */
class DeleteSkill extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
    this.toggle = this.toggle.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  saveData(skillExp) {
    const notificationMessage = 'Compétence supprimée'
    this.props.deleteUserSkills(
      skillExp.id,
      (id, response, message) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  render() {
    const skillExp = this.props.skillExp
    return (
      <>
        <IconButton
          aria-label="supprimer cette compétence"
          onClick={this.toggle}
        >
          <Icon>delete</Icon>
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle ? this.toggle : undefined}
          onExit={() => this.closeAction()}
        >
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer la compétence "
            {skillExp.skill.name}" ?
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.toggle}>
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.closeAction = () => this.saveData(skillExp)
                this.toggle()
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteUserSkills: (id, successCallback, failureCallback) =>
    dispatch(deleteUserSkills(id, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(DeleteSkill)
