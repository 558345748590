import * as React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { getTask } from '../../reducers'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../actions/temporaries/notificationActions'
import { fetchConsumsByTaskId } from '../../actions/requests/consumsActions'
import { fetchTask, updateTask } from '../../actions/requests/tasksActions'
import { fetchProjectById } from '../../actions/requests/projectsActions'
import { Section } from '../../components'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import {checkUserPrivileges, getShowChargeClient, getUser} from '../../helpers'
import PageSpinner from '../../components/PageSpinner'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Header from '../../components/Header'
import { isForfaitProjectType } from '../../constants/global'
import { fetchSprintsByProjectId } from '../../actions/requests/sprintsActions'
import { fetchBoardsByProjectId } from '../../actions/requests/boardsActions'
import HeaderEditableTitle from '../../components/HeaderEditableTitle'
import {
  AssignSection,
  CommentSection,
  ConsumSection,
  DescriptionSection,
  LabelSection,
  ReferenceSection,
  WorkloadSection,
} from './components'
import { fetchUsersByProjectId } from '../../actions/requests/usersActions'

class EditTask extends React.Component {
  constructor(props) {
    super(props)

    if (
      this.props.match.params.id === null ||
      this.props.match.params.id === undefined
    ) {
      this.props.redirectToDashboardPage()
    }

    this.state = {
      boards: [],
      currentUser: null,
      isEdittingName: false,
      isLoading: true,
      isSavingName: false,
      name: '',
      previousName: '',
      project: null,
      isSaveDisable: true,
      showChargeClient: false,
    }
  }

  componentDidMount() {
    const promises = [
      this.props.fetchConsumsByTaskId(this.props.match.params.id),
      this.props.fetchTask(this.props.match.params.id),
    ]

    const showChargeClient = getShowChargeClient()

    Promise.all(promises).then(() => {
      ;(async () => {
        const projectResponse = await this.props.fetchProject(
          this.props.task.board.project.id
        )
        const boardsResponse = await this.props.fetchBoardsByProjectId(
          projectResponse.data.id
        )
        await this.props.fetchSprintsByProjectId(projectResponse.data.id)
        await this.props.fetchUsersByProjectId(projectResponse.data.id)

        this.setState({
          boards: boardsResponse.data,
          currentUser: getUser(),
          isLoading: false,
          name: this.props.task.name,
          previousName: this.props.task.name,
          project: projectResponse.data,
          showChargeClient: showChargeClient,
        })
      })()
    })
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    if (
      name === 'name' &&
      (this.state['previousName'] === value || value === '')
    ) {
      this.setState({
        isSaveDisable: true,
      })
    } else {
      this.setState({
        isSaveDisable: false,
      })
    }

    if (name === 'name' && value === ' ') {
      this.setState({
        name: this.state['previousName'],
      })
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  handleNameSave = () => {
    if (this.state.previousName === this.state.name || !this.checkField())
      return

    this.toggleState('isSavingName')

    const notificationMessage = 'Nom de la tâche modifié'
    this.props
      .editTask(
        this.props.task.id,
        {
          name: this.state.name,
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleChangeState('previousName', this.state.name)
        this.toggleState('isSavingName')
        this.toggleState('isSaveDisable')
        this.toggleState('isEdittingName')
      })
  }

  checkField = () => {
    if (this.state.name === '') {
      this.props.addNotification(
        'Opération échouée',
        'Le nom de la tâche ne peut pas être vide',
        'danger'
      )

      return false
    }

    return true
  }

  userCanEditName = () => {
    return (
      checkUserPrivileges(this.state.currentUser, this.state.project) ||
      !isForfaitProjectType(this.state.project.project_type)
    )
  }

  render() {
    if (this.state.isLoading) return <PageSpinner />

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Edition d'une tâche - Attiprod </title>
        </Helmet>

        <Header
          editableTitle={
            <HeaderEditableTitle
              canEditTitle={this.userCanEditName()}
              isEdittingTitle={this.state.isEdittingName}
              isSavingTitle={this.state.isSavingName}
              onTitleChange={({ target: { name, value } }) =>
                this.handleChangeState(name, value)
              }
              onTitleEditted={() => this.handleNameSave()}
              onToggleEdit={() => this.toggleState('isEdittingName')}
              title={this.state.name}
              isSaveDisable={this.state.isSaveDisable}
            />
          }
          startIcon={
            <IconButton
              aria-label="retour au projet"
              style={{ color: 'rgb(148,139,150,1)' }}
              onClick={() => {
                this.props.redirectToProjectTasksPage(
                  this.props.task.board.project.id
                )
              }}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          }
          user={this.state.currentUser}
        />

        <Grid container>
          <Grid item xs={12} sm={4} md={3}>
            <Section
              title={'Assigner'}
              icon={<Icon>person</Icon>}
              style={{ margin: '1rem', minHeight: '210px' }}
            >
              <AssignSection
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
                minimized={false}
              />
            </Section>
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            <Section
              title={'Description'}
              icon={<Icon>description</Icon>}
              style={{ margin: '1rem', minHeight: '210px' }}
            >
              <DescriptionSection
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
              />
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Section
              title={'Charges'}
              icon={<Icon>timer</Icon>}
              style={{ margin: '1rem' }}
            >
              <WorkloadSection
                boards={this.state.boards}
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
                showChargeClient={this.state.showChargeClient}
              />
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Section
              title={'Saisies'}
              icon={<Icon>input</Icon>}
              style={{ margin: '1rem' }}
            >
              <ConsumSection
                users={this.state.user}
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
              />
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Section
              title={'Références'}
              icon={<Icon>link</Icon>}
              style={{ margin: '1rem' }}
            >
              <ReferenceSection
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
              />
            </Section>
          </Grid>

          <Grid item xs={12} md={6}>
            <Section
              title={'Label'}
              icon={<Icon>label</Icon>}
              style={{ margin: '1rem' }}
            >
              <LabelSection
                currentUser={this.state.currentUser}
                project={this.state.project}
                task={this.props.task}
              />
            </Section>
          </Grid>
        </Grid>

        <Section
          title={'Commentaires'}
          icon={<Icon>comment</Icon>}
          style={{ margin: '1rem' }}
        >
          <CommentSection
            currentUser={this.state.currentUser}
            task={this.props.task}
          />
        </Section>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  task: getTask(state, ownProps.match.params.id),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Fetches
  fetchBoardsByProjectId: (id) => dispatch(fetchBoardsByProjectId(id)),
  fetchConsumsByTaskId: (id) => dispatch(fetchConsumsByTaskId(id)),
  fetchProject: (id) => dispatch(fetchProjectById(id)),
  fetchSprintsByProjectId: (id) => dispatch(fetchSprintsByProjectId(id)),
  fetchUsersByProjectId: (id) => dispatch(fetchUsersByProjectId(id)),
  fetchTask: (id) => dispatch(fetchTask(id)),
  //Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  // Redirects
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTask)
