import React from 'react'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { generateUUID } from '../../../../helpers'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Search } from '@material-ui/icons'
import { CellWithModal } from '../index'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../../../config/routes'

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: 'white',
    '&:nth-of-type(even)': {
      backgroundColor: '#F2F2F2',
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: '1px solid lightgray',
    padding: '2px',
    textAlign: 'center',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '6px',
  },
}))(TableCell)

const getStyles = () => ({
  textHeadCell: {
    minWidth: '120px',
  },
  projectCell: {
    border: '1px solid lightgray',
    minWidth: '180px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

class MonthRecap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: '',
    }
  }

  sortConsumsByProject = () => {
    return this.props.consums.reduce(
      (acc, item) => ({
        ...acc,
        [item.task.board.project.project_name]: [
          ...(acc[item.task.board.project.project_name] || []),
          item,
        ],
      }),
      []
    )
  }

  getMonthConsums = (consums) => {
    return this.props.dates.map((date) => {
      const dateConsums = consums.filter((c) => {
        let d = new Date(c.date)
        d.setHours(0, 0, 0, 0)
        return d.getTime() === date.getTime()
      })
      return {
        consums: dateConsums,
        total: dateConsums.reduce((acc, item) => acc + item.amount, 0),
      }
    })
  }

  render() {
    const { classes } = this.props

    const sortedConsums = this.sortConsumsByProject()
    const searchValue = this.state.searchValue.toLowerCase()
    const sortedConsumsArray = Object.entries(sortedConsums).filter(([key]) =>
      key.toLowerCase().includes(searchValue)
    )

    return (
      <>
        <TextField
          variant={'outlined'}
          size={'small'}
          placeholder={'Nom du projet'}
          value={this.state.searchValue}
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: { backgroundColor: 'white' },
          }}
          style={{ paddingBottom: '16px' }}
        />
        <TableContainer style={{ maxHeight: 33 * 6 }}>
          <Table aria-label={'recap table'} size={'small'} stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.textHeadCell}>
                  Nom du projet
                </StyledTableCell>

                <StyledTableCell className={'font-weight-bold'}>
                  Total
                </StyledTableCell>

                {this.props.dates.map((date) => {
                  const day = date.getDate()
                  return <StyledTableCell key={day}>{day}</StyledTableCell>
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.isLoading ? (
                <TableRow>
                  <StyledTableCell
                    colSpan={this.props.dates.length + 2}
                    style={{ textAlign: 'center' }}
                  >
                    <CircularProgress />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {sortedConsumsArray.map(([key, value]) => {
                    const monthConsums = this.getMonthConsums(value)
                    const positiveConsums = monthConsums.filter(
                      (c) => c.total > 0
                    )
                    const projectId = value[0].task.board.project.id

                    if (monthConsums.find((c) => c.total > 0)) {
                      return (
                        <StyledTableRow key={generateUUID()}>
                          <TableCell
                            className={classes.projectCell}
                            onClick={() =>
                              this.props.redirectToProjectTasksPage(projectId)
                            }
                          >
                            {key}
                          </TableCell>

                          <CellWithModal
                            className={'font-weight-bold'}
                            consums={positiveConsums}
                            projectName={key}
                          >
                            {monthConsums.reduce((a, b) => a + b.total, 0)}
                          </CellWithModal>

                          {monthConsums.map((consum) => (
                            <StyledTableCell key={generateUUID()}>
                              {consum.total === 0 ? '-' : consum.total}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      )
                    }

                    return null
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(getStyles)(MonthRecap))
