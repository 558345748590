import { EventType, PublicClientApplication } from '@azure/msal-browser'
import * as constants from './global'

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
/*
msalInstance.acquireTokenSilent({
  scopes: ["User.Read"],
  redirectUri: "http://localhost:3000/blank.html",
});
*/

export const msalInstance = new PublicClientApplication(constants.MSAL_CONFIG)
export const tokenRequest = {
  scopes: ['profile openid email User.Read'],
  forceRefresh: false,
}
// Avoid being redirect to Azure AD logout
export const logoutRequest = {
  onRedirectNavigate: () => true,
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event))
  }
})
