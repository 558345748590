import React from 'react'
import { connect } from 'react-redux'
import { getComments } from '../../../../reducers'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import {
  fetchCommentsByTaskId,
  postComment,
} from '../../../../actions/requests/commentsActions'
import TextField from '@material-ui/core/TextField'
import ColorButton from '../../../../components/ColorButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import StyledDiv from '../styles/StyledDiv'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Comment from '../../../ProjectTasks/components/Comment'
import { StyledAttiContainer } from '../../../../components'
import moment from 'moment'

class CommentSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      comment: '',
      isLoading: true,
      isSavingComment: false,
    }

    this.toggleState = this.toggleState.bind(this)
    this.handleChangeState = this.handleChangeState.bind(this)
    this.handleCommentSave = this.handleCommentSave.bind(this)
    this.checkField = this.checkField.bind(this)
    this.isFieldDisabled = this.isFieldDisabled.bind(this)
  }

  componentDidMount() {
    const promises = [this.props.fetchCommentsByTaskId(this.props.task.id)]

    Promise.all(promises).then(() => {
      this.toggleState('isLoading')
    })
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleCommentSave = () => {
    this.toggleState('isSavingComment')
    if (!this.checkField()) return

    let body = {
      user: this.props.currentUser.id,
      task: this.props.task.id,
      text: this.state.comment,
    }

    const notificationMessage = 'Commentaire ajouté'
    this.props
      .addComment(
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleChangeState('comment', '')
        this.toggleState('isSavingComment')
      })
  }

  checkField = () => {
    if (this.state.comment === '') {
      this.props.addNotification(
        'Opération échouée',
        'Le champ "Commentaire" ne peut pas être vide',
        'danger'
      )

      return false
    }

    return true
  }

  isFieldDisabled = () => {
    return this.state.isSavingComment
  }

  compareCreationDates = (c1, c2) => {
    const d1 = moment(c1)
    const d2 = moment(c2)

    if (d1.isBefore(d2)) return -1
    if (d1.isAfter(d2)) return 1
    return 0
  }

  render() {
    if (this.state.isLoading) return null

    const comments = this.props.comments.sort((c1, c2) =>
      this.compareCreationDates(c2.creation, c1.creation)
    )

    return (
      <>
        <TextField
          type="Text"
          name="comment"
          id="comment"
          label="Ajouter un commentaire"
          placeholder="Ecrivez un commentaire"
          variant="outlined"
          multiline
          rows={4}
          rowsMax={10}
          fullWidth
          style={{ marginBottom: '0.5rem' }}
          disabled={this.isFieldDisabled()}
          value={this.state.comment}
          onChange={({ target: { value } }) =>
            this.handleChangeState('comment', value)
          }
        />

        <div className="d-flex justify-content-end">
          <div style={{ position: 'relative' }}>
            <ColorButton
              onClick={this.handleCommentSave}
              disabled={this.state.comment === '' || this.state.isSavingComment}
            >
              Envoyer
            </ColorButton>

            {this.state.isSavingComment && (
              <CircularProgress
                size={24}
                color="inherit"
                style={{
                  position: 'absolute',
                  left: '38.5%',
                  marginTop: '7px',
                }}
              />
            )}
          </div>
        </div>

        {comments.length > 0 ? (
          <div>
            <StyledDiv>
              <Icon style={{ marginRight: '5px' }}>list</Icon>
              <Typography style={{ fontWeight: 600 }}>Discussions</Typography>
            </StyledDiv>

            <List>
              {comments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  currentUser={this.state.currentUser}
                  task={this.props.task}
                />
              ))}
            </List>
          </div>
        ) : (
          <StyledAttiContainer className="m-0 p-4 text-center">
            Il n'y a pas de commentaires
          </StyledAttiContainer>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  comments: getComments(state).filter(
    (comment) => comment.task.id === ownProps.task.id
  ),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  addComment: (body, successCallback, failureCallback) =>
    dispatch(postComment(body, successCallback, failureCallback)),
  // Fetches
  fetchCommentsByTaskId: (id) => dispatch(fetchCommentsByTaskId(id)),
  // Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentSection)
