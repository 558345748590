import moment from 'moment'

const shortMonths = [
  'Jan',
  'Fév',
  'Mar',
  'Avr',
  'Mai',
  'Juin',
  'Juil',
  'Août',
  'Sep',
  'Oct',
  'Nov',
  'Déc',
]

export const isInvalidDate = (date) => {
  const day = moment(date).day()
  return day === 0 || day === 6
}

export const getDateFromDeliveryDate = (date) => {
  const [day, month, year] = date.split(' ')
  const index = shortMonths.indexOf(month)

  return new Date(parseInt(year), index, parseInt(day))
}
