import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { MdAdd } from 'react-icons/md'

class UserSelectionButton extends React.Component {
  render() {
    return (
      <Button
        aria-label="ajouter cet utilisateur"
        outline
        color="primary"
        size="sm"
        onClick={() => {
          this.props.onClick && this.props.onClick(this.props.user)
        }}
      >
        <MdAdd />
      </Button>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(null, mapDispatchToProps)(UserSelectionButton)
