import { updateEntity } from '../globalActions'
import { itemTypes } from '../itemTypes'

export const updatePreference = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.preferences,
    id,
    body,
    successCallback,
    failureCallback
  )
}
