import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { deleteProject } from '../../../../actions/requests/projectsActions'

const getButtonStyle = {
  textTransform: 'none',
  height: '2.4rem',
}

/**
 * Props :
 *    - project, the edited project
 *    - disabled
 */
class ProjectInfosDeleteDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      dialog: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  render() {
    return (
      <>
        <Button
          color="secondary"
          style={getButtonStyle}
          variant="outlined"
          disabled={this.props.disabled}
          onClick={this.handleOpenDialog}
        >
          Supprimer le projet
        </Button>
        <Dialog open={this.state.dialog} onClose={this.handleCloseDialog}>
          <DialogTitle>Suppression du projet</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirmez-vous la suppression du projet "
              {this.props.project.project_name}" ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleCloseDialog}>
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const notificationMessage = 'Projet supprimé'
                this.props.deleteProject(
                  this.props.project.id,
                  (id, response) =>
                    this.props.successNotification(
                      id,
                      response,
                      notificationMessage
                    ),
                  (id, error) => this.props.failureNotificationApi(id, error)
                )
              }}
            >
              Supprimer le projet
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteProject: (id, successCallback, failureCallback) =>
    dispatch(deleteProject(id, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(ProjectInfosDeleteDialog)
