import React, { Component } from 'react'
import { UserAvatar, UserSelectionButton } from '..'
import {
  userListButtonAction,
  USERS_LIST_PAGINATION_NB_ITEMS,
  USERS_LIST_PAGINATION_NB_MAX_PAGES_LINKS,
} from '../../constants/global'
import { EditUserDialog } from '../../pages/Admin/components'
import CustomPagination from '../CustomPagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

/**
 * Props :
 *    - omitted, required, users to not display (will be filtered from users)
 *    - buttonAction, required, action to do when selecting a user, either EDITION or SELECTION
 *    - onAdd, required if buttonAction is SELECTION, callback when a user is selected
 *
 * Store :
 *    - Users
 */
class UsersList extends Component {
  usersPerPage = USERS_LIST_PAGINATION_NB_ITEMS
  maxPageLinks = USERS_LIST_PAGINATION_NB_MAX_PAGES_LINKS
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      search: '',
    }
    this.setPage = this.setPage.bind(this)
  }

  setPage(page) {
    this.setState({ page })
  }

  filterUsers() {
    return this.props.users
      .filter((user) => !this.props.omitted.find((u) => u.id === user.id))
      .filter(
        (user) =>
          user.firstname
            .toLowerCase()
            .includes(this.state.search.toLowerCase()) ||
          user.lastname.toLowerCase().includes(this.state.search.toLowerCase())
      )
  }

  render() {
    return (
      <TableContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            name="usersSearchInput"
            id="usersSearchInput"
            placeholder="Recherche"
            value={this.state.search}
            onChange={(e) => {
              this.setState({
                search: e.target.value,
              })
              this.setPage(0)
            }}
            style={{ padding: '4px' }}
          />
          <TableContainer>
            <Table component={Paper}>
              <TableHead>
                <TableRow>
                  <StyledCell />
                  <StyledCell>Prénom</StyledCell>
                  <StyledCell>Nom</StyledCell>
                  <StyledCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>Aucun résultat !</TableCell>
                  </TableRow>
                ) : (
                  this.filterUsers()
                    .slice(
                      this.usersPerPage * this.state.page,
                      Math.min(
                        this.props.users.length,
                        this.usersPerPage * (this.state.page + 1)
                      )
                    )
                    .map((user) => {
                      return user.enabled ? (
                        <TableRow key={user.id}>
                          <StyledCell style={{ padding: '0px' }}>
                            <UserAvatar user={user} />
                          </StyledCell>
                          <StyledCell>{user.firstname}</StyledCell>
                          <StyledCell>{user.lastname}</StyledCell>
                          <StyledCell>
                            {this.props.buttonAction ===
                              userListButtonAction.EDITION && (
                              <EditUserDialog editedUser={user} />
                            )}
                            {this.props.buttonAction ===
                              userListButtonAction.SELECTION && (
                              <UserSelectionButton
                                user={user}
                                onClick={(user) => {
                                  this.props.onAdd(user)
                                }}
                              />
                            )}
                          </StyledCell>
                        </TableRow>
                      ) : (
                        <TableRow key={user.id}>
                          <StyledCell style={{ padding: '0px' }}>
                            <UserAvatar user={user} color="grey" />
                          </StyledCell>
                          <StyledCell color="grey">{user.firstname}</StyledCell>
                          <StyledCell color="grey">{user.lastname}</StyledCell>
                          <StyledCell />
                        </TableRow>
                      )
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <CustomPagination
            page={this.state.page}
            itemsPerPage={this.usersPerPage}
            maxPageLinks={this.maxPageLinks}
            items={this.props.users}
            filteredItems={this.filterUsers()}
            setPage={this.setPage}
          />
        </div>
      </TableContainer>
    )
  }
}

export default UsersList
