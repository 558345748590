import React from 'react'
import { Spinner } from 'reactstrap'

const SectionSpinner = () => (
  <Spinner
    style={{
      width: '3rem',
      height: '3rem',
      margin: '70px auto',
      position: 'fixed',
    }}
    type="grow"
    color="secondary"
  />
)

export default SectionSpinner
