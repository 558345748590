import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'

const Section = (props) => (
  <Card style={props.style}>
    <div
      style={{
        padding: '1rem',
        background: 'rgb(230, 126, 7)',
        color: 'white',
      }}
    >
      {props.title && (
        <div
          style={{
            display: 'flex',
          }}
        >
          {props.icon}
          <Typography
            className="font-medium truncate"
            color="inherit"
            style={props.icon && { marginLeft: '1rem' }}
          >
            {props.title}
          </Typography>
        </div>
      )}
    </div>

    <CardContent style={{ backgroundColor: 'white', paddingBottom: '16px' }}>
      {props.children}
    </CardContent>
  </Card>
)

export default Section
