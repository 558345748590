import styled from 'styled-components'
import Container from '@material-ui/core/Container'

const StyledAttiContainer = styled(Container)`
  width: 100%;
  max-width: 98%;
  border: 0;
  padding: 0px;
  margin: 20px;
  overflow: hidden;
  display: inline-block;
`

export default StyledAttiContainer
