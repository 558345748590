import React from 'react'
import { UserSelection } from '../index'
import { checkUserPrivileges } from '../../../../helpers'
import { connect } from 'react-redux'
import { updateTask } from '../../../../actions/requests/tasksActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getUserSRs } from '../../../../reducers'

class AssignSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSavingUser: false,
      user: this.props.task.user,
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let newUser = nextProps.task.user
    this.setState({
      user: newUser,
    })
  }
  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleUserSave = (user) => {
    this.toggleState('isSavingUser')

    const notificationMessage = `Utilisateur ${
      user !== null ? 'assigné' : 'désassigné'
    }`
    this.props
      .editTask(
        this.props.task.id,
        {
          user: user && user.id,
        },
        (id, response) => {
          this.handleChangeState('user', user)
          this.props.successNotification(id, response, notificationMessage)
        },
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.toggleState('isSavingUser')
        this.setState({
          user: user,
        })
      })
  }

  userCanEditField = () => {
    return (
      checkUserPrivileges(this.props.currentUser, this.props.project)
    )
  }

  isFieldDisabled = () => {
    return this.state.isSavingUser || !this.userCanEditField()
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <UserSelection
          currentUser={this.props.currentUser}
          disabled={this.isFieldDisabled()}
          isSaving={this.state.isSavingUser}
          onAddUser={(user) => this.handleUserSave(user)}
          project={this.props.project}
          user={this.state.user}
          task={this.props.task}
          userSRs={this.props.userSRs}
          minimized={this.props.minimized}
          isFetching={this.props.isFetching}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  userSRs: getUserSRs(state, ownProps.project.id),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Notifications
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignSection)
