import { connect } from 'react-redux'
import { AdminList } from '../../pages/Admin/components'
import { getExperiences } from '../../reducers'
import {
  deleteExperiences,
  postExperiences,
  updateExperiences,
} from '../../actions/requests/experiencesActions'

const mapStateToProps = (state) => ({
  data: getExperiences(state),
  properties: {
    name: 'Nom',
  },
  title: 'experiences',
  object: 'Expérience',
  // feminine définit si l'accord doit se faire
  feminine: true,
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postExperiences(body, successCallback, failureCallback)),
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateExperiences(id, body, successCallback, failureCallback)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteExperiences(id, successCallback, failureCallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
