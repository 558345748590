import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { NavbarItem } from '..'
import { checkUserAdminPrivileges, toggleClass } from '../../helpers'
import { getUser } from '../../helpers'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import NavbarHeader from '../NavbarHeader'
import Box from '@material-ui/core/Box'
import Background from '../../assets/img/dark-material-bg.jpg'

const MyIconButton = withStyles(() => ({
  root: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
}))(IconButton)

const MyIcon = withStyles(() => ({
  root: {
    color: 'white',
  },
}))(Icon)

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navbarIsFixed: false,
      navbarIsExpand: false,
      version:
        process.env.REACT_APP_MAJOR +
        '.' +
        process.env.REACT_APP_MINOR +
        '.' +
        process.env.REACT_APP_REVISION,
    }

    this.toggleIsFixed = this.toggleIsFixed.bind(this)
    this.toggleIsExpand = this.toggleIsExpand.bind(this)
  }

  toggleIsFixed() {
    this.setState((state) => ({
      navbarIsFixed: !state.navbarIsFixed,
    }))
  }

  toggleIsExpand() {
    this.setState((state) => ({
      navbarIsExpand: !state.navbarIsExpand,
    }))
  }

  render() {
    return (
      <nav
        className="vertical-navbar transform"
        id="verticalNavbar"
        style={{
          background: 'no-repeat center center fixed',
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="navbar-group">
          <Box
            display="flex"
            justifyContent="flex-end"
            paddingTop="5px"
            bgcolor="primary.main"
          >
            <MyIconButton
              aria-label="Menu"
              className="mr-1"
              onClick={() => {
                !this.state.navbarIsFixed &&
                  toggleClass(
                    document.getElementById('verticalNavbar'),
                    'vertical-navbar-active'
                  )
                this.toggleIsExpand()
              }}
            >
              <MyIcon>menu</MyIcon>
            </MyIconButton>
          </Box>
          <NavbarHeader
            user={this.props.currentUser}
            expanse={this.state.navbarIsExpand || this.state.navbarIsFixed}
          />
          <NavbarItem
            link={this.props.redirectToDashboardPage}
            logo={<MyIcon>home</MyIcon>}
            title="Tableau de bord"
          />
          <NavbarItem
            link={this.props.redirectToConsumsPage}
            logo={<MyIcon>create</MyIcon>}
            title={'Mes saisies'}
          />
          <NavbarItem
            link={this.props.redirectToProjectsPage}
            logo={<MyIcon>apps</MyIcon>}
            title="Projets"
          />
          <NavbarItem
            link={this.props.redirectToRoadmapPage}
            logo={<MyIcon>timeline</MyIcon>}
            title={'Livraisons '}
            version={'v.' + this.state.version}
          />

          {checkUserAdminPrivileges(this.props.currentUser) && (
            <NavbarItem
              link={this.props.redirectToAdminPage}
              logo={<MyIcon>settings</MyIcon>}
              title="Admin"
            />
          )}
        </div>
      </nav>
    )
  }
}

const mapStateToProps = () => ({
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  redirectToAdminPage: () => dispatch(push(routes.admin)),
  redirectToConsumsPage: () => dispatch(push(routes.consums)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
  redirectToLogoutPage: () => dispatch(push(routes.logout)),
  redirectToProjectsPage: () => dispatch(push(routes.projects)),
  redirectToProfilePage: () => dispatch(push(routes.profile)),
  redirectToRoadmapPage: () => dispatch(push(routes.roadmap)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
