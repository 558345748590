import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

class CustomPagination extends React.Component {
  getMaxPage() {
    if (this.props.filteredItems) {
      if (this.props.filteredItems.length % this.props.itemsPerPage === 0) {
        return (
          Math.floor(
            this.props.filteredItems.length / this.props.itemsPerPage
          ) - 1
        )
      } else {
        return Math.floor(
          this.props.filteredItems.length / this.props.itemsPerPage
        )
      }
    }
    if (this.props.items.length % this.props.itemsPerPage === 0) {
      return Math.floor(this.props.items.length / this.props.itemsPerPage) - 1
    }
    return Math.floor(this.props.items.length / this.props.itemsPerPage)
  }
  getPrevPage() {
    return Math.max(this.props.page - 1, 0)
  }
  getNextPage() {
    return Math.min(this.props.page + 1, this.getMaxPage())
  }
  getFirstLink() {
    return Math.max(this.props.page - this.props.maxPageLinks / 2, 0)
  }
  getLastLink() {
    return Math.min(
      this.getFirstLink() + this.props.maxPageLinks,
      this.getMaxPage()
    )
  }

  render() {
    if (
      this.props.items.length === 0 ||
      this.props.items.length <= this.props.itemsPerPage
    ) {
      return null
    } else {
      return (
        <Pagination
          className="d-flex justify-content-center"
          style={{ paddingTop: '1rem' }}
        >
          <PaginationItem disabled={this.props.page === 0}>
            <PaginationLink
              first
              onClick={() => {
                this.props.setPage(0)
              }}
            />
          </PaginationItem>
          <PaginationItem disabled={this.props.page === 0}>
            <PaginationLink
              previous
              onClick={() => {
                this.props.setPage(this.getPrevPage())
              }}
            />
          </PaginationItem>

          {Array.from(Array(this.getMaxPage() + 1).keys()).map((p) => {
            return (
              this.getFirstLink() <= p &&
              p <= this.getLastLink() && (
                <PaginationItem key={p} active={this.props.page === p}>
                  <PaginationLink
                    onClick={() => {
                      this.props.setPage(p)
                    }}
                  >
                    {p + 1}
                  </PaginationLink>
                </PaginationItem>
              )
            )
          })}
          <PaginationItem disabled={this.props.page === this.getMaxPage()}>
            <PaginationLink
              next
              onClick={() => {
                this.props.setPage(this.getNextPage())
              }}
            />
          </PaginationItem>
          <PaginationItem disabled={this.props.page === this.getMaxPage()}>
            <PaginationLink
              last
              onClick={() => {
                this.props.setPage(this.getMaxPage())
              }}
            />
          </PaginationItem>
        </Pagination>
      )
    }
  }
}

export default CustomPagination
