import React from 'react'
import { Navbar } from '..'
import Container from '@material-ui/core/Container'

const PageContainer = (props) => (
  <Container
    className="m-0 p-0"
    style={{ maxWidth: '100%', backgroundColor: 'rgb(230,231,233,1)' }}
  >
    <div className="w-100 m-0 p-0 d-flex">
      <div className="col-auto m-0 p-0 col-navbar" style={{ zIndex: '1001' }}>
        <Navbar />
      </div>
      <div
        className="w-full m-0 p-0"
        style={{ overflow: 'hidden', width: '100%' }}
      >
        {props.children}
      </div>
    </div>
  </Container>
)

export default PageContainer
