import styled from 'styled-components'

const StyledUl = styled.ul`
  box-shadow: ${(props) =>
    props.isDefault ? '0 0 3px 1px rgba(0, 0, 0, 0.35)' : 'none'};
  overflow: hidden;
  padding: 0px !important;
  border-radius: 0px !important;
  border-bottom: ${(props) => (props.isDefault ? '1px solid #A9A9A9' : 'none')};
  margin-bottom: ${(props) => (props.isDefault ? '1rem' : '0 !important')};
`

export default StyledUl
