import React from 'react'
import { CenteredDiv, StyledAttiContainer } from '../../components'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => (
  <StyledAttiContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <title>404 Page Not Found- Attiprod </title>
    </Helmet>
    <CenteredDiv>
      <h2>404 - Page Not Found</h2>
    </CenteredDiv>
  </StyledAttiContainer>
)

export default NotFoundPage
