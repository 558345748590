import styled from 'styled-components'
import { Button } from 'atti-components'

const StyledAttiHeaderButton = styled(Button)`
  height: 35px;
  width: 35px;
  margin: 0 10px;
  padding: 0;
  position: relative;
  top: -6px;
  background-color: transparent;

  ${({ margin }) =>
    margin &&
    `
    margin: ${margin};
  `}
  ${({ padding }) =>
    padding &&
    `
    padding: ${padding};
  `}
`

export default StyledAttiHeaderButton
