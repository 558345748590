import React from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'

class GenericToast extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      show: false,
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggle()
    }, 7000)
  }

  render() {
    return (
      <Toast style={{ zIndex: 1 }} isOpen={this.state.show} className="toast">
        <ToastHeader toggle={this.toggle} icon={this.props.icon}>
          {this.props.header}
        </ToastHeader>
        <ToastBody>{this.props.children}</ToastBody>
      </Toast>
    )
  }
}

export default GenericToast
