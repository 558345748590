import {
  clearErrors,
  deleteEntity,
  fetchAllEntities,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchComments = () => {
  return fetchAllEntities(itemTypes.comments)
}

export const fetchCommentsByTaskId = (id) => {
  return fetchEntity(otherURLs.tasksComments, id)
}

export const postComment = (body, successCallback, failureCallback) => {
  return postEntity(itemTypes.comments, body, successCallback, failureCallback)
}

export const updateComment = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.comments,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteComment = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.comments, id, successCallback, failureCallback)
}

export const clearErrorComments = () => clearErrors(itemTypes.comments)
