import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchExperiences = () => {
  return fetchEntity(itemTypes.experiences)
}

export const updateExperiences = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.experiences,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteExperiences = (id, successCallback, failureCallback) => {
  return deleteEntity(
    itemTypes.experiences,
    id,
    successCallback,
    failureCallback
  )
}

export const postExperiences = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.experiences,
    body,
    successCallback,
    failureCallback
  )
}

export const clearErrorExperiences = () => clearErrors(itemTypes.experiences)
