import styled from 'styled-components'

const StyledActiveLi = styled.li`
  &,
  &:hover {
    background-color: #3e2c42;
    padding: 0 20px 0 40px;
    line-height: 36px;
    font-size: 16px;
    color: white;

    outline: none;
    display: block;
    float: left;
    position: relative;
  }

  &:before {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    position: absolute;
    top: 0;
    background-color: #3e2c42;
    font-weight: bold;
  }

  &:after,
  &:hover::after {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    background-color: #3e2c42;
    box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4), 3px -3px 0 2px #3e2c42;
    border-radius: 0 5px 0 50px;
  }

  &:hover {
    cursor: pointer;
  }
`

export default StyledActiveLi
