import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const postBoard = (
  projectId,
  body,
  successCallback,
  failureCallback
) => {
  return postEntity(
    itemTypes.boards,
    body,
    successCallback,
    failureCallback,
    projectId
  )
}

export const updateBoard = (
  id,
  body,
  projectId,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.boards,
    id,
    body,
    successCallback,
    failureCallback,
    projectId
  )
}

export const deleteBoard = (
  id,
  projectId,
  successCallback,
  failureCallback
) => {
  return deleteEntity(
    itemTypes.boards,
    id,
    successCallback,
    failureCallback,
    projectId
  )
}

export const fetchBoardsByProjectId = (id) => {
  return fetchEntity(otherURLs.projectBoards, id, id)
}

export const clearErrorBoards = () => clearErrors(itemTypes.boards)
