import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchSecondaryRoles = () => {
  return fetchEntity(itemTypes.secondaryRoles)
}

export const updateSecondaryRoles = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.secondaryRoles,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteSecondaryRoles = (id, successCallback, failureCallback) => {
  return deleteEntity(
    itemTypes.secondaryRoles,
    id,
    successCallback,
    failureCallback
  )
}

export const postSecondaryRoles = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.secondaryRoles,
    body,
    successCallback,
    failureCallback
  )
}

export const clearErrorSecondaryRoles = () =>
  clearErrors(itemTypes.secondaryRoles)
