import React, { useState } from 'react'
import { connect } from 'react-redux'
import { LittleSpinner } from '../../components'
import logo from '../../assets/img/logo.png'
import { loginUser } from '../../helpers'
import { addNotification } from '../../actions/temporaries/notificationActions'
import office from '../../assets/img/office.png'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ColorButton from '../../components/ColorButton'
import Background from '../../assets/img/attiprod_login.png'
import { Redirect } from 'react-router'
import routes from '../../config/routes'
import { useIsAuthenticated } from '@azure/msal-react'

const Login = ({ addNotification }) => {
  const [isLoading, setIsLoading] = useState(false)
  const isAuthenticated = useIsAuthenticated()

  const handleClick = () => {
    setIsLoading(true)

    loginUser().catch((error) => {
      console.log(error)
      setIsLoading(false)
      addNotification(
        'Connexion échouée',
        "Une erreur s'est produite lors de l'authentification",
        'danger'
      )
    })
  }

  if (isAuthenticated) {
    return <Redirect to={{ pathname: routes.dashboard }} />
  }

  return (
    <Container
      maxWidth={false}
      style={{
        background: 'no-repeat center center fixed',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
      }}
    >
      <div
        className="vertical-center d-flex justify-content-center w-100"
        style={{ paddingTop: '5rem' }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login - Attiprod </title>
        </Helmet>
        {isLoading && <LittleSpinner />}
        <div className="vertical-center p-3 mb-5 rounded d-flex flex-column justify-content-center align-items-center">
          <div className="shadow-lg p-4 mb-5 bg-white rounded w-100 d-flex flex-column justify-content-center align-items-center">
            <Box display="flex" alignItems="center" className="mt-2">
              <img src={logo} alt="logo" height="160px" />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                style={{
                  borderLeft: '1px solid',
                  paddingLeft: '15px',
                  height: '9rem',
                  marginLeft: '15px',
                }}
              >
                <Typography variant="h5">ATTIPROD</Typography>
                <Typography>Attineos</Typography>
              </Box>
            </Box>

            <Typography className="mb-4 mt-4">
              L'accès à ce site est réservé aux membres de l'entreprise
              Attineos.
            </Typography>
            <ColorButton
              style={{
                color: 'rgb(235,60,0)',
                textTransform: 'none',
              }}
              onClick={handleClick}
            >
              <img
                style={{ paddingRight: '10px' }}
                src={office}
                alt=""
                height="24px"
              />{' '}
              Se connecter
            </ColorButton>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
})

export default connect(undefined, mapDispatchToProps)(Login)
