import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { DialogActions, DialogTitle, TableCell } from '@material-ui/core'
import clsx from 'clsx'
import { updateTask } from '../../../../actions/requests/tasksActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import ColorButton from '../../../../components/ColorButton'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'

class FilterSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      taskFilters: this.props.taskFilter,
      prevFilters: this.props.taskFilter,
      openFilterList: false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let newFilters = nextProps.taskFilter
    let toUpdate = newFilters.length !== this.state.prevFilters.length
    if (!toUpdate) return
    this.setState({
      taskFilters: newFilters,
      prevFilters: this.props.taskFilter,
    })
  }

  handleClose() {
    this.setState({
      openFilterList: false,
      openAssignList: false,
      openId: null,
    })
  }
  filtersToText(filters) {
    if (!filters) {
      return
    }
    let filtersText = []
    for (let i = 0; i < filters.length; i++) {
      let value = { value: filters[i].name, label: filters[i].name }
      filtersText.push(value)
    }
    return filtersText
  }
  filterTaskToText(filters) {
    if (!filters) {
      return
    }
    const temp = filters.split(', ')
    let filtersText = []
    for (let i = 0; i < temp.length; i++) {
      let value = { value: temp[i], label: temp[i] }
      filtersText.push(value)
    }
    return filtersText
  }

  filtersName(filters) {
    if (filters) {
      let filtersText = ''
      for (let i = 0; i < filters.length; i++) {
        filtersText = filtersText + `${filters[i].name}, `
      }
      return filtersText.slice(0, -2)
    }
  }

  handleOpenFilterList(id) {
    this.setState({
      openFilterList: true,
      openId: id,
    })
  }

  handleSaveDataFilter = (filterList, task) => {
    const notificationMessage = 'Filtres de la tâche modifiés'
    this.props.editTask(
      task.id,
      {
        project_filters: filterList && filterList.map((filter) => filter.id),
      },
      (id, response) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
    this.setState({
      taskFilters: this.filtersName(filterList),
    })
  }

  handleFilterChange = (e, filtersList, task) => {
    let filterList = []
    if (e) {
      for (let i = 0; i < e.length; i++) {
        for (let j = 0; j < filtersList.length; j++) {
          if (e[i].value === filtersList[j].name) {
            filterList.push(filtersList[j])
          }
        }
      }
    }
    this.setState({
      isUpdating: true,
      openFilterList: true,
      openId: task.id,
    })

    this.handleSaveDataFilter(filterList, task)
  }

  render() {
    const animatedComponents = makeAnimated()
    const filtersText = this.filtersToText(this.props.projectFilters)
    const classes = this.props.classes
    return (
      <>
        <TableCell
          className={clsx(classes.cell, classes.linkCell)}
          onClick={() => this.handleOpenFilterList(this.props.task.id)}
        >
          {this.state.taskFilters}
        </TableCell>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={this.state.openFilterList}
          onClose={() => {
            this.handleClose()
          }}
          fullWidth
          aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title">Selection filtre</DialogTitle>
          <DialogContent>
            <div
              style={{ height: `${this.props.projectFilters.length * 70}px` }}
            >
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={this.filterTaskToText(this.state.taskFilters)}
                isMulti
                options={filtersText}
                onChange={(e) =>
                  this.handleFilterChange(
                    e,
                    this.props.projectFilters,
                    this.props.task
                  )
                }
              />
            </div>
          </DialogContent>

          <DialogActions className="justify-content-center">
            <ColorButton
              onClick={() => {
                this.handleClose()
              }}
            >
              {' '}
              Fermer{' '}
            </ColorButton>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
})

export default connect(null, mapDispatchToProps)(FilterSelect)
