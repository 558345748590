import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { updateTask } from '../../../../actions/requests/tasksActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { checkUserPrivileges } from '../../../../helpers'
import { isForfaitProjectType } from '../../../../constants/global'

class DescriptionSection extends React.Component {
  constructor(props) {
    super(props)

    const { description } = this.props.task

    this.state = {
      description: description !== null ? description : '',
      isSavingDescription: false,
      previousDescription: description !== null ? description : '',
    }
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleDataSave = () => {
    if (this.state.previousDescription === this.state.description) return

    this.toggleState('isSavingDescription')

    const notificationMessage = `Champ "Description" mis à jour`
    this.props
      .editTask(
        this.props.task.id,
        {
          description: this.state.description,
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleChangeState('previousDescription', this.state.description)
        this.toggleState('isSavingDescription')
      })
  }

  userCanEditField = () => {
    return (
      checkUserPrivileges(this.props.currentUser, this.props.project) ||
      !isForfaitProjectType(this.props.project.project_type)
    )
  }

  isFieldDisabled = () => {
    return this.state.isSavingDescription || !this.userCanEditField()
  }

  render() {
    return (
      <TextField
        type="Text"
        name="description"
        id="description"
        variant="outlined"
        multiline
        rows={3}
        rowsMax={10}
        fullWidth
        value={this.state.description}
        onChange={({ target: { name, value } }) =>
          this.handleChangeState(name, value)
        }
        onBlur={this.handleDataSave}
        disabled={this.isFieldDisabled()}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Notifications
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(null, mapDispatchToProps)(DescriptionSection)
