import styled from 'styled-components'

const getColor = (props) => {
  if (props.isDragActive || props.isFileDialogActive || props.isFocused)
    return 'rgb(230, 126, 7)'

  return '#CCCCCC'
}

const StyledDropZoneContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  border-radius: 10px;
`

export default StyledDropZoneContainer
