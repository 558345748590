import * as React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { LittleSpinner } from '../../../../components'
import { deleteComment } from '../../../../actions/requests/commentsActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

class DeleteComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      dialog: false,
      isDeleting: false,
    }

    this.toggle = this.toggle.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  handleIsDeleting() {
    this.setState({
      isDeleting: true,
    })
  }

  handleEndDeleting() {
    this.setState({
      isDeleting: false,
    })
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  render() {
    return (
      <>
        {this.state.isLoading && <LittleSpinner />}
        {this.state.isDeleting ? (
          <IconButton>
            <Icon>
              <CircularProgress color="inherit" size={24} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton
            aria-label="supprimer ce commentaire"
            onClick={this.handleOpenDialog}
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        <Dialog open={this.state.dialog}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr(e) de vouloir supprimer ce commentaire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleCloseDialog}>
              Annuler
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.handleIsDeleting()
                this.props.click && this.props.click(this.props.comment.id)
                this.setState({
                  isLoading: true,
                })
                const notificationMessage = 'Commentaire supprimé'
                this.props
                  .deleteItem(
                    this.props.comment.id,
                    (id, response, message) =>
                      this.props.successNotification(
                        id,
                        response,
                        notificationMessage
                      ),
                    (id, error) => this.props.failureNotificationApi(id, error)
                  )
                  .then(() => {
                    this.setState({ isLoading: false })
                    this.handleEndDeleting()
                  })
                this.handleCloseDialog()
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteComment(id, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(DeleteComment)
