import React from 'react'
import { connect } from 'react-redux'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getFilters } from '../../../../reducers'
import { fetchFiltersByProjectId } from '../../../../actions/requests/filtersActions'
import { updateTask } from '../../../../actions/requests/tasksActions'
import FilterList from '../../../ProjectTasks/components/FilterList'
import { checkUserPrivileges } from '../../../../helpers'
import { isForfaitProjectType } from '../../../../constants/global'

class LabelSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      selectedFilters: this.props.task.projectFilters,
    }
  }

  componentDidMount() {
    const promises = [this.props.fetchFiltersByProjectId(this.props.project.id)]

    Promise.all(promises).then(() => {
      this.setState({
        isLoading: false,
      })
    })
  }

  handleSelectFilter = (selected, filter, filterList) => {
    if (this.userCanEditField()) {
      this.setState({
        selectedFilters: filterList,
      })

      this.handleSaveData(filterList)
    }
  }

  handleSaveData = (filterList) => {
    const notificationMessage = 'Filtres de la tâche modifiés'
    this.props.editTask(
      this.props.task.id,
      {
        project_filters: filterList && filterList.map((filter) => filter.id),
      },
      (id, response) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  userCanEditField = () => {
    return (
      checkUserPrivileges(this.props.currentUser, this.props.project) ||
      !isForfaitProjectType(this.props.project.project_type)
    )
  }

  render() {
    if (this.state.isLoading) return null

    return (
      <FilterList
        filters={this.props.filters}
        selected={this.state.selectedFilters}
        onSelecting={(b, f, l) => this.handleSelectFilter(b, f, l)}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  filters: getFilters(state, ownProps.project.id),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Fetches
  fetchFiltersByProjectId: (id) => dispatch(fetchFiltersByProjectId(id)),
  // Notifications
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LabelSection)
