import React from 'react'
import routes from '../../../../config/routes'
import { getProjectTypes } from '../../../../reducers'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from '@material-ui/core/Icon'
import ColorButton from '../../../../components/ColorButton'

/**
 * TODO get project types from store
 * Props :
 *    - className, the className of the dropdown button
 *    - projectTypes
 */

class NewProjectMenu extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
      anchorEl: null,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    return (
      <div>
        <ColorButton
          onClick={this.handleClick}
          endIcon={<Icon>arrow_drop_down</Icon>}
        >
          {' '}
          Nouveau projet
        </ColorButton>
        <Menu
          id="menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.projectTypes.map((projectType) => (
            <MenuItem
              key={projectType.id}
              onClick={() => {
                this.props.redirectTo(routes.newProject, projectType.codename)
              }}
            >
              {'Nouveau projet ' + projectType.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projectTypes: getProjectTypes(state),
})

const mapDispatchToProps = (dispatch) => ({
  redirectTo: (route, projectType) => dispatch(push(route + '/' + projectType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectMenu)
