import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchTasks = () => {
  return fetchEntity(itemTypes.tasks)
}

export const fetchTask = (id) => {
  return fetchEntity(itemTypes.tasks, id)
}

export const fetchTasksByUserId = (id) => {
  return fetchEntity(otherURLs.usersTasks, id)
}

export const fetchRelatedTasksByTaskId = (id) => {
  return fetchEntity(otherURLs.tasksRelatedTasks, id)
}

export const fetchTasksByProjectId = (id) => {
  return fetchEntity(otherURLs.projectTasks, id)
}

export const postTask = (body, successCallback, failureCallback) => {
  return postEntity(itemTypes.tasks, body, successCallback, failureCallback)
}

export const updateTask = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.tasks,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteTask = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.tasks, id, successCallback, failureCallback)
}

export const clearErrorTasks = () => clearErrors(itemTypes.tasks)
export const clearErrorTasksConsums = () => clearErrors(itemTypes.tasksConsums)
export const clearErrorTasksUsers = () => clearErrors(itemTypes.tasksUsers)
