import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchProjectStatuses = () => {
  return fetchEntity(itemTypes.projectStatuses)
}

export const updateProjectStatus = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.projectStatuses,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteProjectStatus = (id, successCallback, failureCallback) => {
  return deleteEntity(
    itemTypes.projectStatuses,
    id,
    successCallback,
    failureCallback
  )
}

export const postProjectStatus = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.projectStatuses,
    body,
    successCallback,
    failureCallback
  )
}

export const clearErrorProjectStatuses = () =>
  clearErrors(itemTypes.projectStatuses)
