import styled from 'styled-components'

const StyledDiv2 = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  padding-left: 12px;
  padding-right: 12px;
`

export default StyledDiv2
