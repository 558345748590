import React from 'react'
import {
  Breadcrumbs,
  Header,
  LittleSpinner,
  PageSpinner,
} from '../../components'
import { connect } from 'react-redux'
import { checkUserPrivileges, getUser } from '../../helpers'
import { clearAddedItems, clearDeletedItems } from '../../actions/globalActions'
import {
  fetchBoardsByProjectId,
  updateBoard,
} from '../../actions/requests/boardsActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../actions/temporaries/notificationActions'
import { fetchFiltersByProjectId } from '../../actions/requests/filtersActions'
import { fetchProjectById } from '../../actions/requests/projectsActions'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import { fetchSprintsByProjectId } from '../../actions/requests/sprintsActions'
import {
  fetchTasksByProjectId,
  updateTask,
} from '../../actions/requests/tasksActions'
import { filterTasks } from '../../helpers/util'
import {
  getAddedItems,
  getBoards,
  getDeletedItems,
  getFilters,
  getProject,
  getProjectStatuses,
  getSprints,
  getTasks,
  isFetching,
} from '../../reducers'
import { Helmet } from 'react-helmet'
import {
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  withWidth
} from '@material-ui/core'
import {
  isAgileProjectType,
  isForfaitProjectType,
} from '../../constants/global'
import { itemTypes } from '../../actions/itemTypes'
import { push } from 'connected-react-router'
import { Search } from '@material-ui/icons'
import { updatePreference } from '../../actions/requests/preferencesActions'
import FilterList from './components/FilterList'
import moment from 'moment'
import routes from '../../config/routes'
import StyledDiv from './styles/StyledDiv'
import TaskBoards from './components/TaskBoards'
import TaskTable from './components/TaskTable'
import ProjectExport from './components/ProjectExport'
import ColoredSwitch from '../../components/ColoredSwitch'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class ProjectTasks extends React.Component {
  constructor(props) {
    super(props)

    if (
      this.props.match.params.id === null ||
      this.props.match.params.id === undefined
    ) {
      this.props.redirectToDashboardPage()
    }

    this.state = {
      boards: [],
      tasks: [],
      currentSprint: null,
      currentStatus: null,
      currentUser: getUser(),
      displayBoard: true,
      displayPref: null,
      isFirstRender: true,
      selectedFilter: [],
      searchValue: '',
      showChargeClient: false,
      remainTotal: 0,
      visibleTotal: 0,
      expectedTotal: 0,
      consumTotal: 0,
    }

    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.state.boards) return

    let addedBoards = nextProps.addedBoards
    let deletedBoards = nextProps.deletedBoards
    let addedTasks = nextProps.addedTasks
    let deletedTasks = nextProps.deletedTasks

    let toUpdate =
      addedBoards.length > 0 ||
      deletedBoards.length > 0 ||
      addedTasks.length > 0 ||
      deletedTasks.length > 0

    if (!toUpdate) return

    let updatedBoards = [
      ...this.state.boards,
      ...addedBoards.filter((board) => {
        return !this.state.boards.find((stateBoard) => {
          return board.id === stateBoard.id
        })
      })
    ].filter((item) => !deletedBoards.find((i) => i.id === item.id))

    let updatedBoardsAfterDeletion = updatedBoards.map((board) => {
      // Supprimer les tâches correspondant aux deletedTasks dans le board correspondant
      let updatedBoardTasks = board.tasks.filter((task) => {
        return !deletedTasks.find((deletedTask) => deletedTask.id === task.id)
      })

      // Vérifier si le nom du board a été modifié
      const matchingBoard = addedBoards.find((addedBoard) => addedBoard.id === board.id)
      if (matchingBoard && matchingBoard.name !== board.name) {
        board = { ...board, name: matchingBoard.name }
      }

      return {...board, tasks: updatedBoardTasks}
    })

    // Supprimer les tâches correspondant aux deletedTasks dans la liste globale de tâches
    let updatedTasks = this.state.tasks.filter((task) => {
      return !deletedTasks.find((deletedTask) => deletedTask.id === task.id)
    })

    // Ajouter les nouvelles tâches et les tâches dupliquées dans le board correspondant
    if (addedTasks.length > 0) {
      const updatedBoardsWithAddedTasks = updatedBoardsAfterDeletion.map((board) => {
        const matchingAddedTasks = addedTasks.filter((addedTask) => addedTask.board.id === board.id)
        const updatedBoardTasks = [...board.tasks, ...matchingAddedTasks]
          .filter((task, index, self) => self.findIndex((t) => t.id === task.id) === index)
        return { ...board, tasks: updatedBoardTasks }
      })

      this.setState({
        boards: updatedBoardsWithAddedTasks,
        tasks: updatedTasks
      })
    } else {
      this.setState({
        boards: updatedBoardsAfterDeletion,
        tasks: updatedTasks
      })
    }



    nextProps.clearAddedBoards()
    nextProps.clearDeletedBoards()
    nextProps.clearAddedTasks()
    nextProps.clearDeletedTasks()
  }

  componentDidMount() {
    let promises = [
      this.props.fetchProject(this.props.match.params.id),
      this.props.fetchBoardsByProjectId(this.props.match.params.id),
      this.props.fetchFiltersByProjectId(this.props.match.params.id),
      this.props.fetchSprintsByProjectId(this.props.match.params.id),
      this.props.fetchTasksByProjectId(this.props.match.params.id),
      this.props.fetchProjectStatuses(),
    ]

    Promise.all(promises).then(() => {
      let boards = this.props.boards
      if (isAgileProjectType(this.props.project.project_type)) {
        const currentSprint = this.getCurrentSprint()
        boards = boards.filter((board) => board.sprint.id === currentSprint.id)

        this.setState({
          currentSprint: currentSprint,
        })
      } else if (isForfaitProjectType(this.props.project.project_type)) {
        const currentStatus = this.props.project.project_status
        boards = boards.filter(
          (board) => board.project_status.id === currentStatus.id
        )

        this.setState({
          currentStatus: currentStatus,
        })
      }

      // Vérification de la valeur de la préférence
      const displayPref = this.props.project.preferences.find(
        (p) => p.user.id === this.state.currentUser.id
      )

      let displayBoard = true
      if (displayPref !== undefined) displayBoard = displayPref.isBoardDisplay

      let tasks = []

      boards.forEach((b) => {
        b.tasks.forEach((t) => {
          tasks = [...tasks, t]
        })
      })

      this.setState({
        boards: boards.sort((b1, b2) => b1.board_order - b2.board_order),
        tasks: tasks,
        displayBoard: displayBoard,
        displayPref: displayPref,
        isFirstRender: false,
        showChargeClient: localStorage.getItem('showChargeClient') === 'true'
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.boards !== this.state.boards) {
      const updatedTasks = []

      this.state.boards.forEach((board) => {
        board.tasks.forEach((task) => {
          updatedTasks.push(task)
        })
      })

      this.setState({ tasks: updatedTasks })
    }

    if (prevProps.tasks !== this.props.tasks) {
      const updatedBoards = this.state.boards.map((board) => {
        const updatedTasks = board.tasks.map((task) => {
          const matchingTask = this.props.tasks.find((t) => t.id === task.id)
          return matchingTask ? { ...task, ...matchingTask } : task
        })
        return { ...board, tasks: updatedTasks }
      })

      this.setState({ boards: updatedBoards })
    }
  }

  getCurrentSprint() {
    let number = -1
    let currentSprint = null
    for (const sprint of this.props.sprints) {
      if (sprint.number > number) {
        currentSprint = sprint
        number = sprint.number
      }
    }

    return currentSprint
  }

  getCurrentBoards(property, object) {
    const projectTasks = this.props.tasks.filter(
      (task) => task.board.project.id === this.props.match.params.id
    )
    const boards = this.props.boards
      .filter((board) => board[property] && board[property].id === object.id)
      .sort((b1, b2) => b1.board_order - b2.board_order)
    let tasks = []
    for (let board of boards) {
      board.tasks = projectTasks.filter((task) => task.board.id === board.id)
      // On met a jour la liste des tâches en fonction du board sélectionné
      for (let task of board.tasks) {
        tasks = [...tasks, task]
      }
    }
    this.setState({
      tasks: tasks
    })
    return boards
  }

  changeStatus(project_status) {
    this.setState({
      boards: this.getCurrentBoards('project_status', project_status),
      currentStatus: project_status,
    })
  }

  changeSprint(sprint) {
    this.setState({
      boards: this.getCurrentBoards('sprint', sprint),
      currentSprint: sprint,
    })
  }

  onSelecting(selected, filter, filterList) {
    this.setState({
      selectedFilter: filterList,
    })
  }

  onDragEnd(result) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === 'boards') {
      // Boards
      const notificationMessage = 'Ordre du board modifié'
      this.props.editBoard(
        this.state.boards[source.index].id,
        {
          board_order: destination.index + 1,
          name: this.state.boards[source.index].name,
        },
        this.props.match.params.id,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      this.setState({
        boards: reorder(this.state.boards, source.index, destination.index),
      })
    } else if (source.droppableId === destination.droppableId) {
      // Tasks on same board
      this.setState({
        boards: this.state.boards.map((board) =>
          board.id === source.droppableId
            ? {
                ...board,
                tasks: reorder(board.tasks, source.index, destination.index),
              }
            : board
        ),
      })
    } else {
      // Tasks on two different boards
      const fromBoard = this.state.boards.find(
        (board) => board.id === source.droppableId
      )
      const toBoard = this.state.boards.find(
        (board) => board.id === destination.droppableId
      )

      // Apply filters and research value on the fromboard tasks
      let filteredTasks = fromBoard.tasks.filter(
        (task) =>
          this.state.selectedFilter.length === 0 ||
          this.state.selectedFilter.some((filter) =>
            task.projectFilters.find((f) => f.id === filter.id)
          )
      )
      const searchValue = this.state.searchValue.toLowerCase()
      if (searchValue !== '')
        filteredTasks = filterTasks(filteredTasks, searchValue)

      const task = filteredTasks[source.index]
      const finished =
        toBoard.board_order === this.state.boards.length
          ? moment().format('DD-MM-YYYY')
          : null

      const notificationMessage = 'Changement de board effectué'
      this.props.editTask(
        task.id,
        {
          board: toBoard.id,
          finished,
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )

      this.setState({
        boards: this.state.boards.map((board) => {
          if (board === fromBoard)
            return { ...board, tasks: board.tasks.filter((t) => t !== task) }
          else if (board === toBoard) {
            let tasks = board.tasks ? board.tasks : []
            return {
              ...board,
              tasks: reorder([task, ...tasks], 0, destination.index),
            }
          } else return board
        }),
      })
    }
  }

  checkUserToTask() {
    return this.props.tasks.find(
      (task) =>
        task.board.project.id === this.props.project.id && task.user === null
    )
  }

  handleDisplayChange = () => {
    const { displayBoard, displayPref } = this.state
    this.setState({ displayBoard: !displayBoard })

    const notificationMessage = "Préférence d'affichage modifiée"
    this.props.editPreference(
      displayPref.id,
      {
        isBoardDisplay: !displayBoard,
      },
      (id, response) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  handleShowChargeClientSwitch = () => {
    localStorage.setItem('showChargeClient', !this.state.showChargeClient + "")
    this.setState({
      showChargeClient: !this.state.showChargeClient,
    })
  }

  updateValues = (values) => {
    this.setState({
      remainTotal: values.RemainTotal,
      visibleTotal: values.VisibleTotal,
      expectedTotal: values.ExpectedTotal,
      consumTotal: values.ConsumTotal,
    })
  }

  getMaxBreadcrumbsItems = () => {
    switch (this.props.width) {
      case 'xs':
        return 5
      case 'sm':
        return 7
      case 'md':
        return 10
      default:
        return 12
    }
  }

  updateTasks = (modifiedTasks) => {
    this.setState((prevState) => {
      const updatedTasks = prevState.tasks.map((task) => {
        const modifiedTask = modifiedTasks.find((t) => t.id === task.id)
        if (modifiedTask) {
          return {
            ...task,
            user: modifiedTask.user,
            remaining_workload: modifiedTask.remaining_workload,
            projectFilters: modifiedTask.projectFilters,
          }
        } else {
          return task
        }
      })
      return { tasks: updatedTasks }
    })
  }

  getAllTasks() {
    let tasks = []
    // Pour récupérer toutes les tâches de tous les boards du projet
    this.props.boards.forEach((board) => {
      board.tasks.forEach((task) => {
        tasks = [...tasks, task]
      })
    })

    return tasks
  }

  render() {
    if (this.state.isFirstRender) return <PageSpinner />

    if (!this.props.project) {
      this.props.redirectToDashboardPage()
      return null
    }

    const project = this.props.project,
      boards = this.state.boards,
      projectName = project.project_name,
      clientName = project.client_name

    const maxItems = this.getMaxBreadcrumbsItems()

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Board - Attiprod </title>
        </Helmet>

        {!this.state.isFirstRender && this.props.isFetching && (
          <LittleSpinner />
        )}

        <StyledDiv
          style={{ height: this.state.displayBoard ? '100vh' : 'auto' }}
        >
          <Header
            clientName={clientName}
            endIcon={
              <div>
                <Tooltip title="Modifier l'affichage">
                  <IconButton
                    aria-label="modifier-affichage"
                    onClick={this.handleDisplayChange}
                    style={{ color: 'rgb(148,139,150,1)' }}
                  >
                    <Icon>
                      {this.state.displayBoard ? 'view_week' : 'table_chart'}
                    </Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Accéder aux statistiques">
                  <IconButton
                    aria-label="accéder aux statistiques"
                    onClick={() => {
                      const tasks = this.getAllTasks()
                      let sumRemainTotal = 0
                      let sumVisibleTotal = 0
                      let sumExpectedTotal = 0
                      let sumConsumTotal = 0

                      tasks.forEach((task) => {
                        sumRemainTotal += task.remaining_workload
                        sumVisibleTotal += task.visible_workload
                        sumExpectedTotal += task.expected_workload
                        task.consums.forEach((consum) => {
                          sumConsumTotal += consum.amount
                        })
                      })
                      this.props.redirectToStatisticsPage(
                        this.props.match.params.id,
                        {
                          showChargeClient: this.state.showChargeClient,
                          remainTotal: sumRemainTotal,
                          visibleTotal: sumVisibleTotal,
                          expectedTotal: sumExpectedTotal,
                          consumTotal: sumConsumTotal,
                        }
                      )
                    }}
                    style={{ color: 'rgb(148,139,150,1)' }}
                  >
                    <Icon>pie_chart</Icon>
                  </IconButton>
                </Tooltip>
                {checkUserPrivileges(this.state.currentUser, project) && (
                  <>
                    <Tooltip title="Accéder aux saisies">
                      <IconButton
                        aria-label="accéder aux saisies"
                        onClick={() => {
                          this.props.redirectToProjectConsumsPage(
                            this.props.match.params.id
                          )
                        }}
                        style={{ color: 'rgb(148,139,150,1)' }}
                      >
                        <Icon>timer</Icon>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Editer ce projet">
                      <IconButton
                        aria-label="éditer ce projet"
                        onClick={() => {
                          this.props.redirectToEditProjectPage(
                            this.props.match.params.id
                          )
                        }}
                        style={{ color: 'rgb(148,139,150,1)' }}
                      >
                        <Icon>settings</Icon>
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            }
            startIcon={
              <IconButton
                aria-label="retour aux projets"
                onClick={() => {
                  this.props.redirectToProjectsPage()
                }}
                style={{ color: 'rgb(148,139,150,1)' }}
              >
                <Icon>arrow_back</Icon>
              </IconButton>
            }
            title={projectName}
            user={this.state.currentUser}
          />

          {isAgileProjectType(project.project_type) ? (
            <Breadcrumbs
              click={(sprint) => this.changeSprint(sprint)}
              currentItem={this.state.currentSprint}
              items={this.props.sprints}
              max={maxItems}
              project={project}
            />
          ) : (
            isForfaitProjectType(project.project_type) && (
              <Breadcrumbs
                click={(status) => this.changeStatus(status)}
                currentItem={this.state.currentStatus}
                items={this.props.projectStatuses}
                project={project}
              />
            )
          )}

          <Grid container spacing={2}>
            <Grid item xs={8} style={{ paddingLeft: '24px' }}>
              <FilterList
                filters={this.props.filters}
                isTmaProject={
                  !isAgileProjectType(project.project_type) &&
                  !isForfaitProjectType(project.project_type)
                }
                onSelecting={(selected, filter, filterList) =>
                  this.onSelecting(selected, filter, filterList)
                }
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                alignItems: 'center',
                display: 'flex',
                paddingRight: '32px',
                paddingTop:
                  !isAgileProjectType(project.project_type) &&
                  !isForfaitProjectType(project.project_type)
                    ? '16px'
                    : '0px',
              }}
            >
              <ProjectExport project={this.props.project} />
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: '#f2f2f2' },
                }}
                onChange={(e) => this.setState({ searchValue: e.target.value })}
                placeholder="Recherche"
                size="small"
                style={{ minWidth: '180px' }}
                value={this.state.searchValue}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {this.state.displayBoard ? (
            <TaskBoards
              boards={boards}
              tasks={this.state.tasks}
              currentSprint={this.state.currentSprint}
              currentStatus={this.state.currentStatus}
              currentUser={this.state.currentUser}
              onDragEnd={this.onDragEnd}
              project={project}
              filters={this.props.filters}
              searchValue={this.state.searchValue}
              selectedFilter={this.state.selectedFilter}
              showChargeClient={this.state.showChargeClient}
            />
          ) : (
            <>
              {checkUserPrivileges(
                this.state.currentUser,
                this.props.project
              ) ? (
                <div style={{ fontSize: '12px', marginLeft: '3%', display: 'flex', alignItems: 'center' }}>
                  <ColoredSwitch
                    checked={this.state.showChargeClient}
                    onChange={this.handleShowChargeClientSwitch}
                  />
                  <p style={{ color: 'black', margin: 0 }}>
                    Afficher la charge client:{' '}
                    {this.state.showChargeClient ? 'on' : 'off'}
                  </p>
                </div>
              ) : null}

              <TaskTable
                boards={boards}
                tasks={this.state.tasks}
                updateTasks={this.updateTasks}
                currentUser={this.state.currentUser}
                project={project}
                showChargeClient={this.state.showChargeClient}
                filters={this.props.filters}
                searchValue={this.state.searchValue}
                selectedFilter={this.state.selectedFilter}
                updateValues={this.updateValues}
              />
            </>
          )}
        </StyledDiv>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  addedBoards: getAddedItems(state, itemTypes.boards),
  addedTasks: getAddedItems(state, itemTypes.tasks),
  boards: getBoards(state, ownProps.match.params.id),
  deletedBoards: getDeletedItems(state, itemTypes.boards),
  deletedTasks: getDeletedItems(state, itemTypes.tasks),
  filters: getFilters(state, ownProps.match.params.id),
  isFetching: isFetching(state),
  project: getProject(state, ownProps.match.params.id),
  projectStatuses: getProjectStatuses(state),
  sprints: getSprints(state, ownProps.match.params.id),
  tasks: getTasks(state),
})

const mapDispatchToProps = (dispatch) => ({
  //Clears
  clearAddedBoards: () => dispatch(clearAddedItems(itemTypes.boards)),
  clearDeletedBoards: () => dispatch(clearDeletedItems(itemTypes.boards)),
  clearAddedTasks: () => dispatch(clearAddedItems(itemTypes.tasks)),
  clearDeletedTasks: () => dispatch(clearDeletedItems(itemTypes.tasks)),
  //Fetchs
  fetchProject: (id) => dispatch(fetchProjectById(id)),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
  fetchBoardsByProjectId: (id) => dispatch(fetchBoardsByProjectId(id)),
  fetchFiltersByProjectId: (id) => dispatch(fetchFiltersByProjectId(id)),
  fetchTasksByProjectId: (id) => dispatch(fetchTasksByProjectId(id)),
  fetchSprintsByProjectId: (id) => dispatch(fetchSprintsByProjectId(id)),
  //Edit
  editBoard: (id, body, projectId, successCallback, failureCallback) =>
    dispatch(
      updateBoard(id, body, projectId, successCallback, failureCallback)
    ),
  editPreference: (id, body, successCallback, failureCallback) =>
    dispatch(updatePreference(id, body, successCallback, failureCallback)),
  editTask: (id, body, successCallback, failureCallback, updateCallback) =>
    dispatch(
      updateTask(id, body, successCallback, failureCallback, updateCallback)
    ),
  //Callbacks
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  //Redirects
  redirectToStatisticsPage: (id, data) =>
    dispatch(
      push({
        pathname: routes.statistics + '/' + id,
        state: data,
      })
    ),
  redirectToEditProjectPage: (id) =>
    dispatch(push(routes.editProject + '/' + id)),
  redirectToProjectsPage: () => dispatch(push(routes.projects)),
  redirectToProjectConsumsPage: (id) =>
    dispatch(push(routes.projectConsums + '/' + id)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(ProjectTasks))
