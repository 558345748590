import React, { Component } from 'react'
import { Box, darken, withStyles } from '@material-ui/core'
import TaskFilter from '../TaskFilter'

const StyledBox = withStyles((theme) => ({
  root: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    width: '98%',
    cursor: 'default',
    scrollbarColor: theme.palette.primary.main + ' transparent',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      height: '7px',
      paddingTop: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.1),
    },
  },
}))(Box)

/**
 * Props :
 *    - filters
 *    - selected, optionnal, a list of already selected filters
 *    - onSelecting(boolean, filter, filterList), callback when the filter is selected
 */
class TaskFilterList extends Component {
  constructor(props) {
    super(props)
    this.selectedFilters = props.selected ? props.selected : []
  }

  callback(selected, filter) {
    if (selected) this.selectedFilters = [...this.selectedFilters, filter]
    else
      this.selectedFilters = this.selectedFilters.filter(
        (f) => f.id !== filter.id
      )
    this.props.onSelecting(selected, filter, this.selectedFilters)
  }

  render() {
    const { filters } = this.props
    let f
    if (filters) {
      f = filters.slice()
      f.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA > nameB) {
          return 1
        }

        if (nameA < nameB) {
          return -1
        }

        return 0
      })
    }
    return (
      <StyledBox
        style={{
          marginTop: this.props.isTmaProject ? '1rem' : '0',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {filters &&
          f.map((filter, index) => (
            <div key={index} style={{ display: 'inline-block' }}>
              <TaskFilter
                filter={filter}
                isSelected={this.selectedFilters.find(
                  (f) => f.id === filter.id
                )}
                onSelecting={(b, f) => this.callback(b, f)}
              />
            </div>
          ))}
      </StyledBox>
    )
  }
}

export default TaskFilterList
