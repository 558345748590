import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section, UserThumbnail } from '../../../../components'
import { getNeededUsers, getUserSRs } from '../../../../reducers'
import UserEditDialog from '../UserEditDialog'
import UserAddDialog from '../UserAddDialog'
import {
  userRoles,
  userThumbnailTypes,
  userTypes,
} from '../../../../constants/global'
import UserNeededAddDialog from '../UserNeededAddDialog'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { itemTypes } from '../../../../actions/itemTypes'
import { deleteFormEntity } from '../../../../actions/globalActions'
import Container from '@material-ui/core/Container'
import Icon from '@material-ui/core/Icon'

/**
 * Props :
 *    - project, the edited project
 * Store :
 *    - UsersSR for the current project
 *    - UserNeeded for the current project
 */

class UsersSection extends Component {
  render() {
    return (
      <Section title="Equipe">
        <Container className="p-3" style={{ height: '19rem' }}>
          <p className="m-0">Gestion de projet</p>
          <div className="p-2">
            {this.props.usersSR
              .filter(
                ({ secondaryRole: { codename } }) =>
                  [userRoles.ROLE_CP, userRoles.ROLE_DP].indexOf(codename) !==
                  -1
              )
              .map((user_sr) => (
                <UserEditDialog
                  key={user_sr.id}
                  disabled={this.props.disabled}
                  project={this.props.project}
                  user_sr={user_sr}
                />
              ))}
            <UserAddDialog
              disabled={this.props.disabled}
              className="m-1"
              userType={userTypes.GESTION_PROJET}
              alreadyAssigned={this.props.usersSR.map((u) => u.user)}
              project={this.props.project}
            />
          </div>
          <p className="m-0">Réalisation</p>
          <div className="p-2">
            {this.props.usersSR
              .filter(
                ({ secondaryRole: { codename } }) =>
                  [userRoles.ROLE_CP, userRoles.ROLE_DP].indexOf(codename) ===
                  -1
              )
              .map((user_sr) => (
                <UserEditDialog
                  key={user_sr.id}
                  disabled={this.props.disabled}
                  project={this.props.project}
                  user_sr={user_sr}
                  usersTasks={this.props.tasksUser}
                />
              ))}
            <UserAddDialog
              disabled={this.props.disabled}
              className="m-1"
              userType={userTypes.REALISATION}
              alreadyAssigned={this.props.usersSR.map((u) => u.user)}
              project={this.props.project}
            />
          </div>
          <p className="m-0">Profils recherchés</p>
          <div className="p-2">
            {this.props.users_needed.map((user_needed) => (
              <UserThumbnail
                disabled={this.props.disabled}
                key={user_needed.id}
                type={userThumbnailTypes.USER_NEEDED}
                data={user_needed}
                icon={<Icon>delete</Icon>}
                onClick={() => {
                  const notificationMessage = 'Profil recherché supprimé'
                  this.props.deleteUserNeeded(
                    user_needed.id,
                    this.props.project.id,
                    (id, response) =>
                      this.props.successNotification(
                        id,
                        response,
                        notificationMessage
                      ),
                    (id, error) => this.props.failureNotificationApi(id, error)
                  )
                }}
              />
            ))}
            <UserNeededAddDialog
              disabled={this.props.disabled}
              project={this.props.project}
              className="m-1"
            />
          </div>
        </Container>
      </Section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  usersSR: getUserSRs(state, ownProps.project.id),
  users_needed: getNeededUsers(state, ownProps.project.id),
})

const mapDispatchToProps = (dispatch) => ({
  deleteUserNeeded: (id, projectId, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.usersNeeded,
        id,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersSection)
