import styled from 'styled-components'

const StyledDiv3 = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`

export default StyledDiv3
