import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import StyledDiv from './styles/StyledDiv'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'

/**
 * TODO A field trying to be reusable with properties, yet it uses the name field explicitely
 * Props :
 *    - disabled, optionnal
 *    - properties, names of the properties
 *    - title, name of this field
 */
class AddItemTextField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      disabled: this.props.disabled,
      isProcessing: false,
    }

    this.handleBoardNameChange = this.handleBoardNameChange.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  handleBoardNameChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  clearInput() {
    let state = {}
    for (let p of Object.keys(this.props.properties)) {
      state[p] = ''
    }
    this.setState(state)
  }

  saveData() {
    if (this.props.properties.name) {
      if (this.state.name === '') {
        this.props.addNotification(
          'Opération échouée',
          'Le nom ne peut pas être vide',
          'danger'
        )
        return
      }
      if (
        this.props.data.some(
          (item) => item.name.toLowerCase() === this.state.name.toLowerCase()
        )
      ) {
        this.props.addNotification(
          'Opération échouée',
          'Cet item existe déjà',
          'danger'
        )
        return
      }
    }

    this.setState({
      disabled: true,
      isProcessing: true,
    })
    let body = {}
    for (let p of Object.keys(this.props.properties)) {
      body[p] = this.state[p]
    }

    if (this.props.additionalInfos)
      body = Object.assign({}, body, this.props.additionalInfos)

    const notificationMessage = this.props.feminine
      ? `${this.props.object} ajoutée`
      : `${this.props.object} ajouté`
    this.props
      .addItem(
        body,
        this.props.belongToProject ? this.props.project.id : null,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.setState({
          name: '',
          disabled: false,
          isProcessing: false,
        })
      })
  }

  render() {
    const name = this.state.name
    return (
      <th colSpan={'100%'}>
        <StyledDiv>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            name={'name'}
            id={`${this.props.title}-input`}
            value={name}
            onChange={this.handleBoardNameChange}
            inputProps={{ maxLength: 20 }}
          />
          <IconButton
            disabled={this.state.disabled}
            aria-label="ajouter"
            size="small"
            onClick={() => this.saveData()}
          >
            {this.state.isProcessing ? (
              <CircularProgress size="sm" />
            ) : (
              <Icon>add</Icon>
            )}
          </IconButton>
        </StyledDiv>
      </th>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(AddItemTextField)
