import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'rgb(230,126,7)',
    border: '1px solid rgb(230,126,7)',
    textTransform: 'none',
  },
}))(Button)

export default ColorButton
