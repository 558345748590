import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Zoom,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: '1px solid lightgray',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}))(TableCell)

class CellWithModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openDialog: false,
    }
  }

  getFullConsums = (consums) => {
    let consumList = []

    consums.forEach((consumWithFilter) => {
      consumWithFilter.consums.forEach((consum) => {
        consumList = [
          ...consumList,
          {
            ...consum,
          },
        ]
      })
    })

    return consumList
  }

  groupConsumsByFilter = (projectConsums) => {
    let projectFilters = []
    let filterNames = []

    projectConsums.forEach((consum) => {
      consum.task.projectFilters.forEach((filter) => {
        if (!filterNames.includes(filter.name)) {
          projectFilters.push(filter)
          filterNames.push(filter.name)
        }
      })
    })

    let groupedConsums = projectFilters.map((filter) => {
      const consums = projectConsums.filter((c) =>
        c.task.projectFilters.find((f) => f.id === filter.id)
      )
      return {
        [filter.name]: consums,
      }
    })

    const consumsWithNoFilters = projectConsums.filter(
      (c) => c.task.projectFilters.length === 0
    )
    if (consumsWithNoFilters.length > 0) {
      groupedConsums = [
        ...groupedConsums,
        { 'Sans filtre': consumsWithNoFilters },
      ]
    }

    return groupedConsums
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true })
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }

  render() {
    const { children, consums, projectName } = this.props

    const projectConsums = this.getFullConsums(consums)
    const groupedConsums = this.groupConsumsByFilter(projectConsums)

    return (
      <>
        <StyledTableCell style={{ padding: '2px' }}>
          <div
            className={'d-flex align-items-center justify-content-between'}
            style={{ fontWeight: 'bold' }}
          >
            {children}
            <IconButton size={'small'} onClick={this.handleOpenDialog}>
              <Icon fontSize={'small'}>info</Icon>
            </IconButton>
          </div>
        </StyledTableCell>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          TransitionComponent={Transition}
        >
          <DialogTitle>{projectName} - Consommations par filtre</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size={'small'}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Filtre</StyledTableCell>
                    <StyledTableCell>Consommations</StyledTableCell>
                    <StyledTableCell>Tâches</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {groupedConsums.map((group, index) => {
                    const [filterName, consums] = Object.entries(group)[0]
                    const total = consums.reduce(
                      (acc, item) => acc + item.amount,
                      0
                    )

                    return (
                      <TableRow key={index}>
                        <StyledTableCell>{filterName}</StyledTableCell>
                        <StyledTableCell>{total}</StyledTableCell>
                        <StyledTableCell>
                          {consums.map((consum) => (
                            <div
                              key={consum.id}
                              className={'d-flex justify-content-between'}
                            >
                              <div style={{ paddingRight: '8px' }}>
                                {consum.task.name}
                              </div>
                              <div>({consum.amount})</div>
                            </div>
                          ))}
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default CellWithModal
