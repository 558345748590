import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { MsalProvider } from '@azure/msal-react'
import store, { history } from './store'
import App from './App'

import './style/style.css'
import './style/bootstrap/bootstrap.min.css'
import './style/atti-bootstrap.css'
import * as serviceWorker from './serviceWorker'
import { msalInstance } from './constants/util'

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
