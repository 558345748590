import React from 'react'
import { Route } from 'react-router'
import { PageContainer } from '..'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PageContainer>
          <Component {...props} />
        </PageContainer>
      )}
    />
  )
}

export default PrivateRoute
