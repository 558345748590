import React from 'react'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import { generateUUID } from '../../../../../../helpers/util'

const ImportDialogTable = ({ data, type }) => {
  const error = 'rgb(255,0,0)'
  const warning = 'rgb(224,217,0)'

  return (
    <div style={{ marginTop: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          fontSize={'large'}
          style={{
            marginRight: '1rem',
            color: type === 'error' ? error : warning,
          }}
        >
          warning
        </Icon>

        {type === 'error' ? (
          <Typography style={{ fontWeight: 'bold' }}>
            Le fichier Excel contient des erreurs ...
          </Typography>
        ) : (
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              Importation réussie!
            </Typography>

            <Typography>
              Attention, certaines tâches n'ont pas été prises en compte ...
            </Typography>
          </div>
        )}
      </div>

      <TableContainer>
        <Table aria-label={'import-dialog-table'} size={'small'}>
          <TableBody>
            {data.map((item) => (
              <TableRow key={generateUUID()}>
                <TableCell>{item.message}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ImportDialogTable
