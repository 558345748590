import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64;
  padding-left: 8px;
  padding-right: 8px;
`

export default StyledDiv
