import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { findItem, getSecondaryRoles } from '../../../../reducers'
import {
  deleteFormEntity,
  postFormEntity,
  updateFormEntity,
} from '../../../../actions/globalActions'
import { itemTypes } from '../../../../actions/itemTypes'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { Tooltip } from '@material-ui/core'

/**
 * Props :
 *    - price, optionnal, the edited price
 *    - project, the edited project,
 *    - disabled, optionnal, is this modal disabled
 *
 * Store :
 *    - SecondaryRoles
 *    - srPrices
 */
class PricesDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModal: false,
      closeAll: false,
      selectedSecondaryRole:
        this.props.price && this.props.price.secondaryRole.id,
      amount: this.props.price ? this.props.price.price : 0,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
    this.handleSelectedSecondaryRole =
      this.handleSelectedSecondaryRole.bind(this)
    this.handleAmountChange = this.handleAmountChange.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleDelete() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: false,
    })
  }

  toggleAll() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: true,
    })
  }

  handleSelectedSecondaryRole(event) {
    this.setState({
      selectedSecondaryRole: event.target.value,
    })
  }

  handleAmountChange({ target: { value } }) {
    this.setState({
      amount: value,
    })
  }

  getText() {
    return this.props.price ? editText : addText
  }

  save(sr) {
    if (!this.state.selectedSecondaryRole) {
      this.props.addNotification(
        'Opération échouée',
        'Veuillez sélectionner un profil',
        'danger'
      )
      return false
    }
    let correctProfile = !this.props.srPrices.some(
      ({ secondaryRole: { id } }) => {
        if (
          this.props.price &&
          sr.id === this.props.price.secondaryRole.id &&
          id === this.props.price.secondaryRole.id
        )
          return false
        else return id === sr.id
      }
    )

    if (!correctProfile)
      this.props.addNotification(
        'Opération échouée',
        'Un seul tarif par profil',
        'danger'
      )
    else if (this.state.amount === '')
      this.props.addNotification('Opération échouée', 'Montant vide', 'danger')
    else if (parseInt(this.state.amount) <= 0)
      this.props.addNotification(
        'Opération échouée',
        'Montant incorrect',
        'danger'
      )
    else {
      if (this.props.price) {
        this.closeAction = () => {
          const notificationMessage = 'Tarif modifié'
          this.props.updatePrice(
            this.props.price.id,
            this.props.project.id,
            {
              secondaryRole: sr,
              price: parseFloat(this.state.amount),
            },
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
        }
        this.toggle()
      } else {
        this.closeAction = () => {
          const notificationMessage = 'Tarif ajouté'
          this.props.addPrice(
            {
              project: this.props.project,
              secondaryRole: sr,
              price: parseFloat(this.state.amount),
            },
            this.props.project.id,
            (id, response) =>
              this.props.successNotification(id, response, notificationMessage),
            (id, error) => this.props.failureNotificationApi(id, error)
          )
        }
        this.toggle()
      }
    }
  }

  render() {
    const { srPrices, price, secondaryRoles } = this.props

    return (
      <>
        <IconButton
          disabled={this.props.disabled}
          aria-label="ajouter un nouveau tarif"
          onClick={this.toggle}
          size="small"
        >
          {price ? (
            <Icon>edit</Icon>
          ) : (
            srPrices.length < secondaryRoles.length && <Icon>add</Icon>
          )}
        </IconButton>
        {!price && srPrices.length === secondaryRoles.length && (
          <Tooltip placement="top" title="Nombre maximal d'ajout atteint">
            <IconButton
              aria-label="Bloqué"
              size="small"
              style={{ color: 'red' }}
            >
              <Icon>block</Icon>
            </IconButton>
          </Tooltip>
        )}
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
        >
          <DialogTitle toggle={this.toggle}>
            {this.getText().header}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <FormLabel htmlFor={'secondaryRoleInput'} m={3}>
                  Profil
                </FormLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="secondaryRoleInput"
                  id="secondaryRoleInput"
                  value={this.state.selectedSecondaryRole}
                  onChange={this.handleSelectedSecondaryRole}
                  defaultValue={secondaryRoles[0]}
                  style={{ width: '100%' }}
                >
                  {secondaryRoles.map((sr) => (
                    <MenuItem value={sr.id} key={sr.id}>
                      {sr.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={'secondaryRoleAmountInput'}
                  sm={3}
                  style={{ paddingTop: '16px' }}
                >
                  Montant
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="number"
                  name="secondaryRoleAmountInput"
                  id="secondaryRoleAmountInput"
                  value={this.state.amount}
                  onChange={this.handleAmountChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="justify-content-center">
            <Button
              color="secondary"
              onClick={() => {
                this.closeAction = () => {}
                this.toggle()
              }}
            >
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.save(
                  this.props.findSecondaryRole(this.state.selectedSecondaryRole)
                )
              }}
            >
              {this.getText().footer}
            </Button>
          </DialogActions>
        </Dialog>
        {this.props.price && (
          <>
            <IconButton
              aria-label="supprimer ce tarif"
              size="small"
              onClick={this.toggleDelete}
            >
              <Icon>delete</Icon>
            </IconButton>
            <Dialog
              open={this.state.deleteModal}
              toggle={this.toggleDelete}
              onExit={() => this.closeAction()}
            >
              <DialogTitle>Confirmation de suppression</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes-vous sûr(e) de vouloir supprimer ce prix ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={this.toggleDelete}>
                  Annuler
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.closeAction = () => {
                      const notificationMessage = 'Tarif supprimé'
                      this.props.deletePrice(
                        price.id,
                        this.props.project.id,
                        (id, response) =>
                          this.props.successNotification(
                            id,
                            response,
                            notificationMessage
                          ),
                        (id, error) =>
                          this.props.failureNotificationApi(id, error)
                      )
                    }
                    this.toggleDelete()
                  }}
                >
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    )
  }
}

const addText = {
  header: "Ajout d'un tarif",
  footer: 'Ajouter',
}

const editText = {
  header: "Edition d'un tarif",
  footer: 'Enregistrer',
}

const mapStateToProps = (state) => ({
  secondaryRoles: getSecondaryRoles(state),
  findSecondaryRole: (id) => findItem(state, itemTypes.secondaryRoles, id),
})

const mapDispatchToProps = (dispatch) => ({
  addPrice: (body, projectId, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.srprices,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  updatePrice: (id, projectId, body, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.srprices,
        id,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  deletePrice: (id, projectId, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.srprices,
        id,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PricesDialog)
