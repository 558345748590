import { connect } from 'react-redux'
import { AdminList } from '../../pages/Admin/components'
import {
  deleteFormEntity,
  postFormEntity,
  updateFormEntity,
} from '../../actions/globalActions'
import { getFilters } from '../../reducers'
import { itemTypes } from '../../actions/itemTypes'

const mapStateToProps = (state, ownProps) => ({
  data: getFilters(state, ownProps.project.id),
  properties: {
    name: 'Nom',
  },
  title: 'Filters',
  object: 'Filtre',
  // feminine définit si l'accord doit se faire
  feminine: false,
  // Est-ce que l'entité est triée dans le store en fonction de l'id du projet ?
  belongToProject: true,
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (body, projectId, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.filters,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  editItem: (id, projectId, body, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.filters,
        id,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  deleteItem: (id, projectId, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.filters,
        id,
        successCallback,
        failureCallback,
        projectId
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
