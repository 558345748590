import React from 'react'

import { userThumbnailTypes } from '../../../../constants/global'
import UserThumbnail from '../../../../components/UserThumbnail'

class ConsumsTotal extends React.Component {
  render() {
    const user = this.props.currentUser
    const total = this.props.total
    return (
      <div style={{ display: 'flex', alignItems: 'center', rowGap: '20px' }}>
        <UserThumbnail
          key={user.id}
          type={userThumbnailTypes.USER}
          data={user}
        />
        <div style={{ paddingLeft: '1rem' }}>
          {user.firstname.slice(0, 1).toUpperCase() +
            user.lastname.slice(0, 2).toUpperCase()}{' '}
          : {total}{' '}
        </div>
      </div>
    )
  }
}
export default ConsumsTotal
