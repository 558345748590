import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchUsers = () => {
  return fetchEntity(itemTypes.users)
}

export const fetchSimpleUsers = () => {
  return fetchEntity(itemTypes.usersSimple)
}

export const fetchUsersByProjectId = (id) => {
  return fetchEntity(otherURLs.projectUsers, id, id)
}

export const fetchUserByEmail = (email) => {
  return fetchEntity(otherURLs.usersEmail, email)
}

export const fetchUsersByTask = (id) => {
  return fetchEntity(otherURLs.tasksUsers, id)
}

export const updateUser = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.users,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteUser = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.users, id, successCallback, failureCallback)
}

export const clearErrorUsers = () => clearErrors(itemTypes.users)
