import React from 'react'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { isFetching } from '../../../../reducers'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'

/**
 * TODO change props to a better named field
 * Props :
 *    - disabled, optionnal
 *    - props, The props of the parent component
 *    - additionnalInfos, optionnal, Infos to add to the body when editing
 *    - onSave, optionnal, callback when editing
 */
class EditListItemDialog extends React.Component {
  closeAction = () => {}

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModal: false,
      closeAll: false,
      disabled: this.props.disabled,
      isProcessing: false,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleDelete() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: false,
    })
  }

  toggleAll() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: true,
    })
  }

  saveData() {
    if (this.props.properties.name) {
      let name = document.getElementById('nameValue').value
      if (name === '') {
        this.props.addNotification(
          'Opération échouée',
          'Le nom ne peut pas être vide',
          'danger'
        )
        return
      }
      if (
        this.props.data.some(
          (item) => item.name.toLowerCase() === name.toLowerCase()
        )
      ) {
        this.props.addNotification(
          'Opération échouée',
          'Cet item existe déjà',
          'danger'
        )
        return
      }
    }

    this.setState({
      disabled: true,
      isProcessing: true,
    })

    let body = {}
    for (let p of Object.keys(this.props.props.properties)) {
      body[p] = document.getElementById(p + 'Value').value
    }

    if (this.props.props.additionalInfos)
      body = Object.assign({}, body, this.props.props.additionalInfos)

    if (this.props.onSave != null) this.props.onSave(body, this.props.item)

    const notificationMessage = this.props.props.feminine
      ? `${this.props.props.object} modifiée`
      : `${this.props.props.object} modifié`
    this.props.props
      .editItem(
        this.props.item.id,
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.setState({
          disabled: false,
          isProcessing: false,
        })
      })
  }

  render() {
    return (
      <>
        <IconButton
          disabled={this.state.disabled}
          aria-label="éditer cet item"
          size="small"
          onClick={this.toggle}
        >
          {this.state.isProcessing ? (
            <CircularProgress size="sm" />
          ) : (
            <Icon>edit</Icon>
          )}
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          centered
          onExit={() => this.closeAction()}
        >
          {!this.props.props.feminine ? (
            <DialogTitle toggle={this.toggle}>
              Édition d'un {this.props.props.object.toLowerCase()}
            </DialogTitle>
          ) : (
            <DialogTitle toggle={this.toggle}>
              Édition d'une {this.props.props.object.toLowerCase()}
            </DialogTitle>
          )}
          {this.props.isFetching ? (
            <CircularProgress />
          ) : (
            <>
              <DialogContent>
                {Object.keys(this.props.props.properties).map((p) => (
                  <FormGroup key={p}>
                    <FormLabel for={p + 'Value'}>
                      {this.props.props.properties[p]}
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      maxLength="20"
                      name={p + 'Value'}
                      id={p + 'Value'}
                      defaultValue={this.props.item[p]}
                    />
                  </FormGroup>
                ))}
              </DialogContent>
              <DialogActions className="justify-content-center">
                <Button
                  color="secondary"
                  onClick={() => {
                    this.closeAction = () => {}
                    this.toggle()
                  }}
                >
                  Fermer
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.closeAction = () => this.saveData()
                    this.toggle()
                  }}
                >
                  Valider
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
})

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditListItemDialog)
