import { initialRequestState, status } from '../../constants/global'
import { onFailure, onFetching } from '../../helpers/reducers'
import {
  ACTION_CLEAR_ADDED,
  ACTION_CLEAR_DELETED,
  ACTION_CLEAR_ERRORS,
  ACTION_DELETE,
  ACTION_FETCH,
  ACTION_FORM_DELETE,
  ACTION_FORM_POST,
  ACTION_FORM_PUT,
  ACTION_POST,
  ACTION_PUT,
} from '../../actions/actionTypes'

let localCounter = 0

export const getLocalId = () => {
  return 'Local-Id-' + localCounter++
}

export const requestsReducer = (state = initialRequestState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (Array.isArray(state.items)) {
        return {
          ...state,
          items: state.items.filter(
            (item) => !(!item.id || item.id.startsWith('Local'))
          ),
        }
      } else {
        const items = { ...state.items }

        for (let entry in items) {
          items[entry] = items[entry].filter(
            (item) => !(!item.id || item.id.startsWith('Local'))
          )
        }

        return {
          ...state,
          items: items,
        }
      }
    case ACTION_FETCH:
    case ACTION_PUT:
    case ACTION_POST:
    case ACTION_DELETE:
      if (action.status === status.IS_FETCHING)
        return handleRequestFetching(onFetching(state), action)
      if (action.status === status.FAILURE)
        return handleRequestFailure(onFailure(state, action.error), action)

      // Clear errors and no longer fetching
      let newState = Object.assign({}, state, {
        isFetching: false,
        error: null,
      })

      // Every request can send back multiple entities, we treat them one at a time
      // Example : deleting a project status can change the order of other statuses
      const isArray = Array.isArray(action.data)
      let array = isArray ? action.data : [action.data]
      for (let element of array)
        newState = Object.assign(
          {},
          newState,
          handleRequestSuccess(
            newState,
            Object.assign({}, action, { data: element }),
            isArray
          )
        )

      return newState
    case ACTION_FORM_PUT:
    case ACTION_FORM_POST:
    case ACTION_FORM_DELETE:
      // Form actions are not supposed to fail, and the fetching status is useless since there is no call
      if (
        action.status === status.IS_FETCHING ||
        action.status === status.FAILURE
      )
        return state

      let newFormState = state
      let fromArray = Array.isArray(action.data) ? action.data : [action.data]
      for (let element of fromArray)
        newFormState = Object.assign(
          {},
          newFormState,
          handleFormSuccess(
            newFormState,
            Object.assign({}, action, { data: element })
          )
        )

      return newFormState
    case ACTION_CLEAR_ERRORS:
    case ACTION_CLEAR_ADDED:
    case ACTION_CLEAR_DELETED:
      if (
        action.status === status.IS_FETCHING ||
        action.status === status.FAILURE
      )
        return state

      return handleClearSuccess(state, action)
    default:
      return state
  }
}

export const handleRequestSuccess = (state, action, isArray) => {
  switch (action.type) {
    case ACTION_FETCH:
      if (isArray) {
        const alreadyFetched = action.projectId
          ? state.items[action.projectId] &&
            state.items[action.projectId].find(
              (item) => item.id === action.data.id
            )
          : state.items.find((item) => item.id === action.data.id)

        let mutableState
        if (alreadyFetched)
          mutableState = {
            ...state,
            items: action.projectId
              ? {
                  ...state.items,
                  [action.projectId]: state.items[action.projectId].filter(
                    (item) => item.id !== alreadyFetched.id
                  ),
                }
              : state.items.filter((item) => item.id !== alreadyFetched.id),
          }

        const items = action.projectId
          ? {
              ...state.items,
              [action.projectId]: alreadyFetched
                ? [...mutableState.items[action.projectId], action.data]
                : state.items[action.projectId]
                ? [...state.items[action.projectId], action.data]
                : [action.data],
            }
          : alreadyFetched
          ? [...mutableState.items, action.data]
          : [...state.items, action.data]

        return {
          ...state,
          initialItems: items,
          items,
        }
      } else {
        const index = action.projectId
          ? state.items[action.projectId] &&
            state.items[action.projectId].findIndex(
              (item) => item.id === action.data.id
            )
          : state.items.findIndex((item) => item.id === action.data.id)

        const alreadyFetched = index !== -1

        let mutableState
        if (alreadyFetched) {
          mutableState = { ...state }
          action.projectId
            ? mutableState.items[action.projectId].splice(index, 1, action.data)
            : mutableState.items.splice(index, 1, action.data)
        }

        const items = action.projectId
          ? {
              ...state.items,
              [action.projectId]: alreadyFetched
                ? mutableState.items
                : state.items[action.projectId]
                ? [...state.items[action.projectId], action.data]
                : [action.data],
            }
          : alreadyFetched
          ? mutableState.items
          : [...state.items, action.data]

        return {
          ...state,
          initialItems: items,
          items,
        }
      }
    case ACTION_PUT:
      const initialItem = action.projectId
        ? state.items[action.projectId] &&
          state.items[action.projectId].find(
            (item) => item.id === action.data.id
          )
        : state.items.find((item) => item.id === action.data.id)

      if (!initialItem) return state

      const newItem = { ...initialItem, ...action.data }
      return {
        ...state,
        initialItems: action.projectId
          ? {
              ...state.initialItems,
              [action.projectId]: state.initialItems[action.projectId].map(
                (item) => (item.id === action.data.id ? newItem : item)
              ),
            }
          : state.initialItems.map((item) =>
              item.id === action.data.id ? newItem : item
            ),
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId].map((item) =>
                item.id === action.data.id ? newItem : item
              ),
            }
          : state.items.map((item) =>
              item.id === action.data.id ? newItem : item
            ),
        waitingRequests: state.waitingRequests.filter(
          (req) => req.id !== action.data.id
        ),
        addedItems: [...state.addedItems, action.data],
      }
    case ACTION_POST:
      if (action.id) {
        // Post of a local item
        // Edit the local item to give it id and properties sent by API
        // Also add to addedItems
        const initialItem = action.projectId
          ? state.items[action.projectId].find((item) => item.id === action.id)
          : state.items.find((item) => item.id === action.id)

        const newItem = { ...initialItem, ...action.data }
        return {
          ...state,
          initialItems: action.projectId
            ? {
                ...state.initialItems,
                [action.projectId]: state.initialItems[action.projectId]
                  ? state.initialItems[action.projectId].map((item) =>
                      item.id === action.id ? newItem : item
                    )
                  : [newItem],
              }
            : state.initialItems.map((item) =>
                item.id === action.id ? newItem : item
              ),
          items: action.projectId
            ? {
                ...state.items,
                [action.projectId]: state.items[action.projectId]
                  ? state.items[action.projectId].map((item) =>
                      item.id === action.id ? newItem : item
                    )
                  : [newItem],
              }
            : state.items.map((item) =>
                item.id === action.id ? newItem : item
              ),
          waitingRequests: state.waitingRequests.filter(
            (req) => req.id !== action.id
          ),
          addedItems: [...state.addedItems, action.data],
        }
      }

      const initialItemPost =
        action.projectId && state.items[action.projectId]
          ? state.items[action.projectId].find(
              (item) => item.id === action.data.id
            )
          : state.items.find((item) => item.id === action.data.id)

      // This item already exists, edit it
      if (initialItemPost) {
        const newItemPost = { ...initialItemPost, ...action.data }
        return {
          ...state,
          initialItems: action.projectId
            ? {
                ...state.initialItems,
                [action.projectId]: state.initialItems[action.projectId].map(
                  (item) => (item.id === action.data.id ? newItemPost : item)
                ),
              }
            : state.initialItems.map((item) =>
                item.id === action.data.id ? newItemPost : item
              ),
          items: action.projectId
            ? {
                ...state.items,
                [action.projectId]: state.items[action.projectId].map((item) =>
                  item.id === action.data.id ? newItemPost : item
                ),
              }
            : state.items.map((item) =>
                item.id === action.data.id ? newItemPost : item
              ),
          waitingRequests: state.waitingRequests.filter(
            (req) => req.id !== action.data.id
          ),
        }
      }

      // Create the item, add it to added items
      return {
        ...state,
        initialItems: action.projectId
          ? {
              ...state.initialItems,
              [action.projectId]: state.initialItems[action.projectId]
                ? [...state.initialItems[action.projectId], action.data]
                : [action.data],
            }
          : [...state.initialItems, action.data],
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId]
                ? [...state.items[action.projectId], action.data]
                : [action.data],
            }
          : [...state.items, action.data],
        waitingRequests: state.waitingRequests.filter(
          (req) => req.id !== action.id
        ),
        addedItems: [...state.addedItems, action.data],
      }
    case ACTION_DELETE:
      return {
        ...state,
        initialItems: action.projectId
          ? {
              ...state.initialItems,
              [action.projectId]: state.initialItems[action.projectId].filter(
                (item) => item.id !== action.id
              ),
            }
          : state.initialItems.filter((item) => item.id !== action.id),
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId].filter(
                (item) => item.id !== action.id
              ),
            }
          : state.items.filter((item) => item.id !== action.id),
        waitingRequests: state.waitingRequests.filter(
          (req) => req.id !== action.id
        ),
        deletedItems: [...state.deletedItems, action.data],
      }
    default:
      return state
  }
}

export const handleFormSuccess = (state, action) => {
  switch (action.type) {
    case ACTION_FORM_PUT:
      // If the object is already modified or created locally, update the request
      // If the item is locally created, the request stays a POST
      // Thus, every POST request must be able to handle parameters from PUT
      let existingRequest = state.requests.find((req) => req.id === action.id)
      let requests = existingRequest
        ? state.requests.map((req) =>
            req === existingRequest
              ? Object.assign({}, req, {
                  body: Object.assign({}, req.body, dataToRequest(action.data)),
                })
              : req
          )
        : [
            ...state.requests,
            {
              actionType: ACTION_PUT,
              itemType: action.itemType,
              id: action.id,
              def: action.def,
              body: dataToRequest(action.data),
              projectId: action.projectId,
              successCallback: action.successCallback,
              failureCallback: action.failureCallback,
            },
          ]

      // Else, create the request
      return {
        ...state,
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId].map((item) =>
                item.id === action.id ? { ...item, ...action.data } : item
              ),
            }
          : state.items.map((item) =>
              item.id === action.id ? { ...item, ...action.data } : item
            ),
        requests,
      }
    case ACTION_FORM_POST:
      // If a local id is given, use it, else generate one
      let id = action.id ? action.id : getLocalId()
      let request = {
        actionType: ACTION_POST,
        itemType: action.itemType,
        id: id,
        def: action.def,
        projectId: action.projectId,
        body: dataToRequest(action.data),
        successCallback: action.successCallback,
        failureCallback: action.failureCallback,
      }

      const item = { ...action.data, id: id }

      return {
        ...state,
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId]
                ? [...state.items[action.projectId], item]
                : [item],
            }
          : [...state.items, item],
        requests: [...state.requests, request],
      }
    case ACTION_FORM_DELETE:
      if (!action.id.startsWith('Local')) {
        // Existing item on API
        // Just remove it, no request sent
        let requestDelete = {
          actionType: ACTION_DELETE,
          itemType: action.itemType,
          def: action.def,
          id: action.id,
          projectId: action.projectId,
          successCallback: action.successCallback,
          failureCallback: action.failureCallback,
        }

        // Add delete request
        return {
          ...state,
          items: action.projectId
            ? {
                ...state.items,
                [action.projectId]: state.items[action.projectId].filter(
                  (item) => item.id !== action.id
                ),
              }
            : state.items.filter((item) => item.id !== action.id),
          requests: [...state.requests, requestDelete],
        }
      }

      return {
        ...state,
        items: action.projectId
          ? {
              ...state.items,
              [action.projectId]: state.items[action.projectId].filter(
                (item) => item.id !== action.id
              ),
            }
          : state.items.filter((item) => item.id !== action.id),
        requests: state.requests.filter((req) => req.id !== action.id),
      }
    default:
      return state
  }
}

export const handleRequestFailure = (state, action) => {
  // Reset to initialItem when request fails
  // If it is POST, do nothing
  switch (action.type) {
    case ACTION_PUT:
      let itemsArrayPut = Array.from(state.items)
      let initialitemsArrayPut = Array.from(state.initialItems)
      return {
        ...state,
        items: action.projectId
          ? {
              ...itemsArrayPut,
              [itemsArrayPut]: itemsArrayPut[action.projectId].map((item) =>
                item.id === action.id
                  ? initialitemsArrayPut[action.projectId].find(
                      (initialItem) => initialItem.id === action.id
                    )
                  : item
              ),
            }
          : itemsArrayPut.map((item) =>
              item.id === action.id
                ? initialitemsArrayPut.find(
                    (initialItem) => initialItem.id === action.id
                  )
                : item
            ),
      }
    case ACTION_DELETE:
      let itemsArrayDelete = Array.from(state.items)
      let initialitemsArrayDelete = Array.from(state.initialItems)
      return {
        ...state,
        items: action.projectId
          ? {
              ...itemsArrayDelete,
              [action.projectId]: [
                ...initialitemsArrayDelete[action.projectId],
                initialitemsArrayDelete[action.projectId].find(
                  (initialItem) => initialItem.id === action.id
                ),
              ],
            }
          : [
              ...itemsArrayDelete,
              initialitemsArrayDelete.find(
                (initialItem) => initialItem.id === action.id
              ),
            ],
      }
    default:
      return state
  }
}

export const handleRequestFetching = (state, action) => {
  switch (action.type) {
    case ACTION_POST:
    case ACTION_DELETE:
    case ACTION_PUT:
      let existingRequest = state.requests.find((req) => req.id === action.id)
      if (existingRequest)
        return Object.assign({}, state, {
          waitingRequests: [
            ...state.waitingRequests,
            state.requests.find((req) => req.id === action.id),
          ],
          requests: state.requests.filter((req) => req.id !== action.id),
        })
      return state
    default:
      return state
  }
}

export const handleClearSuccess = (state, action) => {
  switch (action.type) {
    case ACTION_CLEAR_ERRORS:
      return Object.assign({}, state, {
        error: null,
      })
    case ACTION_CLEAR_ADDED:
      return Object.assign({}, state, {
        addedItems: [],
      })
    case ACTION_CLEAR_DELETED:
      return Object.assign({}, state, {
        deletedItems: [],
      })
    default:
      return state
  }
}

/**
 * Flatten the state to a correct request
 * Example : Bills contains billStatus, an object with id and name
 * Requests only accept id, thus we only keep billStatus: id
 *
 * When using a form request, always use the full object, not just the id
 */
const dataToRequest = (data) => {
  let requestData = {}
  for (let key of Object.keys(data))
    if (data[key] instanceof Object)
      Object.assign(requestData, { [key]: data[key].id })
    else Object.assign(requestData, { [key]: data[key] })
  return requestData
}
