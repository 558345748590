import React from 'react'

const CenteredDiv = (props) => (
  <div className="vertical-center d-flex justify-content-center w-100">
    <div className="d-flex flex-column justify-content-center">
      {props.children}
    </div>
  </div>
)

export default CenteredDiv
