const deliveries = [
  {
    date: '05 Oct 2020',
    front: {
      version: '3.0.0',
      tickets: [],
      message: 'Mise en production',
    },
  },
  {
    date: '23 Oct 2020',
    front: {
      version: '3.0.2',
      tickets: [],
      message: 'Correctifs mineurs',
    },
  },
  {
    date: '30 Oct 2020',
    front: {
      version: '3.0.8',
      tickets: [
        {
          number: '327',
          name: 'Impossible de créer une tâche',
        },
        {
          number: '328',
          name: 'Déconnexion',
        },
        {
          number: '329',
          name: 'Erreur rencontré',
        },
      ],
    },
  },
  {
    date: '17 Nov 2020',
    front: {
      version: '3.0.18',
      tickets: [
        {
          number: '180',
          name: '[US#179] Différencier les utilisateurs désactivés',
        },
        {
          number: '284',
          name: "Ajouter fonctionnalité s'assigner la tâche",
        },
        {
          number: '314',
          name: 'Passer la tâche à terminé lorsque le RAF passe à 0',
        },
        {
          number: '332',
          name: 'Les boards pour un projet forfait ne doivent pas être éditable',
        },
        {
          number: '333',
          name: 'Afficher la photo de profil',
        },
        {
          number: '334',
          name: "Modification de l'affichage des livraisons",
        },
        {
          number: '335',
          name: "EditTask - s'assigner une tâche que si on appartient au projet",
        },
        {
          number: '336',
          name: 'ProjectTask - pour un projet forfait drag&drop toujours actif',
        },
        {
          number: '337',
          name: "ProjectTask - après suppression pas de mise à jour de l'ordre des board",
        },
        {
          number: '339',
          name: "EditTask - erreur s'assigner une nouvelle tâche",
        },
      ],
    },
  },
  {
    date: '10 Mai 2021',
    front: {
      version: '3.0.38',
      tickets: [
        {
          number: '341',
          name: "Login - Photo introuvable causant des bugs d'affichage sur les avatars",
        },
        {
          number: '342',
          name: "Pages affichant l'icône de l'utilisateur - Initiales non affichées pour certains utilisateurs",
        },
        {
          number: '343',
          name: "Page Admin - Affichage vide lors de la suppression d'un élément unique d'une page",
        },
        {
          number: '344',
          name: "Page Edition d'un projet - Bugs de gestion des utilisateurs assignés au projet",
        },
        {
          number: '345',
          name: "Page Edition d'un projet & Dashboard - Bugs de gestion de projets",
        },
        {
          number: '346',
          name: 'Page Board - Bug de gestion du Board',
        },
        {
          number: '347',
          name: "Page Edition d'une tâche - Crash lors d'un clic sur le bouton Editer ou sur le cadenas quand l'utilisateur n'est pas assigné",
        },
        {
          number: '348',
          name: "Page Edition d'une tâche - Crash lors de la modification d'une tâche avec une charge client de 0",
        },
        {
          number: '350',
          name: 'Page Board & Dashboard- Bug de gestion des boards, sprints et tâches',
        },
        {
          number: '351',
          name: 'Page Dashboard - Revisite de la page Dashboard',
        },
        {
          number: '353',
          name: 'Page EditProject - Adaptation des prices d\'un projet avec le champ "secondaryRole"',
        },
        {
          number: '354',
          name: "Page Edition d'un projet - Bug de gestion des filtres",
        },
        {
          number: '355',
          name: "Page Board - Clic sur les tâches pas intuitifs pour l'accès à la fiche correspondante",
        },
        {
          number: '356',
          name: "Page Edition d'un projet - Saisie des dates non conforme",
        },
        {
          number: '357',
          name: "Page Statistics - Crash lors d'un accès à la page pour un projet Forfait ou TMA",
        },
        {
          number: '359',
          name: 'Redux - Mises à jour du store',
        },
        {
          number: '360',
          name: "Appli en mode membre - Bugs de gestion des droits d'accès",
        },
        {
          number: '361',
          name: 'Page EditTask - Remplacer "Charge visible" par "charge"',
        },
        {
          number: '362',
          name: 'Page EditProject - Gestion du projet par un membre CP',
        },
      ],
    },
  },
  {
    date: '03 Juin 2021',
    front: {
      version: '3.1.11',
      tickets: [
        {
          number: '229',
          name: 'Intégration en masse',
        },
        {
          number: '331',
          name: 'Refonte design page Task',
        },
        {
          number: '363',
          name: 'Bug affichage du slider',
        },
        {
          number: '364',
          name: 'ProjectTasks - Duplication des boards en changeant de projet',
        },
        {
          number: '365',
          name: "Import Excel - Popup d'import affichant la liste des erreurs / warnings",
        },
        {
          number: '367',
          name: 'page Board : Marge supplémentaire',
        },
        {
          number: '369',
          name: 'Projet Agile: Déplacement à travers les sprints impossible',
        },
        {
          number: '370',
          name: 'Edition projet Agile: Numéro de sprint',
        },
      ],
    },
  },
  {
    date: '14 Juin 2021',
    front: {
      version: '3.1.18',
      tickets: [
        {
          number: '368',
          name: "Hauteur minimal d'un board",
        },
        {
          number: '371',
          name: 'Store: Hiérarchie des items',
        },
        {
          number: '372',
          name: 'Page ProjectTasks - Gestion des boards',
        },
      ],
    },
  },
  {
    date: '20 Août 2021',
    front: {
      version: '3.1.34',
      tickets: [
        {
          number: '376',
          name: 'Mes saisies',
        },
        {
          number: '377',
          name: 'Tableau de bord - Recherche',
        },
        {
          number: '378',
          name: 'Mes projets - Recherche',
        },
        {
          number: '379',
          name: 'Boards - recherche',
        },
        {
          number: '380',
          name: 'Affichage des tâches',
        },
        {
          number: '381',
          name: 'Tâches - mise en avant',
        },
        {
          number: '382',
          name: "Page récapitulatif saisie des membres d'un projet",
        },
        {
          number: '383',
          name: 'Menu - Icone de profil',
        },
        {
          number: '384',
          name: 'Dashboard - Modification de la rubrique "Mes tâches"',
        },
        {
          number: '385',
          name: 'Project Tasks - Drag n Drop et filtres',
        },
        {
          number: '386',
          name: 'Project Tasks - Bug lors de la mise à 0 du RAF sur une tâche',
        },
        {
          number: '387',
          name: "Sauvegarde des préférences d'affichage des tâches",
        },
        {
          number: '388',
          name: 'Corrections de bugs divers',
        },
        {
          number: '390',
          name: 'Project Tasks - Amélioration du bandeau de sprints',
        },
        {
          number: '391',
          name: 'Liens vers les projets du tableau mensuel de la page Consums manquants',
        },
      ],
    },
  },
  {
    date: '23 Août 2021',
    front: {
      version: '3.1.41',
      tickets: [
        {
          number: '392',
          name: "Adaptation du traitement des données suite à l'optimisation des Groups back",
        },
        {
          number: '393',
          name: 'Corrections de bugs',
        },
        {
          number: '394',
          name: 'Dashboard - Carousel des projets non centré',
        },
        {
          number: '395',
          name: 'Dashboard - Affichage des tâches de projets terminés anormal',
        },
        {
          number: '396',
          name: "Projects - La page n'affiche pas les projets de l'utilisateur pour un membre",
        },
        {
          number: '397',
          name: "Dashboard - Le tableau apparait alors qu'aucune tâche ne peut être affichée",
        },
      ],
    },
  },
  {
    date: '21 Septembre 2021',
    front: {
      version: '3.1.46',
      tickets: [
        {
          number: '402',
          name: 'Mauvaise récupération des consommations',
        },
        {
          number: '401',
          name: 'Projet Forfait - Impossible de déplacer une tâche pour un membre',
        },
        {
          number: '400',
          name: 'EditTask - Assigner ne fonctionne pas',
        },
        {
          number: '399',
          name: 'Boards - Ajout tâche en mode tableau non prévu pour les membres',
        },
      ],
    },
  },
  {
    date: '30 Nov 2021',
    front: {
      version: '3.1.50',
      tickets: [
        {
          number: '404',
          name: "Icônes utilisateurs - Zone de hover de taille différente de l'icône",
        },
        {
          number: '403',
          name: 'ProjectTasks - Agencer la liste des labels',
        },
        {
          number: '398',
          name: 'EditTask - Trier les consommations par date et ajouter une pagination',
        },
      ],
    },
  },
  {
    date: '15 Juin 2022',
    api: {
      version: '3.2.0',
      tickets: [
        {
          number: '12',
          name: 'Remise en place de la CI',
        },
        {
          number: '2',
          name: 'Synthèse du projet: correction des valeurs incohérentes',
        },
        {
          number: '10',
          name: 'Vue tabulaire: pouvoir choisir le nombre de résultats affichés',
        },
        {
          number: '11',
          name: 'Vue tabulaire: ajout de la colonne "Charge client"',
        },
        {
          number: '3',
          name: 'Pouvoir ajouter une tâche à 0',
        },
        {
          number: '4',
          name: 'Pouvoir ajouter une tâche de même nom',
        },
      ],
    },
  },
  {
    date: '18 Août 2022',
    api: {
      version: '3.2.1',
      tickets: [
        {
          number: '15',
          name: 'Ajout tâche rapide',
        },
        {
          number: '52',
          name: 'Limite caractère nom de projet/ client',
        },
        {
          number: '66',
          name: 'Modification requête SQL et App/Repository',
        },
        {
          number: '16',
          name: 'Evolutions sur les filtres',
        },
        {
          number: '41',
          name: "Impossible d'importer un fichier contenant des tâches avec le même nom",
        },
        {
          number: '30',
          name: 'Saisie supérieure à 1 sur un même jour',
        },
        {
          number: '9',
          name: 'Vue tabulaire - Totaux',
        },
        {
          number: '45',
          name: "Problème d'accessibilité",
        },
        {
          number: '46',
          name: 'Requête récupération utilisateur',
        },
        {
          number: '7',
          name: 'Projet - Liste Kanban',
        },
        {
          number: '29',
          name: 'Suppression de board pas prise en compte',
        },
        {
          number: '14',
          name: 'Divers ajustements ergonomiques',
        },
        {
          number: '34',
          name: "Suppresion d'un profil dans la partie administration",
        },
        {
          number: '5',
          name: 'Dupliquer une tâche',
        },
        {
          number: '35',
          name: 'Edition de données dans la partie administration',
        },
        {
          number: '18',
          name: 'Tâche - Anomalie modification du nom de la tâche',
        },
        {
          number: '6',
          name: 'Export excel',
        },
        {
          number: '17',
          name: "Cartes projet - problème d'affichage",
        },
        {
          number: '19',
          name: 'Barre de recherche dans un projet',
        },
      ],
    },
  },
  {
    date: '19 Octobre 2023',
    api: {
      version: '3.2.2',
      tickets: [
        {
          number: '67',
          name: 'Evolution écran tabulation projet',
        },
        {
          number: '58',
          name: 'Evolution sur la page statistique',
        },
        {
          number: '60',
          name: 'Ajout des consommations totales',
        },
        {
          number: '47',
          name: 'Charge client - Visible ou non visible',
        },
        {
          number: '89',
          name: 'Ouverture des tâches dans un nouvel onglet',
        },
        {
          number: '62',
          name: "Ajout temps session lorsque l'on est actif",
        },
        {
          number: '69',
          name: 'Ordre affichage tâche vue tableau',
        },
        {
          number: '117',
          name: "Récupération des données de l'utilisateur au chargement de la page",
        },
        {
          number: '116',
          name: 'Accueil / Liste des projets - Suppression utilisateur',
        },
        {
          number: '105',
          name: "Ajout d'une tache impossible",
        },
        {
          number: '104',
          name: "Dédoublement d'une tâche lors du changement de bord",
        },
        {
          number: '103',
          name: 'Problème de chargement des projets',
        },
        {
          number: '49',
          name: "Mauvais nom lors de la suppression d'un board",
        },
        {
          number: '54',
          name: 'Nom de tâche trop long',
        },
        {
          number: '61',
          name: 'Problème de pagination',
        },
        {
          number: '102',
          name: 'Gestion des droits des utilisateurs',
        },
        {
          number: '32',
          name: "Soucis enregistrement modification d'un projet",
        },
        {
          number: '97',
          name: "Ne pas perdre la connexion à l'ouverture dans un nouvel onglet",
        },
        {
          number: '99',
          name: "Bug à la création/modification d'une tâche",
        },
        {
          number: '98',
          name: "Dockerisation de l'environnement de dev",
        },
        {
          number: '59',
          name: "Suppression d'un utilisateur dans un projet",
        },
        {
          number: '55',
          name: "Toast d'erreur au second plan",
        },
        {
          number: '57',
          name: 'Montants tarifs',
        },
        {
          number: '42',
          name: 'Modification charge client inférieure à la charge visible',
        },
        {
          number: '31',
          name: "Valider un ajout de lien vide (Références d'une tâche)",
        },
        {
          number: '53',
          name: "Modification d'une description de tâche",
        },
        {
          number: '27',
          name: "Édition d'un commentaire",
        },
        {
          number: '38',
          name: 'Visualisation des projets supprimés',
        },
        {
          number: '43',
          name: 'Nom de projet déjà utilisé pour un client',
        },
        {
          number: '51',
          name: 'Pas de profil renseigné ajout tarif',
        },
        {
          number: '50',
          name: "Problème lors de l'ajout d'une compétence vide",
        },
        {
          number: '56',
          name: "Pages vides dans la page d'administration",
        },
        {
          number: '70',
          name: 'Bouton hover supprimer mal placé',
        },
        {
          number: '21',
          name: "Annulation d'une modification de nom de tâche",
        },
        {
          number: '26',
          name: 'Charge supérieure à la charge client',
        },
        {
          number: '40',
          name: 'Doublons Projets sur Dashboard / projets terminés',
        },
      ],
    },
  },
]

export default deliveries
