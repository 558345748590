import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import moment from 'moment'
import { CellWithPopover } from '../index'

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: '1px solid lightgray',
    textAlign: 'left',
    backgroundColor: 'white',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))(TableCell)

const TotalTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    border: '1px solid lightgray',
    fontWeight: 'bold',
    textAlign: 'left',
  },
}))(TableCell)

class ProjectConsumsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      page: 0,
      rowsPerPage: 5,
    }
  }

  getWeek = () => {
    return Array.from({ length: 7 }, (x, i) =>
      moment(this.props.startWeek).startOf('week').add(i, 'days').toDate()
    )
  }

  getData = (consums, dates, users) => {
    let totals = [0, 0, 0, 0, 0, 0, 0]

    const usersData = users.map((user) => {
      let index = 0
      const name = `${user.firstname.slice(0, 1).toUpperCase()}${user.lastname
        .slice(0, 2)
        .toUpperCase()}`
      const dateConsums = dates.map((date) => {
        const dateConsum = this.getDayConsumsByUser(consums, date, user)
        totals[index] =
          totals[index] + dateConsum.reduce((acc, item) => acc + item.amount, 0)
        index++
        return dateConsum
      })
      return { name: name, consums: dateConsums }
    })

    return { users: usersData, totals: totals }
  }

  getDayConsumsByUser = (consums, date, user) => {
    return consums.filter((consum) => {
      let d = new Date(consum.date)
      d.setHours(0, 0, 0, 0)
      return d.getTime() === date.getTime() && consum.user.id === user.id
    })
  }

  render() {
    const { consums, users } = this.props
    const dates = this.getWeek()
    const data = this.getData(consums, dates, users)

    return (
      <>
        <TableContainer>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Ressource</StyledTableCell>
                {dates.map((date, index) => (
                  <StyledTableCell key={'datecol-' + index}>
                    {moment(date).format('ddd D MMMM')}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.isLoading ? (
                <TableRow>
                  <StyledTableCell colSpan={8} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {data.users.map((item, index) => (
                    <TableRow key={'user-' + index}>
                      <StyledTableCell>{item.name}</StyledTableCell>
                      {item.consums.map((consum, index) => (
                        <CellWithPopover
                          key={'consum-' + index}
                          consums={consum}
                        />
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TotalTableCell>Total</TotalTableCell>
                    {data.totals.map((total, index) => (
                      <TotalTableCell key={'total-' + index}>
                        {total}
                      </TotalTableCell>
                    ))}
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

export default ProjectConsumsTable
