import React from 'react'
import Select from 'react-select'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  DialogActions,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import { Edit } from '@material-ui/icons'
import { filterTasks } from '../../../../helpers/util'
import TaskTableHead from './components/TaskTableHead'
import {
  checkUserPrivileges,
  getComparator,
  stableSort,
} from '../../../../helpers'
import clsx from 'clsx'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import routes from '../../../../config/routes'
import {
  isForfaitProjectType,
  userListButtonAction,
} from '../../../../constants/global'
import DeleteTask from '../DeleteTask'
import AddTask from '../AddTask'
import CopyTask from '../CopyTask'
import { AssignSection } from '../../../EditTask/components'
import {
  updateTask,
  deleteTask,
} from '../../../../actions/requests/tasksActions'
import {
  failureNotificationApi,
  successNotification,
  addNotification,
} from '../../../../actions/temporaries/notificationActions'
import { fetchUsersByProjectId } from '../../../../actions/requests/usersActions'
import { getUserSRs, isFetching } from '../../../../reducers'
import Input from '@material-ui/core/Input'
import FilterSelect from '../FilterSelect'
import ColorButton from '../../../../components/ColorButton'
import { UsersListContainer } from '../../../../components'
import DialogContentText from '@material-ui/core/DialogContentText'
import User from '../../../EditProject/components/User'
import Icon from '@material-ui/core/Icon'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  table: {
    backgroundColor: 'white',
    border: '1px solid lightgray',
  },
  cell: {
    border: '1px solid lightgray',
  },
  headCell: {
    fontWeight: 'bold',
  },
  textCell: {
    minWidth: '150px',
    maxWidth: '280px',
  },
  textCenteredCell: {
    textAlign: 'center',
  },
  linkCell: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
})
const StyledTableHead = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))(TableCell)

const defaultHeadCells = [
  { id: 'filter', numeric: false, isLarge: true, label: 'Filtre' },
  { id: 'ref', numeric: false, isLarge: false, label: 'Ref' },
  { id: 'name', numeric: false, isLarge: true, label: 'Tâche' },
  { id: 'assigned', numeric: false, isLarge: false, label: 'Affecté' },
  { id: 'workload', numeric: true, isLarge: false, label: 'Charge' },
  { id: 'consum', numeric: true, isLarge: false, label: 'Consommé' },
  { id: 'remaining', numeric: true, isLarge: false, label: 'RAF' },
  { id: 'diff', numeric: true, isLarge: false, label: 'Ecart' },
]

class TaskTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: '',
      page: 0,
      rowsPerPage: 15,
      actualBoard: this.props.boards,
      headCells: defaultHeadCells,
      openFilterList: false,
      openAssignList: false,
      selectedTasks: [],
      multipleRemainingWorkload: null,
      multipleAssigned: null,
      saveMultiAssigned: null,
      multipleFiltersList: [],
      saveFiltersList: [],
      formOpen: false,
      addedUser: [],
      modifiedTasks: [],
      showChargeClient: this.props.showChargeClient,
      clearFilters: false
    }

    if (
      checkUserPrivileges(this.props.currentUser, this.props.project) &&
      this.props.showChargeClient
    ) {
      if (!defaultHeadCells.find((header) => header.id === 'expected_workload'))
        defaultHeadCells.splice(4, 0, {
          id: 'expected_workload',
          numeric: true,
          isLarge: false,
          label: 'Charge client',
        })
    } else {
      //remove header if exist
      let index = defaultHeadCells.findIndex(
        (header) => header.id === 'expected_workload'
      )
      if (index !== -1) {
        defaultHeadCells.splice(index, 1)
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentUser, project, showChargeClient } = this.props

    const totals = this.getAllTotal()
    this.props.updateValues(totals)

    if (
      this.state.multipleRemainingWorkload === '' &&
      prevState.multipleRemainingWorkload !== ''
    ) {
      this.setState({
        multipleRemainingWorkload: -1,
      })
    }

    if (this.state.modifiedTasks !== prevState.modifiedTasks) {
      this.props.updateTasks(this.state.modifiedTasks)
    }

    if(this.props.boards !== prevState.actualBoard) {
      this.setState({
        actualBoard: this.props.boards
      })
    }

    if (this.props.showChargeClient !== prevProps.showChargeClient) {
      if (checkUserPrivileges(currentUser, project) && showChargeClient) {
        if (
          !defaultHeadCells.find((header) => header.id === 'expected_workload')
        )
          defaultHeadCells.splice(4, 0, {
            id: 'expected_workload',
            numeric: true,
            isLarge: false,
            label: 'Charge client',
          })
      } else {
        //remove header if exist
        let index = defaultHeadCells.findIndex(
          (header) => header.id === 'expected_workload'
        )
        if (index !== -1) {
          defaultHeadCells.splice(index, 1)
        }
      }

      this.setState({
        headCells: this.state.headCells,
        showChargeClient: this.props.showChargeClient,
      })
    }
  }

  componentDidMount() {
    this.props.fetchUsersByProjectId(this.props.project.id)
  }

  getFormattedTasks = () => {
    // On récupère les tâches dont celles modifiées du parent
    let tasks = this.props.tasks

    tasks = tasks.filter(
      (task) =>
        this.props.selectedFilter.length === 0 ||
        this.props.selectedFilter.some((filter) =>
          task.projectFilters.find((f) => f.id === filter.id)
        )
    )

    const searchValue = this.props.searchValue.toLowerCase()
    if (searchValue !== '') tasks = filterTasks(tasks, searchValue)

    return tasks.map((t) => {
      const username = this.getUsername(t)
      const time = this.getElapsedTime(t)
      const diff = t.visible_workload - t.remaining_workload - time

      return {
        ...t,
        filter: t.projectFilters.map((f) => f.name).join(', '),
        ref: t.reference ? t.reference : '',
        assigned: username,
        client_workload: t.expected_workload,
        workload: t.visible_workload,
        consum: time,
        remaining: t.remaining_workload,
        diff: diff,
      }
    })
  }

  getElapsedTime = (task) => {
    return task.consums.length > 0
      ? task.consums.reduce((accumulator, consum) => {
          return accumulator + consum.amount
        }, 0)
      : 0
  }

  getUsername = (task) => {
    if (task.user) {
      const name = task.user.firstname[0].concat(
        '',
        task.user.lastname.substr(0, 2)
      )
      return name.toUpperCase()
    }

    return ''
  }

  getAllTotal = () => {
    const rows = this.state.rowsPerPage
    const page = this.state.page
    let RemainTotal = 0
    let VisibleTotal = 0
    let ExpectedTotal = 0
    let ConsumTotal = 0
    let DiffTotal = 0

    stableSort(
      this.getFormattedTasks(),
      getComparator(this.state.order, this.state.orderBy)
    )
      .slice(page * rows, page * rows + rows)
      .forEach((task) => {
        RemainTotal += task.remaining
        VisibleTotal += task.workload
        ExpectedTotal += task.client_workload
        ConsumTotal += task.consum
        DiffTotal += task.diff
      })

    return {
      RemainTotal,
      VisibleTotal,
      ExpectedTotal,
      ConsumTotal,
      DiffTotal,
    }
  }

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    })
  }

  handleResetOrder = () => {
    this.setState({
      order: 'desc',
      orderBy: '',
    })
  }

  handlePageChange = (page) => {
    this.setState({
      page: page,
      selectedTasks: [],
    })
  }

  handleRowsPerPageChange = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    })
  }

  taskIdSelected(task) {
    let compt = 0
    while (
      compt < this.state.selectedTasks.length &&
      task.id !== this.state.selectedTasks[compt].id
    ) {
      compt += 1
    }
    if (compt === this.state.selectedTasks.length) {
      return [false, this.state.selectedTasks.length]
    } else {
      return [true, compt]
    }
  }

  addSelectedTasks(task) {
    let temp = this.state.selectedTasks
    if (!this.taskIdSelected(task)[0]) {
      temp.push(task)
      this.setState({
        selectedTasks: temp,
      })
    } else {
      temp.splice(this.taskIdSelected(task)[1], 1)
      this.setState({
        selectedTasks: temp,
      })
    }
  }
  handleOpenForm() {
    this.setState({
      formOpen: true,
    })
  }

  handleCloseForm() {
    this.setState({
      formOpen: false,
      multipleRemainingWorkload: null,
      multipleAssigned: null,
      multipleFiltersList: [],
      addedUser: [],
    })
  }
  saveData() {
    let compt = 0
    for (let i = 0; i < this.state.selectedTasks.length; i++) {
      const selectedFilter = []
      for (let i = 0; i < this.state.multipleFiltersList.length; i++) {
        for (let j = 0; j < this.props.filters.length; j++) {
          if (this.state.multipleFiltersList[i] === this.props.filters[j].name) {
            selectedFilter.push(this.props.filters[j])
          }
        }
      }

      let body = {}
      if (this.state.multipleAssigned) {
        compt += 1
        body.user = this.state.multipleAssigned && this.state.multipleAssigned.id
      }
      if (
        this.state.multipleRemainingWorkload &&
        this.state.multipleRemainingWorkload >= 0
      ) {
        compt += 1
        body.remaining_workload = this.state.multipleRemainingWorkload
      }
      // Si la case "Vider les filtres" est cochée, on vide le tableau de filtres
      if (this.state.clearFilters) {
        compt += 1
        body.project_filters = []
      } else if (this.state.multipleFiltersList.length !== 0) {
        compt += 1
        body.project_filters = selectedFilter.map((filter) => filter.id)
      }

      let modifiedTasks = []

      this.props
        .editTask(this.state.selectedTasks[i].id, body, null, (id, error) =>
          this.props.failureNotification(id, error)
        )
        .then((response) => {
          modifiedTasks.push(response.data)
          this.setState({ modifiedTasks: modifiedTasks })
        })
        .catch((error) => {
          return error
        })
    }
    if (compt > 0) {
      this.props.addNotification(
        'Opération réussie',
        'Les tâches ont été modifiées',
        'success'
      )
    }
    this.handleCloseForm()
  }


  handleStopPropagation(event) {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      event.stopPropagation()
    }
  }

  filtersToOption(filters) {
    let filtersOptions = []
    for (let i = 0; i < filters.length; i++) {
      filtersOptions.push({ value: filters[i].name, label: filters[i].name })
    }
    return filtersOptions
  }

  handleMultipleFilterChange = (e) => {
    let temp = []
    if (e) {
      for (let i = 0; i < e.length; i++) {
        for (let j = 0; j < this.props.filters.length; j++) {
          if (e[i].value === this.props.filters[j].name) {
            temp.push(this.props.filters[j].name)
          }
        }
      }
    }

    this.setState({
      multipleFiltersList: temp,
    })
  }

  handleAddUser(user) {
    this.setState({
      multipleAssigned: user,
      addedUser: [user],
    })
  }

  removeStateUser() {
    this.setState({
      addedUser: [],
    })
  }
  onDeleteTasks = () => {
    this.setState({
      selectedTasks: [],
    })
  }

  render() {
    if(this.state.actualBoard !== this.props.boards) {
      this.setState({
        actualBoard:this.props.boards,
        page:0
      })
    }
    const { classes, currentUser, project } = this.props
    const { order, orderBy, page, rowsPerPage } = this.state
    const { RemainTotal, VisibleTotal, ExpectedTotal, ConsumTotal, DiffTotal } =
      this.getAllTotal()
    const tasks = this.getFormattedTasks()
    const addBoard = this.props.boards[0]
    const rowsConfig =
      tasks.length <= 50 ? [15, 25, 50] : [15, 25, 50, tasks.length]
    const nbRows = rowsPerPage > 50 && tasks.length <= 50 ? 50 : rowsPerPage
    let filtersName = this.filtersToOption(this.props.filters)

    return (
      <>
        <Container maxWidth={false} className={classes.root}>
          <TableContainer>
            <Table
              aria-label={'tasks table'}
              size={'small'}
              stickyHeader
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TaskTableHead
                    classes={classes}
                    headCells={defaultHeadCells}
                    onRequestSort={this.handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    currentUser={this.props.currentUser}
                    project={this.props.project}
                    tasks={tasks}
                  />
                  <StyledTableHead
                    className={clsx(classes.cell, classes.headCell)}
                  >
                    Actions
                  </StyledTableHead>
                  {checkUserPrivileges(currentUser, project) && (
                    <StyledTableHead
                      className={clsx(
                        classes.cell,
                        classes.headCell,
                        classes.textCenteredCell
                      )}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row wrap',
                          alignItems: 'center',
                          alignContent: 'flex-start',
                          justifyContent: 'flex-start',
                          rowGap: '10px',
                        }}
                      >
                        <div style={{ display: 'flex', flexBasis: '33%' }}>
                          <IconButton aria-label="edit" size="small">
                            <Edit
                              htmlColor={'black'}
                              aria-disabled={!this.state.selectedTasks}
                              onClick={() => {
                                this.handleOpenForm()
                              }}
                              fontSize="small"
                            />
                          </IconButton>
                        </div>

                        <div style={{ display: 'flex', flexBasis: '33%' }}>
                          <DeleteTask
                            task={this.state.selectedTasks}
                            small
                            onDeleteTask={this.onDeleteTasks}
                          />
                        </div>
                      </div>
                    </StyledTableHead>
                  )}
                </TableRow>
              </TableHead>
              <Dialog
                open={this.state.formOpen}
                onClose={() => {
                  this.handleCloseForm()
                }}
                fullWidth
                maxWidth="md"
                aria-labelledby="dialog-title"
                onKeyDown={this.handleStopPropagation}
              >
                <DialogTitle id="dialog-title">
                  Modification multiple
                </DialogTitle>
                <DialogContent
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '50%',
                      paddingLeft: '2rem',
                    }}
                  >
                    <FormControl
                      style={{ minWidth: 300, maxWidth: 400 }}
                      variant="outlined"
                    >
                      <label htmlFor="filters-select">Filtre(s) : </label>
                      <Select
                        id="filters-select"
                        closeMenuOnSelect={false}
                        isMulti
                        options={filtersName}
                        label={'Filtre'}
                        onChange={this.handleMultipleFilterChange}
                        isDisabled={this.state.clearFilters}
                        value={
                          this.state.clearFilters
                            ? null
                            : this.state.selectedFilter
                        }
                      />
                    </FormControl>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.clearFilters}
                          onChange={(e) =>
                            this.setState({ clearFilters: e.target.checked })
                          }
                          color="primary"
                        />
                      }
                      label="Vider les filtres"
                    />

                    <TextField
                      style={{ minWidth: 120, width: 400, marginTop: '2rem', zIndex: 0 }}
                      aria-label="Reste à faire de la tâche"
                      name="task_remaining_workload"
                      type="number"
                      label="RAF"
                      value={this.state.multipleRemainingWorkload}
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({
                          multipleRemainingWorkload: e.target.value,
                        })
                      }
                      inputProps={{ step: '0.25' }}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '50%',
                      paddingRight: '1rem',
                    }}
                  >
                    <UsersListContainer
                      buttonAction={userListButtonAction.SELECTION}
                      omitted={[]}
                      users={this.props.userSRs.map((userSR) => userSR.user)}
                      onAdd={(user) => this.handleAddUser(user)}
                    />
                    <DialogContentText style={{ marginTop: '1rem' }}>
                      Utilisateur sélectionné :
                    </DialogContentText>
                    {this.state.addedUser.map((user) => (
                      <User
                        key={user.id}
                        user={user}
                        icon={<Icon>delete</Icon>}
                        onClick={() => this.removeStateUser()}
                      />
                    ))}
                  </div>
                </DialogContent>

                <DialogActions className="justify-content-center">
                  <ColorButton
                    onClick={() => {
                      this.handleCloseForm()
                    }}
                  >
                    {' '}
                    Fermer{' '}
                  </ColorButton>
                  <ColorButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.saveData(tasks)}
                  >
                    {' '}
                    Modifier{' '}
                  </ColorButton>
                </DialogActions>
              </Dialog>

              <TableBody>
                {stableSort(tasks, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((task) => (
                    <StyledTableRow
                      key={task.id}
                      style={{
                        backgroundColor:
                          task.remaining === 0 ? 'rgb(215,232,215)' : 'inherit',
                      }}
                    >
                      <>
                        {!checkUserPrivileges(
                          this.props.currentUser,
                          this.props.project
                        ) ? (
                          <TableCell className={classes.cell}>
                            {task.filter}
                          </TableCell>
                        ) : (
                          <FilterSelect
                            task={task}
                            classes={classes}
                            projectFilters={this.props.filters}
                            taskFilter={task.filter}
                          />
                        )}
                      </>
                      <TableCell className={classes.cell}>{task.ref}</TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.linkCell)}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: 200,
                        }}
                        title={task.name}
                      >
                        <Link
                          to={routes.editTask + '/' + task.id}
                          rel="noopener noreferrer"
                          target={(e) => {
                            if (e.ctrlKey || e.metaKey || e.button === 1) {
                              return '_blank'
                            } else {
                              return '_self'
                            }
                          }}
                          style={{ color: 'inherit' }}
                        >
                          {task.name}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={clsx(classes.cell, classes.linkCell)}
                      >
                        {!checkUserPrivileges(
                          this.props.currentUser,
                          this.props.project
                        ) ? (
                          task.assigned
                        ) : (
                          <AssignSection
                            currentUser={this.props.currentUser}
                            project={this.props.project}
                            task={task}
                            minimized={true}
                            isFetching={this.props.isFetching}
                            user={task.user}
                          />
                        )}
                      </TableCell>

                      {checkUserPrivileges(currentUser, project) &&
                      this.props.showChargeClient ? (
                        <TableCell
                          className={clsx(
                            classes.cell,
                            classes.textCenteredCell
                          )}
                        >
                          {task.client_workload}
                        </TableCell>
                      ) : null}
                      <TableCell
                        className={clsx(classes.cell, classes.textCenteredCell)}
                      >
                        {task.workload}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.textCenteredCell)}
                      >
                        {task.consum}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.textCenteredCell)}
                      >
                        {task.remaining}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            Math.sign(task.diff) === 1
                              ? 'rgb(98,175,98)'
                              : Math.sign(task.diff) === -1
                              ? 'rgb(238,101,101)'
                              : 'black',
                        }}
                        className={clsx(classes.cell, classes.textCenteredCell)}
                      >
                        {task.diff}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.textCenteredCell)}
                      >
                        <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() =>
                            this.props.redirectToEditTaskPage(task.id)
                          }
                        >
                          <Edit htmlColor={'black'} fontSize="small" />
                        </IconButton>

                        {(checkUserPrivileges(currentUser, project)) && (
                          <>
                            <CopyTask task={task} boards={this.props.boards} />
                            <DeleteTask task={task} small />
                          </>
                        )}
                      </TableCell>
                      {checkUserPrivileges(currentUser, project) && (
                        <TableCell
                          className={clsx(
                            classes.cell,
                            classes.linkCell,
                            classes.textCenteredCell
                          )}
                        >
                          <Input
                            type="checkbox"
                            style={{ width: '30px', height: '30px' }}
                            disableUnderline={true}
                            onChange={() => {
                              this.addSelectedTasks(task)
                            }}
                          ></Input>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  ))}
                <StyledTableRow>
                  <TableCell
                    className={clsx(classes.cell, classes.textCenteredCell)}
                  >
                    Total
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  {checkUserPrivileges(currentUser, project) &&
                  this.props.showChargeClient ? (
                    <TableCell
                      className={clsx(classes.cell, classes.textCenteredCell)}
                    >
                      {ExpectedTotal}
                    </TableCell>
                  ) : null}
                  <TableCell
                    className={clsx(classes.cell, classes.textCenteredCell)}
                  >
                    {VisibleTotal}
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.textCenteredCell)}
                  >
                    {ConsumTotal}
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.textCenteredCell)}
                  >
                    {RemainTotal}
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.textCenteredCell)}
                  >
                    {DiffTotal}
                  </TableCell>
                </StyledTableRow>
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3}>
                    {(checkUserPrivileges(currentUser, project) ||
                      !isForfaitProjectType(project.project_type)) && (
                      <AddTask
                        board={addBoard}
                        table
                        filters={this.props.filters}
                      />
                    )}
                  </TableCell>
                  <TablePagination
                    count={tasks.length}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from} - ${to} sur ${count !== -1 && count}`
                    }
                    labelRowsPerPage={'Lignes par page'}
                    onChangePage={(event, page) => this.handlePageChange(page)}
                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={nbRows}
                    rowsPerPageOptions={rowsConfig}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Container>
      </>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state),
  userSRs: getUserSRs(state, ownProps.project.id),
})
const mapDispatchToProps = (dispatch) => ({
  redirectToEditTaskPage: (id) =>
    dispatch(push(routes.editTask + '/' + id)),
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  fetchUsersByProjectId: (id) => dispatch(fetchUsersByProjectId(id)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteTask(id, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TaskTable))
