import { connect } from 'react-redux'
import { AdminList } from '../../pages/Admin/components'
import { getSecondaryRoles } from '../../reducers'
import {
  deleteSecondaryRoles,
  postSecondaryRoles,
  updateSecondaryRoles,
} from '../../actions/requests/secondaryRolesActions'

const mapStateToProps = (state) => ({
  data: getSecondaryRoles(state),
  properties: {
    name: 'Nom',
  },
  title: 'secondaryRoles',
  object: 'Rôle secondaire',
  // feminine définit si l'accord doit se faire
  feminine: false,
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postSecondaryRoles(body, successCallback, failureCallback)),
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateSecondaryRoles(id, body, successCallback, failureCallback)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteSecondaryRoles(id, successCallback, failureCallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
