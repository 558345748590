import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../../../../components'
import { getBills, retrieveWaitingRequests } from '../../../../reducers'
import { BillsDialog } from '..'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

/**
 * Props :
 *    - project, the edited project
 *    - disabled
 * Store :
 *    - Bills
 */
class BillsSection extends Component {
  render() {
    return (
      <Section title={'Facturation'}>
        <TableContainer style={{ height: '15rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledCell>Statut</StyledCell>
                <StyledCell>Montant (€)</StyledCell>
                <StyledCell>A facturer</StyledCell>
                <StyledCell>
                  <BillsDialog
                    disabled={this.props.disabled}
                    project={this.props.project}
                  />
                </StyledCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.bills.map((bill) => (
                <TableRow key={bill.id}>
                  <StyledCell>{bill.bill_status.name}</StyledCell>
                  <StyledCell>{bill.amount}</StyledCell>
                  <StyledCell>
                    {(bill.billing_check_date || bill.billing_check) && (
                      <Icon>check</Icon>
                    )}
                  </StyledCell>
                  <StyledCell>
                    {this.props.waitingRequests.find(
                      (req) => req.id === bill.id
                    )
                      ? null
                      : !bill.billing_check_date &&
                        !bill.billing_check && (
                          <BillsDialog
                            bill={bill}
                            disabled={this.props.disabled}
                            project={this.props.project}
                          />
                        )}
                  </StyledCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  bills: getBills(state, ownProps.project.id),
  waitingRequests: retrieveWaitingRequests(state),
})

export default connect(mapStateToProps, null)(BillsSection)
