import React from 'react'
import StyledDiv from './styles/StyledDiv'
import logo from '../../assets/img/logo.png'

const WindowSpinner = () => (
  <StyledDiv>
    <img src={logo} className="image-spinner" alt="Logo AttiProd" />
  </StyledDiv>
)

export default WindowSpinner
