import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const addUserNeeded = (body) => {
  return postEntity(itemTypes.usersNeeded, body)
}

export const updateUserNeeded = (id, body) => {
  return updateEntity(itemTypes.usersNeeded, id, body)
}

export const deleteUserNeeded = (id) => {
  return deleteEntity(itemTypes.usersNeeded, id)
}

export const fetchUsersNeededByProjectId = (id) => {
  return fetchEntity(otherURLs.projectUserNeeded, id, id)
}

export const clearErrorsUserSR = () => clearErrors(itemTypes.usersNeeded)
