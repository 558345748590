import * as React from 'react'
import { isFetching } from '../../../../reducers'
import { getUser } from '../../../../helpers'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { connect } from 'react-redux'
import { updateComment } from '../../../../actions/requests/commentsActions'
import DeleteComment from '../DeleteComment'
import ListItem from '@material-ui/core/ListItem'
import UserThumbnail from '../../../../components/UserThumbnail'
import { userThumbnailTypes } from '../../../../constants/global'
import StyledDiv from './styles/StyledDiv'
import StyledDiv2 from './styles/StyledDiv2'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'

const styles = (theme) => ({
  commentBubble: {
    borderRadius: '20px 20px 20px 20px',
    border: `1px solid ${theme.palette.divider}`,
    marginLeft: '10px',
  },
})

class Comment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: this.props.comment.text,
      editedComment: this.props.comment.text,
      isEditingComment: false,
      isSavingComment: false,
    }
    this.handleEditedCommentChange = this.handleEditedCommentChange.bind(this)
    this.toggleEditedComment = this.toggleEditedComment.bind(this)
    this.saveEditedComment = this.saveEditedComment.bind(this)
    this.handleIsSavingComment = this.handleIsSavingComment.bind(this)
    this.handleEndSavingComment = this.handleEndSavingComment.bind(this)
  }

  handleEditedCommentChange(e) {
    this.setState({
      editedComment: e.target.value,
    })
  }

  toggleEditedComment() {
    this.setState({
      isEditingComment: !this.state.isEditingComment,
    })
  }

  handleIsSavingComment() {
    this.setState({
      isSavingComment: true,
    })
  }

  handleEndSavingComment() {
    this.setState({
      isSavingComment: false,
    })
  }

  saveEditedComment() {
    this.handleIsSavingComment()
    if (!this.state.editedComment) {
      this.props.addNotification(
        'Opération échouée',
        'Le commentaire ne peut pas être vide',
        'danger'
      )
      this.setState({
        isLoading: false,
        isEditingComment: false,
        isSavingComment: false,
        editedComment: this.props.comment.text,
      })
      return
    }
    this.setState({
      isLoading: true,
    })
    const notificationMessage = 'Commentaire modifié'
    this.props
      .editItem(
        this.props.comment.id,
        { text: this.state.editedComment },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.setState({
          isLoading: false,
          isEditingComment: false,
        })
        this.handleEndSavingComment()
      })
  }

  render() {
    const { classes, comment } = this.props

    const date = moment(comment.creation).format('DD/MM/YYYY HH:mm:ss')

    return (
      <ListItem dense style={{ paddingRight: 0, paddingLeft: 0 }}>
        <UserThumbnail
          key={this.props.comment.user.id}
          type={userThumbnailTypes.USER}
          data={this.props.comment.user}
        />
        <StyledDiv className={clsx(classes.commentBubble)}>
          <StyledDiv2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0.5rem',
              }}
            >
              <Typography>
                {this.props.comment.user.firstname}{' '}
                {this.props.comment.user.lastname}{' '}
              </Typography>
              <Typography color="textSecondary">&nbsp; le {date}</Typography>
            </div>
            {this.props.comment.user.id === this.props.currentUser.id && (
              <div>
                {this.state.isSavingComment ? (
                  <IconButton>
                    <Icon>
                      <CircularProgress color={'inherit'} size={24} />
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="modifier le commentaire"
                    size="small"
                    onClick={this.toggleEditedComment}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                )}
                <DeleteComment comment={this.props.comment} />
              </div>
            )}
          </StyledDiv2>
          {this.state.isEditingComment ? (
            this.state.isSavingComment ? (
              <div />
            ) : (
              <>
                <TextField
                  type="textarea"
                  name="comment"
                  id="comment"
                  variant="outlined"
                  value={this.state.editedComment}
                  onChange={this.handleEditedCommentChange}
                />
                <div className="d-flex justify-content-end">
                  <Button color="secondary" onClick={this.toggleEditedComment}>
                    Annuler
                  </Button>
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={this.saveEditedComment}
                  >
                    Envoyer
                  </Button>
                </div>
              </>
            )
          ) : (
            <Typography style={{ paddingLeft: '1rem', paddingBottom: '4px' }}>
              {this.props.comment.text}
            </Typography>
          )}
        </StyledDiv>
      </ListItem>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateComment(id, body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Comment))
