import axios from 'axios'
import routes from '../config/routes'
import { msalInstance, tokenRequest } from '../constants/util'

export const NO_CALL = (url, itemType, body, options) => {
  return Promise.resolve({ itemType, data: body })
}

const actionType = (name, call) => ({
  name,
  call,
})

export const ACTION_FETCH = actionType(
  'FETCH',
  (url, itemType, body, options) => {
    return msalInstance.acquireTokenSilent(tokenRequest).then((response) =>
      axios.get(url, {
        ...options,
        headers: { Authorization: `Bearer ${response.idToken}` },
      })
    )
  }
)

export const ACTION_POST = actionType(
  'POST',
  (url, itemType, body, options) => {
    return msalInstance.acquireTokenSilent(tokenRequest).then((response) =>
      axios.post(routes.apiUrl + '/' + itemType.url, body, {
        ...options,
        headers: { Authorization: `Bearer ${response.idToken}` },
      })
    )
  }
)

export const ACTION_PUT = actionType('PUT', (url, itemType, body, options) => {
  return msalInstance.acquireTokenSilent(tokenRequest).then((response) =>
    axios.put(url, body, {
      ...options,
      headers: { Authorization: `Bearer ${response.idToken}` },
    })
  )
})
export const ACTION_DELETE = actionType(
  'DELETE',
  (url, itemType, body, options) => {
    return msalInstance.acquireTokenSilent(tokenRequest).then((response) =>
      axios.delete(url, {
        ...options,
        headers: { Authorization: `Bearer ${response.idToken}` },
      })
    )
  }
)

export const ACTION_FORM_SET = actionType('FORM_SET', NO_CALL)
export const ACTION_FORM_POST = actionType('FORM_POST', NO_CALL)
export const ACTION_FORM_PUT = actionType('FORM_PUT', NO_CALL)
export const ACTION_FORM_DELETE = actionType('FORM_DELETE', NO_CALL)
export const ACTION_CLEAR_ADDED = actionType('CLEAR_ADDED', NO_CALL)
export const ACTION_CLEAR_DELETED = actionType('CLEAR_DELETED', NO_CALL)
export const ACTION_CLEAR_ERRORS = actionType('CLEAR_ERRORS', NO_CALL)
export const ACTION_CLEAR_ADDED_ITEMS = actionType('CLEAR_ADDED_ITEMS', NO_CALL)
export const ACTION_ADD_NOTIFICATION = actionType('ADD_NOTIFICATION', NO_CALL)
export const ACTION_CLEAR_NOTIFICATIONS = actionType(
  'CLEAR_NOTIFICATIONS',
  NO_CALL
)
