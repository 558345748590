import { BrowserCacheLocation } from '@azure/msal-browser'
import routes from '../config/routes'

export const APP_CLIENT_ID_OFFICE = process.env.REACT_APP_APP_CLIENT_ID_OFFICE
export const APP_TENANT_ID_OFFICE = process.env.REACT_APP_APP_TENANT_ID_OFFICE

export const USERS_LIST_PAGINATION_NB_ITEMS = 7
export const USERS_LIST_PAGINATION_NB_MAX_PAGES_LINKS = 2

export const ADMIN_LIST_PAGINATION_NB_ITEMS = 5
export const ADMIN_LIST_PAGINATION_NB_MAX_PAGES_LINKS = 2

export const DASHBOARD_CARROUSEL_NB_ITEMS = 3
export const USERS_CARROUSEL_NB_ITEMS = 4

export const EDIT_TASK_CONSUMS_PAGINATION_NB_ITEMS = 5
export const EDIT_TASK_CONSUMS_PAGINATION_NB_MAX_PAGES_LINKS = 2

export const TOKEN_REFRESH_DURATION = 1440 * 60000 // 24 heures en ms

export const DISCONNECT_URL =
  'https://login.microsoftonline.com/' +
  APP_TENANT_ID_OFFICE +
  '/oauth2/logout?ui_locales=fr-FR&mkt=fr-FR&post_logout_redirect_uri=' +
  routes.root +
  routes.login

export const MSAL_CONFIG = {
  auth: {
    clientId: APP_CLIENT_ID_OFFICE,
    authority: 'https://login.microsoftonline.com/' + APP_TENANT_ID_OFFICE,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
  },
  system: {
    tokenRenewalOffsetSeconds: 10, // Dont work ?!
  },
}

export const status = {
  IS_FETCHING: 'STATUS_IS_FETCHING',
  SUCCESS: 'STATUS_SUCCESS',
  FAILURE: 'STATUS_FAILURE',
}

export const initialRequestState = {
  isFetching: false,
  error: null,
  // The items to display, do not necessarly exist on API
  items: [],
  // Request to send (When using form)
  requests: [],
  // Items existing on API, used to rollback in case of failure
  initialItems: [],
  // Requests sent but not completed yet (Cannot be FETCH)
  waitingRequests: [],
  // Items that have been added by POST
  addedItems: [],
  // Items that have been removed by DELETE
  deletedItems: [],
}

export const sizes = {
  small: 24,
  medium: 32,
  large: 64,
  xlarge: 200,
}

export const projectTypes = {
  forfait: 'forfait',
  agile: 'agile',
  tma: 'tma',
}

export const isAgileProjectType = (projectType) =>
  projectType.codename === projectTypes.agile
export const isForfaitProjectType = (projectType) =>
  projectType.codename === projectTypes.forfait
export const isTMAProjectType = (projectType) =>
  projectType.codename === projectTypes.tma

export const userTypes = {
  GESTION_PROJET: 'GESTION_PROJET',
  REALISATION: 'REALISATION',
}

export const userRoles = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_GEST: 'ROLE_GEST',
  ROLE_MB: 'ROLE_MB',
  ROLE_CP: 'ROLE_CP',
  ROLE_DP: 'ROLE_DP',
  ROLE_DEV: 'ROLE_DEV',
  ROLE_ING: 'ROLE_ING',
  ROLE_EXP: 'ROLE_EXP',
  ROLE_QA: 'ROLE_QA',
}

export const userListButtonAction = {
  EDITION: 'EDITION',
  SELECTION: 'SELECTION',
}

export const userThumbnailTypes = {
  USER: 'USER',
  USER_SR: 'USER_SR',
  USER_NEEDED: 'USER_NEEDED',
}
