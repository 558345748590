import React from 'react'
import StyledToolbar from './styles/StyledToolbar'
import StyledDiv from './styles/StyledDiv'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import Box from '@material-ui/core/Box'
import { logoutRequest, msalInstance } from '../../constants/util'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userMenu: null,
    }

    this.handleLogout = this.handleLogout.bind(this)
    this.handleUserMenu = this.handleUserMenu.bind(this)
    this.handleUserMenuClose = this.handleUserMenuClose.bind(this)
  }

  handleUserMenu(e) {
    this.setState({
      userMenu: e.currentTarget,
    })
  }

  handleUserMenuClose() {
    this.setState({
      userMenu: null,
    })
  }

  handleLogout() {
    msalInstance.logoutRedirect(logoutRequest).catch((e) => {
      console.error(e)
    })
    // history.push(routes.login)
  }

  render() {
    return (
      <AppBar style={{ zIndex: 2 }} position="static" color="backgroundHeader">
        <StyledToolbar>
          {this.props.startIcon}
          <StyledDiv style={{ width: '80%' }}>
            {this.props.editableTitle ? (
              this.props.editableTitle
            ) : (
              <Box display="flex" alignItems="center">
                {this.props.clientName ? (
                  <>
                    <Typography noWrap variant="h5" color="secondary">
                      {this.props.title + ' '}
                    </Typography>

                    <Typography
                      variant="h6"
                      color="secondary"
                      style={{ padding: '0.4rem' }}
                    >
                      {' - ' + this.props.clientName}
                    </Typography>
                  </>
                ) : (
                  <Typography noWrap color="secondary" variant="h5">
                    {this.props.title}
                  </Typography>
                )}
              </Box>
            )}
            {this.props.endIcon}
          </StyledDiv>

          {this.props.user && (
            <div>
              <Button
                aria-label="Menu utilisateur"
                className="h-64"
                onClick={this.handleUserMenu}
                style={{
                  textTransform: 'none',
                }}
              >
                {this.props.user.profile_pic &&
                this.props.user.profile_pic !== '' ? (
                  <Avatar
                    alt="user photo"
                    src={this.props.user.profile_pic}
                    style={{ marginRight: '0.8rem' }}
                  />
                ) : (
                  <Avatar style={{ marginRight: '0.8rem' }}>
                    {this.props.user.firstname[0]}
                    {this.props.user.lastname[0]}
                  </Avatar>
                )}

                <div>
                  <Typography component="span" color="secondary">
                    {this.props.user.firstname} {this.props.user.lastname}
                  </Typography>
                </div>

                <Icon
                  className="text-16 hidden sm:flex"
                  variant="action"
                  color="secondary"
                >
                  keyboard_arrow_down
                </Icon>
              </Button>

              <Popover
                open={Boolean(this.state.userMenu)}
                anchorEl={this.state.userMenu}
                onClose={this.handleUserMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{
                  paper: 'py-8',
                }}
              >
                <MenuItem
                  aria-label="Profil"
                  onClick={this.props.redirectToProfilePage}
                >
                  <ListItemIcon className="min-w-40">
                    <Icon>person</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Profil" />
                </MenuItem>

                <MenuItem aria-label="Déconnexion" onClick={this.handleLogout}>
                  <ListItemIcon className="min-w-40">
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Déconnexion" />
                </MenuItem>
              </Popover>
            </div>
          )}
        </StyledToolbar>
      </AppBar>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  redirectToLogoutPage: () => dispatch(push(routes.logout)),
  redirectToProfilePage: () => dispatch(push(routes.profile)),
})

export default connect(null, mapDispatchToProps)(Header)
