import styled from 'styled-components'
import CardContent from '@material-ui/core/CardContent'

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex: auto;
  height: 100%;
  min-height: 0;
  width: 100%;
  overflow: auto;
`

export default StyledCardContent
