import * as React from 'react'
import { connect } from 'react-redux'
import { deleteConsum } from '../../../../actions/requests/consumsActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import StyledDiv from '../EditConsum/styles/StyledDiv'
import CircularProgress from '@material-ui/core/CircularProgress'

/**
 * Props :
 *    - consum
 *    - click(consumId), optionnal, callback
 */
class DeleteConsum extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      dialog: false,
      isDeleting: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleisDeleting = this.handleisDeleting.bind(this)
    this.handleEndDeleting = this.handleEndDeleting.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  handleisDeleting() {
    this.setState({
      isDeleting: true,
    })
  }

  handleEndDeleting() {
    this.setState({
      isDeleting: false,
    })
  }

  render() {
    return (
      <StyledDiv>
        {this.state.isDeleting ? (
          <IconButton>
            <Icon>
              <CircularProgress size={24} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton
            aria-label="supprimer cette consommation"
            onClick={this.handleOpenDialog}
            size="small"
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        <Dialog open={this.state.dialog} onClose={this.handleCloseDialog}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr(e) de vouloir supprimer cette consommation ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleCloseDialog}>
              Annuler
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.handleisDeleting()
                this.props.click && this.props.click(this.props.consum.id)
                const notificationMessage = 'Consommation supprimée'
                this.props
                  .deleteItem(
                    this.props.consum.id,
                    (id, response, message) =>
                      this.props.successNotification(
                        id,
                        response,
                        notificationMessage
                      ),
                    (id, error) => this.props.failureNotificationApi(id, error)
                  )
                  .then(() => {
                    this.handleEndDeleting()
                  })
                this.handleCloseDialog()
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </StyledDiv>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteConsum(id, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(DeleteConsum)
