import React from 'react'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { CustomPagination, StyledAttiContainer } from '../../../../components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  EDIT_TASK_CONSUMS_PAGINATION_NB_ITEMS,
  EDIT_TASK_CONSUMS_PAGINATION_NB_MAX_PAGES_LINKS,
} from '../../../../constants/global'
import { getConsums } from '../../../../reducers'
import { isInvalidDate } from '../../../../helpers/date'
import { postConsum } from '../../../../actions/requests/consumsActions'
import Consum from '../../../ProjectTasks/components/Consum'
import ConsumsTotal from '../../../ProjectTasks/components/ConsumsTotal'
import moment from 'moment'

const StyledCell = withStyles(() => ({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
}))(TableCell)

class ConsumSection extends React.Component {
  constructor(props) {
    super(props)

    this.maxPageLinks = EDIT_TASK_CONSUMS_PAGINATION_NB_MAX_PAGES_LINKS
    this.rowsPerPage = EDIT_TASK_CONSUMS_PAGINATION_NB_ITEMS

    this.state = {
      isSavingConsum: false,
      page: 0,
      selectedConsum: -1,
      startDate: moment().toDate(),
      totalConsums: 0,
    }
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleConsumSave = () => {
    this.toggleState('isSavingConsum')

    const user = this.props.project.users.find(
      (userSR) => userSR.user.id === this.props.currentUser.id
    )
    if (user === undefined) {
      this.toggleState('isSavingConsum')
      this.props.addNotification(
        'Opération échouée',
        `Vous ne faites pas partie du projet`,
        'danger'
      )

      return
    }

    const date = moment(this.state.startDate).format('DD-MM-YYYY')

    let actualConsum = 0
    let today = date.split('-').reverse()
    let consumDate = []

    this.props.consums.forEach((consum, _) => {
      consumDate = consum.date.split('T')[0].split('-')

      if (
        today[0] === consumDate[0] &&
        today[1] === consumDate[1] &&
        today[2] === consumDate[2] &&
        consum.user.id === this.props.currentUser.id
      ) {
        actualConsum += consum.amount
        return
      }
    })

    if (actualConsum + (this.state.selectedConsum + 1) * 0.25 > 1) {
      this.toggleState('isSavingConsum')
      this.props.addNotification(
        'Opération échouée',
        `Une saisie ne peut dépasser 1 par jour et par personne`,
        'danger'
      )

      return
    }
    const body = {
      amount: (this.state.selectedConsum + 1) * 0.25,
      date: date,
      secondaryRole: user.secondaryRole.id,
      task: this.props.task.id,
      user: this.props.currentUser.id,
    }

    const notificationMessage = 'Consommation ajoutée'
    this.props
      .addConsum(
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleChangeState('selectedConsum', -1)
        this.toggleState('isSavingConsum')
      })
  }

  totalPerUser(idUser) {
    let total = 0
    for (let consum of this.props.consums) {
      if (consum.user.id === idUser) {
        total = total + consum.amount
      }
    }
    return total
  }

  totalAllUser() {
    let total = 0
    for (let consum of this.props.consums) {
      total = total + consum.amount
    }
    return total
  }

  removeDublicates(users) {
    let withoutDuplicate = [users[0]]
    for (let i = 0; i < users.length; i++) {
      let j = 0
      if (this.totalPerUser(users[i].user.id) !== 0) {
        while (
          j < withoutDuplicate.length &&
          users[i].user.id !== withoutDuplicate[j].user.id
        ) {
          j = j + 1
        }
        if (j === withoutDuplicate.length) {
          withoutDuplicate.push(users[i])
        } else {
          j = 0
        }
      }
    }
    return withoutDuplicate
  }
  render() {
    const consums = [...this.props.consums].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    )
    const emptyRows =
      this.rowsPerPage -
      Math.min(
        this.rowsPerPage,
        consums.length - this.state.page * this.rowsPerPage
      )
    const users = this.props.consums.sort((a, b) => b.user.id - a.user.id)
    const filteredUsers = this.removeDublicates(users).sort(
      (a, b) => this.totalPerUser(b.user.id) - this.totalPerUser(a.user.id)
    )

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <KeyboardDatePicker
            format="DD-MM-yyyy"
            InputProps={{ style: { backgroundColor: 'white', height: '40px' } }}
            inputVariant="outlined"
            maxDate={new Date()}
            onChange={(date) => this.handleChangeState('startDate', date)}
            shouldDisableDate={isInvalidDate}
            KeyboardButtonProps={{ 'aria-label': 'Ouvrir le calendrier' }}
            leftArrowButtonProps={{ 'aria-label': 'Aller au mois précédent' }}
            rightArrowButtonProps={{ 'aria-label': 'Aller au mois suivant' }}
            value={this.state.startDate}
            variant="inline"
            autoOk
          />

          <ButtonGroup
            fullWidth={true}
            style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
          >
            {[0.25, 0.5, 0.75, 1].map((value, index) => (
              <Button
                color="primary"
                onClick={() => this.handleChangeState('selectedConsum', index)}
                style={{
                  backgroundColor:
                    this.state.selectedConsum === index
                      ? 'rgb(168,106,1)'
                      : 'rgb(242, 148, 0, 1)',
                }}
                variant="contained"
              >
                {value}
              </Button>
            ))}
          </ButtonGroup>

          {this.state.isSavingConsum ? (
            <IconButton>
              <Icon>
                <CircularProgress color="inherit" size={24} />
              </Icon>
            </IconButton>
          ) : (
            <IconButton
              disabled={this.state.selectedConsum === -1}
              onClick={this.handleConsumSave}
            >
              <Icon>playlist_add</Icon>
            </IconButton>
          )}
        </div>

        {this.props.consums.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCell>Profil</StyledCell>
                    <StyledCell>Date</StyledCell>
                    <StyledCell>Conso</StyledCell>
                    <StyledCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {consums
                    .slice(
                      this.rowsPerPage * this.state.page,
                      this.state.page * this.rowsPerPage + this.rowsPerPage
                    )
                    .map((consum) => (
                      <Consum
                        consum={consum}
                        currentUser={this.props.currentUser}
                        key={consum.id}
                      />
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <CustomPagination
              items={consums}
              itemsPerPage={this.rowsPerPage}
              maxPageLinks={this.maxPageLinks}
              page={this.state.page}
              setPage={(value) => this.handleChangeState('page', value)}
            />

            <Table>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gridGap: '10px',
                  padding: '7px',
                }}
              >
                {filteredUsers.map((user, index) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent:
                        index % 3 === 0 ? 'flex-start' : index % 3 === 1 ? 'center' : 'flex-end',
                    }}
                  >
                    <ConsumsTotal
                      currentUser={user.user}
                      total={this.totalPerUser(user.user.id)}
                      key={user.id}
                    />
                  </div>
                ))}
              </div>
              <div style={{ fontWeight: 'bolder', paddingTop: '10px', width: '100%' }}>
                Total : {this.totalAllUser()}
              </div>
            </Table>
            <TableRow></TableRow>
          </>
        ) : (
          <StyledAttiContainer className="m-0 mb-4 p-4 text-center">
            Il n'y a pas de consommation pour cette tâche
          </StyledAttiContainer>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  consums: getConsums(state).filter(
    (consum) => consum.task.id === ownProps.task.id
  ),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  addConsum: (body, successCallback, failureCallback) =>
    dispatch(postConsum(body, successCallback, failureCallback)),
  // Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConsumSection)
