import { getUser } from '../../../../helpers'
import { isFetching } from '../../../../reducers'
import { push } from 'connected-react-router'
import routes from '../../../../config/routes'
import { connect } from 'react-redux'
import { postBoard } from '../../../../actions/requests/boardsActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import clsx from 'clsx'
import StyledCard from './styles/StyledCard'
import StyledDiv from './styles/StyledDiv'
import CircularProgress from '@material-ui/core/CircularProgress'
import ColorButton from '../../../../components/ColorButton'
import { projectTypes } from '../../../../constants/global'

const styles = (theme) => ({
  card: {
    backgroundColor: darken(
      theme.palette.background.default,
      theme.palette.type === 'light' ? 0.02 : 0.4
    ),
  },
})

/**
 * Props :
 *    - project, the edited project
 *    - boards, the existing boards on the project
 *    - project_status, the current status of the project
 */

class AddBoard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      board_name: '',
      formOpen: false,
    }
    if (
      this.props.project === null ||
      this.props.project === undefined ||
      this.props.boards === null ||
      this.props.boards === undefined
    ) {
      this.props.redirectToDashboardPage()
    }
    this.handleBoardNameChange = this.handleBoardNameChange.bind(this)
    this.handleOpenForm = this.handleOpenForm.bind(this)
    this.handleCloseForm = this.handleCloseForm.bind(this)
    this.handleStartLoading = this.handleStartLoading.bind(this)
    this.handleEndLoading = this.handleEndLoading.bind(this)
  }

  handleBoardNameChange(e) {
    this.setState({
      board_name: e.target.value,
    })
  }

  handleOpenForm() {
    this.setState({
      formOpen: true,
    })
  }

  handleCloseForm() {
    this.setState({
      formOpen: false,
    })
  }

  handleStartLoading() {
    this.setState({
      isLoading: true,
    })
  }

  handleEndLoading() {
    this.setState({
      isLoading: false,
    })
  }

  isFormInvalid() {
    return this.state.board_name.length === 0
  }
  saveData() {
    this.handleStartLoading()

    if (this.state.board_name.match(/^ *$/) !== null) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du board ne peut pas être vide',
        'danger'
      )
      this.handleEndLoading()
      return
    } else if (
      this.props.boards.some((board) => board.name === this.state.board_name)
    ) {
      this.props.addNotification(
        'Opération échouée',
        `Le board ${this.state.board_name} existe déjà`,
        'danger'
      )
      this.handleEndLoading()
      return
    }

    let body = {}
    switch (this.props.project.project_type.codename) {
      case projectTypes.agile:
        body = {
          project: this.props.project.id,
          project_status: null,
          sprint: this.props.currentSprint.id,
          name: this.state.board_name,
        }
        break
      case projectTypes.forfait:
        body = {
          project: this.props.project.id,
          project_status: this.props.currentStatus.id,
          sprint: null,
          name: this.state.board_name,
        }
        break
      case projectTypes.tma:
        body = {
          project: this.props.project.id,
          project_status: null,
          sprint: null,
          name: this.state.board_name,
        }
        break
      default:
        this.props.addNotification(
          'Opération échouée',
          "Le type de projet n'est pas reconnu",
          'danger'
        )
    }

    this.setState({
      board_name: '',
    })

    const notificationMessage = 'Board ajouté'
    this.props
      .addItem(
        this.props.project.id,
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleEndLoading()
        this.handleCloseForm()
      })
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <div>
          <StyledCard className={clsx(classes.card)} square>
            {this.state.formOpen ? (
              <ClickAwayListener onClickAway={this.handleCloseForm}>
                <div style={{ padding: 16 }} className="p-16">
                  <TextField
                    className="mb-16"
                    required
                    fullWidth
                    variant="outlined"
                    label="Nom du board"
                    autoFocus
                    name="title"
                    value={this.state.board_name}
                    onChange={this.handleBoardNameChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleCloseForm}>
                            <Icon className="text-18">close</Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <StyledDiv
                    style={{
                      marginTop: '12px',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <ColorButton
                        aria-label="Ajouter"
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          this.saveData()
                        }}
                        disabled={this.isFormInvalid() || this.state.isLoading}
                      >
                        Ajouter
                      </ColorButton>
                      {this.state.isLoading && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: 'absolute',
                            top: '14%',
                            left: '34%',
                            color: 'inherit',
                          }}
                        />
                      )}
                    </div>
                  </StyledDiv>
                </div>
              </ClickAwayListener>
            ) : (
              <>
                <Button
                  aria-label="Ajouter un board"
                  onClick={this.handleOpenForm}
                  classes={{
                    root: 'normal-case font-600 w-full rounded-none h-64',
                    label: 'justify-start',
                  }}
                  style={{
                    textTransform: 'none',
                    justifyContent: 'start',
                    width: '100%',
                    height: 40,
                    fontWeight: 600,
                  }}
                >
                  <Icon
                    className="text-32 text-red"
                    style={{
                      color: 'orange',
                      paddingRight: '32px',
                    }}
                  >
                    add_circle
                  </Icon>
                  <span className="mx-8">Ajouter un board</span>
                </Button>
              </>
            )}
          </StyledCard>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(),
  isFetching: isFetching(state),
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (projectId, body, successCallback, failureCallback) =>
    dispatch(postBoard(projectId, body, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddBoard))
