import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import EditListItemDialog from '../EditListItemDialog'
import DeleteListItemDialog from '../DeleteListItemDialog'
import AddItemTextField from '../AddItemTextField'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

const getItemStyle = (isDragging, draggableStyle) => ({
  display: isDragging && 'table',
  ...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * TODO A DnD only usable with project statuses, to generalize
 * Store :
 *    - ProjectStatuses
 */
class AdminDnD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.data,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  removeItem(id) {
    let data = []
    let test = this.props.data.filter((item) => {
      return item.id !== id
    })
    for (let t of test) {
      if (!data.includes(t)) {
        data.push(t)
      }
    }
    this.setState({
      items: data,
    })
  }

  onSave(body, item) {
    this.setState({
      items: this.state.items.map((currentItem) => {
        if (currentItem.id === item.id)
          return Object.assign({}, body, { id: item.id })
        else return currentItem
      }),
    })
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    this.setState({
      items: reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      ),
    })

    const notificationMessage = 'Ordre modifié'
    this.props.editItem(
      this.props.data.find(
        (item) => item.status_order === result.source.index + 1
      ).id,
      { status_order: result.destination.index + 1 },
      (id, response, message) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  componentWillReceiveProps(nextProps) {
    let addedProjectStatus = nextProps.addedProjectStatus
    let deletedProjectStatus = nextProps.deletedProjectStatus

    if (addedProjectStatus.length === 0 && deletedProjectStatus.length === 0)
      return

    this.setState({
      items: [...addedProjectStatus, ...this.state.items].filter(
        (item) => !deletedProjectStatus.find((i) => i.id === item.id)
      ),
    })

    this.props.clearAddedProjectStatus()
    this.props.clearDeletedProjectStatus()
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <AddItemTextField {...this.props} />
                    </TableRow>
                    <TableRow>
                      <StyledCell />
                      {Object.keys(this.props.properties).map((p) => (
                        <StyledCell key={p}>
                          {this.props.properties[p]}
                        </StyledCell>
                      ))}
                      <StyledCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.items.map((item, index) => (
                      <Draggable
                        key={item.name}
                        draggableId={item.name}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <StyledCell>
                              <IconButton disabled size="small">
                                <Icon>drag_indicator</Icon>
                              </IconButton>
                            </StyledCell>
                            {Object.keys(this.props.properties).map((p) => (
                              <StyledCell
                                key={p}
                                style={{ verticalAlign: 'middle' }}
                              >
                                {item[p]}
                              </StyledCell>
                            ))}
                            <StyledCell>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <EditListItemDialog
                                  disabled={this.props.disabled}
                                  properties={this.props.properties}
                                  data={this.props.data}
                                  item={item}
                                  props={this.props}
                                  additionalInfos={{
                                    status_order: item.status_order,
                                  }}
                                  onSave={(body, item) =>
                                    this.onSave(body, item)
                                  }
                                />
                                <DeleteListItemDialog
                                  item={item}
                                  props={this.props}
                                  click={(id) => this.removeItem(id)}
                                />
                              </div>
                            </StyledCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(AdminDnD)
