import React from 'react'
import { TableCell, TableSortLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { deleteTask } from '../../../../../../actions/requests/tasksActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../../../actions/temporaries/notificationActions'
import { connect } from 'react-redux'

const StyledTableHead = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))(TableCell)

class TaskTableHead extends React.Component {
  createSortHandler = (property) => {
    this.props.onRequestSort(property)
  }

  resetOrderHandler() {
    this.props.onResetOrder()
  }

  render() {
    const { classes, headCells, order, orderBy } = this.props

    return (
      <>
        {headCells.map((cell) => (
          <StyledTableHead
            key={cell.id}
            sortDirection={orderBy === cell.id ? order : false}
            className={clsx(
              classes.cell,
              classes.headCell,
              cell.isLarge ? classes.textCell : null
            )}
          >
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={() => this.createSortHandler(cell.id)}
            >
              {cell.label}
              {orderBy === cell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableHead>
        ))}
      </>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteTask(id, successCallback, failureCallback)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
})

export default connect(null, mapDispatchToProps)(TaskTableHead)
