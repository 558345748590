import styled from 'styled-components'

const StyledDiv2 = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 5px;
  justify-content: space-between;
`

export default StyledDiv2
