import React from 'react'
import { connect } from 'react-redux'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getExperiences, getSkills } from '../../../../reducers'
import { getElemByIdInArray } from '../../../../helpers'
import { postFormEntity } from '../../../../actions/globalActions'
import { itemTypes } from '../../../../actions/itemTypes'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

/**
 * Props :
 *    - disabled
 *    - project, the project to add the needed user to
 * Store :
 *    - Skills
 *    - Experiences
 */
class UserNeededAddDialog extends React.Component {
  closeAction = () => {}

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      selectedSkill: null,
      selectedExperience: null,
    }

    this.toggle = this.toggle.bind(this)
    this.handleSelectedSkill = this.handleSelectedSkill.bind(this)
    this.handleSelectedExperience = this.handleSelectedExperience.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  handleSelectedSkill(event) {
    this.setState({
      selectedSkill: event.target.value,
    })
  }

  handleSelectedExperience(event) {
    this.setState({
      selectedExperience: event.target.value,
    })
  }

  render() {
    return (
      <>
        <IconButton
          disabled={this.props.disabled}
          aria-label="ajouter un profil d'utilisateur"
          className="m-1"
          style={{ position: 'relative', top: '5px' }}
          onClick={() => {
            this.toggle()
          }}
        >
          <Icon>search</Icon>
        </IconButton>

        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
        >
          <DialogTitle toggle={this.toggle}>
            Ajouter un profil recherché
          </DialogTitle>

          <DialogContent style={{ overflow: 'visible' }}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormLabel for="skillSelect">Compétence</FormLabel>
                  <Select
                    aria-label="skillSelect"
                    name={'skillSelect'}
                    id="skillSelect"
                    value={this.state.selectedSkill}
                    onChange={this.handleSelectedSkill}
                    style={{ width: '100%' }}
                  >
                    {this.props.skills.map((skill) => (
                      <MenuItem key={skill.id} value={skill.id}>
                        {skill.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={6}>
                  <FormLabel for="experienceSelect">Expérience</FormLabel>
                  <Select
                    aria-label="experienceSelect"
                    name="experienceSelect"
                    value={this.state.selectedExperience}
                    onChange={this.handleSelectedExperience}
                    style={{ width: '100%' }}
                  >
                    {this.props.experiences.map((experience) => (
                      <MenuItem value={experience.id} key={experience.id}>
                        {experience.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>

          <DialogActions className="justify-content-center">
            <Button
              aria-label="Fermer"
              color="secondary"
              onClick={() => {
                this.closeAction = () => {}
                this.toggle()
              }}
            >
              Fermer
            </Button>

            <Button
              disabled={
                this.state.selectedExperience === null ||
                this.state.selectedSkill === null
              }
              aria-label="Ajouter"
              color="primary"
              onClick={() => {
                this.closeAction = () => {
                  const notificationMessage = 'Profil recherché ajouté'
                  this.props
                    .addUserNeeded(
                      {
                        project: this.props.project,
                        skill: getElemByIdInArray(
                          this.props.skills,
                          this.state.selectedSkill
                        ),
                        exp: getElemByIdInArray(
                          this.props.experiences,
                          this.state.selectedExperience
                        ),
                      },
                      this.props.project.id,
                      (id, response) =>
                        this.props.successNotification(
                          id,
                          response,
                          notificationMessage
                        ),
                      (id, error) =>
                        this.props.failureNotificationApi(id, error)
                    )
                    .then(() => {
                      this.setState({ isLoading: false })
                    })
                }
                this.toggle()
              }}
            >
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  skills: getSkills(state),
  experiences: getExperiences(state),
})

const mapDispatchToProps = (dispatch) => ({
  addUserNeeded: (user_needed, projectId, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.usersNeeded,
        user_needed,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserNeededAddDialog)
