import React from 'react'
import { createStyles, Icon, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { WeekPicker } from '../index'
import moment from 'moment'

const styles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  fullWidth: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  coloredArrow: {
    color: theme.palette.primary.main,
  },
}))

class WeekPickerWithArrows extends React.Component {
  render() {
    const { classes, disabled, fullWidth, onChange, value } = this.props

    const prevWeek = moment(value).subtract(1, 'week').toDate()
    const nextWeek = moment(value).add(1, 'week').toDate()

    return (
      <div
        className={clsx({
          [classes.container]: true,
          [classes.fullWidth]: fullWidth,
        })}
      >
        <IconButton disabled={disabled} onClick={() => onChange(prevWeek)}>
          <Icon className={classes.coloredArrow}>arrow_back</Icon>
        </IconButton>

        <WeekPicker value={value} onChange={(date) => onChange(date)} />

        <IconButton disabled={disabled} onClick={() => onChange(nextWeek)}>
          <Icon className={classes.coloredArrow}>arrow_forward</Icon>
        </IconButton>
      </div>
    )
  }
}

export default withStyles(styles)(WeekPickerWithArrows)
