import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

/**
 * Props :
 *    - filter
 *    - isSelected, base state
 *    - onSelecting(boolean, filter), callback when the filter is selected
 */

class TaskFilter extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: this.props.isSelected }
  }

  select() {
    this.props.onSelecting(!this.state.selected, this.props.filter)
    this.setState({
      selected: !this.state.selected,
    })
  }

  render() {
    return (
      <Button
        size="small"
        className="m-1"
        variant="contained"
        disabled
        style={{
          backgroundColor: 'rgb(109, 118, 125)',
          color: 'white',
          borderRadius: '16px',
          textTransform: 'none',
          fontSize: '0.6rem',
          padding: '2px 5px',
        }}
      >
        {this.props.filter.name}
      </Button>
    )
  }
}

export default TaskFilter
