import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchUserSkills = () => {
  return fetchEntity(itemTypes.skillsExps)
}

export const fetchUserSkillsExpsByUserId = (id) => {
  return fetchEntity(otherURLs.usersSkillsExps, id)
}

export const deleteUserSkills = (id, successCallback, failureCallback) => {
  return deleteEntity(
    itemTypes.skillsExps,
    id,
    successCallback,
    failureCallback
  )
}

export const postUserSkills = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.skillsExps,
    body,
    successCallback,
    failureCallback
  )
}

export const updateUserSkill = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.skillsExps,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const clearErrorUserSkills = () => clearErrors(itemTypes.skillsExps)
