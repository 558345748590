import React from 'react'
import BadgeCounter from '../../../../components/BadgeCounter'

class TaskConsums extends React.Component {
  render() {
    const { task } = this.props
    const totalConso = task.consums.reduce(
      (acc, consum) => acc + consum.amount,
      0
    )

    return (
      <div className={'d-flex align-items-center'}>
        <BadgeCounter title={'Prévu'} number={task.visible_workload} />
        <BadgeCounter title={'Conso'} number={totalConso} />
        <BadgeCounter title={'RAF'} number={task.remaining_workload} />
        <BadgeCounter
          colored
          title={'Écart'}
          number={task.visible_workload - totalConso - task.remaining_workload}
        />
      </div>
    )
  }
}

export default TaskConsums
