import { connect } from 'react-redux'
import { AdminDnD } from '../../pages/Admin/components'
import {
  getAddedItems,
  getDeletedItems,
  getProjectStatuses,
} from '../../reducers'
import {
  deleteProjectStatus,
  postProjectStatus,
  updateProjectStatus,
} from '../../actions/requests/projectStatusesActions'
import { clearAddedItems, clearDeletedItems } from '../../actions/globalActions'
import { itemTypes } from '../../actions/itemTypes'
import { addNotification } from '../../actions/temporaries/notificationActions'

const mapStateToProps = (state) => ({
  data: getProjectStatuses(state).filter((status) => !status.is_sprint),
  addedProjectStatus: getAddedItems(state, itemTypes.projectStatuses),
  deletedProjectStatus: getDeletedItems(state, itemTypes.projectStatuses),
  properties: {
    name: 'Nom',
  },
  title: 'projectStatuses',
  object: 'Statut de projet',
  // feminine définit si l'accord doit se faire
  feminine: false,
})

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postProjectStatus(body, successCallback, failureCallback)),
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateProjectStatus(id, body, successCallback, failureCallback)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteProjectStatus(id, successCallback, failureCallback)),
  clearAddedProjectStatus: () =>
    dispatch(clearAddedItems(itemTypes.projectStatuses)),
  clearDeletedProjectStatus: () =>
    dispatch(clearDeletedItems(itemTypes.projectStatuses)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminDnD)
