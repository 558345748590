import React from 'react'
import Box from '@material-ui/core/Box'
import { CircularProgress, TextField } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

const HeaderEditableTitle = (props) => {
  const {
    canEditTitle,
    isSaveDisable,
    clientName,
    isEdittingTitle,
    isSavingTitle,
    onTitleChange,
    onTitleEditted,
    onToggleEdit,
    title,
  } = props

  const doCancel = () => {
    onTitleChange({ target: { name: 'name', value: ' ' } })
    onToggleEdit()
  }

  return (
    <Box display="flex" alignItems="center" style={{ width: '100%' }}>
      {clientName ? (
        <>
          <Typography noWrap variant="h5" color="secondary">
            {title + ' '}
          </Typography>

          <Typography
            variant="h6"
            color="secondary"
            style={{ padding: '0.4rem' }}
          >
            {' - ' + clientName}
          </Typography>
        </>
      ) : (
        <>
          {isEdittingTitle ? (
            <TextField
              label="Nom de la tâche"
              type="Text"
              name="name"
              id={'taskname'}
              variant="outlined"
              size={'small'}
              autoFocus
              value={title}
              onChange={onTitleChange}
              inputProps={{
                size: title.length,
              }}
              style={{
                'max-width': '90%',
                'min-width': '20%',
              }}
            />
          ) : (
            <Tooltip title={title}>
              <Typography noWrap color="secondary" variant="h5">
                {title}
              </Typography>
            </Tooltip>
          )}

          {canEditTitle &&
            (isEdittingTitle ? (
              <div style={{ position: 'relative' }}>
                <IconButton
                  aria-label="save-taskName"
                  style={{ marginLeft: '0.5rem' }}
                  disabled={isSavingTitle || isSaveDisable}
                  onClick={onTitleEditted}
                >
                  <Icon>save</Icon>
                </IconButton>

                {isSavingTitle && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    style={{ position: 'absolute', left: '35%', top: '25%' }}
                  />
                )}

                <IconButton aria-label="update-task-off" onClick={doCancel}>
                  <Icon>close</Icon>
                </IconButton>
              </div>
            ) : (
              <IconButton
                aria-label="update-task-on"
                style={{ marginLeft: '0.5rem' }}
                onClick={onToggleEdit}
              >
                <Icon>create</Icon>
              </IconButton>
            ))}
        </>
      )}
    </Box>
  )
}

export default HeaderEditableTitle
