import React from 'react'
import { connect } from 'react-redux'
import { getExperiences, getItems, getSkills, isFetching } from '../../reducers'
import { getUser } from '../../helpers'
import {
  Header,
  LittleSpinner,
  PageSpinner,
  Section,
  StyledAttiContainer,
  UserAvatar,
} from '../../components'
import routes from '../../config/routes'
import {
  deleteUserSkills,
  fetchUserSkillsExpsByUserId,
  postUserSkills,
} from '../../actions/requests/userSkillsActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../actions/temporaries/notificationActions'
import { fetchSkills } from '../../actions/requests/skillsActions'
import { fetchExperiences } from '../../actions/requests/experiencesActions'
import { deleteUser } from '../../actions/requests/usersActions'
import DeleteSkill from './components/DeleteSkill'
import office from '../../assets/img/office.png'
import EditSkill from './components/EditSkill'
import { fetchSecondaryRoles } from '../../actions/requests/secondaryRolesActions'
import { itemTypes } from '../../actions/itemTypes'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ColorButton from '../../components/ColorButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { msalInstance } from '../../constants/util'

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
})

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFirstRender: true,
      selectedSkill: '',
      selectedExperience: '',
    }

    this.handleSelectedSkill = this.handleSelectedSkill.bind(this)
    this.handleSelectedExperience = this.handleSelectedExperience.bind(this)
  }

  handleSelectedSkill(event) {
    this.setState({
      selectedSkill: event.target.value,
    })
  }

  handleSelectedExperience(event) {
    this.setState({
      selectedExperience: event.target.value,
    })
  }

  saveData() {
    if (!this.state.selectedSkill || !this.state.selectedExperience) {
      this.props.addNotification(
        'Opération échouée',
        'Veuillez remplir entièrement les champs',
        'danger'
      )
      this.setState({
        selectedSkill: '',
        selectedExperience: '',
      })
      return
    }
    let idSkill = this.state.selectedSkill
    let idExp = this.state.selectedExperience
    let body = {
      user: this.props.currentUser.id,
      exp: idExp,
      skill: idSkill,
    }
    const notificationMessage = 'Compétence ajoutée'
    this.props
      .postUserSkills(
        body,
        (id, response, message) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(
        this.setState({
          selectedSkill: '',
          selectedExperience: '',
        })
      )
  }

  componentDidMount() {
    this.setState({
      azure_id: msalInstance.getActiveAccount().localAccountId,
    })
    let promises = [
      this.props.fetchUserSkills(this.props.currentUser.id),
      this.props.fetchExperiences(),
      this.props.fetchSkills(),
      this.props.fetchSecondaryRoles(),
    ]
    Promise.all(promises).then(() => {
      this.setState({
        isFirstRender: false,
      })
    })
  }

  render() {
    const { classes } = this.props

    if (this.state.isFirstRender || !this.props.currentUser)
      return <PageSpinner />
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mon profil - Attiprod </title>
        </Helmet>
        {!this.state.isFirstRender && this.props.isFetching && (
          <LittleSpinner />
        )}
        <Header title="Mon profil" user={this.props.currentUser} />
        <StyledAttiContainer>
          <Container>
            <Grid container spacing={3} className="pt-1">
              <Grid item xs={12} sm={4}>
                <Section className="w-100 m-0" title="Photo de profil">
                  <div
                    className="p-2"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <UserAvatar size="xlarge" user={this.props.currentUser} />
                  </div>
                </Section>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                className="d-flex flex-column justify-content-between"
              >
                <Section
                  className="w-100 m-0"
                  title="Informations personnelles"
                >
                  <div className="p-3">
                    <div className="mt-2">
                      <b>Nom : </b>
                      {this.props.currentUser.firstname +
                        ' ' +
                        this.props.currentUser.lastname}
                    </div>
                    <div>
                      <b>Email : </b>
                      {this.props.currentUser.email}
                    </div>
                  </div>
                </Section>
                <Card className="w-100 m-0" style={{ height: '40%' }}>
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <img
                      src={office}
                      className="mr-4"
                      alt="logo office"
                      height="42px"
                    />
                    <ColorButton
                      onClick={() => {
                        window.open(
                          routes.editProfile +
                            '/?u=' +
                            this.state.azure_id +
                            '&v=editprofile',
                          '_blank'
                        )
                      }}
                    >
                      Modifier votre profil
                    </ColorButton>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </StyledAttiContainer>
        <StyledAttiContainer>
          <Container>
            <Grid container className="pt-1">
              <Grid item xs={12}>
                <Section className="w-100 m-0" title="Compétences">
                  <Container>
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body1" className={'pt-2 pl-2'}>
                          Ajouter une compétence :
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={3}>
                        <FormControl className={classes.formControl}>
                          <Select
                            aria-label="Compétence"
                            name="skillSelect"
                            id="skillSelect"
                            labelId="skillSelect"
                            value={this.state.selectedSkill}
                            onChange={this.handleSelectedSkill}
                          >
                            {this.props.skills.map((skill) => (
                              <MenuItem value={skill.id} key={skill.id}>
                                {skill.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} sm={3}>
                        <FormControl className={classes.formControl}>
                          <Select
                            aria-label="Expérience"
                            name="experienceSelect"
                            id="experienceSelect"
                            labelId="experienceSelect"
                            value={this.state.selectedExperience}
                            onChange={this.handleSelectedExperience}
                          >
                            {this.props.experiences.map((experience) => (
                              <MenuItem
                                value={experience.id}
                                key={experience.id}
                              >
                                {experience.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={2} className="text-center">
                        <IconButton
                          aria-label="Ajouter cette compétence"
                          onClick={() => {
                            this.saveData()
                          }}
                        >
                          <Icon>add_circle</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table component={Paper}>
                            <TableHead>
                              <TableRow>
                                <StyledCell>Compétence</StyledCell>
                                <StyledCell>Expérience</StyledCell>
                                <StyledCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.props.userSkills &&
                                this.props.userSkills
                                  .filter(
                                    (skill) =>
                                      skill.user.id ===
                                      this.props.currentUser.id
                                  )
                                  .map((skillExp) => (
                                    <TableRow key={skillExp.id}>
                                      <StyledCell>
                                        {skillExp.skill.name}
                                      </StyledCell>
                                      <StyledCell>
                                        {skillExp.exp.name}
                                      </StyledCell>
                                      <StyledCell className="text-right">
                                        <EditSkill
                                          skillExp={skillExp}
                                          experiences={this.props.experiences}
                                          userSkills={this.props.userSkills}
                                        />
                                        <DeleteSkill skillExp={skillExp} />
                                      </StyledCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Container>
                </Section>
              </Grid>
            </Grid>
          </Container>
        </StyledAttiContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
  currentUser: getUser(),
  experiences: getExperiences(state),
  skills: getSkills(state),
  userSkills: getItems(state, itemTypes.skillsExps),
})

const mapDispatchToProps = (dispatch) => ({
  fetchSkills: () => dispatch(fetchSkills()),
  fetchExperiences: () => dispatch(fetchExperiences()),
  fetchSecondaryRoles: () => dispatch(fetchSecondaryRoles()),
  fetchUserSkills: (id) => dispatch(fetchUserSkillsExpsByUserId(id)),
  deleteUser: (id, successCallback, failureCallback) =>
    dispatch(deleteUser(id, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  deleteUserSkills: (id, successCallback, failureCallback) =>
    dispatch(deleteUserSkills(id, successCallback, failureCallback)),
  postUserSkills: (body, successCallback, failureCallback) =>
    dispatch(postUserSkills(body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile))
