import * as React from 'react'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fetchBills } from '../../../../actions/requests/billsActions'
import { fetchUserSkills } from '../../../../actions/requests/userSkillsActions'
import { fetchUserSRs } from '../../../../actions/requests/userSRActions'
import { fetchProjects } from '../../../../actions/requests/projectsActions'
import { ADMIN_LIST_PAGINATION_NB_ITEMS } from '../../../../constants/global'
import { fetchTasks } from '../../../../actions/requests/tasksActions'

/**
 * TODO Change props.props to a lambda and click
 * Props :
 *    - disabled, optionnal
 *    - item, the item to delete
 *    - props, the props of the parent compenent (use to call deleteItem)
 *    - click, callback method
 */
class DeleteListItemDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      isProcessing: false,
    }

    this.toggle = this.toggle.bind(this)
    this.deleteData = this.deleteData.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  isDeletable(response, property) {
    let entities = response.data.find(
      (entity) =>
        entity[property] !== null &&
        entity[property].name === this.props.item.name
    )

    if (entities !== undefined && entities !== null) {
      this.props.addNotification(
        'Opération échouée',
        "Elément toujours en cours d'utilisation",
        'danger'
      )
      this.setState({
        isProcessing: false,
      })
      return false
    }
    return true
  }

  isDeletableFilter(response) {
    const task = response.data.find(
      (task) =>
        task.board.project.id === this.props.props.project.id &&
        task.projectFilters.length > 0
    )

    if (task !== null && task !== undefined) {
      const filter = task.projectFilters.find(
        ({ name }) => name === this.props.item.name
      )

      if (filter !== null && filter !== undefined) {
        this.props.addNotification(
          'Opération échouée',
          "Filtre toujours en cours d'utilisation",
          'danger'
        )

        this.setState({
          isProcessing: false,
        })

        return false
      }

      return true
    }

    return true
  }

  deleteAction() {
    const notificationMessage = this.props.props.feminine
      ? `${this.props.props.object} supprimée`
      : `${this.props.props.object} supprimé`

    this.props.props
      .deleteItem(
        this.props.item.id,
        this.props.props.belongToProject ? this.props.props.project.id : null,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then((resp) => {
        if (resp.status === 'STATUS_SUCCESS') {
          if (
            this.props.props.data.length ===
            ADMIN_LIST_PAGINATION_NB_ITEMS + 1
          )
            this.props.setPage(0)
          this.props.click && this.props.click(this.props.item.id)
        }
        this.setState({
          isProcessing: false,
        })
      })
  }

  deleteData() {
    this.setState({
      isProcessing: true,
    })

    switch (this.props.props.title) {
      case 'secondaryRoles':
        this.props.fetchUserSRs().then((response) => {
          if (this.isDeletable(response, 'secondaryRole')) {
            this.deleteAction()
          }
        })
        break
      case 'skills':
        this.props.fetchUserSkills().then((response) => {
          if (this.isDeletable(response, 'skill')) {
            this.deleteAction()
          }
        })
        break
      case 'experiences':
        this.props.fetchUserSkills().then((response) => {
          if (this.isDeletable(response, 'exp')) {
            this.deleteAction()
          }
        })
        break
      case 'billStatuses':
        this.props.fetchBills().then((response) => {
          if (this.isDeletable(response, 'bill_status')) {
            this.deleteAction()
          }
        })
        break
      case 'projectStatuses':
        this.props.fetchProjects().then((response) => {
          if (this.isDeletable(response, 'project_status')) {
            this.deleteAction()
          }
        })
        break
      case 'Filters':
        this.props.fetchTasks().then((response) => {
          if (this.isDeletableFilter(response)) this.deleteAction()
        })
        break
      default:
        this.props.addNotification(
          'Opération échouée',
          'Elément introuvable',
          'danger'
        )
        break
    }
  }

  render() {
    return (
      <>
        <IconButton
          disabled={this.props.disabled}
          aria-label="supprimer cet item"
          size="small"
          onClick={this.toggle}
        >
          {this.state.isProcessing ? (
            <CircularProgress size="sm" />
          ) : (
            <Icon>delete</Icon>
          )}
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle ? this.toggle : undefined}
          onExit={() => this.closeAction()}
        >
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr(e) de vouloir supprimer "{this.props.item.name}" ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.toggle}>
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.closeAction = () => this.deleteData()
                this.toggle()
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchBills: () => dispatch(fetchBills()),
  fetchUserSkills: () => dispatch(fetchUserSkills()),
  fetchUserSRs: () => dispatch(fetchUserSRs()),
  fetchProjects: () => dispatch(fetchProjects()),
  fetchTasks: () => dispatch(fetchTasks()),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(DeleteListItemDialog)
