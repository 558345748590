import styled from 'styled-components'

const StyledDiv = styled.div`
  cursor: pointer;
  float: right;
  display: flex;
  margin: 4px 0px 0 0;
`

export default StyledDiv
