import styled from 'styled-components'
import Toolbar from '@material-ui/core/Toolbar'

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: between;
  padding-left: 4px;
  padding-right: 4px;
`

export default StyledToolbar
