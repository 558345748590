import React, { Component } from 'react'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import 'moment/locale/fr'
import ListItem from '@material-ui/core/ListItem'
import deliveries from '../../deliveries/deliveries'
import Background from '../../assets/img/dark-material-bg.jpg'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { getUser } from '../../helpers'
import { PageSpinner } from '../../components'
import { getDateFromDeliveryDate } from '../../helpers/date'
import axios from 'axios'
import { msalInstance, tokenRequest } from '../../constants/util'

class Roadmap extends Component {
  reorderedDeliveries = []

  constructor(props) {
    super(props)

    this.state = {
      allDeliveries: [],
      index: 0,
      isFirstRender: true,
      reverseDeliveries: deliveries,
      version:
        process.env.REACT_APP_MAJOR +
        '.' +
        process.env.REACT_APP_MINOR +
        '.' +
        process.env.REACT_APP_REVISION,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    msalInstance
      .acquireTokenSilent(tokenRequest)
      .then((response) =>
        axios.get(routes.apiUrl + '/roadmap', {
          headers: { Authorization: `Bearer ${response.idToken}` },
        })
      )
      .then((resp) => {
        // Ajout des livraisons API à la liste de livraisons front
        const dels = [...this.state.reverseDeliveries, ...resp.data]
        let deliveries = []

        // Tri des livraisons en fonction de la date
        for (let delivery of dels) {
          delivery = {
            ...delivery,
            dateFormat: getDateFromDeliveryDate(delivery.date),
          }
          deliveries = [...deliveries, delivery]
        }

        const sortedDeliveries = deliveries.sort(
          (a, b) => a.dateFormat - b.dateFormat
        )

        // Fusion des livraisons de dates communes
        let i = 0
        if (sortedDeliveries) {
          for (const delivery of sortedDeliveries) {
            const findDelivery = this.reorderedDeliveries.find(
              (d) => d !== undefined && d.date === delivery.date
            )

            if (findDelivery !== undefined) {
              const index = this.reorderedDeliveries.indexOf(findDelivery)
              this.reorderedDeliveries[index] = {
                ...this.reorderedDeliveries[index],
                ...delivery,
              }
            } else {
              this.reorderedDeliveries[i] = delivery
            }
            ++i
          }
        }

        this.setState({
          allDeliveries: this.reorderedDeliveries.reverse(),
          isFirstRender: false,
        })
      })
  }

  handleChange(event, newValue) {
    this.setState({
      index: newValue,
    })
  }

  render() {
    if (this.state.isFirstRender) return <PageSpinner />

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Livraison - Attiprod </title>
        </Helmet>

        <Header
          title={'Livraison v.' + this.state.version}
          startIcon={
            <IconButton
              aria-label="retour au dashboard"
              style={{ color: 'rgb(148,139,150,1)' }}
              onClick={() => {
                this.props.redirectToDashboardPage()
              }}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          }
          user={this.props.currentUser}
        />

        <VerticalTimeline className="mr-lg-n5" layout="1-column">
          {this.state.allDeliveries.map((delivery) => {
            return (
              <VerticalTimelineElement
                key={delivery.date}
                className="vertical-timeline-element--work"
                contentStyle={{
                  backgroundImage: `url(${Background})`,
                  width: '600px',
                  top: '-1px',
                  paddingBottom: '0px',
                  paddingRight: '21px',
                  paddingTop: '21px',
                  paddingLeft: '21px',
                }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(62,44,66)' }}
                date={
                  <Chip
                    label={delivery.date}
                    style={{
                      background: 'inherit',
                      backgroundImage: `url(${Background})`,
                      color: '#fff',
                      left: '-195px',
                      top: '5px',
                      position: 'absolute',
                      fontSize: 'medium',
                    }}
                  />
                }
                iconStyle={{
                  backgroundImage: `url(${Background})`,
                  color: '#fff',
                }}
              >
                <Tabs value={this.state.index} onChange={this.handleChange}>
                  {delivery.api &&
                    (this.state.index === 0 ? (
                      <Tab
                        label={'API : ' + delivery.api.version}
                        style={{
                          color: 'rgb(230,126,7)',
                          fontStyle: 'bold',
                          backgroundColor: 'white',
                          borderTopLeftRadius: '3px',
                          opacity: '80%',
                        }}
                        value={0}
                      />
                    ) : this.state.index !== 0 && delivery.front ? (
                      <Tab
                        label={'API : ' + delivery.api.version}
                        style={{
                          backgroundColor: 'rgb(109, 118, 125)',
                          borderTopLeftRadius: '3px',
                          opacity: '50%',
                        }}
                        value={0}
                      />
                    ) : (
                      <Tab
                        label={'API : ' + delivery.api.version}
                        style={{
                          color: 'rgb(230,126,7)',
                          fontStyle: 'bold',
                          backgroundColor: 'white',
                          borderTopRightRadius: '3px',
                          borderTopLeftRadius: '3px',
                          opacity: '80%',
                        }}
                        value={0}
                      />
                    ))}
                  {delivery.front &&
                    (this.state.index === 1 ? (
                      <Tab
                        label={'Front : ' + delivery.front.version}
                        style={{
                          color: 'rgb(230,126,7)',
                          fontStyle: 'bold',
                          backgroundColor: 'white',
                          borderTopRightRadius: '3px',
                          opacity: '80%',
                        }}
                        value={1}
                      />
                    ) : this.state.index !== 1 && delivery.api ? (
                      <Tab
                        label={'Front : ' + delivery.front.version}
                        style={{
                          backgroundColor: 'rgb(109, 118, 125)',
                          borderTopRightRadius: '3px',
                          opacity: '50%',
                        }}
                        value={1}
                      />
                    ) : (
                      <Tab
                        label={'Front : ' + delivery.front.version}
                        style={{
                          color: 'rgb(230,126,7)',
                          fontStyle: 'bold',
                          backgroundColor: 'white',
                          borderTopRightRadius: '3px',
                          borderTopLeftRadius: '3px',
                          opacity: '80%',
                        }}
                        value={1}
                      />
                    ))}
                </Tabs>
                {((delivery.api && this.state.index === 0) ||
                  (!delivery.front && delivery.api)) && (
                  <Grid item xs={12}>
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      style={{
                        color: 'rgb(230,126,7)',
                        backgroundColor: 'white',
                        align: 'right',
                        borderRadius: '3px',
                        borderTopLeftRadius: '0px',
                        opacity: '80%',
                      }}
                    >
                      {delivery.api.tickets.length ? (
                        delivery.api.tickets.map((ticket, index) => {
                          return (
                            <ListItem
                              key={`api-${ticket.number}`}
                              button
                              divider={index < delivery.api.tickets.length - 1}
                              onClick={() => {
                                window.open(
                                  routes.gitlab + '/' + ticket.number,
                                  '_blank'
                                )
                              }}
                            >
                              <ListItemText
                                primary={
                                  '#' + ticket.number + ' : ' + ticket.name
                                }
                              />
                            </ListItem>
                          )
                        })
                      ) : (
                        <ListItem>
                          <ListItemText primary={delivery.api.message} />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                )}
                {((delivery.front && this.state.index === 1) ||
                  (!delivery.api && delivery.front)) && (
                  <Grid item xs={12}>
                    <List
                      component="nav"
                      aria-label="main mailbox folders"
                      style={{
                        color: 'rgb(230,126,7)',
                        backgroundColor: 'white',
                        align: 'right',
                        borderRadius: '3px',
                        borderTopLeftRadius: '0px',
                        opacity: '80%',
                      }}
                    >
                      {delivery.front.tickets.length ? (
                        delivery.front.tickets.map((ticket, index) => {
                          return (
                            <ListItem
                              key={`front-${ticket.number}`}
                              button
                              divider={
                                index < delivery.front.tickets.length - 1
                              }
                              onClick={() => {
                                window.open(
                                  routes.gitlab + '/' + ticket.number,
                                  '_blank'
                                )
                              }}
                            >
                              <ListItemText
                                primary={
                                  '#' + ticket.number + ' : ' + ticket.name
                                }
                              />
                            </ListItem>
                          )
                        })
                      ) : (
                        <ListItem>
                          <ListItemText primary={delivery.front.message} />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                )}
              </VerticalTimelineElement>
            )
          })}
        </VerticalTimeline>
      </>
    )
  }
}

const mapStateToProps = () => ({
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Roadmap)
