import React from 'react'
import { connect } from 'react-redux'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getItems, getSecondaryRoles } from '../../../../reducers'
import {
  userListButtonAction,
  userThumbnailTypes,
  userTypes,
} from '../../../../constants/global'
import { UsersListContainer, UserThumbnail } from '../../../../components'
import { getElemByCustomFieldInArray } from '../../../../helpers'
import { itemTypes } from '../../../../actions/itemTypes'
import { postFormEntity } from '../../../../actions/globalActions'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

/**
 * Props :
 *    - project, the edited project
 *    - userType, GESTION_PROJET or REALISATION
 *    - alreadyAssigned, the users already assigned on this project
 *    - disabled, optionnal, if this modal is disabled
 *    - action, opationnel, callback overriding default behaviour
 * Store :
 *    - Users
 *    - SecondaryRoles
 */

class UserAddDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      addedUsers: [],
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  addStateUser(user) {
    this.setState({
      addedUsers: [...this.state.addedUsers, user],
    })
  }

  removeStateUser(user) {
    this.setState({
      addedUsers: this.state.addedUsers.filter((u) => u !== user),
    })
  }

  render() {
    return (
      <>
        <IconButton
          disabled={this.props.disabled}
          aria-label="ajouter un utilisateur au projet"
          className="m-1"
          style={{ position: 'relative', top: '5px' }}
          onClick={() => {
            this.toggle()
            this.setState({ addedUsers: [] })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
          onClose={this.toggle}
        >
          <DialogTitle toggle={this.toggle}>
            Sélectionner des utilisateurs à ajouter
          </DialogTitle>
          <DialogContent>
            <UsersListContainer
              buttonAction={userListButtonAction.SELECTION}
              omitted={[
                ...this.props.alreadyAssigned,
                ...this.state.addedUsers,
              ]}
              onAdd={(user) => this.addStateUser(user)}
              users={this.props.users}
            />
            <DialogContentText>Utilisateurs à ajouter :</DialogContentText>
            {this.state.addedUsers.map((user) => (
              <UserThumbnail
                key={user.id}
                disabled={this.props.disabled}
                data={user}
                type={userThumbnailTypes.USER}
                icon={<Icon>delete</Icon>}
                onClick={() => this.removeStateUser(user)}
              />
            ))}
          </DialogContent>
          <DialogActions className="justify-content-center">
            <Button
              aria-label="Fermer"
              color="secondary"
              onClick={() => {
                this.closeAction = () => {}
                this.toggle()
              }}
            >
              Fermer
            </Button>
            <Button
              aria-label="Enregistrer"
              color="primary"
              onClick={() => {
                this.closeAction = () => {
                  if (this.props.action) {
                    this.props.action(this.state.addedUsers)
                  } else {
                    this.state.addedUsers.forEach((user) => {
                      let u = this.props.users.find((us) => us.id === user.id)
                      const notificationMessage = 'Utilisateur ajouté au projet'
                      this.props.addUser(
                        {
                          project: this.props.project,
                          user: u,
                          secondaryRole:
                            this.props.userType === userTypes.GESTION_PROJET
                              ? getElemByCustomFieldInArray(
                                  this.props.secondaryRoles,
                                  'codename',
                                  'ROLE_CP'
                                )
                              : getElemByCustomFieldInArray(
                                  this.props.secondaryRoles,
                                  'codename',
                                  'ROLE_DEV'
                                ),
                        },
                        this.props.project.id,
                        (id, response) =>
                          this.props.successNotification(
                            id,
                            response,
                            notificationMessage
                          ),
                        (id, error) =>
                          this.props.failureNotificationApi(id, error)
                      )
                    })
                  }
                }
                this.toggle()
              }}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  users: getItems(state, itemTypes.usersSimple),
  secondaryRoles: getSecondaryRoles(state),
})

const mapDispatchToProps = (dispatch) => ({
  addUser: (body, projectId, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.userSecondaryRoles,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserAddDialog)
