import styled from 'styled-components'

const StyledLi = styled.li`
  & {
    outline: none;
    display: block;
    float: left;
    line-height: 36px;
    color: black;
    padding: 0 20px 0 40px;
    background-color: #e9ecef;
    position: relative;
    font-size: 16px;
  }

  &:before {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 8px 0;
    position: absolute;
    top: 0;
    background-color: #e9ecef;
    font-weight: bold;
  }

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    width: 36px;
    height: 36px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    background-color: #e9ecef;
    box-shadow: 2px -2px 0 2px rgba(0, 0, 0, 0.4), 3px -3px 0 2px #3e2c42;
    border-radius: 0 5px 0 50px;
  }

  &:last-child:after {
    content: none;
  }

  &:hover {
    cursor: pointer;
  }
`

export default StyledLi
