import { status } from '../constants/global'
import routes from '../config/routes'
import {
  ACTION_CLEAR_ADDED,
  ACTION_CLEAR_DELETED,
  ACTION_CLEAR_ERRORS,
  ACTION_DELETE,
  ACTION_FETCH,
  ACTION_FORM_DELETE,
  ACTION_FORM_POST,
  ACTION_FORM_PUT,
  ACTION_POST,
  ACTION_PUT,
  NO_CALL,
} from './actionTypes'

export const notify = (
  status,
  type,
  itemType,
  def,
  data,
  id,
  projectId,
  error,
  successCallback,
  failureCallback
) => ({
  status,
  type,
  itemType,
  def,
  data,
  id,
  projectId,
  error,
  successCallback,
  failureCallback,
})

export const notifyIsFetching = (type, itemType, id, def) => {
  return notify(status.IS_FETCHING, type, itemType, def, null, id)
}

// failureCallback is used by form actions to transmit it to the request action later
export const notifySuccess = (
  type,
  itemType,
  def,
  data,
  id,
  projectId,
  successCallback,
  failureCallback
) => {
  return notify(
    status.SUCCESS,
    type,
    itemType,
    def,
    data,
    id,
    projectId,
    null,
    successCallback,
    failureCallback
  )
}

export const notifyFailure = (type, itemType, id, def, error) => {
  return notify(
    status.FAILURE,
    type,
    itemType,
    def,
    null,
    id,
    null,
    error,
    null
  )
}

export const fetchAllEntities = (itemType, def = true) => {
  return applyAction(ACTION_FETCH, itemType, def)
}

export const fetchEntity = (itemType, id, projectId = null, def = true) => {
  return applyAction(ACTION_FETCH, itemType, def, id, projectId)
}

export const fetchEntityWithParams = (
  itemType,
  id,
  projectId = null,
  def = true,
  requestParams = []
) => {
  return applyAction(
    ACTION_FETCH,
    itemType,
    def,
    id,
    projectId,
    null,
    undefined,
    undefined,
    requestParams
  )
}

export const postEntity = (
  itemType,
  body,
  successCallback,
  failureCallback,
  projectId = null,
  def = true
) => {
  return applyAction(
    ACTION_POST,
    itemType,
    def,
    null,
    projectId,
    body,
    successCallback,
    failureCallback
  )
}

export const updateEntity = (
  itemType,
  id,
  body,
  successCallback,
  failureCallback,
  projectId = null,
  def = true
) => {
  return applyAction(
    ACTION_PUT,
    itemType,
    def,
    id,
    projectId,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteEntity = (
  itemType,
  id,
  successCallback,
  failureCallback,
  projectId = null,
  def = true
) => {
  return applyAction(
    ACTION_DELETE,
    itemType,
    def,
    id,
    projectId,
    null,
    successCallback,
    failureCallback
  )
}

export const clearErrors = (itemType) =>
  notifySuccess(ACTION_CLEAR_ERRORS, itemType, true)
export const clearAddedItems = (itemType) =>
  notifySuccess(ACTION_CLEAR_ADDED, itemType, true)
export const clearDeletedItems = (itemType) =>
  notifySuccess(ACTION_CLEAR_DELETED, itemType, true)

export const postFormEntity = (
  itemType,
  body,
  successCallback,
  failureCallback,
  projectId = null
) => {
  return applyAction(
    ACTION_FORM_POST,
    itemType,
    true,
    null,
    projectId,
    body,
    successCallback,
    failureCallback
  )
}

export const updateFormEntity = (
  itemType,
  id,
  body,
  successCallback,
  failureCallback,
  projectId = null
) => {
  return applyAction(
    ACTION_FORM_PUT,
    itemType,
    true,
    id,
    projectId,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteFormEntity = (
  itemType,
  id,
  successCallback,
  failureCallback,
  projectId = null
) => {
  return applyAction(
    ACTION_FORM_DELETE,
    itemType,
    true,
    id,
    projectId,
    null,
    successCallback,
    failureCallback
  )
}

export const applyRequest = (request) => {
  return applyAction(
    request.actionType,
    request.itemType,
    request.def,
    request.id,
    request.projectId,
    request.body,
    request.successCallback,
    request.failureCallback
  )
}

const applyAction = (
  actionType,
  itemType,
  def,
  id = null,
  projectId,
  body = null,
  successCallback,
  failureCallback,
  params
) => {
  return (dispatch) => {
    dispatch(notifyIsFetching(actionType, itemType, id, def))

    let url =
      routes.apiUrl +
      '/' +
      (id === null ? itemType.url : itemType.urlWithID(id))
    if (params && params.length > 0) {
      const paramsString = params.map((p) => `${p.key}=${p.value}`)
      url += '?' + paramsString.join('&')
    }

    return actionType
      .call(url, itemType, body)
      .then((response) => {
        actionType.call !== NO_CALL &&
          successCallback &&
          successCallback(id, response)
        return dispatch(
          notifySuccess(
            actionType,
            itemType,
            def,
            response.data,
            id,
            projectId,
            successCallback,
            failureCallback
          )
        )
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          window.location.href = routes.logout
        }
        actionType.call !== NO_CALL &&
          failureCallback &&
          failureCallback(id, error)
        dispatch(
          notifyFailure(
            actionType,
            itemType,
            id,
            def,
            error,
            successCallback,
            failureCallback
          )
        )
        return error
      })
  }
}
