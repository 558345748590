import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { Section, StyledAttiContainer, CenteredDiv } from '../../components'
import {
  getProjectStatuses,
  getProjectTypes,
  getProjectTypesIdByIdentifier,
  isFetching,
} from '../../reducers'
import { projectTypes } from '../../constants/global'
import { postProject } from '../../actions/requests/projectsActions'
import { fetchProjectTypes } from '../../actions/requests/projectTypesActions'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../actions/temporaries/notificationActions'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import Header from '../../components/Header'
import { getUser } from '../../helpers'
import ColorButton from '../../components/ColorButton'
import { Helmet } from 'react-helmet'

class NewProject extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clientName: '',
      projectName: '',
      projectStatus: '',
    }

    this.handleValidSubmit = this.handleValidSubmit.bind(this)
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this)
  }

  handleValidSubmit(event, values) {
    document.getElementById('saveButton').disabled = true
    let project_type =
        this.props.projectTypesByIdentifier[
          this.props.match.params.projecttype
        ],
      body = {
        project_name: this.state.projectName,
        client_name: this.state.clientName,
        project_type: project_type,
      }

    if (this.props.match.params.projecttype === projectTypes.forfait)
      Object.assign(body, { project_status: this.state.projectStatus })

    const notificationMessage = 'Projet créé'
    this.props
      .postProject(
        body,
        (id, response, message) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then((response) => {
        if (response.status === 'STATUS_SUCCESS') {
          this.props.redirectToEditProjectPage(response.data.id)
        }
        return response
      })
      .catch((error) => {
        return error
      })
  }

  handleInvalidSubmit() {
    this.props.addNotification(
      'Formulaire invalide',
      'Attention, des champs sont mal renseignés',
      'danger'
    )
  }

  componentDidMount() {
    this.props.fetchProjectTypes()
    this.props.fetchProjectStatuses()
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nouveau projet - Attiprod </title>
        </Helmet>
        <Header
          title="Créer un projet"
          startIcon={
            <IconButton
              aria-label="retour au dashboard"
              style={{ color: 'rgb(148,139,150,1)' }}
              onClick={() => {
                this.props.redirectToDashboardPage()
              }}
            >
              <Icon>arrow_back</Icon>
            </IconButton>
          }
          user={this.props.currentUser}
        />
        <div>
          <StyledAttiContainer>
            <CenteredDiv>
              <Section
                title={
                  'Nouveau projet ' +
                  projectTypes[this.props.match.params.projecttype]
                }
              >
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleValidSubmit}
                  onError={this.handleInvalidSubmit}
                  style={{ padding: '10px' }}
                >
                  <TextValidator
                    name="clientNameInput"
                    id="clientNameInput"
                    label="Client"
                    value={this.state.clientName}
                    onChange={(e) => {
                      this.setState({
                        clientName: e.target.value,
                      })
                    }}
                    validators={['required']}
                    errorMessages={[
                      'Remplir le nom du Client est obligatoire.',
                    ]}
                    fullWidth
                    variant="outlined"
                    style={{ paddingBottom: '10px' }}
                  />
                  <TextValidator
                    label="Nom"
                    name="projectNameInput"
                    id="projectNameInput"
                    value={this.state.projectName}
                    onChange={(e) => {
                      this.setState({
                        projectName: e.target.value,
                      })
                    }}
                    validators={['required']}
                    errorMessages={[
                      'Remplir le nom du projet est obligatoire.',
                    ]}
                    fullWidth
                    variant="outlined"
                    style={{ paddingBottom: '10px' }}
                  />
                  {this.props.match.params.projecttype === 'forfait' && (
                    <TextValidator
                      select
                      label="Statut"
                      name="projectStatusInput"
                      id="projectStatusInput"
                      value={this.state.projectStatus}
                      onChange={(e) => {
                        this.setState({
                          projectStatus: e.target.value,
                        })
                      }}
                      validators={['required']}
                      errorMessages={[
                        'Selectionner un Statut est obligatoire pour ce type de projet.',
                      ]}
                      fullWidth
                      variant="outlined"
                      style={{ paddingBottom: '10px' }}
                    >
                      {this.props.projectStatuses
                        .filter((status) => !status.is_sprint)
                        .map((status) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.name}
                          </MenuItem>
                        ))}
                    </TextValidator>
                  )}
                  <div className="d-flex justify-content-center">
                    <ColorButton
                      type="submit"
                      id="saveButton"
                      disabled={this.props.isFetching}
                      style={{
                        position: this.props.isFetching ? 'relative' : 'static',
                        left: this.props.isFetching ? '12px' : '0px',
                      }}
                    >
                      Enregistrer
                    </ColorButton>
                    {this.props.isFetching && (
                      <CircularProgress
                        size={24}
                        color="inherit"
                        style={{
                          position: 'relative',
                          top: '7px',
                          right: '44px',
                        }}
                      />
                    )}
                  </div>
                </ValidatorForm>
              </Section>
            </CenteredDiv>
          </StyledAttiContainer>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  projectTypes: getProjectTypes(state),
  projectStatuses: getProjectStatuses(state),
  isFetching: isFetching(state),
  projectTypesByIdentifier: getProjectTypesIdByIdentifier(state),
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  fetchProjectTypes: () => dispatch(fetchProjectTypes()),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
  postProject: (body, successCallback, failureCallback) =>
    dispatch(postProject(body, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  redirectToEditProjectPage: (id) =>
    dispatch(push(routes.editProject + '/' + id)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewProject)
