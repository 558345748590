import * as React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import routes from '../../../../config/routes'
import {
  failureNotification,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import ColorButton from '../../../../components/ColorButton'

/**
 * props :
 *  project
 *  */
class ProjectExport extends React.Component {
  downloadFile = () => {
    axios({
      url: `${routes.apiUrl}/projects/${this.props.project.id}/export`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const { client_name, project_name } = this.props.project
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute(
          'download',
          `${client_name} - ${project_name}  - Status.xlsx`
        )
        document.body.appendChild(link)
        link.click()

        return this.props.successNotification(
          this.props.project.id,
          response,
          'Projet exporté'
        )
      })
      .catch((error) => {
        return this.props.failureNotification(this.props.project.id, error)
      })
  }

  render() {
    return (
      <>
        <ColorButton
          aria-label="Enregistrer"
          variant="outlined"
          style={{ width: '12rem', margin: 'auto', 'margin-right': '1rem' }}
          onClick={this.downloadFile}
        >
          Exporter
        </ColorButton>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotification: (id, error) => dispatch(failureNotification(id, error)),
})

export default connect(null, mapDispatchToProps)(ProjectExport)
