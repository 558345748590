import styled from 'styled-components'

const StyledDiv = styled.div`
  min-height: 180px;
  min-width: 300px;
  margin: 10px;
  padding: 0px;
  overflow: hidden;
  display: inline-block;
  box-shadow: 4px 4px 10px 2px lightgrey;
`

export default StyledDiv
