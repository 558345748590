import styled from 'styled-components'

const StyledDiv = styled.div`
  background: #f4f6fa;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  right: 0;
  left: 0px;
`

export default StyledDiv
