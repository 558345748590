import * as React from 'react'
import { connect } from 'react-redux'
import {
  failureNotificationApi,
  successNotification,
} from '../../../actions/temporaries/notificationActions'
import { updateUserSkill } from '../../../actions/requests/userSkillsActions'
import { SectionSpinner } from '../../../components'
import { getExperiences, getSkills } from '../../../reducers'
import { getUser } from '../../../helpers'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'

/**
 * Props :
 *    - skillExp, the edited skillExp
 * Store :
 *    - Skills
 *    - Experiences
 */

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
})

class EditSkill extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)

    if (!props.skillExp) {
      return
    }

    this.state = {
      modal: false,
      selectedSkill: props.skillExp.skill.id,
      selectedExp: props.skillExp.exp.id,
    }

    this.toggle = this.toggle.bind(this)
    this.handleSkillChange = this.handleSkillChange.bind(this)
    this.handleExpChange = this.handleExpChange.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  handleSkillChange(event) {
    this.setState({
      selectedSkill: event.target.value,
    })
  }

  handleExpChange(event) {
    this.setState({
      selectedExp: event.target.value,
    })
  }

  saveData() {
    let body = {
      skill: this.state.selectedSkill,
      exp: this.state.selectedExp,
    }
    const notificationMessage = 'Compétence modifiée'
    this.props.editUserSkill(
      this.props.skillExp.id,
      body,
      (id, response, message) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  render() {
    const { classes } = this.props

    let skills = this.props.skills
    let experiences = this.props.experiences
    return (
      <>
        <IconButton aria-label="éditer cette compétence" onClick={this.toggle}>
          <Icon>edit</Icon>
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
        >
          <DialogTitle toggle={this.toggle}>Editer un item</DialogTitle>
          {this.props.isFetching ? (
            <SectionSpinner />
          ) : (
            <>
              <DialogContent style={{ overflow: 'visible' }}>
                <Container>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="skillSelect">Compétence</InputLabel>
                        <Select
                          name="skillSelect"
                          id="skillSelect"
                          labelId="skillSelect"
                          value={this.state.selectedSkill}
                          onChange={(event) => this.handleSkillChange(event)}
                        >
                          {skills.map((skill) => (
                            <MenuItem value={skill.id} key={skill.id}>
                              {skill.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel id="experienceSelect">
                          Expérience
                        </InputLabel>
                        <Select
                          name="experienceSelect"
                          id="experienceSelect"
                          labelId="experienceSelect"
                          value={this.state.selectedExp}
                          onChange={this.handleExpChange}
                        >
                          {experiences.map((experience) => (
                            <MenuItem value={experience.id} key={experience.id}>
                              {experience.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Container>
              </DialogContent>
              <DialogActions className="justify-content-center">
                <Button color="secondary" onClick={this.toggle}>
                  Fermer
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.closeAction = () => this.saveData()
                    this.toggle()
                  }}
                >
                  Enregistrer
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  sessionUser: getUser(state),
  experiences: getExperiences(state),
  skills: getSkills(state),
})

const mapDispatchToProps = (dispatch) => ({
  editUserSkill: (id, body, successCallback, failureCallback) =>
    dispatch(updateUserSkill(id, body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditSkill))
