import React from 'react'
import Typography from '@material-ui/core/Typography'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getComparator, stableSort } from '../../../../helpers'

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: '1px solid lightgray',
    textAlign: 'right',
    backgroundColor: 'white',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))(TableCell)

const getStyles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
})

const headCells = [
  { id: 'date', label: 'Jour', width: 150 },
  { id: 'project', label: 'Projet', width: 250 },
  { id: 'task', label: 'Tâche', width: 400 },
  { id: 'amount', label: 'Charge', width: 120 },
]

class MonthDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'desc',
      orderBy: 'date',
    }
  }

  getConsums = () => {
    return this.props.consums.filter((c) => {
      let date = new Date(c.date)
      date.setHours(0, 0, 0, 0)
      return this.props.dates.find((d) => d.getTime() === date.getTime())
    })
  }

  getFormattedConsums = (consums) => {
    return consums.map((consum) => ({
      ...consum,
      date: consum.date.split('T')[0],
      project: consum.task.board.project.project_name,
      task: consum.task.name,
    }))
  }

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    })
  }

  render() {
    const { classes } = this.props
    const { order, orderBy } = this.state

    const filteredConsums = this.getConsums()
    const consums = this.getFormattedConsums(filteredConsums)

    return (
      <>
        <Typography>
          Détails sur les saisies effectuées durant le mois choisi :
        </Typography>

        <TableContainer style={{ maxHeight: 33 * 11 }}>
          <Table aria-label={'details table'} size={'small'} stickyHeader>
            <TableHead>
              <TableRow>
                {headCells.map((cell) => (
                  <StyledTableCell
                    key={cell.id}
                    sortDirection={orderBy === cell.id ? order : false}
                    style={{ minWidth: cell.width }}
                  >
                    <TableSortLabel
                      active={orderBy === cell.id}
                      direction={orderBy === cell.id ? order : 'asc'}
                      onClick={() => this.handleRequestSort(cell.id)}
                    >
                      {cell.label}
                      {orderBy === cell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.isLoading ? (
                <TableRow>
                  <StyledTableCell colSpan={4} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </StyledTableCell>
                </TableRow>
              ) : (
                <>
                  {stableSort(consums, getComparator(order, orderBy)).map(
                    (consum) => (
                      <TableRow key={consum.id}>
                        <StyledTableCell>{consum.date}</StyledTableCell>
                        <StyledTableCell>{consum.project}</StyledTableCell>
                        <StyledTableCell>{consum.task}</StyledTableCell>
                        <StyledTableCell>{consum.amount}</StyledTableCell>
                      </TableRow>
                    )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

export default withStyles(getStyles)(MonthDetails)
