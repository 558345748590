import React from 'react'
import { connect } from 'react-redux'
import {
  clearAllErrors,
  getNotifications,
  retrieveErrors,
} from '../../reducers'
import { GenericToast } from '..'
import { clearNotifications } from '../../actions/temporaries/notificationActions'
import { itemTypes } from '../../actions/itemTypes'

class Toaster extends React.Component {
  clearErrors() {
    if (this.props.errors.length > 0) {
      for (let key of Object.keys(itemTypes)) this.props[key]()
    }
  }

  componentDidMount() {
    //dispatch CLEAR_ERRORS
    this.clearErrors()
    this.props.clearNotifications()
  }

  componentDidUpdate() {
    //dispatch CLEAR_ERRORS
    this.clearErrors()
  }

  render() {
    return (
      <div id="messageField" className="toastContainer">
        {this.props.errors.map((error) => {
          if (error.response === null || error.response === undefined) {
            return (
              <GenericToast key={error.date} icon="danger" header={'Erreur'}>
                Erreur d'origine inconnue
              </GenericToast>
            )
          } else {
            return (
              <GenericToast
                key={error.date}
                icon="danger"
                header={'Erreur ' + error.response.status}
              >
                {error.response.statusText}
              </GenericToast>
            )
          }
        })}
        {this.props.notifications.map((notif) => {
          return (
            <GenericToast
              key={notif.id}
              icon={notif.icon}
              header={notif.header}
            >
              {notif.body}
            </GenericToast>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    errors: retrieveErrors(state),
    notifications: getNotifications(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...clearAllErrors(dispatch),
  clearNotifications: () => dispatch(clearNotifications()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Toaster)
