import React from 'react'
import { postSprint } from '../../../../actions/requests/sprintsActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import ColorButton from '../../../../components/ColorButton'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { isInvalidDate } from '../../../../helpers/date'

const getButtonStyle = {
  textTransform: 'none',
  height: '2.4rem',
}

const StyledDialog = withStyles({
  paper: {
    overflowY: 'visible',
  },
})(Dialog)

/**
 * Props :
 *    - nextSprint(), callback when user clicks accept on the modal
 *    - disabled
 */
class ProjectInfosNextSprintDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      startDate: null,
      endDate: null,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date })
  }

  handleEndDateChange(date) {
    this.setState({ endDate: date })
  }

  nextSprint() {
    const number = parseInt(this.props.currentSprint.number) + 1
    const notificationMessage = 'Sprint créé'
    const body = {
      project: this.props.project.id,
      number,
      start: moment(this.state.startDate).format('DD-MM-YYYY'),
      end: moment(this.state.endDate).format('DD-MM-YYYY'),
    }
    if (
      this.state.startDate < this.state.endDate &&
      this.props.currentSprintEndDate < this.state.startDate
    ) {
      this.props
        .postSprint(
          body,
          this.props.project.id,
          (id, response) =>
            this.props.successNotification(id, response, notificationMessage),
          (id, error) => this.props.failureNotificationApi(id, error)
        )
        .then((response) => {
          if (response.status === 'STATUS_SUCCESS') {
            this.props.onNextSprint(response.data)
            this.setState({ startDate: null, endDate: null })
          }
        })
    } else {
      this.props.addNotification(
        'Opération échouée',
        'Dates de sprint suivant invalides',
        'danger'
      )
    }
  }

  render() {
    return (
      <>
        <ColorButton
          style={getButtonStyle}
          disabled={this.props.disabled}
          onClick={this.toggle}
        >
          Passer au sprint suivant
        </ColorButton>
        <StyledDialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
          style={{ overflow: 'visible !important' }}
        >
          <DialogTitle toggle={this.toggle}>Sprint suivant</DialogTitle>
          <DialogContent>
            <FormGroup style={{ paddingBottom: '1rem' }}>
              <FormLabel>Début</FormLabel>
              <DatePicker
                variant={'inline'}
                inputVariant={'outlined'}
                format={'DD-MM-yyyy'}
                shouldDisableDate={isInvalidDate}
                value={this.state.startDate}
                minDate={
                  this.props.currentSprintEndDate || new Date('1970-01-01')
                }
                maxDate={this.state.endDate || new Date('2100-12-31')}
                onChange={(date) => this.handleStartDateChange(date)}
                InputProps={{
                  style: { backgroundColor: 'white', height: '45px' },
                }}
                autoOk
              />
            </FormGroup>
            <FormGroup style={{ paddingBottom: '1rem' }}>
              <FormLabel>Fin</FormLabel>
              <DatePicker
                variant={'inline'}
                inputVariant={'outlined'}
                format={'DD-MM-yyyy'}
                shouldDisableDate={isInvalidDate}
                value={this.state.endDate}
                minDate={this.state.startDate || new Date('1970-01-01')}
                onChange={(date) => this.handleEndDateChange(date)}
                InputProps={{
                  style: { backgroundColor: 'white', height: '45px' },
                }}
                autoOk
              />
            </FormGroup>
          </DialogContent>
          <DialogActions className="justify-content-center">
            <Button color="secondary" onClick={this.toggle}>
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.closeAction = () => {
                  this.nextSprint()
                }
                this.toggle()
              }}
            >
              Accepter
            </Button>
          </DialogActions>
        </StyledDialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  postSprint: (body, projectId, successCallback, failureCallback) =>
    dispatch(postSprint(body, successCallback, failureCallback, projectId)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(ProjectInfosNextSprintDialog)
