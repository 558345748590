import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import Background from '../../assets/img/dark-material-bg.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    '&.user': {
      '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
    marginBottom: '4rem',
  },
  avatar: {
    width: '7rem',
    height: '7rem',
    position: 'absolute',
    top: '73%',
    left: '23.5%',
    boxSizing: 'content-box',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& > img': {
      borderRadius: '50%',
    },
    padding: '0.6rem',
    borderRadius: '50%',
    backgroundImage: `url(${Background})`,
    background: 'no-repeat center center fixed',
    backgroundSize: 'cover',
  },
  littleavatar: {
    width: 40,
    height: 40,
    margin: 'auto',
    '& > img': {
      borderRadius: '50%',
    },
  },
}))

function UserNavbarHeader(props) {
  const classes = useStyles()

  return (
    <>
      {props.expanse ? (
        <AppBar
          elevation={0}
          className={'pr-0'}
          classes={{ root: classes.root }}
          style={{
            position: 'relative',
            whiteSpace: 'nowrap',
            paddingTop: '1rem',
            marginBottom: '7rem',
            height: '10rem',
          }}
          color="primary"
        >
          <>
            <Typography
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '1rem',
              }}
            >
              {props.user.firstname} {props.user.lastname}
            </Typography>
            <Typography
              variant="body2"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingBottom: '1rem',
                fontSize: '0.75rem',
                fontStyle: 'italic',
              }}
            >
              {props.user.email}
            </Typography>
            <Avatar
              className={clsx(classes.avatar, 'avatar')}
              alt="user photo"
              src={
                props.user.profile_pic && props.user.profile_pic !== ''
                  ? props.user.profile_pic
                  : ''
              }
            />
          </>
        </AppBar>
      ) : (
        <AppBar
          elevation={0}
          className={'pr-0'}
          classes={{ root: classes.root }}
          style={{
            position: 'relative',
            whiteSpace: 'nowrap',
            paddingTop: '1rem',
            marginBottom: '2rem',
            height: '10rem',
          }}
          color="primary"
        >
          <Avatar
            className={clsx(classes.littleavatar, 'avatar')}
            alt="user photo"
            src={
              props.user.profile_pic && props.user.profile_pic !== ''
                ? props.user.profile_pic
                : ''
            }
          />
        </AppBar>
      )}
    </>
  )
}

export default UserNavbarHeader
