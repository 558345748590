import styled from 'styled-components'
import { Button } from 'atti-components'

const StyledAttiButton = styled(Button)`
  background-color: #f29400;
  ${({ color }) =>
    color &&
    `
    background-color: ${color};
  `}
`

export default StyledAttiButton
