import { fetchEntity } from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchAllFilters = () => {
  return fetchEntity(itemTypes.filters)
}

export const fetchFiltersByProjectId = (id) => {
  return fetchEntity(otherURLs.projectFilters, id, id)
}
