import React from 'react'
import { UserAvatar } from '../../../../components'
import { getElemByIdInArray, getUser } from '../../../../helpers'
import { getItems, isFetching, retrieveRequests } from '../../../../reducers'
import {
  applyRequest,
  deleteEntity,
  deleteFormEntity,
  fetchEntity,
  postFormEntity,
} from '../../../../actions/globalActions'
import { itemTypes } from '../../../../actions/itemTypes'
import {
  successNotification,
  failureNotificationApi,
  addNotification,
} from '../../../../actions/temporaries/notificationActions'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { updateUser } from '../../../../actions/requests/usersActions'
import DeleteSkill from '../../../Profile/components/DeleteSkill'

class EditUserDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModalUser: false,
      deleteModalUserSkill: false,
      newSkillModal: false,
      closeAll: false,
      disabled: this.props.disabled ? this.props.disabled : false,
      isProcessing: false,
      selectedSkill: null,
      selectedExperience: null,
      selectedRole: this.props.editedUser.role_id,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDeleteUser = this.toggleDeleteUser.bind(this)
    this.toggleDeleteUserSkill = this.toggleDeleteUserSkill.bind(this)
    this.toggleNewSkill = this.toggleNewSkill.bind(this)
    this.toggleAllUser = this.toggleAllUser.bind(this)
    this.toggleAllUserSkill = this.toggleAllUserSkill.bind(this)
    this.handleSelectedSkill = this.handleSelectedSkill.bind(this)
    this.handleSelectedExperience = this.handleSelectedExperience.bind(this)
    this.handleSelectedRole = this.handleSelectedRole.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleDeleteUser() {
    this.setState({
      deleteModalUser: !this.state.deleteModalUser,
      closeAll: false,
    })
  }

  toggleDeleteUserSkill() {
    this.setState({
      deleteModalUserSkill: !this.state.deleteModalUserSkill,
      closeAll: false,
    })
  }

  toggleNewSkill() {
    this.setState({
      newSkillModal: !this.state.newSkillModal,
      closeAll: false,
    })
  }

  toggleAllUser() {
    this.setState({
      deleteModal: !this.state.deleteModalUser,
      closeAll: true,
    })
  }

  toggleAllUserSkill() {
    this.setState({
      deleteModal: !this.state.deleteModalUserSkill,
      closeAll: true,
    })
  }

  saveActions() {
    for (let request of this.props.requests) {
      this.props.sendRequest(request)
    }
  }

  handleSelectedSkill(e) {
    this.setState({
      selectedSkill: e.target.value,
    })
  }

  handleSelectedExperience(e) {
    this.setState({
      selectedExperience: e.target.value,
    })
  }

  handleSelectedRole(e) {
    this.setState({
      selectedRole: e.target.value,
    })
  }

  addData() {
    let idSkill = this.state.selectedSkill
    let idExp = this.state.selectedExperience
    let checkSkill = this.props.skillExps.find(
      (skillExp) =>
        skillExp.user.id === this.props.editedUser.id &&
        skillExp.skill.id === idSkill
    )
    if (checkSkill !== undefined) {
      this.props.addNotification(
        'Opération échouée',
        'Compétence déjà ajoutée',
        'danger'
      )
      this.setState({
        selectedSkill: null,
        selectedExperience: null,
      })
      return
    }

    const notificationMessage = 'Compétence ajoutée'
    this.props.postUserSkills(
      {
        user: { id: this.props.editedUser.id },
        skill: {
          id: idSkill,
          name: getElemByIdInArray(this.props.skills, idSkill).name,
        },
        exp: {
          id: idExp,
          name: getElemByIdInArray(this.props.experiences, idExp).name,
        },
      },
      (id, response, message) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  render() {
    return (
      <>
        <IconButton
          aria-label="éditer cet utilisateur"
          disabled={this.state.disabled || this.state.isProcessing}
          onClick={() => {
            this.toggle()
          }}
          size="small"
        >
          {this.state.isProcessing ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            <Icon>edit</Icon>
          )}
        </IconButton>
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          centered
          onExit={() => this.closeAction()}
          onClose={this.toggle}
        >
          <DialogTitle>Edition du profil</DialogTitle>
          {/*{this.props.isFetching || this.props.editedUser === undefined ?*/}
          {this.props.isFetching ? (
            <DialogContent>
              <div className="d-flex justify-content-center">
                <CircularProgress color={'inherit'} />
              </div>
            </DialogContent>
          ) : (
            <>
              <DialogContent>
                <Container>
                  <Grid
                    container
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid item xs={2}>
                      <UserAvatar
                        user={this.props.editedUser}
                        disableAvatarLoading
                      />
                    </Grid>
                    <Grid xs={8} aria-label="name">
                      {this.props.editedUser.firstname +
                        ' ' +
                        this.props.editedUser.lastname}
                    </Grid>
                    <Grid xs={2}>
                      {this.props.currentUser.id !==
                        this.props.editedUser.id && (
                        <>
                          <IconButton
                            aria-label="supprimer cet utilisateur"
                            size="small"
                            onClick={this.toggleDeleteUser}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                          <Dialog
                            open={this.state.deleteModalUser}
                            toggle={this.toggleDeleteUser}
                            onExit={() => this.closeAction()}
                            centered
                          >
                            <DialogTitle>
                              Confirmation de suppression
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Êtes-vous sûr(e) de vouloir supprimer
                                l'utilisateur "
                                {this.props.editedUser.firstname +
                                  ' ' +
                                  this.props.editedUser.lastname}
                                " ?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                color="secondary"
                                onClick={this.toggleDeleteUser}
                              >
                                Fermer
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.closeAction = () => {
                                    const notificationMessage =
                                      'Utilisateur désactivé'
                                    this.props.deleteUser(
                                      this.props.editedUser.id,
                                      (id, response, message) =>
                                        this.props.successNotification(
                                          id,
                                          response,
                                          notificationMessage
                                        ),
                                      (id, error) =>
                                        this.props.failureNotificationApi(
                                          id,
                                          error
                                        )
                                    )
                                  }
                                  this.toggleDeleteUser()
                                }}
                              >
                                Supprimer
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: '1rem' }}
                      aria-label="role"
                    >
                      <InputLabel style={{ paddingTop: '1rem' }}>
                        Rôle dans AttiProd :
                      </InputLabel>
                      <Select
                        name="roleSelect"
                        id="roleSelect"
                        fullWidth
                        value={this.state.selectedRole}
                        onChange={this.handleSelectedRole}
                      >
                        {this.props.primaryRoles.map((role) => (
                          <MenuItem value={role.id} key={role.id}>
                            {role.codename}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Card>
                          <CardContent>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Compétence</TableCell>
                                  <TableCell>Expérience</TableCell>
                                  <TableCell>
                                    <IconButton
                                      aria-label="ajouter une compétence"
                                      size="small"
                                      onClick={this.toggleNewSkill}
                                    >
                                      <Icon>add</Icon>
                                    </IconButton>
                                    <Dialog
                                      open={this.state.newSkillModal}
                                      toggle={this.toggleNewSkill}
                                      onExit={() => this.closeAction()}
                                      fullWidth
                                    >
                                      <DialogTitle>
                                        Ajouter une compétence
                                      </DialogTitle>
                                      <DialogContent>
                                        <Container>
                                          <Grid container spacing={2}>
                                            <Grid xs={6}>
                                              <InputLabel>
                                                Compétence
                                              </InputLabel>
                                              <Select
                                                name="skillSelect"
                                                id="skillSelect"
                                                fullWidth
                                                value={this.state.selectedSkill}
                                                onChange={
                                                  this.handleSelectedSkill
                                                }
                                              >
                                                {this.props.skills.map(
                                                  (skill) => (
                                                    <MenuItem
                                                      value={skill.id}
                                                      key={skill.id}
                                                    >
                                                      {skill.name}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </Grid>
                                            <Grid xs={6}>
                                              <InputLabel for="experienceSelect">
                                                Expérience
                                              </InputLabel>
                                              <Select
                                                name="experienceSelect"
                                                id="experienceSelect"
                                                fullWidth
                                                value={
                                                  this.state.selectedExperience
                                                }
                                                onChange={
                                                  this.handleSelectedExperience
                                                }
                                              >
                                                {this.props.experiences.map(
                                                  (experience) => (
                                                    <MenuItem
                                                      value={experience.id}
                                                      key={experience.id}
                                                    >
                                                      {experience.name}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </Grid>
                                          </Grid>
                                        </Container>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          color="secondary"
                                          onClick={this.toggleNewSkill}
                                        >
                                          Fermer
                                        </Button>
                                        <Button
                                          color="primary"
                                          disabled={
                                            !(
                                              this.state.selectedSkill &&
                                              this.state.selectedExperience
                                            )
                                          }
                                          onClick={() => {
                                            this.addData()
                                            this.toggleNewSkill()
                                          }}
                                        >
                                          Ajouter
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.props.skillExps.length ? (
                                  this.props.skillExps
                                    .filter(
                                      (skill) =>
                                        skill.user.id ===
                                        this.props.editedUser.id
                                    )
                                    .map((skillExp) => (
                                      <TableRow key={skillExp.id}>
                                        <TableCell>
                                          {skillExp.skill.name}
                                        </TableCell>
                                        <TableCell>
                                          {skillExp.exp.name}
                                        </TableCell>
                                        <TableCell>
                                          <DeleteSkill skillExp={skillExp} />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      className="text-center pt-4 pr-4 pb-0 pl-4"
                                      colSpan={3}
                                    >
                                      Aucune compétence à afficher
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Container>
              </DialogContent>
              <DialogActions className="justify-content-center">
                <Button color="secondary" onClick={this.toggle}>
                  Fermer
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    this.closeAction = () => this.saveActions()
                    let idPR = this.state.selectedRole
                    const notificationMessage = 'Role modifié'
                    let body = {
                      role: idPR,
                    }
                    this.props.updateUserRole(
                      this.props.editedUser.id,
                      body,
                      (id, response, message) =>
                        this.props.successNotification(
                          id,
                          response,
                          notificationMessage
                        ),
                      (id, error) =>
                        this.props.failureNotificationApi(id, error)
                    )
                    this.toggle()
                  }}
                >
                  Enregistrer
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(),
  primaryRoles: getItems(state, itemTypes.primaryRoles),
  experiences: getItems(state, itemTypes.experiences),
  skills: getItems(state, itemTypes.skills),
  skillExps: getItems(state, itemTypes.skillsExps),
  isFetching: isFetching(state),
  requests: retrieveRequests(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchUserRole: (role) =>
    dispatch(fetchEntity(itemTypes.userSecondaryRoles, role)),
  updateUserRole: (id, body, successCallback, failureCallback) =>
    dispatch(updateUser(id, body, successCallback, failureCallback)),
  deleteUser: (id, successCallback, failureCallback) =>
    dispatch(
      deleteEntity(itemTypes.users, id, successCallback, failureCallback)
    ),
  deleteUserSkills: (id, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.skillsExps,
        id,
        successCallback,
        failureCallback
      )
    ),
  postUserSkills: (body, successCallback, failureCallback) =>
    dispatch(
      postFormEntity(
        itemTypes.skillsExps,
        body,
        successCallback,
        failureCallback
      )
    ),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  sendRequest: (request) => dispatch(applyRequest(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserDialog)
