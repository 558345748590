import React from 'react'
import { PageSpinner } from '../../components'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { getConsums, getProject, getTasks } from '../../reducers'
import { fetchProjectById } from '../../actions/requests/projectsActions'
import { checkUserPrivileges, getUser } from '../../helpers'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import { Tooltip, Typography } from '@material-ui/core'
import Header from '../../components/Header'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { fetchConsumsByProjectIdAndDates } from '../../actions/requests/consumsActions'
import { fetchTasksByProjectId } from '../../actions/requests/tasksActions'
import { ProjectConsumsTable, WeekPickerWithArrows } from './components'
import moment from 'moment'
import Container from '@material-ui/core/Container'

class ProjectConsums extends React.Component {
  constructor(props) {
    super(props)

    if (
      this.props.match.params.id === null ||
      this.props.match.params.id === undefined
    ) {
      this.props.redirectToDashboardPage()
    }

    const today = new Date()

    this.state = {
      currentUser: getUser(),
      isLoading: true,
      selectedDate: today,
      startWeek: moment(today).startOf('week').toDate(),
      tableIsLoading: true,
    }
  }

  componentDidMount() {
    const startWeek = moment(this.state.startWeek).format('YYYY-MM-DD')
    const endWeek = moment(this.state.startWeek)
      .add(6, 'day')
      .format('YYYY-MM-DD')

    const requests = [
      this.props.fetchConsumsByProjectIdAndDates(this.props.match.params.id, [
        { key: 'startDate', value: startWeek },
        { key: 'endDate', value: endWeek },
      ]),
      this.props.fetchProject(this.props.match.params.id),
      this.props.fetchTasksByProjectId(this.props.match.params.id),
    ]

    Promise.all(requests).then(() => {
      if (!checkUserPrivileges(this.state.currentUser, this.props.project))
        this.props.redirectToDashboardPage()

      this.setState({ isLoading: false, tableIsLoading: false })
    })
  }

  checkUserToTask() {
    return this.props.tasks.find(
      (task) =>
        task.board.project.id === this.props.project.id && task.user === null
    )
  }

  handleWeekChange = (date) => {
    const startWeek = moment(date).startOf('week').toDate()
    const endWeek = moment(startWeek).add(6, 'day').toDate()

    this.setState({
      selectedDate: date,
      startWeek: startWeek,
      tableIsLoading: true,
    })

    const strStartWeek = moment(startWeek).format('YYYY-MM-DD')
    const strEndWeek = moment(endWeek).format('YYYY-MM-DD')

    Promise.all([
      this.props.fetchConsumsByProjectIdAndDates(this.props.match.params.id, [
        { key: 'startDate', value: strStartWeek },
        { key: 'endDate', value: strEndWeek },
      ]),
    ]).then(() => {
      this.setState({ tableIsLoading: false })
    })
  }

  render() {
    if (this.state.isLoading) return <PageSpinner />

    if (!this.props.project) {
      this.props.redirectToDashboardPage()
      return null
    }

    const { consums, project } = this.props
    const users = project.users.map((u) => u.user)

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Saisies du projet - Attiprod </title>
        </Helmet>

        <Header
          title={project.project_name}
          clientName={project.client_name}
          endIcon={
            <div>
              <Tooltip title="Accéder au boards">
                <IconButton
                  className="m-0"
                  aria-label="accéder aux boards"
                  onClick={() => {
                    this.props.redirectToProjectTasksPage(
                      this.props.match.params.id
                    )
                  }}
                >
                  <Icon>assignment</Icon>
                </IconButton>
              </Tooltip>

              {checkUserPrivileges(this.state.currentUser, project) && (
                <>
                  {
                    <Tooltip title="Accéder aux statistiques">
                      <IconButton
                        aria-label="accéder aux statistiques"
                        style={{ color: 'rgb(148,139,150,1)' }}
                        onClick={() => {
                          this.props.redirectToStatisticsPage(
                            this.props.match.params.id
                          )
                        }}
                      >
                        <Icon>pie_chart</Icon>
                      </IconButton>
                    </Tooltip>
                  }
                </>
              )}
            </div>
          }
          user={this.state.currentUser}
        />

        <Container className={'p-3'}>
          <Typography variant={'h5'} className={'pb-2'}>
            Récapitulatif des saisies du projet
          </Typography>

          <WeekPickerWithArrows
            value={this.state.selectedDate}
            onChange={this.handleWeekChange}
            style={{ minWidth: '120px' }}
          />

          <ProjectConsumsTable
            consums={consums}
            isLoading={this.state.tableIsLoading}
            startWeek={this.state.startWeek}
            users={users}
          />
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  consums: getConsums(state).filter(
    (consum) => consum.task.board.project.id === ownProps.match.params.id
  ),
  project: getProject(state, ownProps.match.params.id),
  tasks: getTasks(state).filter(
    (task) => task.board.project.id === ownProps.match.params.id
  ),
})

const mapDispatchToProps = (dispatch) => ({
  // FETCH
  fetchConsumsByProjectIdAndDates: (id, dates) =>
    dispatch(fetchConsumsByProjectIdAndDates(id, dates)),
  fetchProject: (id) => dispatch(fetchProjectById(id)),
  fetchTasksByProjectId: (id) => dispatch(fetchTasksByProjectId(id)),
  // REDIRECT
  redirectToEditProjectPage: (id) =>
    dispatch(push(routes.editProject + '/' + id)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
  redirectToStatisticsPage: (id) =>
    dispatch(push(routes.statistics + '/' + id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectConsums)
