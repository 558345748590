import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getSecondaryRoles } from '../../../../reducers'
import { UserAvatar, UserThumbnail } from '../../../../components'
import {
  getElemByCustomFieldInArray,
  getElemByIdInArray,
} from '../../../../helpers'
import { userThumbnailTypes } from '../../../../constants/global'
import {
  deleteFormEntity,
  updateFormEntity,
} from '../../../../actions/globalActions'
import { itemTypes } from '../../../../actions/itemTypes'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import FormLabel from '@material-ui/core/FormLabel'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'

/**
 * Props :
 *    - disabled
 *    - user_sr, the edited secondary role
 * Store :
 *    - SecondaryRoles
 */
class UserEditDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModal: false,
      closeAll: false,
      delete: false,
      update: false,
      user: this.props.user_sr,
      previousSR: null,
      isAffected: false,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
    this.toggleClose = this.toggleClose.bind(this)
    this.handleChangeSecondaryRole = this.handleChangeSecondaryRole.bind(this)
  }

  handleChangeSecondaryRole(event) {
    this.setState({
      previousUserSR: this.state.user,
      user: {
        id: this.state.user.id,
        user: this.state.user.user,
        secondaryRole: getElemByIdInArray(
          this.props.secondaryRoles,
          event.target.value
        ),
      },
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  toggleClose() {
    this.setState({
      modal: !this.state.modal,
    })
    if (this.state.previousUserSR) {
      this.setState({
        user: this.state.previousUserSR,
        previousUserSR: null,
      })
    }
  }

  toggleDelete() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: false,
    })
  }

  toggleAll() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: true,
    })
  }
  checkIfUserAffected() {
    if (this.props.usersTasks) {
      for (let i = 0; i < this.props.usersTasks.length - 1; i++) {
        if (this.props.usersTasks[i].user) {
          if (this.state.user.user.id === this.props.usersTasks[i].user.id) {
            this.props.addNotification(
              'Opération échouée',
              'Cet utilisateur est assigné à une tâche',
              'danger'
            )
            this.setState({
              isAffected: true,
            })
            return
          }
        }
      }
    }
  }

  onClosing() {
    if (this.state.delete) {
      if (this.state.isAffected) {
        return
      }
      const notificationMessage = 'Utilisateur retiré du projet'
      this.props.deleteUser(
        this.props.user_sr.id,
        this.props.project.id,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
    }

    if (this.state.update) {
      const notificationMessage = 'Utilisateur modifié'
      this.props.updateUser(
        this.props.user_sr.id,
        this.props.project.id,
        {
          secondaryRole: getElemByCustomFieldInArray(
            this.props.secondaryRoles,
            'name',
            document.getElementById('secondaryRoleSelect').innerHTML
          ),
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
    }

    this.setState({
      update: false,
    })
  }

  render() {
    return (
      <>
        {!this.state.delete && (
          <UserThumbnail
            disabled={this.props.disabled}
            data={this.state.user}
            type={userThumbnailTypes.USER_SR}
            icon={<Icon>edit</Icon>}
            onClick={this.toggle}
          />
        )}
        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.onClosing()}
        >
          <DialogTitle toggle={this.toggle}>
            Edition d'un utilisateur
          </DialogTitle>
          <DialogContent>
            <Container>
              <Grid container>
                <Grid item xs={2} style={{ paddingTop: '5px' }}>
                  <UserAvatar user={this.props.user_sr.user} />
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      paddingTop: '10px',
                    }}
                  >
                    {this.props.user_sr.user.firstname +
                      ' ' +
                      this.props.user_sr.user.lastname}
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="supprimer cet utilisateur"
                    size="sm"
                    onClick={() => {
                      this.checkIfUserAffected()
                      this.toggleDelete()
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                  <Dialog
                    open={this.state.deleteModal && !this.state.isAffected}
                    toggle={this.toggleDelete}
                    onExit={this.state.closeAll ? this.toggle : undefined}
                    centered
                  >
                    <DialogTitle>Confirmation de suppression</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Êtes-vous sûr(e) de vouloir retirer l'utilisateur "
                        {this.props.user_sr.user.firstname +
                          ' ' +
                          this.props.user_sr.user.lastname}
                        " du projet ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button color="secondary" onClick={this.toggleDelete}>
                        Annuler
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            deleteModal: !this.state.deleteModal,
                            closeAll: true,
                            delete: true,
                          })
                        }}
                      >
                        Supprimer
                      </Button>{' '}
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
              <Grid column className="mt-3">
                <Grid item>
                  <FormLabel for="secondaryRoleSelect">
                    Rôle dans le projet :
                  </FormLabel>
                  <Select
                    name="secondaryRoleSelect"
                    id="secondaryRoleSelect"
                    value={this.state.user.secondaryRole.id}
                    onChange={this.handleChangeSecondaryRole}
                    style={{ width: '100%' }}
                  >
                    {this.props.secondaryRoles.map((role) => (
                      <MenuItem value={role.id} key={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
          <DialogActions className="justify-content-center">
            <Button color="secondary" onClick={this.toggleClose}>
              Fermer
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.setState({
                  modal: !this.state.modal,
                  update: true,
                  previousUserSR: null,
                })
                this.toggle()
              }}
            >
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  secondaryRoles: getSecondaryRoles(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, projectId, body, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.userSecondaryRoles,
        id,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  deleteUser: (id, projectId, successCallback, failureCallback) =>
    dispatch(
      deleteFormEntity(
        itemTypes.userSecondaryRoles,
        id,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEditDialog)
