import React from 'react'
import { connect } from 'react-redux'
import {
  fetchTasksByProjectId,
  updateTask,
} from '../../../../actions/requests/tasksActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import {
  InputLabel,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { checkUserPrivileges } from '../../../../helpers'
import { isForfaitProjectType } from '../../../../constants/global'
import { getTasks } from '../../../../reducers'
import ColorButton from '../../../../components/ColorButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import StyledCell from '../../../Admin/components/AdminDnD/styles/StyledCell'
import Link from '@material-ui/core/Link'
import DeleteReferenceDialog from '../DeleteReferenceDialog'
import { push } from 'connected-react-router'
import routes from '../../../../config/routes'
import Grid from '@material-ui/core/Grid'

class ReferenceSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isSavingLinks: false,
      isSavingReference: false,
      relatedTaskNames: [],
      selectedTasks: [],
      taskNames: [],
      reference: this.props.task.reference ? this.props.task.reference : '',
      previousReference: this.props.task.reference
        ? this.props.task.reference
        : '',
    }
  }

  componentDidMount() {
    const promises = [this.props.fetchTasksByProjectId(this.props.project.id)]

    Promise.all(promises).then(() => {
      this.setState({
        isLoading: false,
        relatedTaskNames: this.props.task.related_tasks.map((task) => ({
          value: task.id,
          label: task.id.slice(-5) + ' - ' + task.name,
        })),
        taskNames: this.props.tasks
          .filter((task) => task.id !== this.props.task.id)
          .filter(
            (task) =>
              this.props.task.related_tasks.find((t) => t.id === task.id) ===
              undefined
          )
          .map((task) => ({
            value: task.id,
            label: task.id.slice(-5) + ' - ' + task.name,
          })),
      })
    })
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleSelectedTaskChange = (event, values) => {
    this.setState({
      selectedTasks: values,
      taskNames: this.state.taskNames.filter(
        (task) =>
          this.state.relatedTaskNames.find((t) => t.value === task.value) ===
          undefined
      ),
    })
  }

  handleRelatedTasksChange = () => {
    this.handleRelatedTasksSave([
      ...this.state.relatedTaskNames,
      ...this.state.selectedTasks,
    ])
  }

  handleRelatedTasksDelete = (taskId) => {
    this.handleRelatedTasksSave(
      this.state.relatedTaskNames.filter((task) => task.value !== taskId),
      taskId
    )
  }

  handleRelatedTasksSave = (relatedTaskNames, deletedTaskId) => {
    if (this.state.selectedTasks.length === 0 && !deletedTaskId) {
      this.props.addNotification(
        'Opération échouée',
        'Veuillez choisir une tâche à ajouter',
        'danger'
      )
      return
    }
    this.toggleState('isSavingLinks')

    const notificationMessage = 'Filtres de la tâche modifiés'
    this.props
      .editTask(
        this.props.task.id,
        {
          related_tasks:
            relatedTaskNames && relatedTaskNames.map((task) => task.value),
        },
        (id, response) => {
          if (deletedTaskId) {
            this.setState((prevState) => ({
              relatedTaskNames: relatedTaskNames,
              taskNames: [
                ...prevState.taskNames,
                prevState.relatedTaskNames.find(
                  (task) => task.value === deletedTaskId
                ),
              ],
            }))
          } else {
            this.setState((prevState) => ({
              relatedTaskNames: relatedTaskNames,
              selectedTasks: [],
              taskNames: prevState.taskNames.filter(
                (task) =>
                  relatedTaskNames.find((t) => t.value === task.value) ===
                  undefined
              ),
            }))
          }

          this.props.successNotification(id, response, notificationMessage)
        },
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.toggleState('isSavingLinks')
      })
  }

  handleReferenceSave = () => {
    if (
      this.state.previousReference === this.state.reference ||
      this.state.selectedTasks.length === 0
    )
      return

    this.toggleState('isSavingReference')

    const notificationMessage = 'Référence mise à jour'
    this.props
      .editTask(
        this.props.task.id,
        {
          reference: this.state.reference,
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.setState({ previousReference: this.state.reference })
        this.toggleState('isSavingReference')
      })
  }

  userCanEditField = () => {
    return (
      checkUserPrivileges(this.props.currentUser, this.props.project) ||
      !isForfaitProjectType(this.props.project.project_type)
    )
  }

  isLinksDisabled = () => {
    return this.state.isSavingLinks || !this.userCanEditField()
  }

  isReferenceDisabled = () => {
    return this.state.isSavingReference
  }

  render() {
    if (this.state.isLoading) return null

    return (
      <>
        <Grid container spacing={2}>
          <Grid container item xs={3} alignItems={'center'}>
            <InputLabel
              id="taskReference"
              style={{
                paddingRight: '1rem',
                marginBottom: '0',
                whiteSpace: 'nowrap',
              }}
            >
              Référence
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant={'outlined'}
              fullWidth
              disabled={this.isReferenceDisabled()}
              value={this.state.reference}
              onChange={(e) => this.setState({ reference: e.target.value })}
              onBlur={this.handleReferenceSave}
            />
          </Grid>
          <Grid container item xs={3} alignItems={'center'}>
            <InputLabel
              id="taskLink"
              style={{
                paddingRight: '1rem',
                marginBottom: '0',
                whiteSpace: 'nowrap',
              }}
            >
              Ajouter un lien
            </InputLabel>
          </Grid>
          <Grid item xs={9}>
            <div className={'d-flex align-items-center'}>
              <Autocomplete
                name={'relatedTasksName'}
                id={'relatedTaskName'}
                disableCloseOnSelect
                multiple
                style={{ width: '100%' }}
                options={this.state.taskNames}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Tâches" variant="outlined" />
                )}
                disabled={!this.userCanEditField()}
                value={this.state.selectedTasks}
                onChange={this.handleSelectedTaskChange}
              />

              <ColorButton
                aria-label="Valider le nom de la tâche"
                style={{
                  border: '1px solid',
                  marginLeft: '1rem',
                  height: '40px',
                }}
                disabled={this.isLinksDisabled()}
                onClick={this.handleRelatedTasksChange}
              >
                Valider
              </ColorButton>
            </div>
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <TableBody>
              {this.props.task.related_tasks.map((task) => (
                <TableRow key={task.id}>
                  <StyledCell>
                    <Link
                      component={'button'}
                      onClick={() => {
                        // Obligé, sinon page non mise à jour (cas isolé)
                        window.location.href = routes.editTask + '/' + task.id
                      }}
                    >
                      {task.id.slice(-5)} - {task.name}
                    </Link>
                  </StyledCell>

                  {this.isLinksDisabled() ? (
                    <StyledCell />
                  ) : (
                    <StyledCell
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <DeleteReferenceDialog
                        task={task}
                        onDelete={() => this.handleRelatedTasksDelete(task.id)}
                      />
                    </StyledCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  tasks: getTasks(state).filter(
    (task) => task.board.project.id === ownProps.task.board.project.id
  ),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Fetches
  fetchTasksByProjectId: (id) => dispatch(fetchTasksByProjectId(id)),
  // Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  // Redirects
  redirectToEditTaskPage: (id) => dispatch(push(routes.editTask + '/' + id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceSection)
