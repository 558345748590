import React from 'react'
import { connect } from 'react-redux'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getSecondaryRoles } from '../../../../reducers'
import { UserAvatar, UserThumbnail } from '../../../../components'
import { userThumbnailTypes } from '../../../../constants/global'
import {
  deleteUserSR,
  updateUserSR,
} from '../../../../actions/requests/userSRActions'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Container from '@material-ui/core/Container'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

/**
 * Props :
 *    - user_sr
 *    - onRemove(user_sr), callback
 * Store :
 *    - Secondary Roles
 */
class UserPreview extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      deleteModal: false,
      closeAll: false,
      selectedRole: this.props.user_sr.secondaryRole.id,
    }

    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleDelete() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: false,
    })
  }

  toggleAll() {
    this.setState({
      deleteModal: !this.state.deleteModal,
      closeAll: true,
    })
  }

  handleRoleChange = (event) => {
    this.setState({
      selectedRole: event.target.value,
    })
  }

  render() {
    return (
      <>
        <UserThumbnail
          color={this.props.color}
          data={this.props.user_sr}
          icon={<Icon>edit</Icon>}
          type={userThumbnailTypes.USER_SR}
          onClick={this.toggle}
        />

        <Dialog
          open={this.state.modal}
          toggle={this.toggle}
          onExit={() => this.closeAction()}
        >
          <DialogTitle>Edition d'un utilisateur</DialogTitle>

          <DialogContent>
            <Container>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <UserAvatar user={this.props.user_sr.user} size="medium" />
                </Grid>

                <Grid item xs={7} style={{ whiteSpace: 'nowrap' }}>
                  {this.props.user_sr.user.firstname +
                    ' ' +
                    this.props.user_sr.user.lastname}
                </Grid>

                {this.props.isDeletable && (
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="supprimer cet utilisateur"
                      onClick={this.toggleDelete}
                    >
                      <Icon>delete</Icon>
                    </IconButton>

                    <Dialog
                      open={this.state.deleteModal}
                      toggle={this.toggleDelete}
                      onExit={this.state.closeAll ? this.toggle : undefined}
                      centered
                    >
                      <DialogTitle>Confirmation de suppression</DialogTitle>

                      <DialogContent>
                        <DialogContentText>
                          Êtes-vous sûr de vouloir retirer cet utilisateur du projet ?
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button color="secondary" onClick={this.toggleDelete}>
                          Fermer
                        </Button>

                        <Button
                          color="primary"
                          onClick={() => {
                            this.closeAction = () => {
                              const notificationMessage = 'Utilisateur retiré du projet';
                              this.props.deleteUserSR(
                                this.props.user_sr.id,
                                this.props.project.id,
                                (id, response) => {
                                  this.props.successNotification(
                                    id,
                                    response,
                                    notificationMessage
                                  );
                                  this.props.onUpdate();
                                },
                                (id, error) => this.props.failureNotificationApi(id, error)
                              );
                            };
                            this.toggleAll();
                          }}
                        >
                          Supprimer
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                )}

              </Grid>

              <Grid container style={{ padding: '1rem' }}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="secondaryRoleSelect">
                    Rôle dans le projet :
                  </InputLabel>
                  <Select
                    id="secondaryRoleSelect"
                    name="secondaryRoleSelect"
                    fullWidth
                    defaultValue={this.props.user_sr.secondaryRole.id}
                    onChange={this.handleRoleChange}
                  >
                    {this.props.secondaryRoles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>

          <DialogActions className="justify-content-center">
            <Button color="secondary" onClick={this.toggle}>
              Fermer
            </Button>

            <Button
              color="primary"
              onClick={() => {
                const notificationMessage = 'Rôle modifié'
                this.props.updateUserSR(
                  this.props.user_sr.id,
                  this.props.project.id,
                  {
                    secondaryRole: this.state.selectedRole,
                  },
                  (id, response) => {
                    this.props.successNotification(
                      id,
                      response,
                      notificationMessage
                    )
                    this.props.onUpdate()
                  },
                  (id, error) => this.props.failureNotificationApi(id, error)
                )
                this.toggle()
              }}
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  secondaryRoles: getSecondaryRoles(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateUserSR: (id, projectId, body, successCallback, failureCallback) =>
    dispatch(
      updateUserSR(id, body, successCallback, failureCallback, projectId)
    ),
  deleteUserSR: (id, projectId, successCallback, failureCallback) =>
    dispatch(deleteUserSR(id, successCallback, failureCallback, projectId)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPreview)
