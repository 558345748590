import { connect } from 'react-redux'
import { UsersList } from '../../components'
import { getAllUsers } from '../../reducers'

const mapStateToProps = (state, ownProps) => ({
  omitted: ownProps.omitted,
  users: ownProps.users ? ownProps.users : getAllUsers(state),
  buttonAction: ownProps.buttonAction,
  object: 'Utilisateur',
  // feminine définit si l'accord doit se faire
  feminine: false,
})

export default connect(mapStateToProps, null)(UsersList)
