import React from 'react'
import { createStyles, Popover, TableCell, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(() => ({
  root: {
    border: '1px solid lightgray',
    textAlign: 'left',
    backgroundColor: 'white',
  },
}))(TableCell)

const styles = createStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

class CellWithPopover extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
  }

  handlePopoverOpen = (ref) => {
    this.setState({ anchorEl: ref.current })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, consums } = this.props
    const open = Boolean(this.state.anchorEl)
    const ref = React.createRef()

    return (
      <>
        <StyledTableCell
          ref={ref}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup={'true'}
          onMouseEnter={() =>
            consums.length > 0 ? this.handlePopoverOpen(ref) : null
          }
          onMouseLeave={this.handlePopoverClose}
        >
          {consums.reduce((acc, value) => acc + value.amount, 0)}
        </StyledTableCell>

        <Popover
          id={'mouse-over-popover'}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          {consums.map((consum) => (
            <Typography
              key={consum.id}
              component={'div'}
              className={'d-flex justify-content-between'}
            >
              <div style={{ paddingRight: '8px' }}>
                <span style={{ textDecoration: 'underline' }}>
                  {consum.task.name}
                </span>{' '}
                :
              </div>
              <span style={{ fontWeight: 'bold' }}> {consum.amount}</span>
            </Typography>
          ))}
        </Popover>
      </>
    )
  }
}

export default withStyles(styles)(CellWithPopover)
