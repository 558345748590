import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const getButtonStyle = {
  textTransform: 'none',
  height: '2.4rem',
}

/**
 * Props :
 *    - finished, true if this modal is for reopening this project, false to close it
 */
class ProjectInfosCloseDialog extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      dialog: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  render() {
    return (
      <>
        {this.props.finished ? (
          <>
            <Button
              color="secondary"
              style={getButtonStyle}
              variant="outlined"
              onClick={this.handleOpenDialog}
            >
              Rouvrir le projet
            </Button>
            <Dialog open={this.state.dialog} onClose={this.handleCloseDialog}>
              <DialogTitle>Réouverture du projet</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Confirmez-vous la réouverture du projet "
                  {this.props.project.project_name}" ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  aria-label="Fermer"
                  color="secondary"
                  onClick={this.handleCloseDialog}
                >
                  Fermer
                </Button>
                <Button
                  aria-label="Confirmer"
                  color="primary"
                  onClick={() => {
                    this.props.setFinished(false)
                    this.handleCloseDialog()
                  }}
                >
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <Button
              color="secondary"
              style={getButtonStyle}
              variant="outlined"
              onClick={this.handleOpenDialog}
            >
              Clôturer le projet
            </Button>
            <Dialog open={this.state.dialog} onClose={this.handleCloseDialog}>
              <DialogTitle>Clôture du projet</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Confirmez-vous la clôture du projet "
                  {this.props.project.project_name}" ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  aria-label="Fermer"
                  color="secondary"
                  onClick={this.handleCloseDialog}
                >
                  Fermer
                </Button>
                <Button
                  aria-label="Confirmer"
                  color="primary"
                  onClick={() => {
                    this.props.setFinished(true)
                    this.handleCloseDialog()
                  }}
                >
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    )
  }
}
export default ProjectInfosCloseDialog
