import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { checkUserPrivileges } from '../../../../helpers'
import StyledCard from '../../styles/StyledCard'
import Board from '../Board'
import AddBoard from '../AddBoard'
import StyledDiv3 from '../../styles/StyledDiv3'
import { withStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'

const styles = (theme) => ({
  list: {
    backgroundColor: darken(
      theme.palette.background.default,
      theme.palette.type === 'light' ? 0.02 : 0.4
    ),
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
})

const getListStyle = () => ({
  display: 'flex',
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 8,
  paddingRight: 8,
})

class TaskBoards extends React.Component {
  render() {
    const {
      boards,
      classes,
      currentUser,
      currentSprint,
      currentStatus,
      onDragEnd,
      project,
      selectedFilter,
    } = this.props

    return (
      <StyledDiv3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="boards" type="column" direction="horizontal">
            {(provided, _) => (
              <div
                ref={provided.innerRef}
                style={getListStyle()}
                {...provided.droppableProps}
              >
                {boards.map((item, index) =>
                  checkUserPrivileges(currentUser, project) ? (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            maxHeight: '100%',
                          }}
                        >
                          <StyledCard
                            className={classes.list}
                            square
                            elevation={snapshot.isDragging ? 3 : 1}
                            style={{
                              width: 300,
                              minHeight: '110px',
                              maxHeight: '100%',
                              overflow: 'auto',
                            }}
                          >
                            <Board
                              provided={provided}
                              selectedFilters={selectedFilter}
                              searchValue={this.props.searchValue}
                              board={item}
                              project={project}
                              firstBoard={boards[0]}
                              boards={boards}
                              key={item.id}
                              filters={this.props.filters}
                              showChargeClient={this.props.showChargeClient}
                            />
                          </StyledCard>
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <StyledCard
                      className={classes.list}
                      square
                      style={{
                        width: 300,
                        maxHeight: '100%',
                        minHeight: '110px',
                        overflow: 'auto',
                      }}
                    >
                      <Board
                        selectedFilters={selectedFilter}
                        searchValue={this.props.searchValue}
                        board={item}
                        project={project}
                        firstBoard={boards[0]}
                        filters={this.props.filters}
                        boards={boards}
                        key={item.id}
                      />
                    </StyledCard>
                  )
                )}
                {provided.placeholder}
                {checkUserPrivileges(currentUser, project) && (
                  <AddBoard
                    project={project}
                    boards={boards}
                    currentStatus={currentStatus}
                    currentSprint={currentSprint}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StyledDiv3>
    )
  }
}

export default withStyles(styles)(TaskBoards)
