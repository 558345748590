import React, { Component } from 'react'
import { EditListItemDialog } from '..'
import AddItemTextField from '../AddItemTextField'
import DeleteListItemDialog from '../DeleteListItemDialog'
import CustomPagination from '../../../../components/CustomPagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { withStyles } from '@material-ui/core/styles'

import {
  ADMIN_LIST_PAGINATION_NB_ITEMS,
  ADMIN_LIST_PAGINATION_NB_MAX_PAGES_LINKS,
} from '../../../../constants/global'

const StyledCell = withStyles({
  root: {
    padding: '7px',
  },
  head: {
    fontWeight: '600',
  },
})(TableCell)

/**
 * Props :
 *    - disabled, optionnal
 *
 * Store :
 *    - BillStatuses
 */
class AdminList extends Component {
  itemsPerPage = ADMIN_LIST_PAGINATION_NB_ITEMS
  maxPageLinks = ADMIN_LIST_PAGINATION_NB_MAX_PAGES_LINKS

  constructor(props) {
    super(props)
    this.state = { page: 0 }
    this.setPage = this.setPage.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
  }

  setPage(value) {
    this.setState({ page: value })
  }

  handleChangePage(event, value) {
    this.setPage(value)
  }

  render() {
    return (
      <>
        <TableContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <AddItemTextField {...this.props} />
              </TableRow>
              <TableRow>
                {Object.keys(this.props.properties).map((p) => (
                  <StyledCell key={p}>{this.props.properties[p]}</StyledCell>
                ))}
                <StyledCell />
                <StyledCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data
                .slice(
                  this.itemsPerPage * this.state.page,
                  Math.min(
                    this.props.data.length,
                    this.itemsPerPage * (this.state.page + 1)
                  )
                )
                .map((item) => {
                  return (
                    <TableRow key={item.id}>
                      {Object.keys(this.props.properties).map((p) => (
                        <StyledCell key={p} style={{ verticalAlign: 'middle' }}>
                          {item[p]}
                        </StyledCell>
                      ))}
                      <StyledCell />
                      <StyledCell>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <EditListItemDialog
                            disabled={this.props.disabled}
                            properties={this.props.properties}
                            data={this.props.data}
                            item={item}
                            props={this.props}
                          />
                          <DeleteListItemDialog
                            disabled={this.props.disabled}
                            item={item}
                            props={this.props}
                            setPage={this.setPage}
                          />
                        </div>
                      </StyledCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          <div style={{ overflow: 'hidden' }}>
            <CustomPagination
              page={this.state.page}
              itemsPerPage={this.itemsPerPage}
              maxPageLinks={this.maxPageLinks}
              items={this.props.data}
              setPage={this.setPage}
            />
          </div>
        </TableContainer>
      </>
    )
  }
}

export default AdminList
