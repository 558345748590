import React from 'react'
import StyledActiveLi from './styles/StyledActiveLi'
import StyledLi from './styles/StyledLi'
import StyledUl from './styles/StyledUl'
import { isAgileProjectType } from '../../constants/global'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Icon, Menu, MenuItem } from '@material-ui/core'

const StyledGrid = withStyles(() => ({
  root: {
    boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.35)',
    borderBottom: '1px solid #A9A9A9',
    marginBottom: '1rem',
  },
}))(Grid)

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      isFirstRender: true,
      items: [],
    }
  }

  componentDidMount() {
    const sortedItems = [...this.props.items].sort(
      (a, b) => a.number - b.number
    )
    this.setState({
      items: isAgileProjectType(this.props.project.project_type)
        ? sortedItems
        : this.props.items,
      isFirstRender: false,
    })
  }

  getBreadcrumbs = (isDefault, index) => {
    const items = isDefault ? this.state.items : this.state.items.slice(index)

    return (
      <StyledUl
        aria-label="Fil d'Ariane"
        className="breadcrumb flat"
        isDefault={isDefault}
      >
        {items.map((item) =>
          this.props.currentItem && item.id === this.props.currentItem.id ? (
            <StyledActiveLi key={item.id}>
              {isAgileProjectType(this.props.project.project_type)
                ? `Sprint ${item.number}`
                : item.name}
            </StyledActiveLi>
          ) : (
            <StyledLi key={item.id} onClick={() => this.props.click(item)}>
              {isAgileProjectType(this.props.project.project_type)
                ? item.number
                : item.name}
            </StyledLi>
          )
        )}
      </StyledUl>
    )
  }

  handleOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ anchorEl: null })
  }

  handleSelectItem = (item) => {
    if (!this.props.currentItem || item.id !== this.props.currentItem.id)
      this.props.click(item)
    this.setState({ anchorEl: null })
  }

  render() {
    if (this.state.isFirstRender) return null

    if (!this.props.max || this.state.items.length < this.props.max) {
      return this.getBreadcrumbs(true)
    }

    const indexToSlice = this.state.items.length - this.props.max - 1
    const items = this.state.items.slice(0, indexToSlice)

    return (
      <StyledGrid container>
        <Grid
          item
          xs={1}
          className={'d-flex justify-content-center align-items-center'}
          style={{
            backgroundColor: '#e9ecef',
            borderRight: '1px solid #A9A9A9',
          }}
        >
          <IconButton
            aria-label="more"
            size="small"
            onClick={this.handleOpenMenu}
          >
            <Icon>more_horiz</Icon>
          </IconButton>

          <Menu
            id="left-items-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleCloseMenu}
          >
            {items.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => this.handleSelectItem(item)}
              >
                Sprint {item.number}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={11}>
          {this.getBreadcrumbs(false, indexToSlice)}
        </Grid>
      </StyledGrid>
    )
  }
}

export default Breadcrumbs
