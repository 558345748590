import * as React from 'react'
import BadgeCounter from '../../../../components/BadgeCounter'
import DeleteTask from '../DeleteTask'
import routes from '../../../../config/routes'
import { connect } from 'react-redux'
import UserAvatar from '../../../../components/UserAvatar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import StyledCard from './styles/StyledCard'
import StyledDiv from './styles/StyledDiv'
import StyledDiv2 from './styles/StyledDiv2'
import { checkUserPrivileges, getUser } from '../../../../helpers'
import {
  isForfaitProjectType,
  isAgileProjectType,
} from '../../../../constants/global'
import TaskFilterList from './components/TaskFilterList'
import CopyTask from '../CopyTask'
import { Link } from 'react-router-dom'

/**
 * Props :
 *    - task
 *    - board, the board of the task
 *    - showChargeClient
 */

const styles = (theme) => ({
  card: {
    transitionProperty: 'box-shadow',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
})

class BoardTask extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showChargeClient: this.props.showChargeClient,
    }
  }

  render() {
    const { classes } = this.props
    const task = this.props.task,
      elapsedReducer = function (accumulator, consum) {
        return accumulator + consum.amount
      },
      elapsedTime =
        this.props.consums && this.props.consums.length
          ? this.props.consums.reduce(elapsedReducer, 0)
          : 0,
      remaining = task.remaining_workload

    return (
      <StyledCard className={clsx(classes.card)}>
        <div
          style={{
            padding: 16,
            paddingBottom: 0,
          }}
        >
          <div className={'d-flex'}>
            <Typography
              className="font-600 mb-12 task-name"
              style={{
                wordWrap: 'break-word',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
              title={task.name}
            >
              <Link
                to={routes.editTask + '/' + task.id}
                rel="noopener noreferrer"
                target={(e) => {
                  if (e.ctrlKey || e.metaKey || e.button === 1) {
                    return '_blank'
                  } else {
                    return '_self'
                  }
                }}
                style={{ color: 'inherit' }}
              >
                {task.name}
              </Link>
            </Typography>
          </div>
          <div
            className="flex flex-wrap mb-12 -mx-4"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {task.user && !task.user.enabled ? (
              <UserAvatar user={task.user} color={'grey'} />
            ) : (
              <UserAvatar user={task.user} />
            )}
          </div>
        </div>
        <TaskFilterList
          filters={task.projectFilters}
          isTmaProject={
            !isAgileProjectType(this.props.project.project_type) &&
            !isForfaitProjectType(this.props.project.project_type)
          }
          onSelecting={() => {}}
        />
        <StyledDiv>
          <StyledDiv2>
            <BadgeCounter title={'Prévu'} number={task.visible_workload} />
            <BadgeCounter title={'Conso'} number={elapsedTime} />
            <BadgeCounter title={'RAF'} number={remaining} />
            <BadgeCounter
              title={'Écart'}
              number={task.visible_workload - elapsedTime - remaining}
              colored
            />
          </StyledDiv2>
          {(checkUserPrivileges(this.props.currentUser, this.props.project) ||
            !isForfaitProjectType(this.props.project.project_type)) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CopyTask task={task} board={this.props.board} />
              <DeleteTask task={task} />
            </div>
          )}
        </StyledDiv>
      </StyledCard>
    )
  }
}

const mapStateToProps = () => ({
  currentUser: getUser(),
})

export default connect(
  mapStateToProps,
)(withStyles(styles)(BoardTask))
