import React from 'react'
import { Avatar, Icon, IconButton, Tooltip } from '@material-ui/core'
import { UserAvatar } from '..'
import { userThumbnailTypes } from '../../constants/global'

class UserThumbnail extends React.Component {
  render() {
    const { color, data, disabled, icon, onClick, type } = this.props

    switch (type) {
      case userThumbnailTypes.USER:
        return (
          <Tooltip title={`${data.firstname} ${data.lastname}`}>
            {onClick ? (
              <IconButton
                aria-label="éditer le profil utilisateur"
                className="d-inline-block p-0 m-1 position-relative userEditButton"
                disabled={disabled}
                onClick={onClick}
              >
                <UserAvatar color={color} user={data} />
                <div
                  aria-label="Editer le profil utilisateur"
                  className="userEditIcon"
                >
                  {icon}
                </div>
              </IconButton>
            ) : (
              <UserAvatar color={color} user={data} />
            )}
          </Tooltip>
        )
      case userThumbnailTypes.USER_SR:
        return (
          <Tooltip
            title={
              <>
                {data.user.firstname} {data.user.lastname}
                <br />
                {data.secondaryRole.name}
              </>
            }
          >
            {onClick ? (
              <IconButton
                aria-label="éditer le profil utilisateur"
                className="d-inline-block p-0 m-1 position-relative userEditButton"
                disabled={disabled}
                onClick={onClick}
              >
                <UserAvatar color={color} user={data.user} />
                <div className="userEditIcon">{icon}</div>
              </IconButton>
            ) : (
              <UserAvatar color={color} user={data.user} />
            )}
          </Tooltip>
        )
      case userThumbnailTypes.USER_NEEDED:
        return (
          <Tooltip
            title={
              <>
                {data.skill.name}
                <br />
                {data.exp.name}
              </>
            }
          >
            {onClick ? (
              <IconButton
                aria-label="supprimer le profil recherché"
                className="d-inline-block p-0 m-1 position-relative userEditButton"
                disabled={disabled}
                onClick={onClick}
              >
                <Avatar style={{ backgroundColor: 'rgb(64,50,125)' }}>
                  <Icon>person</Icon>
                </Avatar>
                <div
                  aria-label="Supprimer le profil recherché"
                  className="userEditIcon"
                >
                  {icon}
                </div>
              </IconButton>
            ) : (
              <Avatar>
                <Icon>person</Icon>
              </Avatar>
            )}
          </Tooltip>
        )
      default:
        return null
    }
  }
}

export default UserThumbnail
