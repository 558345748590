import styled from 'styled-components'

const StyledDiv = styled.div`
  color: #f29400;
  display: flex;
  flex: 1
  flex:auto
  flex-direction: column;
  width: 100%
  position: relative
  // padding: 10px;
  // background-color: #fff;
  // border-radius: 3px;
  // -webkit-transition: all 0.3s ease;
  // transition: all 0.3s ease;
  // margin: 10px 5px;
  // border: 1px solid #f29400;
  // align-items: stretch;
`

export default StyledDiv
