import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  fetchEntityWithParams,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchConsums = () => {
  return fetchEntity(itemTypes.consums)
}

export const fetchConsumsByTaskId = (id) => {
  return fetchEntity(otherURLs.tasksConsums, id)
}

export const fetchConsumsByProjectIdAndDates = (id, dates) => {
  return fetchEntityWithParams(otherURLs.projectConsums, id, null, true, dates)
}

export const fetchConsumsByUserIdAndDates = (id, dates) => {
  return fetchEntityWithParams(otherURLs.usersConsums, id, null, true, dates)
}

export const postConsum = (body, successCallback, failureCallback) => {
  return postEntity(itemTypes.consums, body, successCallback, failureCallback)
}

export const updateConsum = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.consums,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteConsum = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.consums, id, successCallback, failureCallback)
}

export const clearErrorConsums = () => clearErrors(itemTypes.consums)
