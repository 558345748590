import React, { Component } from 'react'
import {
  Section,
  StyledAttiContainer,
  UsersListContainer,
  PageSpinner,
  LittleSpinner,
} from '../../components'
import {
  ProjectStatusesListContainer,
  SecondaryRolesListContainer,
  ExperiencesListContainer,
  SkillsListContainer,
  BillStatusesListContainer,
} from '../../containers'
import { getItems, isFetching } from '../../reducers'
import { connect } from 'react-redux'
import { fetchBillStatuses } from '../../actions/requests/billStatusesActions'
import { fetchSimpleUsers } from '../../actions/requests/usersActions'
import { fetchSecondaryRoles } from '../../actions/requests/secondaryRolesActions'
import { fetchSkills } from '../../actions/requests/skillsActions'
import { fetchExperiences } from '../../actions/requests/experiencesActions'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import { fetchPrimaryRoles } from '../../actions/requests/primaryRoleActions'
import { fetchUserSkills } from '../../actions/requests/userSkillsActions'
import { userListButtonAction } from '../../constants/global'
import Grid from '@material-ui/core/Grid'
import Header from '../../components/Header'
import { getUser } from '../../helpers'
import { Helmet } from 'react-helmet'
import { itemTypes } from '../../actions/itemTypes'

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFirstRender: true,
    }
  }

  componentDidMount() {
    const promises = [
      this.props.fetchSimpleUsers(),
      this.props.fetchBillStatuses(),
      this.props.fetchPrimaryRoles(),
      this.props.fetchSecondaryRoles(),
      this.props.fetchSkills(),
      this.props.fetchExperiences(),
      this.props.fetchProjectStatuses(),
      this.props.fetchUserSkills(),
    ]

    Promise.all(promises).then(() => {
      this.setState({ isFirstRender: false })
    })
  }

  render() {
    if (this.state.isFirstRender) return <PageSpinner />
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Admin - Attiprod </title>
        </Helmet>
        {!this.state.isFirstRender && this.props.isFetching && (
          <LittleSpinner />
        )}
        <Header title="Administration" user={this.props.currentUser} />
        <StyledAttiContainer>
          <Grid container spacing={4} style={{ maxWidth: 'none' }}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Utilisateurs'}>
                <UsersListContainer
                  users={this.props.users}
                  buttonAction={userListButtonAction.EDITION}
                  omitted={[]}
                />
              </Section>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Profils'} height="100%">
                <SecondaryRolesListContainer />
              </Section>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Compétences'} height="100%">
                <SkillsListContainer />
              </Section>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Expériences'} height="100%">
                <ExperiencesListContainer />
              </Section>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Statuts de projet'}>
                <ProjectStatusesListContainer />
              </Section>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Section title={'Statuts de facturation'}>
                <BillStatusesListContainer />
              </Section>
            </Grid>
          </Grid>
        </StyledAttiContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  users: getItems(state, itemTypes.usersSimple),
  isFetching: isFetching(state),
  currentUser: getUser(),
})

const mapDispatchToProps = (dispatch) => ({
  fetchBillStatuses: () => dispatch(fetchBillStatuses()),
  fetchSimpleUsers: () => dispatch(fetchSimpleUsers()),
  fetchPrimaryRoles: () => dispatch(fetchPrimaryRoles()),
  fetchSecondaryRoles: () => dispatch(fetchSecondaryRoles()),
  fetchSkills: () => dispatch(fetchSkills()),
  fetchUserSkills: () => dispatch(fetchUserSkills()),
  fetchExperiences: () => dispatch(fetchExperiences()),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
