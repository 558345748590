import {
  clearErrors,
  deleteEntity,
  fetchAllEntities,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchBillStatuses = () => {
  return fetchAllEntities(itemTypes.billStatuses)
}

export const updateBillStatus = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.billStatuses,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteBillStatus = (id, successCallback, failureCallback) => {
  return deleteEntity(
    itemTypes.billStatuses,
    id,
    successCallback,
    failureCallback
  )
}

export const postBillStatus = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.billStatuses,
    body,
    successCallback,
    failureCallback
  )
}

export const clearErrorBillStatuses = () => clearErrors(itemTypes.billStatus)
