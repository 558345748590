import * as React from 'react'
import { IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { postTask } from '../../../../actions/requests/tasksActions'
import { FileCopy } from '@material-ui/icons'
import {
  addNotification,
  failureNotification,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'

/**
 * Props :
 *    - task
 *    - board, the board of the task
 *    - boards, all board (only used if board is undefined)
 */
class CopyTask extends React.Component {
  doCopy(task) {
    let boardID = false

    if (!this.props.board) {
      for (let i = 0; i < this.props.boards.length; i++) {
        let b = this.props.boards[i]

        for (let ti = 0; ti < b.tasks.length; ti++) {
          if (task.id === b.tasks[ti].id) {
            boardID = b.id
            break
          }
        }
      }
    } else {
      boardID = this.props.board.id
    }

    if (!boardID) {
      this.props.addNotification(
        'Opération échoué',
        "La tâche n'appartient à aucun board",
        'danger'
      )
      return null
    }

    let body = {
      board: boardID,
      name: task.name,
      description: task.description,
      related_tasks: [],
      expected_workload: task.expected_workload,
      visible_workload: task.visible_workload,
      remaining_workload: task.remaining_workload,
      user: null,
      reference: task.reference,
      projectFilters: task.projectFilters.map((filter) => filter.id),
    }

    this.props
      .postTask(
        body,
        (id, response) =>
          this.props.successNotification(id, response, 'Tâche ajoutée'),
        (id, error) => this.props.failureNotification(id, error)
      )
      .catch((error) => {
        return error
      })
  }

  render() {
    let color = this.props.board ? '#948b96' : '#000000'

    return (
      <>
        <IconButton
          aria-label="copy"
          size="small"
          onClick={() => this.doCopy(this.props.task)}
        >
          <FileCopy htmlColor={color} fontSize="small" />
        </IconButton>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  postTask: (body, successCallback, failureCallback) =>
    dispatch(postTask(body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotification: (id, error) => dispatch(failureNotification(id, error)),
})

export default connect(null, mapDispatchToProps)(CopyTask)
