import styled from 'styled-components'

const StyledDiv = styled.div`
  background: ${(props) => (props.color ? props.color : '#f29400')};
  border-radius: 24px;
  color: white;
  text-align: center;
  margin: 0 auto 5px;
  width: 24px;
  height: 24px;
  line-height: 24px;
`

export default StyledDiv
