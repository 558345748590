const defaultURL = (itemName, url) =>
  buildType(itemName, url, (id) => url + '/' + id)
const defaultNameURL = (itemName) =>
  buildType(itemName, itemName, (id) => itemName + '/' + id)
const buildType = (itemName, url, urlWithID) => ({
  [itemName]: {
    itemName,
    url,
    urlWithID,
  },
})

export const replaceURLWithID = (itemType, urlWithID) => {
  return Object.assign({}, itemType, { urlWithID })
}

export const itemTypes = {
  ...defaultNameURL('bills'),
  ...defaultURL('billStatuses', 'billstatuses'),
  ...defaultNameURL('boards'),
  ...defaultNameURL('comments'),
  ...defaultNameURL('consums'),
  ...defaultNameURL('experiences'),
  ...defaultURL('filters', 'projectfilters'),
  ...defaultURL('importedFile', 'importedfile'),
  ...defaultNameURL('preferences'),
  ...defaultURL('primaryRoles', 'primaryroles'),
  ...defaultNameURL('projects'),
  ...defaultNameURL('srprices'),
  ...defaultURL('projectStatuses', 'projectstatuses'),
  ...defaultURL('projectTypes', 'projecttypes'),
  ...defaultURL('userSecondaryRoles', 'usersr'),
  ...defaultURL('secondaryRoles', 'secondaryroles'),
  ...defaultNameURL('skills'),
  ...defaultNameURL('sprints'),
  ...defaultNameURL('tasks'),
  ...defaultNameURL('users'),
  ...defaultURL('usersSimple', 'users/all/simple'),
  ...defaultURL('usersNeeded', 'usersneeded'),
  ...defaultURL('skillsExps', 'userskills'),
  ...defaultNameURL('relatedTasks', 'relatedtasks'),
}

/*
 * URLs to fetch using another entity's id
 * Example : Fetch bills from a project
 * Do not use in getItems, use the corresponding itemType
 */
export const otherURLs = {
  projectBills: replaceURLWithID(
    itemTypes.bills,
    (id) => 'projects/' + id + '/bills'
  ),
  projectFilters: replaceURLWithID(
    itemTypes.filters,
    (id) => 'projects/' + id + '/filters'
  ),
  projectBoards: replaceURLWithID(
    itemTypes.boards,
    (id) => 'projects/' + id + '/boards'
  ),
  projectConsums: replaceURLWithID(
    itemTypes.consums,
    (id) => 'projects/' + id + '/consums'
  ),
  projectTasks: replaceURLWithID(
    itemTypes.tasks,
    (id) => 'projects/' + id + '/tasks'
  ),
  projectUsers: replaceURLWithID(
    itemTypes.userSecondaryRoles,
    (id) => 'projects/' + id + '/users'
  ),
  projectSRPrices: replaceURLWithID(
    itemTypes.srprices,
    (id) => 'projects/' + id + '/secondaryroles/prices'
  ),
  projectSprints: replaceURLWithID(
    itemTypes.sprints,
    (id) => 'projects/' + id + '/sprints'
  ),
  projectUserNeeded: replaceURLWithID(
    itemTypes.usersNeeded,
    (id) => 'projects/' + id + '/usersneeded'
  ),
  projectImportedFile: replaceURLWithID(
    itemTypes.importedFile,
    (id) => 'projects/' + id + '/importedfile'
  ),
  tasksComments: replaceURLWithID(
    itemTypes.comments,
    (id) => 'tasks/' + id + '/comments'
  ),
  tasksConsums: replaceURLWithID(
    itemTypes.consums,
    (id) => 'tasks/' + id + '/consums'
  ),
  tasksFilters: replaceURLWithID(
    itemTypes.filters,
    (id) => 'tasks/' + id + '/filters'
  ),
  tasksRelatedTasks: replaceURLWithID(
    itemTypes.relatedTasks,
    (id) => 'tasks/' + id + '/relatedtasks'
  ),
  usersConsums: replaceURLWithID(
    itemTypes.consums,
    (id) => 'users/' + id + '/consums'
  ),
  usersEmail: replaceURLWithID(
    itemTypes.bills,
    (email) => 'users/email/' + email
  ),
  usersTasks: replaceURLWithID(
    itemTypes.tasks,
    (id) => 'users/' + id + '/tasks'
  ),
  usersSearch: replaceURLWithID(
    itemTypes.users,
    (value) => 'users/search/' + value
  ),
  usersProjects: replaceURLWithID(
    itemTypes.projects,
    (id) => 'users/' + id + '/projects'
  ),
  usersSkillsExps: replaceURLWithID(
    itemTypes.skillsExps,
    (id) => 'users/' + id + '/skills'
  ),
  exportProject: replaceURLWithID(
    itemTypes.projects,
    (id) => 'projects/' + id + '/export'
  ),
}
