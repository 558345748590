import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

// FETCHS

export const fetchProjects = () => {
  return fetchEntity(itemTypes.projects)
}

export const fetchProjectById = (id) => {
  return fetchEntity(itemTypes.projects, id)
}

export const fetchProjectsByUserId = (id) => {
  return fetchEntity(otherURLs.usersProjects, id)
}

// PROJECT

export const postProject = (body, successCallback, failureCallback) => {
  return postEntity(itemTypes.projects, body, successCallback, failureCallback)
}

export const updateProject = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.projects,
    id,
    body,
    successCallback,
    failureCallback
  )
}

// Ne met pas à jour le store car retourne plusieurs itemTypes
export const updateProjectByImport = (
  id,
  body,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    otherURLs.projectImportedFile,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteProject = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.projects, id, successCallback, failureCallback)
}

// CLEAR ERRORS
export const clearErrorProjects = () => clearErrors(itemTypes.projects)
