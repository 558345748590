import { userRoles } from '../../constants/global'

export const randomizeColor = (value1, value2, value3) => {
  var rgb1 = 0
  for (let i = 0; i < value1.length; ++i) {
    rgb1 += value1.charCodeAt(i)
  }
  var rgb2 = 0
  for (let i = 0; i < value2.length; ++i) {
    rgb2 += value2.charCodeAt(i)
  }
  var rgb3 = 0
  for (let i = 0; i < value3.length; ++i) {
    rgb3 += value3.charCodeAt(i)
  }
  rgb1 = (rgb1 % 125) + 130
  rgb2 = (rgb2 % 125) + 75
  rgb3 = rgb3 % 50
  return 'rgb(' + rgb1 + ', ' + rgb2 + ', ' + rgb3 + ')'
}

export const withoutAccent = (str) => {
  var accent = [
    /[\300-\306]/g,
    /[\340-\346]/g, // A, a
    /[\310-\313]/g,
    /[\350-\353]/g, // E, e
    /[\314-\317]/g,
    /[\354-\357]/g, // I, i
    /[\322-\330]/g,
    /[\362-\370]/g, // O, o
    /[\331-\334]/g,
    /[\371-\374]/g, // U, u
    /[\321]/g,
    /[\361]/g, // N, n
    /[\307]/g,
    /[\347]/g, // C, c
  ]
  var noaccent = [
    'A',
    'a',
    'E',
    'e',
    'I',
    'i',
    'O',
    'o',
    'U',
    'u',
    'N',
    'n',
    'C',
    'c',
  ]

  for (var i = 0; i < accent.length; i++) {
    str = str.replace(accent[i], noaccent[i])
  }

  return str
}

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now() //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : r && (0x3 || 0x8)).toString(16)
  })
}

export const getElemByIdInArray = (array, id) => {
  for (let elem of array) {
    if (elem.id === id) {
      return elem
    }
  }
  return null
}

export const getElemByCustomFieldInArray = (array, field, searched_value) => {
  for (let elem of array) {
    if (elem[field] === searched_value) {
      return elem
    }
  }
  return null
}

export const toggleClass = (element, toggleClass) => {
  var currentClass = element.className
  var newClass
  if (currentClass.split(' ').indexOf(toggleClass) > -1) {
    //has class
    newClass = currentClass.replace(
      new RegExp('\\b' + toggleClass + '\\b', 'g'),
      ''
    )
  } else {
    newClass = currentClass + ' ' + toggleClass
  }
  element.className = newClass.trim()
}

export const checkUserPrivileges = (user, project = null) => {
  if (user.role === null || user.role === undefined) {
    return false
  }
  if (
    user.role.codename === userRoles.ROLE_ADMIN ||
    user.role.codename === userRoles.ROLE_GEST
  ) {
    return true
  }
  if (project === null) {
    return false
  }
  for (let user_sr of project.users) {
    if (
      user_sr.user.id === user.id &&
      user_sr.secondaryRole.codename === userRoles.ROLE_CP
    ) {
      return true
    }
  }
  return false
}

export const checkUserAdminOrCPPrivileges = (user, project = null) => {
  if (user.role === null || user.role === undefined || project === null) {
    return false
  }

  if (user.role.codename === userRoles.ROLE_ADMIN) {
    return true
  }

  for (let user_sr of project.users) {
    if (
      user_sr.user.id === user.id &&
      user_sr.secondaryRole.codename === userRoles.ROLE_CP
    ) {
      return true
    }
  }
}

export const checkUserAdminPrivileges = (user) => {
  if (user.role === null || user.role === undefined) {
    return false
  }
  return user.role.codename === userRoles.ROLE_ADMIN
}

export const checkUserAdminOrGestPrivileges = (user) => {
  const { role } = user
  if (role === null || role === undefined) {
    return false
  }
  return (
    role.codename === userRoles.ROLE_ADMIN ||
    role.codename === userRoles.ROLE_GEST
  )
}

export const filterTasks = (tasks, searchValue) => {
  return tasks.filter(
    (t) =>
      (t.reference && t.reference.toLowerCase().includes(searchValue)) ||
      t.name.toLowerCase().includes(searchValue) ||
      (t.user &&
        (t.user.lastname.toLowerCase().includes(searchValue) ||
          t.user.firstname.toLowerCase().includes(searchValue)))
  )
}

export const trigram = (task) => {
  if (task.user) {
    const name = task.user.firstname[0].concat(
      '',
      task.user.lastname.substr(0, 2)
    )
    return name.toUpperCase()
  }

  return ''
}
