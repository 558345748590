import styled from 'styled-components'
import Card from '@material-ui/core/Card'

const StyledCard = styled(Card)`
  width: 256px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
`

export default StyledCard
