import React from 'react'
import { UserAvatar } from '../../../../components'
import { Tooltip } from 'reactstrap'
import RoundButton from '../UsersSection/styles/RoundButton'
import { MdPerson } from 'react-icons/md'

/**
 * Props :
 *    - user
 *    - isUserNeeded, is needed user or a real user
 *    - icon, the icon to show when hovering it
 *    - onClick(), callback when clicking on the icon
 *    - sr, optionnal, role to show
 */
class User extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      tooltipOpen: false,
    }
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }

  render() {
    return (
      <span className="position-relative userEditSpan">
        <button
          aria-label="Edition utilisateur"
          className="d-inline-block p-0 m-1 position-relative userEditButton"
          id={'Tooltip-' + this.props.user.id}
          onClick={this.props.onClick}
        >
          {this.props.isUserNeeded ? (
            <RoundButton className="m-1">
              <MdPerson />
            </RoundButton>
          ) : (
            <UserAvatar user={this.props.user} />
          )}
          <div className="userEditIcon">{this.props.icon}</div>
        </button>
        <Tooltip
          placement="bottom"
          isOpen={this.state.tooltipOpen}
          target={'Tooltip-' + this.props.user.id}
          toggle={this.toggle}
        >
          {this.props.isUserNeeded ? (
            <>
              Profil recherché
              {
                <>
                  <br />
                  {this.props.user.skill.name}
                </>
              }{' '}
              {this.props.user.exp.name}
            </>
          ) : (
            <>
              {this.props.user.firstname} {this.props.user.lastname}
              {this.props.sr && (
                <>
                  <br />
                  {this.props.sr.name}
                </>
              )}
            </>
          )}
        </Tooltip>
      </span>
    )
  }
}

export default User
