import * as React from 'react'
import { userThumbnailTypes } from '../../../../constants/global'
import UserThumbnail from '../../../../components/UserThumbnail'
import EditConsum from '../EditConsum'
import DeleteConsum from '../DeleteConsum'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import moment from 'moment'
import { styled } from '@material-ui/core/styles'

/**
 * Props :
 *    - consum
 */

const StyledCell = styled(TableCell)({
  padding: '7px',
})

class Consum extends React.Component {
  render() {
    const consum = this.props.consum
    const date = moment(consum.date).format('DD/MM/YYYY')
    return (
      <TableRow>
        <StyledCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserThumbnail
              key={consum.user.id}
              type={userThumbnailTypes.USER}
              data={consum.user}
            />
            <div style={{ paddingLeft: '1rem' }}>
              {consum.user.firstname.slice(0, 1).toUpperCase() +
                consum.user.lastname.slice(0, 2).toUpperCase()}
            </div>
          </div>
        </StyledCell>
        <StyledCell>{date}</StyledCell>
        <StyledCell>{consum.amount}</StyledCell>
        <StyledCell>
          {this.props.currentUser.id === consum.user.id && (
            <div>
              <DeleteConsum consum={consum} />
              <EditConsum consum={consum} />
            </div>
          )}
        </StyledCell>
      </TableRow>
    )
  }
}

export default Consum
