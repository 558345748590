import React from 'react'
import { connect } from 'react-redux'
import { updateTask } from '../../../../actions/requests/tasksActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import StyledDiv from '../styles/StyledDiv'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import { checkUserPrivileges } from '../../../../helpers'
import { isAgileProjectType } from '../../../../constants/global'
import { getSprints } from '../../../../reducers'

class WorkloadSection extends React.Component {
  constructor(props) {
    super(props)

    const { board, expected_workload, remaining_workload, visible_workload } =
      this.props.task

    this.state = {
      expected_workload: expected_workload !== null ? expected_workload : 0,
      isSavingExpected_workload: false,
      isSavingRemaining_workload: false,
      isSavingVisible_workload: false,
      previousExpected_workload:
        expected_workload !== null ? expected_workload : 0,
      previousRemaining_workload:
        remaining_workload !== null ? remaining_workload : 0,
      previousVisible_workload:
        visible_workload !== null ? visible_workload : 0,
      remaining_workload: remaining_workload !== null ? remaining_workload : 0,
      sprint: board.sprint ? board.sprint.id : null,
      visible_workload: visible_workload !== null ? visible_workload : 0,
    }
  }

  toggleState = (property) => {
    this.setState((prevState) => ({
      [property]: !prevState[property],
    }))
  }

  handleChangeState = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleTextualDataSave = (value) => {
    const [name, upperCaseName, translation] = this.getDataText(value)
    if (
      this.state[`previous${upperCaseName}`] === this.state[name] ||
      !this.checkField(name, translation)
    )
      return

    this.toggleState(`isSaving${upperCaseName}`)

    const notificationMessage = `Champ "${translation}" mis à jour`
    let body = { [name]: this.state[name] }

    this.props
      .editTask(
        this.props.task.id,
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.handleChangeState(`previous${upperCaseName}`, this.state[name])
        this.toggleState(`isSaving${upperCaseName}`)
      })
  }

  handleSprintSave = (sprint) => {
    const board = this.props.boards.find(
      (b) => b.sprint.id === sprint && b.board_order === 1
    )
    const notificationMessage = 'Tâche déplacée'
    this.props.editTask(
      this.props.task.id,
      {
        board: board.id,
      },
      (id, response) => {
        this.handleChangeState('sprint', sprint)
        this.props.successNotification(id, response, notificationMessage)
      },
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  checkField = (name, translation) => {
    if (this.state[name] < 0) {
      this.props.addNotification(
        'Opération échouée',
        `Le champ "${translation}" ne peut avoir une valeur négative`,
        'danger'
      )

      return false
    }
    if (name === 'expected_workload') {
      if (this.state[name] < this.props.task.visible_workload) {
        this.props.addNotification(
          'Opération échouée',
          `La charge client doit être supérieure ou égale à la charge visible.`,
          'danger'
        )
        this.setState({
          expected_workload: this.state.previousExpected_workload,
        })
        return false
      }
    }
    if (name === 'visible_workload') {
      if (this.state[name] > this.props.task.expected_workload) {
        this.props.addNotification(
          'Opération échouée',
          `La charge visible doit être inférieure ou égale à la charge client.`,
          'danger'
        )
        this.setState({
          visible_workload: this.state.previousVisible_workload,
        })
        return false
      }
    }

    return true
  }

  userCanEditField = (fieldName) => {
    if (
      ['Expected_workload', 'Sprint', 'Visible_workload'].includes(fieldName)
    ) {
      return checkUserPrivileges(this.props.currentUser, this.props.project)
    }

    return true
  }

  isFieldDisabled = (fieldName) => {
    return (
      this.state[`isSaving${fieldName}`] || !this.userCanEditField(fieldName)
    )
  }

  isFieldVisible = (fieldName) => {
    switch (fieldName) {
      case 'Expected_workload':
        return (
          checkUserPrivileges(this.props.currentUser, this.props.project) &&
          this.props.showChargeClient
        )
      case 'Sprint':
        return isAgileProjectType(this.props.project.project_type)
      default:
        return true
    }
  }

  getDataText = (name) => {
    const upperCaseName = name.charAt(0).toUpperCase() + name.slice(1)
    switch (name) {
      case 'expected_workload':
        return [name, upperCaseName, 'Charge client']
      case 'remaining_workload':
        return [name, upperCaseName, 'RAF']
      case 'visible_workload':
        return [name, upperCaseName, 'Charge']
      default:
        return [name, upperCaseName, upperCaseName]
    }
  }

  render() {
    return (
      <>
        {this.isFieldVisible('Expected_workload') && (
          <StyledDiv>
            <TextField
              type="Number"
              label="Charge client"
              name="expected_workload"
              id="expected_workload"
              variant="outlined"
              fullWidth
              value={this.state.expected_workload}
              onChange={({ target: { name, value } }) =>
                this.handleChangeState(name, Number(value))
              }
              onBlur={({ target: { name } }) =>
                this.handleTextualDataSave(name)
              }
              disabled={this.isFieldDisabled('Expected_workload')}
              inputProps={{ step: '0.25' }}
            />
          </StyledDiv>
        )}

        <StyledDiv>
          <TextField
            type="Number"
            label="Charge"
            name="visible_workload"
            id="visible_workload"
            variant="outlined"
            fullWidth
            value={this.state.visible_workload}
            onChange={({ target: { name, value } }) =>
              this.handleChangeState(name, Number(value))
            }
            onBlur={({ target: { name } }) => this.handleTextualDataSave(name)}
            disabled={this.isFieldDisabled('Visible_workload')}
            inputProps={{ step: '0.25' }}
          />
        </StyledDiv>

        <StyledDiv>
          <TextField
            type="Number"
            label="RAF"
            name="remaining_workload"
            id="remaining_workload"
            variant="outlined"
            fullWidth
            value={this.state.remaining_workload}
            onChange={({ target: { name, value } }) =>
              this.handleChangeState(name, Number(value))
            }
            onBlur={({ target: { name } }) => this.handleTextualDataSave(name)}
            disabled={this.isFieldDisabled('Remaining_workload')}
            inputProps={{ step: '0.25' }}
          />
        </StyledDiv>

        {this.isFieldVisible('Sprint') && (
          <StyledDiv>
            {this.userCanEditField('Sprint') ? (
              <>
                <FormControl variant={'outlined'} fullWidth>
                  <InputLabel>Sprint en cours</InputLabel>

                  <Select
                    label="Sprint en cours"
                    name="currentSprint"
                    id="currentSprint"
                    fullWidth
                    value={this.state.sprint}
                    onChange={({ target: { value } }) =>
                      this.handleSprintSave(value)
                    }
                    disabled={this.isFieldDisabled('Sprint')}
                  >
                    {this.props.sprints.map((sprint) => (
                      <MenuItem key={sprint.id} value={sprint.id}>
                        {sprint.number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <FormLabel variant="outlined">Sprint en cours :</FormLabel>
                <InputLabel
                  style={{
                    fontSize: '1rem',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: '400',
                    marginLeft: '1rem',
                  }}
                >
                  {this.props.task.board.sprint.number}
                </InputLabel>
              </>
            )}
          </StyledDiv>
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  sprints: getSprints(state, ownProps.project.id),
})

const mapDispatchToProps = (dispatch) => ({
  // Edit
  editTask: (id, body, successCallback, failureCallback) =>
    dispatch(updateTask(id, body, successCallback, failureCallback)),
  // Notifications
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkloadSection)
