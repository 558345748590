import styled from 'styled-components'

const RoundButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  color: black;
  border: solid 1px black;
  background: none;
  border-radius: 16px;
  transition: all ease 0.4s;
  &:hover {
    color: white;
    background: darkorange;
    transition: all ease 0.4s;
  }
`

export default RoundButton
