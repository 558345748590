import { connect } from 'react-redux'
import { AdminList } from '../../pages/Admin/components'
import { getSkills } from '../../reducers'
import { postSkills, updateSkills } from '../../actions/requests/skillsActions'
import { deleteEntity } from '../../actions/globalActions'
import { itemTypes } from '../../actions/itemTypes'

const mapStateToProps = (state) => ({
  data: getSkills(state),
  properties: {
    name: 'Nom',
  },
  title: 'skills',
  object: 'Compétence',
  // feminine définit si l'accord doit se faire
  feminine: true,
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postSkills(body, successCallback, failureCallback)),
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateSkills(id, body, successCallback, failureCallback)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(
      deleteEntity(itemTypes.skills, id, successCallback, failureCallback)
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
