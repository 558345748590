import { createMuiTheme } from '@material-ui/core/styles'

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(242, 148, 0,1)',
      contrastText: 'rgb(255,255,255)',
    },
    secondary: {
      main: 'rgb(148,139,150,1)',
    },
    backgroundHeader: {
      main: 'rgb(255,255,255)',
      contrastText: 'rgb(148,139,150,1)',
    },
    text: {
      primary: 'rgb(0,0,0,0.87)',
      secondary: 'rgb(33,37,41)',
    },
    badge: {
      red: 'rgb(238,101,101)',
      green: 'rgb(98,175,98)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 1060,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default Theme
