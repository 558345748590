import * as React from 'react'
import BoardTask from '../BoardTask/'
import { filterTasks, getUser } from '../../../../helpers'
import { connect } from 'react-redux'
import { getItems } from '../../../../reducers'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { itemTypes } from '../../../../actions/itemTypes'
import RootRef from '@material-ui/core/RootRef'
import StyledCardContent from './styles/StyledCardContent'
import StyledDiv2 from './styles/StyledDiv2'
import EditBoard from '../EditBoard'

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
})

/**
 * Props :
 *    - board, the board to show
 *    - selectedFilters, used to filter the tasks
 */
class Board extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      board: this.props.board,
      contentScrollEl: null,
    }
  }

  filterTasks() {
    const tasks = this.props.board.tasks.filter(
      (task) =>
        this.props.selectedFilters.length === 0 ||
        this.props.selectedFilters.some((filter) =>
          task.projectFilters.find((f) => f.id === filter.id)
        )
    )

    const searchValue = this.props.searchValue.toLowerCase()
    return searchValue !== '' ? filterTasks(tasks, searchValue) : tasks
  }

  render() {
    const tasks = this.filterTasks()

    return (
      <>
        <div
          style={{
            BorderBottomWidth: 1,
            marginTop: '5px',
          }}
        >
          <EditBoard
            board={this.props.board}
            boards={this.props.boards}
            handleProps={
              this.props.provided ? this.props.provided.dragHandleProps : null
            }
            project={this.props.project}
            filters={this.props.filters}
            user={this.props.currentUser}
            firstBoard={this.props.firstBoard}
          />
        </div>

        <RootRef rootRef={this.state.contentScrollEl}>
          <StyledCardContent>
            <Droppable
              droppableId={this.props.board.id}
              type="card"
              direction="vertical"
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    width: '100%',
                    height: '100%',
                    paddingLeft: 16,
                    paddingRight: 16,
                    flexDirection: 'column',
                    display: 'flex',
                  }}
                >
                  {tasks.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={this.props.board.id + item.id}
                      index={index}
                      type="card"
                    >
                      {(provided, snapshot) => (
                        <StyledDiv2
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <BoardTask
                            key={item.id}
                            task={item}
                            board={this.props.board}
                            project={this.props.project}
                            consums={item.consums}
                            showChargeClient={this.props.showChargeClient}
                          />
                        </StyledDiv2>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div style={{ minHeight: '10px' }} />
                </div>
              )}
            </Droppable>
          </StyledCardContent>
        </RootRef>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(),
  tasks: getItems(state, itemTypes.tasks),
})

export default connect(mapStateToProps, null)(Board)
