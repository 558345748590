import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const MyButton = withStyles((theme) => ({
  root: {
    justifyContent: 'start',
    width: '100%',
    marginLeft: '0.25rem',
    '&:hover': {
      backgroundColor: 'rgba(105, 105, 105, 0.5)',
    },
  },
}))(Button)

const NavbarItem = (props) => (
  <MyButton onClick={props.link} style={{ textTransform: 'none' }}>
    <div>{props.logo}</div>
    <div
      style={{
        paddingLeft: '14px',
        paddingBottom: '5px',
        whiteSpace: 'nowrap',
      }}
    >
      <Typography
        variant="h6"
        style={{ fontSize: '0.9rem', color: 'rgb(255,255,255)' }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="h6"
        style={{
          color: 'rgb(255,255,255)',
          fontStyle: 'italic',
          fontSize: 'x-small',
        }}
      >
        {props.version}
      </Typography>
    </div>
  </MyButton>
)

export default NavbarItem
