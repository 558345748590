import * as fromConnexion from './connexion'
import * as fromModels from './models'
import * as fromLocalStorage from './localStorage'
import * as fromSessionStorage from './sessionStorage'
import * as fromTable from './table'
import * as fromUtil from './util'

//CONNEXION
export const loginUser = fromConnexion.loginUser
export const getUserInfos = () => fromConnexion.getUserInfos()

//MODELS
export const BillStatus = fromModels.BillStatus
export const Experience = fromModels.Experience
export const ProjectStatus = fromModels.ProjectStatus
export const Skill = fromModels.Skill
export const SecondaryRole = fromModels.SecondaryRole
export const UserSync = fromModels.UserSync
export const UserPromote = fromModels.UserPromote

//LOCAL STORAGE
export const getShowChargeClient = fromLocalStorage.getShowChargeClient

//SESSION STORAGE
export const getUser = fromSessionStorage.getUser

//TABLE
export const getComparator = (order, orderBy) =>
  fromTable.getComparator(order, orderBy)
export const stableSort = (array, comparator) =>
  fromTable.stableSort(array, comparator)

//UTIL
export const randomizeColor = (value1, value2, value3) =>
  fromUtil.randomizeColor(value1, value2, value3)
export const withoutAccent = (str) => fromUtil.withoutAccent(str)
export const generateUUID = () => fromUtil.generateUUID()
export const getElemByIdInArray = (array, id) =>
  fromUtil.getElemByIdInArray(array, id)
export const getElemByCustomFieldInArray = (array, field, searched_value) =>
  fromUtil.getElemByCustomFieldInArray(array, field, searched_value)
export const toggleClass = (element, toggleClass) =>
  fromUtil.toggleClass(element, toggleClass)
export const checkUserPrivileges = (user, project) =>
  fromUtil.checkUserPrivileges(user, project)
export const checkUserAdminPrivileges = (user) =>
  fromUtil.checkUserAdminPrivileges(user)
export const checkUserAdminOrGestPrivileges = (user) =>
  fromUtil.checkUserAdminOrGestPrivileges(user)
export const filterTasks = (tasks, searchValue) =>
  fromUtil.filterTasks(tasks, searchValue)
export const checkUserAdminOrCPPrivileges = (user, project) =>
  fromUtil.checkUserAdminOrCPPrivileges(user, project)
