import React from 'react'
import logo from '../../assets/img/logo.png'

const LittleSpinner = () => (
  <div>
    <img src={logo} className="little-image-spinner" alt="Logo AttiProd" />
  </div>
)

export default LittleSpinner
