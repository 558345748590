const routes = {
  root: process.env.REACT_APP_CLIENT_ROOT_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  editProfile: 'https://eur.delve.office.com',
  admin: '/admin',
  consums: '/consums',
  dashboard: '/dashboard',
  editProject: '/editproject',
  editTask: '/edittask',
  home: '/',
  login: '/login',
  logout: '/logout',
  newProject: '/newproject',
  notFoundPage: '/404',
  projectConsums: '/projectconsums',
  projectTasks: '/projecttasks',
  projects: '/projects',
  profile: '/profile',
  statistics: '/statistics',
  roadmap: '/roadmap',
  gitlab: 'http://gitlab.attineos.cloud/attineos/attiprod/-/issues',
}

export default routes
