export const onFailure = (state, error) => {
  const err = Object.assign({}, error, { date: Date.now() })
  return Object.assign({}, state, {
    isFetching: false,
    error: err,
  })
}

export const onFetching = (state) =>
  Object.assign({}, state, {
    isFetching: true,
    error: null,
  })
