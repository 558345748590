import { connect } from 'react-redux'
import { AdminList } from '../../pages/Admin/components'
import { getBillStatuses } from '../../reducers'
import {
  deleteBillStatus,
  postBillStatus,
  updateBillStatus,
} from '../../actions/requests/billStatusesActions'

const mapStateToProps = (state) => ({
  data: getBillStatuses(state),
  properties: {
    name: 'Nom',
  },
  title: 'billStatuses',
  object: 'Statut de facturation',
  // feminine définit si l'accord doit se faire
  feminine: false,
})

const mapDispatchToProps = (dispatch) => ({
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postBillStatus(body, successCallback, failureCallback)),
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateBillStatus(id, body, successCallback, failureCallback)),
  deleteItem: (id, successCallback, failureCallback) =>
    dispatch(deleteBillStatus(id, successCallback, failureCallback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
