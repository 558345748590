import {
  clearErrors,
  deleteEntity,
  fetchAllEntities,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchUserSRs = () => {
  return fetchAllEntities(itemTypes.userSecondaryRoles)
}

export const addUserSR = (body, successCallback, failureCallback) => {
  return postEntity(
    itemTypes.userSecondaryRoles,
    body,
    successCallback,
    failureCallback
  )
}

export const updateUserSR = (
  id,
  body,
  successCallback,
  failureCallback,
  projectId
) => {
  return updateEntity(
    itemTypes.userSecondaryRoles,
    id,
    body,
    successCallback,
    failureCallback,
    projectId
  )
}

export const deleteUserSR = (
  id,
  successCallback,
  failureCallback,
  projectId
) => {
  return deleteEntity(
    itemTypes.userSecondaryRoles,
    id,
    successCallback,
    failureCallback,
    projectId
  )
}

export const clearErrorsUserSR = () => clearErrors(itemTypes.userSecondaryRoles)
