import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import { Header, PageSpinner, PreviewProject } from '../../components'
import { getProjects, getProjectStatuses } from '../../reducers'
import {
  fetchProjects,
  fetchProjectsByUserId,
} from '../../actions/requests/projectsActions'
import { fetchProjectTypes } from '../../actions/requests/projectTypesActions'
import { checkUserPrivileges, getUser } from '../../helpers'
import { fetchSecondaryRoles } from '../../actions/requests/secondaryRolesActions'
import { fetchProjectStatuses } from '../../actions/requests/projectStatusesActions'
import { Helmet } from 'react-helmet'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Search } from '@material-ui/icons'
import { TextField } from '@material-ui/core'

class Projects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFirstRender: true,
      searchValue: '',
    }
  }

  componentDidMount() {
    let promises = [
      this.props.fetchProjectTypes(),
      this.props.fetchProjectStatuses(),
      this.props.fetchSecondaryRoles(),
    ]

    if (checkUserPrivileges(this.props.currentUser)) {
      promises.push(this.props.fetchAllProjects())
    } else {
      promises.push(this.props.fetchUserProjects(this.props.currentUser.id))
    }

    Promise.all(promises).then(() => {
      this.setState({ isFirstRender: false })
    })
  }

  getProjects = () => {
    let projects
    checkUserPrivileges(this.props.currentUser)
      ? projects = this.props.projects
      : projects = this.props.projects.filter(
        (p) =>
          p.users.find((u) => u.user.id === this.props.currentUser.id) !==
          undefined
      )
    return projects
  }

  render() {
    if (this.state.isFirstRender) return <PageSpinner />

    const projects = this.getProjects()

    const searchValue = this.state.searchValue.toLowerCase()
    const filteredProjects = projects.filter((p) =>
      p.project_name.toLowerCase().includes(searchValue)
    )

    return projects.length > 0 ? (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Projets - Attiprod </title>
        </Helmet>
        <Header title="Projets" user={this.props.currentUser} />
        <div>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div className="page-section-title">
              <h4>Projets en cours</h4>
            </div>
            <TextField
              variant={'outlined'}
              size={'small'}
              placeholder={'Nom du projet'}
              value={this.state.searchValue}
              onChange={(e) => this.setState({ searchValue: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                style: { backgroundColor: '#f2f2f2' },
              }}
              style={{ marginRight: '24px' }}
            />
          </div>
          <div className="page-section">
            {filteredProjects
              .filter((project) => project.enabled && !project.finished)
              .map((project) => (
                <PreviewProject
                  key={project.id}
                  project={project}
                  statuses={this.props.statuses}
                  click={() => {
                    this.props.redirectToProjectTasksPage(project.id)
                  }}
                  isDeletable={false}
                />
              ))}
          </div>
          <div className="page-section-title">
            <h4>Projets terminés</h4>
          </div>
          <div className="page-section">
            {filteredProjects
              .filter((project) => project.enabled && project.finished)
              .map((project) => (
                <PreviewProject
                  key={project.id}
                  project={project}
                  statuses={this.props.statuses}
                  click={() => {
                    this.props.redirectToProjectEditPage(project.id)
                  }}
                  isDeletable={false}
                />
              ))}
          </div>
        </div>
      </>
    ) : (
      <div>
        <Header title="Mes projets" user={this.props.currentUser} />
        <div className="page-section">Aucun projet à afficher</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: getUser(),
  projects: getProjects(state),
  statuses: getProjectStatuses(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchAllProjects: () => dispatch(fetchProjects()),
  fetchUserProjects: (id) => dispatch(fetchProjectsByUserId(id)),
  fetchSecondaryRoles: () => dispatch(fetchSecondaryRoles()),
  fetchProjectTypes: () => dispatch(fetchProjectTypes()),
  fetchProjectStatuses: () => dispatch(fetchProjectStatuses()),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
  redirectToProjectEditPage: (id) =>
    dispatch(push(routes.editProject + '/' + id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
