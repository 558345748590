import React from 'react'
import {
  isForfaitProjectType,
  userListButtonAction,
} from '../../../../constants/global'
import { UserAvatar, UsersListContainer } from '../../../../components'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import StyledButton from '../styles/StyledButton'
import Button from '@material-ui/core/Button'
import { Avatar } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { checkUserPrivileges } from '../../../../helpers'
import IconButton from '@material-ui/core/IconButton'
/**
 * Props :
 *    - project, the edited project
 *    - setUser, callback when a user is picked
 * Store :
 *    - Users
 *    - SecondaryRoles
 */
class UserSelection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }

    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleAddUser = this.handleAddUser.bind(this)
  }

  handleOpenDialog() {
    this.setState({
      open: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      open: false,
    })
  }

  handleAddUser = (user) => {
    this.props.onAddUser(user)
    this.handleCloseDialog()
  }

  isProjectParticipant = () => {
    return (
      this.props.project.users.find(
        (userSR) => userSR.user.id === this.props.currentUser.id
      ) !== undefined
    )
  }

  render() {
    const isUserCurrentUser =
      this.props.user && this.props.currentUser.id === this.props.user.id

    return (
      <>
        {this.props.minimized ? (
          this.props.user ? (
            <div
              onClick={() => {
                this.handleOpenDialog()
              }}
            >
              {this.props.user.firstname[0].concat(
                '',
                this.props.user.lastname.substr(0, 2).toUpperCase()
              )}
            </div>
          ) : (
            <IconButton
              aria-label="assigner un utilisateur"
              style={{ color: 'rgb(148,139,150,1)' }}
              onClick={() => {
                this.handleOpenDialog()
              }}
              disabled={this.props.isFetching}
            >
              <Icon>{'person_add'}</Icon>
            </IconButton>
          )
        ) : (
          <StyledButton
            disabled={this.props.disabled}
            style={{ marginTop: '1rem' }}
            onClick={this.handleOpenDialog}
          >
            {this.props.user ? (
              <UserAvatar user={this.props.user} size={'large'} />
            ) : (
              <Avatar style={{ width: '64px', height: '64px' }}>
                <Icon>{this.props.disabled ? 'person' : 'person_add'}</Icon>
              </Avatar>
            )}
          </StyledButton>
        )}

        {(checkUserPrivileges(this.props.currentUser, this.props.project) ||
          !isForfaitProjectType(this.props.project.project_type)) &&
          !this.props.minimized && (
            <Button
              color={'primary'}
              variant={'contained'}
              disabled={this.props.isSaving || !this.isProjectParticipant()}
              onClick={() =>
                this.props.onAddUser(
                  isUserCurrentUser ? null : this.props.currentUser
                )
              }
              style={{ marginTop: '2rem' }}
            >
              {isUserCurrentUser ? 'Me désassigner' : "M'assigner"}
            </Button>
          )}

        <Dialog
          aria-labelledby="add-user-dialog"
          open={this.state.open}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle id="add-user-dialog">
            Selectionner des users à ajouter
          </DialogTitle>
          <DialogContent>
            <UsersListContainer
              buttonAction={userListButtonAction.SELECTION}
              omitted={this.props.user ? [this.props.user] : []}
              users={this.props.userSRs.map((userSR) => userSR.user)}
              onAdd={(user) => this.handleAddUser(user)}
              task={this.props.task}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default UserSelection
