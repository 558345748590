import { logoutRequest, msalInstance, tokenRequest } from '../../constants/util'
import routes from '../../config/routes'
import axios from 'axios'

export function loginUser() {
  return msalInstance.loginRedirect(tokenRequest)
}

function callGraphAPI(accessToken, method, graphEndpoint) {
  const config = {
    method: method,
    url: graphEndpoint,
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'arraybuffer',
  }

  return axios(config).catch((error) => {
    if (error.response) return error.response
    else if (error.request) return error.request
    else return { status: 0, data: { error: error } }
  })
}

function forceLogout() {
  try {
    msalInstance.logoutRedirect(logoutRequest)
  } catch (err) {
    console.log("forceLogout don't work", err)
  }
}

function cleanLocalStorageInfosAboutToken() {
  // Just clear all localstorage, simpler and cleaner
  localStorage.clear()
}

export async function getUserInfos({ onLogoutCallback }) {
  try {
    // Get access token
    const tokens = await msalInstance
      .acquireTokenSilent(tokenRequest)
      .catch((err) => {
        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
        if (err.name === 'InteractionRequiredAuthError') {
          loginUser()
        }

        // Après 24, les tokens ne sont plus valides et provoque une exception 'BrowserAuthError'
        // J'ai testé de déclencher le loginUser également pour cette exception et ça redirige sur le login azure qui crash alors comme si l'APP était mal config.
        // Si on clean le local storage (et donc les tokens) la connexion se passe correctement
        // chiant à reproduire car se déclenche qu'après 24h depuis la génération du token. Je suis en train de voir avec les infras pour réduire ce délai
        // Msal 2.x utilise un système de cache qui peut être la source du problème.
        console.log(err) // ajouté pour avoir des infos en test
        forceLogout()
        cleanLocalStorageInfosAboutToken()
        onLogoutCallback && onLogoutCallback()
      })

    const {
      accessToken,
      idToken,
      idTokenClaims: { email },
    } = tokens
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }

    let picture = null

    // Répération de la photo de l'utilisateur connecté
    const responseGraph = await callGraphAPI(
      accessToken,
      'GET',
      'https://graph.microsoft.com/v1.0/me/photo/$value'
    )

    // Si l'utilisateur a une photo
    if (responseGraph.status === 200) {
      // Transformation des données binaires en base 64
      picture = `data:${responseGraph.headers['content-type']};base64,`
      picture += Buffer.from(responseGraph.data, 'binary').toString('base64')
    }

    // Récupération des données de l'utilisateur
    return axios
      .get(`${routes.apiUrl}/users/email/${email}`, config)
      .then((response) => {
        if (picture !== null) {
          const configPut = {
            ...config,
            url: `${routes.apiUrl}/users/${response.data.id}`,
            method: 'PUT',
            data: JSON.stringify({ profile_pic: picture }),
          }

          return axios(configPut)
        }

        return response
      })
      .then((response) => {
        if (picture !== null) response.data.profile_pic = picture
        sessionStorage.setItem('user', JSON.stringify(response.data))
        return response.data
      })
      .catch((error) => {
        return new Error('error')
      })
  } catch (err) {
    forceLogout()
    cleanLocalStorageInfosAboutToken()
    onLogoutCallback && onLogoutCallback()
    console.log('global getUserInfos error', err)
  }
}

export const getUser = () => msalInstance.getActiveAccount()
