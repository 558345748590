import * as React from 'react'
import { Col, Progress, Row } from 'reactstrap'
import { connect } from 'react-redux'
import {
  getBoards,
  getProject,
  getProjectTypes,
  getSecondaryRolesPrices,
  getSprints,
  isFetching,
} from '../../reducers'
import { getUser } from '../../helpers'
import { fetchProjectById } from '../../actions/requests/projectsActions'
import { push } from 'connected-react-router'
import routes from '../../config/routes'
import PageSpinner from '../../components/PageSpinner'
import {
  FaHourglass,
  FaFireAlt,
  FaHourglassHalf,
  FaArrowsAltH,
} from 'react-icons/fa'
import { fetchBoardsByProjectId } from '../../actions/requests/boardsActions'
import { fetchSRPricesByProjectId } from '../../actions/requests/srPricesActions'
import { fetchProjectTypes } from '../../actions/requests/projectTypesActions'
import { Line } from 'react-chartjs-2'
import { fetchSprintsByProjectId } from '../../actions/requests/sprintsActions'
import { isAgileProjectType } from '../../constants/global'
import { checkUserPrivileges } from '../../helpers'
import Breadcrumbs from '../../components/Breadcrumbs'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Header from '../../components/Header'
import moment from 'moment'

class Statistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      consumed: 0,
      consumedPrice: 0,
      differentialPrice: 0,
      expected_workload: 0,
      expected_workloadPrice: 0,
      workload: 0,
      workloadPrice: 0,
      isLoading: true,
      isMissingPrice: [],
      raf: 0,
      rafPrice: 0,
      sprintStartEndError: false,
      showChargeClient: false,
      remainTotal: 0,
      visibleTotal: 0,
      expectedTotal: 0,
      consumTotal: 0,
    }
  }

  componentDidMount() {
    let promises = [
      this.props.fetchProject(this.props.match.params.id),
      this.props.fetchProjectTypes(),
      this.props.fetchBoardsByProjectId(this.props.match.params.id),
      this.props.fetchSprintsByProjectId(this.props.match.params.id),
      this.props.fetchProjectPrices(this.props.match.params.id),
    ]

    Promise.all(promises).then(() => {
      switch (this.props.project.project_type.codename) {
        case 'agile':
          this.getGraphData()
          break
        case 'forfait':
        case 'tma':
          this.getStatistics()
          break
        default:
          break
      }
    })

    this.setState({
      ...this.props.location.state,
    })
  }

  getStatistics() {
    if (this.props.boards.length === 0) return

    let consumed = 0
    let consumedPrice = 0
    let expected_workload = 0
    let expected_workloadPrice = 0
    let workload = 0
    let workloadPrice = 0
    let isMissingPrice = []
    let raf = 0
    let rafPrice = 0
    let srPrices = this.props.srPrices.filter(
      (srPrice) => srPrice.project.id === this.props.project.id
    )

    let isConsumedPriceUnknown = false
    for (const board of this.props.boards) {
      for (const task of board.tasks) {
        let taskUser
        let userPrice
        if (!task.user) {
          taskUser = 0
          userPrice = 0
        } else {
          taskUser = this.props.project.users.find(
            (user) => user.user.id === task.user.id
          )
          userPrice = srPrices.find(
            (srPrice) => srPrice.secondaryRole.id === taskUser.secondaryRole.id
          )
            ? srPrices.find(
                (srPrice) =>
                  srPrice.secondaryRole.id === taskUser.secondaryRole.id
              ).price
            : 0
        }

        let taskConsumed = 0
        for (const consum of task.consums) {
          taskConsumed += consum.amount

          const consumPrice = srPrices.find(
            (srPrice) => srPrice.secondaryRole.id === consum.secondaryRole.id
          )
            ? srPrices.find(
                (srPrice) =>
                  srPrice.secondaryRole.id === consum.secondaryRole.id
              ).price
            : 0
          consumedPrice = consumPrice
            ? consumedPrice + consumPrice * consum.amount
            : consumedPrice

          if (
            !consumPrice &&
            !isMissingPrice.includes(consum.secondaryRole.name)
          ) {
            isConsumedPriceUnknown = true
            isMissingPrice = [...isMissingPrice, consum.secondaryRole.name]
          }
        }

        consumed += taskConsumed
        expected_workload += task.expected_workload ? task.expected_workload : 0
        workload += task.visible_workload ? task.visible_workload : 0
        raf += task.remaining_workload

        if (!userPrice) {
          if (
            taskUser &&
            !isMissingPrice.includes(taskUser.secondaryRole.name)
          ) {
            isConsumedPriceUnknown = true
            isMissingPrice = [...isMissingPrice, taskUser.secondaryRole.name]
          }
          continue
        }

        expected_workloadPrice += userPrice * task.expected_workload
        workloadPrice += userPrice * task.visible_workload
        rafPrice += userPrice * task.remaining_workload
      }
    }

    this.setState({
      consumed,
      consumedPrice: isConsumedPriceUnknown ? -1 : consumedPrice,
      expected_workload,
      expected_workloadPrice,
      workload,
      workloadPrice,
      raf,
      rafPrice,
      isMissingPrice,
      isLoading: false,
    })
  }

  async changeSprint(sprint) {
    await this.setState({
      sprintStartEndError: false,
      currentSprint: sprint,
    })
    this.getGraphData()
  }

  getCurrentSprint() {
    let sprints = []
    for (let sprint of this.props.sprints) {
      sprints = [...sprints, sprint.number]
    }
    return this.props.sprints.find(
      (sprint) => parseInt(sprint.number) === Math.max(...sprints)
    )
  }

  getGraphData() {
    const currentSprint = this.state.isLoading
      ? this.getCurrentSprint()
      : this.state.currentSprint
    if (currentSprint.start && currentSprint.end) {
      const startDate = new Date(currentSprint.start)
      const endDate = new Date(currentSprint.end)
      const sprintDuration =
        (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)

      let tasks = []
      this.props.boards
        .filter((board) => board.sprint.id === currentSprint.id)
        .map((board) => (tasks = [...tasks, ...board.tasks]))

      let tasksCount = tasks.length
      let data = []
      let labels = []
      for (let i = 0; i <= sprintDuration; i++) {
        let date = moment(currentSprint.start).add(i, 'days')
        if (date.day() === 6 || date.day() === 0) continue
        date = moment(date).format('DD/MM')

        for (let task of tasks) {
          if (moment(task.finished).format('DD/MM') === date) {
            tasksCount -= 1
          }
        }

        data.push(tasksCount)
        labels.push(date)
      }

      const idealData = []
      for (let i = 0; i < labels.length; i++) {
        const step = tasks.length - (tasks.length / (labels.length - 1)) * i
        idealData.push(step)
      }

      this.setState({
        isLoading: false,
        currentSprint,
        data,
        idealData,
        labels,
      })
    } else {
      this.setState({
        isLoading: false,
        sprintStartEndError: true,
        currentSprint,
        data: [],
        idealData: [],
        labels: [],
      })
    }
  }

  renderSwitch() {
    switch (this.props.project.project_type.codename) {
      case 'agile':
        const data = {
          labels: this.state.labels,
          datasets: [
            {
              label: 'Ligne du travail restant effectif',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(253,164,42,0.4)',
              borderColor: 'rgba(253,164,42,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(62,44,66,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(62,44,66,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: this.state.data,
            },
            {
              label: 'Ligne du travail restant idéal',
              fill: false,
              borderColor: 'rgba(62,44,66,1)',
              pointBorderColor: 'rgba(253,164,42,1)',
              pointHoverBackgroundColor: 'rgba(253,164,42,1)',
              pointRadius: 0,
              pointHitRadius: 0,
              data: this.state.idealData,
            },
          ],
        }
        const options = {
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Tâches',
                },
                ticks: {
                  min: 0,
                  max: this.state.idealData[0],
                  stepSize: 1,
                },
              },
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Jours',
                },
              },
            ],
          },
        }

        return (
          <Row>
            <Col>
              <div className="page-section">
                <div
                  className="d-flex px-4"
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  {this.state.sprintStartEndError ? (
                    <>
                      <span>
                        Veuillez renseigner les dates de sprint dans la page
                        d'édition de projet
                      </span>
                      <IconButton
                        className="m-0"
                        aria-label="éditer ce projet"
                        color={'#F29400'}
                        onClick={() => {
                          this.props.redirectToEditProjectPage(
                            this.props.match.params.id
                          )
                        }}
                      >
                        <Icon size={24} color="#3E2C42">
                          settings
                        </Icon>
                      </IconButton>
                    </>
                  ) : (
                    <Line data={data} options={options} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )
      case 'forfait':
      case 'tma':
        const isMissingPriceWithoutDupes = [
          ...new Set(this.state.isMissingPrice),
        ]
        const differential =
          checkUserPrivileges(this.props.currentUser, this.props.project) &&
          this.state.showChargeClient
            ? this.state.expectedTotal -
              (this.state.remainTotal + this.state.consumTotal)
            : this.state.visibleTotal -
              (this.state.remainTotal + this.state.consumTotal)
        const differentialPrice =
          this.state.isMissingPrice.length > 0
            ? null
            : this.state.expected_workloadPrice -
              (this.state.rafPrice + this.state.consumedPrice)
        let progress = Math.round(
          100 - (this.state.raf / this.state.expected_workload) * 100
        )
        if (this.state.raf === 0) progress = 100
        if (this.state.expected_workload === 0) progress = 0

        return (
          <>
            <Row>
              <Col>
                <div className="page-section">
                  <div className="px-4">
                    {isMissingPriceWithoutDupes.length > 0 &&
                      isMissingPriceWithoutDupes.map((secondaryRole, key) => {
                        return (
                          <p
                            key={key}
                          >{`Tarif ${secondaryRole.toLowerCase()} manquant`}</p>
                        )
                      })}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-section d-flex">
                  <div className="d-flex flex-column align-items-center p-4">
                    <h1 className="font-weight-bold">
                      {checkUserPrivileges(
                        this.props.currentUser,
                        this.props.project
                      ) && this.state.showChargeClient
                        ? this.state.expectedTotal
                        : this.state.visibleTotal}
                    </h1>
                    <FaHourglass size={64} />
                    <p>Charge prévue</p>
                    <p>
                      {checkUserPrivileges(
                        this.props.currentUser,
                        this.props.project
                      ) && this.state.showChargeClient
                        ? (
                            Math.round(
                              this.state.expected_workloadPrice * 100
                            ) / 100
                          ).toFixed(2) + '€'
                        : ''}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center p-4">
                    <h1 className="font-weight-bold">
                      {this.state.consumTotal}
                    </h1>
                    <FaFireAlt size={64} />
                    <p>Charge consommée</p>
                    <p>
                      {checkUserPrivileges(
                        this.props.currentUser,
                        this.props.project
                      ) && this.state.showChargeClient
                        ? this.state.consumedPrice > -1
                          ? (
                              Math.round(this.state.consumedPrice * 100) / 100
                            ).toFixed(2) + '€'
                          : '?'
                        : ''}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center p-4">
                    <h1 className="font-weight-bold">
                      {this.state.remainTotal}
                    </h1>
                    <FaHourglassHalf size={64} />
                    <p>Charge restante</p>
                    <p>
                      {checkUserPrivileges(
                        this.props.currentUser,
                        this.props.project
                      ) && this.state.showChargeClient
                        ? (Math.round(this.state.rafPrice * 100) / 100).toFixed(
                            2
                          ) + '€'
                        : ''}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-center p-4">
                    <h1
                      className={
                        differential < 0
                          ? 'font-weight-bold text-danger'
                          : 'font-weight-bold text-success'
                      }
                    >
                      {differential}
                    </h1>
                    <FaArrowsAltH size={64} />
                    <p>Écart</p>
                    <p
                      className={
                        differentialPrice &&
                        (differentialPrice < 0 ? 'text-danger' : 'text-success')
                      }
                    >
                      {checkUserPrivileges(
                        this.props.currentUser,
                        this.props.project
                      ) && this.state.showChargeClient
                        ? differentialPrice
                          ? (Math.round(differentialPrice * 100) / 100).toFixed(
                              2
                            ) + '€'
                          : '?'
                        : ''}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-section">
                  <div className="p-4">
                    <p>Avancement</p>
                    <Progress
                      value={progress}
                      style={{
                        width: '50%',
                        height: '24px',
                        fontSize: '16px',
                      }}
                    >
                      {progress > 0 && `${progress}%`}
                    </Progress>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )
      default:
        return null
    }
  }

  render() {
    if (this.state.isLoading) return <PageSpinner />
    const sprints =
      this.props.sprints.length > 0
        ? this.props.sprints.sort((a, b) => a.number - b.number)
        : this.props.sprints

    return (
      <>
        <Header
          title={this.props.project.project_name}
          clientName={this.props.project.client_name}
          endIcon={
            <div>
              <Tooltip title="Acceder aux boards">
                <IconButton
                  className="m-0"
                  aria-label="accéder aux boards"
                  onClick={() => {
                    this.props.redirectToProjectTasksPage(
                      this.props.match.params.id
                    )
                  }}
                >
                  <Icon>assignment</Icon>
                </IconButton>
              </Tooltip>

              {checkUserPrivileges(
                this.props.currentUser,
                this.props.project
              ) ? (
                <>
                  <Tooltip title="Acceder aux saisies">
                    <IconButton
                      aria-label="accéder aux saisies"
                      style={{ color: 'rgb(148,139,150,1)' }}
                      onClick={() => {
                        this.props.redirectToProjectConsumsPage(
                          this.props.match.params.id
                        )
                      }}
                    >
                      <Icon>timer</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editer ce projet">
                    <IconButton
                      className="m-0"
                      aria-label="éditer ce projet"
                      onClick={() => {
                        this.props.redirectToEditProjectPage(
                          this.props.match.params.id
                        )
                      }}
                    >
                      <Icon>settings</Icon>
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </div>
          }
          user={this.props.currentUser}
        ></Header>
        {isAgileProjectType(this.props.project.project_type) && (
          <Breadcrumbs
            project={this.props.project}
            items={sprints}
            currentItem={this.state.currentSprint}
            click={(sprint) => this.changeSprint(sprint)}
          />
        )}
        <Row>
          <Col>
            <div className="page-section-title">
              <h4>Statistiques</h4>
            </div>
          </Col>
        </Row>
        {this.renderSwitch()}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: isFetching(state),
  currentUser: getUser(),
  project: getProject(state, ownProps.match.params.id),
  projectTypes: getProjectTypes(state),
  boards: getBoards(state, ownProps.match.params.id),
  sprints: getSprints(state, ownProps.match.params.id),
  srPrices: getSecondaryRolesPrices(state, ownProps.match.params.id),
})

const mapDispatchToProps = (dispatch) => ({
  //Fetchs
  fetchProject: (id) => dispatch(fetchProjectById(id)),
  fetchProjectTypes: () => dispatch(fetchProjectTypes()),
  fetchBoardsByProjectId: (id) => dispatch(fetchBoardsByProjectId(id)),
  fetchSprintsByProjectId: (id) => dispatch(fetchSprintsByProjectId(id)),
  fetchProjectPrices: (id) => dispatch(fetchSRPricesByProjectId(id)),
  //Redirects
  redirectToProjectConsumsPage: (id) =>
    dispatch(push(routes.projectConsums + '/' + id)),
  redirectToProjectTasksPage: (id) =>
    dispatch(push(routes.projectTasks + '/' + id)),
  redirectToEditProjectPage: (id) =>
    dispatch(push(routes.editProject + '/' + id)),
  redirectToDashboardPage: () => dispatch(push(routes.dashboard)),
})

export default  connect(mapStateToProps, mapDispatchToProps)(Statistics)
