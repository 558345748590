import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

const ColoredSwitch = withStyles((theme) => ({
  switchBase: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch)

export default ColoredSwitch
