import React from 'react'
import moment from 'moment'
import { getUser } from '../../../../helpers'
import { fetchProjects } from '../../../../actions/requests/projectsActions'
import { postConsum } from '../../../../actions/requests/consumsActions'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { fetchTasksByUserId } from '../../../../actions/requests/tasksActions'
import { connect } from 'react-redux'
import { isInvalidDate } from '../../../../helpers/date'
import { KeyboardDatePicker } from '@material-ui/pickers'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import CircularProgress from '@material-ui/core/CircularProgress'

class AddConsum extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      date: moment().toDate(),
      isLoading: false,
      selectedConsum: 0,
    }
  }

  saveData() {
    if (this.state.selectedConsum === 0) return

    this.setState({ isLoading: true })

    const user = getUser()
    const date = moment(this.state.date).format('DD-MM-YYYY')

    this.props.fetchProjects().then((response) => {
      const project = response.data.find(
        (project) => project.id === this.props.task.board.project.id
      )
      const userInProject = project.users.find((u) => u.user.id === user.id)

      let body = {
        user: user.id,
        task: this.props.task.id,
        secondaryRole: userInProject.secondaryRole.id,
        date,
        amount: this.state.selectedConsum * 0.25,
      }

      const notificationMessage = 'Consommation ajoutée'
      this.props
        .addConsum(
          body,
          (id, response) =>
            this.props.successNotification(id, response, notificationMessage),
          (id, error) => this.props.failureNotificationApi(id, error)
        )
        .then(() => {
          this.setState({
            isLoading: false,
            selectedConsum: 0,
          })
          this.props.fetchUserTasks(user.id)
        })
    })
  }

  render() {
    return (
      <div className={'d-flex align-items-center justify-content-between'}>
        <KeyboardDatePicker
          variant={'inline'}
          inputVariant={'outlined'}
          format={'DD-MM-yyyy'}
          shouldDisableDate={isInvalidDate}
          maxDate={new Date()}
          value={this.state.date}
          onChange={(date) => this.setState({ date: date })}
          InputProps={{
            style: { backgroundColor: 'white', height: '40px', width: '180px' },
          }}
          KeyboardButtonProps={{ 'aria-label': 'Ouvrir le calendrier' }}
          leftArrowButtonProps={{ 'aria-label': 'Aller au mois précédent' }}
          rightArrowButtonProps={{ 'aria-label': 'Aller au mois suivant' }}
          autoOk
        />

        <ButtonGroup style={{ paddingLeft: '8px' }}>
          <Button
            aria-label="Consommation 0,25"
            variant="contained"
            color={'primary'}
            style={{
              backgroundColor:
                this.state.selectedConsum === 1
                  ? 'rgb(168,106,1)'
                  : 'rgb(242, 148, 0, 1)',
            }}
            onClick={() => this.setState({ selectedConsum: 1 })}
          >
            0,25
          </Button>

          <Button
            aria-label="Consommation 0,50"
            variant="contained"
            color={'primary'}
            style={{
              backgroundColor:
                this.state.selectedConsum === 2
                  ? 'rgb(168,106,1)'
                  : 'rgb(242, 148, 0, 1)',
            }}
            onClick={() => this.setState({ selectedConsum: 2 })}
          >
            0,50
          </Button>

          <Button
            aria-label="Consommation 0,75"
            variant="contained"
            color={'primary'}
            style={{
              backgroundColor:
                this.state.selectedConsum === 3
                  ? 'rgb(168,106,1)'
                  : 'rgb(242, 148, 0, 1)',
            }}
            onClick={() => this.setState({ selectedConsum: 3 })}
          >
            0,75
          </Button>

          <Button
            aria-label="Consommation 1,00"
            variant="contained"
            color={'primary'}
            style={{
              backgroundColor:
                this.state.selectedConsum === 4
                  ? 'rgb(168,106,1)'
                  : 'rgb(242, 148, 0, 1)',
            }}
            onClick={() => this.setState({ selectedConsum: 4 })}
          >
            1,00
          </Button>
        </ButtonGroup>

        <div style={{ position: 'relative' }}>
          <IconButton
            className="align-middle"
            aria-label="ajouter"
            disabled={this.state.selectedConsum === 0}
            onClick={() => this.saveData()}
          >
            <Icon>playlist_add</Icon>
          </IconButton>

          {this.state.isLoading && (
            <CircularProgress
              color="inherit"
              size={24}
              style={{
                position: 'absolute',
                left: '21%',
                top: '21%',
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(fetchProjects()),
  fetchUserTasks: (id) => dispatch(fetchTasksByUserId(id)),
  addConsum: (body, successCallback, failureCallback) =>
    dispatch(postConsum(body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(null, mapDispatchToProps)(AddConsum)
