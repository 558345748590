import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  height: 48px;
  margin-top: 10px;
  border-top: 1px solid;
  border-top-color: rgba(0, 0, 0, 0.12);
  padding-bottom: 15px;
`

export default StyledDiv
