import * as React from 'react'
import { connect } from 'react-redux'
import { postTask } from '../../../../actions/requests/tasksActions'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { push } from 'connected-react-router'
import routes from '../../../../config/routes'
import ColorButton from '../../../../components/ColorButton'
import {
  Icon,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core'

/**
 * Props :
 *    - board, the board to add the task to
 *    - filters, the filters of the project
 *    - doClose, function to close the menu
 */

class AddTask extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      task_name: '',
      task_desc: '',
      task_workload: 0,
      task_visible_workload: 0,
      task_remaining_workload: 0,
      task_label: '',
      formOpen: false,
      isLoading: false,
    }

    if (this.props.board === null || this.props.board === undefined) {
      this.props.redirectToDashboardPage()
    }

    this.handleCloseForm = this.handleCloseForm.bind(this)
    this.handleOpenForm = this.handleOpenForm.bind(this)
  }

  handleOpenForm() {
    this.setState({
      formOpen: true,
    })
  }

  handleCloseForm() {
    this.setState({
      formOpen: false,
    })

    if (this.props.doClose) {
      this.props.doClose()
    }
  }

  isFormInvalid() {
    //only description and filter optionnal
    return this.state.task_name.length === 0
  }

  saveData() {
    if (this.state.task_name === '' || !this.state.task_name.trim()) {
      this.props.addNotification(
        'Opération échouée',
        'Le titre de la tâche ne peut pas être vide',
        'danger'
      )
      return
    }

    const selectedFilter = this.props.filters.find(
      (filter) => filter.name === this.state.task_label
    )

    let body = {
      board: this.props.board.id,
      name: this.state.task_name,
      description: this.state.task_desc,
      related_tasks: [],
      expected_workload: this.state.task_workload,
      visible_workload: this.state.task_visible_workload,
      remaining_workload: this.state.task_remaining_workload,
      user: null,
      projectFilters: [],
      reference: null,
    }

    if (selectedFilter) {
      // On récupère la valeur du filtre
      body.projectFilters[body.projectFilters.length] = selectedFilter.id
    }

    const notificationMessage = 'Tâche ajoutée'
    this.props
      .postTask(
        body,
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then((response) => {
        this.handleCloseForm()
        this.props.redirectToEditTaskPage(response.data.id)
      })
      .catch((error) => {
        return error
      })
  }

  handleStopPropagation(event) {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown'
    ) {
      event.stopPropagation()
    }
  }

  render() {
    let filtersName = this.props.filters
      .map((filter) => filter.name)
      .sort((a, b) => a.localeCompare(b))

    return (
      <>
        <div>
          {this.props.table ? (
            <>
              <Button
                variant={'outlined'}
                color={'primary'}
                startIcon={<Icon style={{ color: 'orange' }}>add_circle</Icon>}
                onClick={this.handleOpenForm}
              >
                Ajouter une tâche
              </Button>
            </>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  this.handleOpenForm()
                }}
              >
                <ListItemIcon
                  aria-label="Ajouter une tâche"
                  style={{ minWidth: 40 }}
                >
                  <Icon style={{ color: 'orange' }}>add_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="Ajouter une tâche" />
              </MenuItem>
            </>
          )}
        </div>

        <div>
          <Dialog
            open={this.state.formOpen}
            onClose={this.handleCloseForm}
            fullWidth
            aria-labelledby="dialog-title"
            onKeyDown={this.handleStopPropagation}
          >
            <DialogTitle id="dialog-title">Ajout d'une tâche</DialogTitle>
            <DialogContent>
              <div style={{ width: '100%' }}>
                <TextField
                  aria-label="Nom de la tâche"
                  name="task_name"
                  label="Nom de la tâche "
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    this.setState({ task_name: e.currentTarget.value })
                  }}
                  value={this.state.task_name}
                />
              </div>

              <div style={{ paddingTop: '10px', width: '100%' }}>
                <TextField
                  aria-label="Description de la tâche"
                  name="task_desc"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    this.setState({ task_desc: e.currentTarget.value })
                  }}
                  value={this.state.task_desc}
                />
              </div>

              <div style={{ paddingTop: '10px', width: '100%' }}>
                <TextField
                  aria-label="Charge client de la tâche"
                  name="task_workload"
                  variant="outlined"
                  type="number"
                  value={this.state.task_workload}
                  label="Charge client"
                  fullWidth
                  onChange={(e) => {
                    this.setState({ task_workload: e.currentTarget.value })
                  }}
                  inputProps={{ step: '0.25' }}
                />
              </div>

              <div style={{ paddingTop: '10px', width: '100%' }}>
                <TextField
                  aria-label="Charge visible de la tâche"
                  name="task_visible_workload"
                  type="number"
                  variant="outlined"
                  value={this.state.task_visible_workload}
                  label="Charge"
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      task_visible_workload: e.currentTarget.value,
                    })
                  }}
                  inputProps={{ step: '0.25' }}
                />
              </div>

              <div style={{ paddingTop: '10px', width: '100%' }}>
                <TextField
                  aria-label="Reste à faire de la tâche"
                  name="task_remaining_workload"
                  type="number"
                  label="RAF"
                  value={this.state.task_remaining_workload}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      task_remaining_workload: e.currentTarget.value,
                    })
                  }}
                  inputProps={{ step: '0.25' }}
                />
              </div>
              <div style={{ width: '100%', paddingTop: '10px' }}>
                <FormControl style={{ width: '100%' }} variant="outlined">
                  <InputLabel
                    htmlFor="label-select"
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    Label
                  </InputLabel>
                  <Select
                    aria-label="Label de la tâche"
                    inputProps={{
                      name: 'task_label',
                      id: 'label-select',
                    }}
                    style={{ witdh: '100%' }}
                    onChange={(e) => {
                      this.setState({ task_label: e.currentTarget.value })
                    }}
                    native
                  >
                    <option value="" />
                    {filtersName.map((name) => (
                      <option value={name}>{name}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>

            <DialogActions className="justify-content-center">
              <ColorButton onClick={this.handleCloseForm}> Fermer </ColorButton>
              <ColorButton
                variant="outlined"
                color="secondary"
                onClick={() => this.saveData()}
                disabled={this.isFormInvalid() || this.state.isLoading}
              >
                {' '}
                Ajouter{' '}
              </ColorButton>
            </DialogActions>
          </Dialog>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  postTask: (body, successCallback, failureCallback) =>
    dispatch(postTask(body, successCallback, failureCallback)),
  addItem: (body, successCallback, failureCallback) =>
    dispatch(postTask(body, successCallback, failureCallback)),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
  redirectToEditTaskPage: (id) =>
    dispatch(push(routes.editTask + '/' + id)),
  redirectToDashboardPage: () =>
    dispatch(push(routes.dashboard)),
})

export default connect(null, mapDispatchToProps)(AddTask)
