import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createSelector } from 'reselect'
import { clearErrors } from '../actions/globalActions'
import { itemTypes } from '../actions/itemTypes'
import { emptyReducer } from './defaultReducers/emptyReducer'
import { notificationsReducer } from './defaultReducers/notificationsReducer'
import { requestsReducer } from './defaultReducers/requestsReducer'

const emptyReducers = () => {
  let empty = {}
  for (let key of Object.keys(itemTypes)) {
    empty[key] = emptyReducer
  }
  return empty
}

const defaultReducers = () => {
  let empty = {}
  for (let key of Object.keys(itemTypes)) {
    empty[key] = requestsReducer
  }
  return empty
}

/**
 * Returns the specific reducers, none at the time.
 * By default, returns empty reducers for all item
 */
const specificReducers = () => {
  let empty = emptyReducers()

  return Object.assign({}, empty, {
    // Put here specific reducers if needed
  })
}

export default (history) => {
  return (state, action) => {
    // Redux's actions, like init.
    if (action.itemType === null || action.itemType === undefined)
      return {
        router: connectRouter(history)(state.router, action),
        notifications: notificationsReducer(state.notifications, action),
        requests: combineReducers(defaultReducers())(state.requests, action),
      }

    // Specific action. Uses specific reducers. None exist at the time
    if (!action.def) {
      return {
        router: connectRouter(history)(state.router, action),
        notifications: notificationsReducer(state.notifications, action),
        requests: Object.assign({}, state.requests, {
          [action.itemType.itemName]: specificReducers()[
            action.itemType.itemName
          ](state.requests[action.itemType.itemName], action),
        }),
      }
    }

    // Default action, use the default reducers and send it the item part of the state
    return {
      router: connectRouter(history)(state.router, action),
      notifications: notificationsReducer(state.notifications, action),
      requests: Object.assign({}, state.requests, {
        [action.itemType.itemName]: requestsReducer(
          state.requests[action.itemType.itemName],
          action
        ),
      }),
    }
  }
}

/**
 * Do not use a otherURLs in itemType
 */
export const getItems = (state, itemType) =>
  state.requests[itemType.itemName].items
export const getItemsByProject = (state, itemType, projectId) => {
  const items = state.requests[itemType.itemName].items[projectId]
  return items !== undefined ? items : []
}
export const findItem = (state, itemType, id) =>
  getItems(state, itemType).find((item) => item.id === id)
/**
 * Use a clear added tasks action after
 * Outdated, you should use the callbacks to know when something is added
 */
export const getAddedItems = (state, itemType) =>
  state.requests[itemType.itemName].addedItems
/**
 * Use a clear deleted tasks action after
 * Outdated, you should use the callbacks to know when something is deleted
 */
export const getDeletedItems = (state, itemType) =>
  state.requests[itemType.itemName].deletedItems

//-------------
//REQUESTS
//-------------

export const isFetching = (state) =>
  Object.values(state.requests).some((value) => value.isFetching)
export const retrieveRequests = (state) =>
  Object.values(state.requests).flatMap((value) => value.requests)
export const retrieveWaitingRequests = (state) =>
  Object.values(state.requests).flatMap((value) => value.waitingRequests)
export const retrieveErrors = (state) =>
  Object.values(state.requests)
    .flatMap((value) => value.error)
    .filter((error) => error)
export const clearAllErrors = (dispatch) => {
  let clearErrorActions = []
  for (let key of Object.keys(itemTypes)) {
    clearErrorActions[key] = () => dispatch(clearErrors(itemTypes[key]))
  }
  return clearErrorActions
}

// Shortcuts method

//BILL
export const getBills = (state, projectId) =>
  getItemsByProject(state, itemTypes.bills, projectId)

//BILL STATUSES
export const getBillStatuses = (state) =>
  getItems(state, itemTypes.billStatuses)

//COMMENTS
export const getComments = (state) => getItems(state, itemTypes.comments)

//CONSUMS
export const getConsums = (state) => getItems(state, itemTypes.consums)

//EXPERIENCES
export const getExperiences = (state) => getItems(state, itemTypes.experiences)

//FILTERS
export const getFilters = (state, projectId) =>
  getItemsByProject(state, itemTypes.filters, projectId)

//PRIMARY ROLES
export const getPrimaryRoles = (state) =>
  getItems(state, itemTypes.primaryRoles)

//PROJECTS
export const getProjects = (state) => getItems(state, itemTypes.projects)
export const getOpenedProjects = (state) =>
  getItems(state, itemTypes.projects).filter(
    (project) => project.enabled && !project.finished
  )
export const getProject = (state, id) => {
  return getProjects(state).find((p) => p.id === id)
}

//BOARDS
export const getBoards = (state, projectId) =>
  getItemsByProject(state, itemTypes.boards, projectId)

//TASKS
export const getTasks = (state) => getItems(state, itemTypes.tasks)
export const getTask = (state, id) => {
  return getTasks(state).find((p) => p.id === id)
}
export const getUserTasks = (state, userId) =>
  getTasks(state).filter((t) => t.user && t.user.id === userId)
export const getAddedTasks = (state) => getAddedItems(state, itemTypes.tasks)

//RELATED TASKS
export const getRelatedTasks = (state) =>
  getItems(state, itemTypes.relatedTasks)

//PROJECT STATUSES
export const getProjectStatuses = (state) =>
  getItems(state, itemTypes.projectStatuses)

//PROJECT TYPES
export const getProjectTypes = (state) =>
  getItems(state, itemTypes.projectTypes)
export const getProjectTypesIdByIdentifier = createSelector(
  [getProjectTypes],
  (projectTypes) => {
    let result = {}
    projectTypes.forEach(function (projectType) {
      result[projectType.codename] = projectType.id
    })
    return result
  }
)

//SECONDARY ROLES
export const getSecondaryRoles = (state) =>
  getItems(state, itemTypes.secondaryRoles)

//SECONDARY ROLES PRICES
export const getSecondaryRolesPrices = (state, projectId) =>
  getItemsByProject(state, itemTypes.srprices, projectId)

//SKILLS
export const getSkills = (state) => getItems(state, itemTypes.skills)

//SPRINTS
export const getSprints = (state, projectId) =>
  getItemsByProject(state, itemTypes.sprints, projectId)

//USERS
export const getAllUsers = (state) => getItems(state, itemTypes.users)
export const getEnabledUsers = (state) =>
  getAllUsers(state).filter((user) => user.enabled)
export const getSimpleEnabledUsers = (state) =>
  getItems(state, itemTypes.usersSimple).filter((user) => user.enabled)

//USERS NEEDED
export const getNeededUsers = (state, projectId) =>
  getItemsByProject(state, itemTypes.usersNeeded, projectId)

//USER SECONDARY ROLES
export const getAllUserSRs = (state) =>
  getItems(state, itemTypes.userSecondaryRoles)
export const getUserSRs = (state, projectId) =>
  getItemsByProject(state, itemTypes.userSecondaryRoles, projectId)

//NOTIFICATIONS
export const getNotifications = (state) => state.notifications.items
