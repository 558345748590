import {
  ACTION_ADD_NOTIFICATION,
  ACTION_CLEAR_NOTIFICATIONS,
} from '../actionTypes'
import { generateUUID } from '../../helpers'

export const successNotification = (id, response, message) =>
  addNotification('Opération réussie', message, 'success')

export const failureNotificationApi = (id, error) =>
  addNotification(
    'Opération échouée',
    error.response.data.message.split(':')[1],
    'danger'
  )

export const failureNotification = (error) =>
  addNotification('Opération échouée', error, 'danger')

export const addNotification = (header, body, icon) => ({
  type: ACTION_ADD_NOTIFICATION,
  data: {
    header: header,
    body: body,
    icon: icon,
    id: generateUUID(),
  },
})

export const clearNotifications = () => ({
  type: ACTION_CLEAR_NOTIFICATIONS,
})
