import React from 'react'
import {
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getComparator, stableSort } from '../../../../helpers'
import { AddConsum, TaskConsums } from '../index'

const StyledTableCell = withStyles((theme) => ({
  root: {
    textAlign: 'left',
    backgroundColor: 'white',
  },
  head: {
    backgroundColor: lighten(theme.palette.primary.main, 0.2),
    border: '1px solid lightgray',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))(TableCell)

const getStyles = () => ({
  linkCell: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    maxWidth: 200,
    whiteSpace: 'nowrap',
  },
  nameCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tooltip: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
})

const headCells = [
  { id: 'projectName', label: 'Projet', width: 150 },
  { id: 'name', label: 'Tâche', width: 250 },
]

class UserTasksTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      order: 'asc',
      orderBy: 'projectName',
      page: 0,
      rowsPerPage: 5,
    }
  }

  getFormattedTasks = () => {
    return this.props.tasks.map((task) => ({
      ...task,
      project: task.board.project,
      projectName: task.board.project.project_name,
    }))
  }

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc'
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    })
  }

  handlePageChange = (page) => {
    this.setState({ page: page })
  }

  handleRowsPerPageChange = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    })
  }

  render() {
    const { classes, onProjectClick, onTaskClick } = this.props
    const { order, orderBy, page, rowsPerPage } = this.state

    const tasks = this.getFormattedTasks()

    return (
      <TableContainer>
        <Table
          aria-label={'userTasks table'}
          size={'small'}
          style={{ border: '1px solid lightgray' }}
        >
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <StyledTableCell
                  key={cell.id}
                  sortDirection={orderBy === cell.id ? order : false}
                  style={{ minWidth: cell.width }}
                >
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={() => this.handleRequestSort(cell.id)}
                  >
                    {cell.label}
                    {orderBy === cell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell colSpan={2} />
            </TableRow>
          </TableHead>

          <TableBody>
            {stableSort(tasks, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((task) => (
                <TableRow key={task.id}>
                  <StyledTableCell
                    className={`${classes.linkCell} ${classes.nameCell}`}
                    onClick={() => onProjectClick(task.project.id)}
                  >
                    <Tooltip
                      title={task.projectName}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span>
                        {task.projectName}
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    className={`${classes.linkCell} ${classes.nameCell}`}
                    onClick={() => onTaskClick(task.id)}
                  >
                    <Tooltip
                      title={task.name}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <span>
                        {task.name}
                      </span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TaskConsums task={task} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <AddConsum task={task} />
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={tasks.length}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} sur ${count !== -1 && count}`
                }
                labelRowsPerPage={'Lignes par page'}
                onChangePage={(event, page) => this.handlePageChange(page)}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(getStyles)(UserTasksTable)
