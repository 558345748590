import * as React from 'react'
import { connect } from 'react-redux'
import StyledDiv from './styles/StyledDiv'
import {
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import { getConsums, isFetching } from '../../../../reducers'
import { getUser } from '../../../../helpers'
import { updateConsum } from '../../../../actions/requests/consumsActions'
import UserThumbnail from '../../../../components/UserThumbnail'
import { userThumbnailTypes } from '../../../../constants/global'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import moment from 'moment'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import ColorButton from '../../../../components/ColorButton'
import { isInvalidDate } from '../../../../helpers/date'
import { DatePicker } from '@material-ui/pickers'

/**
 * Props :
 *    - consum
 */
class EditConsum extends React.Component {
  closeAction = () => {}
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      modal: false,
      dialog: false,
      selected: 0,
      startDate: moment(this.props.consum.date).toDate(),
    }

    this.toggle = this.toggle.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  handleDateChange(date) {
    this.setState({ startDate: date })
  }

  setSelected(selected) {
    this.setState({ selected })
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  handleOpenDialog() {
    this.setState({
      dialog: true,
    })
  }

  handleCloseDialog() {
    this.setState({
      dialog: false,
    })
  }

  saveData() {
    let date = moment(this.state.startDate).format('DD-MM-YYYY')
    let body = {
      amount: this.state.selected * 0.25,
      date,
    }
    this.setState({ isLoading: true })
    const notificationMessage = 'Consommation modifiée'
    this.props.editItem(
      this.props.consum.id,
      body,
      (id, response) =>
        this.props.successNotification(id, response, notificationMessage),
      (id, error) => this.props.failureNotificationApi(id, error)
    )
  }

  render() {
    const consum = this.props.consum

    return (
      <StyledDiv>
        {this.state.isDeleting ? (
          <IconButton>
            <Icon>
              <CircularProgress size={24} />
            </Icon>
          </IconButton>
        ) : (
          <IconButton onClick={this.handleOpenDialog} size="small">
            <Icon>edit</Icon>
          </IconButton>
        )}
        <Dialog open={this.state.dialog} onClose={this.handleCloseDialog}>
          <DialogTitle>Edition d'une consommation</DialogTitle>
          <DialogContent>
            <div className="d-flex justify-content-center align-items-center pb-3">
              <UserThumbnail
                key={consum.user.id}
                type={userThumbnailTypes.USER}
                data={consum.user}
              />
              {consum.user.firstname.slice(0, 1).toUpperCase() +
                consum.user.lastname.slice(0, 2).toUpperCase()}
            </div>
            <FormGroup>
              <div className="d-flex justify-content-around align-items-center">
                <DatePicker
                  variant={'inline'}
                  inputVariant={'outlined'}
                  format={'DD-MM-yyyy'}
                  shouldDisableDate={isInvalidDate}
                  maxDate={new Date()}
                  value={this.state.startDate}
                  onChange={(date) => this.handleDateChange(date)}
                  InputProps={{
                    style: { backgroundColor: 'white', height: '40px' },
                  }}
                  autoOk
                />

                <ButtonGroup fullWidth={true}>
                  <Button
                    variant="contained"
                    color={this.state.selected === 1 ? 'secondary' : 'default'}
                    onClick={() => this.setSelected(1)}
                  >
                    0,25
                  </Button>
                  <Button
                    variant="contained"
                    color={this.state.selected === 2 ? 'secondary' : 'default'}
                    onClick={() => this.setSelected(2)}
                  >
                    0,50
                  </Button>
                  <Button
                    variant="contained"
                    color={this.state.selected === 3 ? 'secondary' : 'default'}
                    onClick={() => this.setSelected(3)}
                  >
                    0,75
                  </Button>
                  <Button
                    variant="contained"
                    color={this.state.selected === 4 ? 'secondary' : 'default'}
                    onClick={() => this.setSelected(4)}
                  >
                    1,00
                  </Button>
                </ButtonGroup>
              </div>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <div className="d-flex justify-content-center p-2">
              <ColorButton
                onClick={() => {
                  this.saveData()
                  this.handleCloseDialog()
                }}
                variant="outlined"
                disabled={this.state.selected === 0}
              >
                Enregistrer
              </ColorButton>
            </div>
          </DialogActions>
        </Dialog>
      </StyledDiv>
    )
  }
}

const mapStateToProps = (state) => ({
  isFetching: isFetching(state),
  currentUser: getUser(state),
  consums: getConsums(state),
})

const mapDispatchToProps = (dispatch) => ({
  editItem: (id, body, successCallback, failureCallback) =>
    dispatch(updateConsum(id, body, successCallback, failureCallback)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditConsum)
