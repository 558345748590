import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes, otherURLs } from '../itemTypes'

export const fetchSprints = () => {
  return fetchEntity(itemTypes.sprints)
}

export const fetchSprintsByProjectId = (id) => {
  return fetchEntity(otherURLs.projectSprints, id, id)
}

export const postSprint = (
  body,
  successCallback,
  failureCallback,
  projectId
) => {
  return postEntity(
    itemTypes.sprints,
    body,
    successCallback,
    failureCallback,
    projectId
  )
}

export const updateSprint = (
  id,
  body,
  projectId,
  successCallback,
  failureCallback
) => {
  return updateEntity(
    itemTypes.sprints,
    id,
    body,
    successCallback,
    failureCallback,
    projectId
  )
}

export const deleteSprint = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.sprints, id, successCallback, failureCallback)
}

export const clearErrorSprints = () => clearErrors(itemTypes.sprints)
