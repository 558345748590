import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Section } from '../../../../components'
import { getProjectStatuses } from '../../../../reducers'
import ProjectInfosDeleteDialog from '../ProjectInfosDeleteDialog'
import ProjectInfosCloseDialog from '../ProjectInfosCloseDialog'
import ProjectInfosNextSprintDialog from '../ProjectInfosNextSprintDialog'
import { updateFormEntity } from '../../../../actions/globalActions'
import { itemTypes } from '../../../../actions/itemTypes'
import {
  isAgileProjectType,
  isForfaitProjectType,
} from '../../../../constants/global'
import {
  addNotification,
  failureNotificationApi,
  successNotification,
} from '../../../../actions/temporaries/notificationActions'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Select from '@material-ui/core/Select'
import FormLabel from '@material-ui/core/FormLabel'
import { DatePicker } from '@material-ui/pickers'
import { isInvalidDate } from '../../../../helpers/date'
import moment from 'moment'

/**
 * Props :
 *    - project, the edited project
 *    - disabled
 * Store :
 *    - Project Statuses
 */

class ProjectInfosSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFirstRender: true,
      clientNameHasChanged: false,
      clientName: this.props.project.client_name,
      clientNameInitial: this.props.project.client_name,
      projectNameHasChanged: false,
      projectName: this.props.project.project_name,
      projectNameInitial: this.props.project.project_name,
      currentSprint: null,
      startDate: null,
      endDate: null,
      projectStatus: null,
    }

    this.handleFormChanged = this.handleFormChanged.bind(this)
    this.handleSelectedProjectStatus =
      this.handleSelectedProjectStatus.bind(this)
    this.handleNextSprint = this.handleNextSprint.bind(this)
  }

  componentDidMount() {
    isAgileProjectType(this.props.project.project_type) &&
      this.getCurrentSprint()
    this.setState({ isFirstRender: false })
  }

  handleFormChanged =
    (key) =>
    ({ target: { value } }) => {
      this.setState({ [key]: value })
      if (value !== this.state[`${key}Initial`]) {
        if (!this.state[`${key}HasChanged`]) {
          this.setState({ [`${key}HasChanged`]: true })
          this.props.onInfoChange(true)
        }
      } else {
        this.setState({ [`${key}HasChanged`]: false })
        this.props.onInfoChange(false)
      }
    }

  handleSelectedProjectStatus(event) {
    this.setState(
      {
        projectStatus: event.target.value,
      },
      () => {
        this.updateProjectInfos()
      }
    )
  }

  getCurrentSprint() {
    let sprints = []
    for (let sprint of this.props.project.sprints) {
      sprints = [...sprints, sprint.number]
    }

    const currentSprint = this.props.project.sprints.find(
      (sprint) => parseInt(sprint.number) === Math.max(...sprints)
    )
    const startDate = currentSprint.start ? moment(currentSprint.start) : null
    const endDate = currentSprint.end ? moment(currentSprint.end) : null

    this.setState({
      currentSprint,
      startDate,
      endDate,
    })
  }

  updateSprintInfos() {
    if (this.state.endDate !== null && this.state.endDate !== '') {
      if (this.state.startDate < this.state.endDate) {
        const notificationMessage = 'Sprint modifié'
        this.props.updateSprint(
          this.state.currentSprint.id,
          this.props.project.id,
          {
            start: moment(this.state.startDate).format('DD-MM-YYYY'),
            end: moment(this.state.endDate).format('DD-MM-YYYY'),
          },
          (id, response) =>
            this.props.successNotification(id, response, notificationMessage),
          (id, error) => this.props.failureNotificationApi(id, error)
        )
      } else {
        this.props.addNotification(
          'Opération échouée',
          'Dates de sprint invalides',
          'danger'
        )
      }
    }
  }

  handleNextSprint = (sprint) => {
    this.setState({
      currentSprint: sprint,
      startDate: moment(sprint.start),
      endDate: moment(sprint.end),
    })
  }

  async handleStartDateChange(date) {
    await this.setState({ startDate: date })
    this.updateSprintInfos()
  }

  async handleEndDateChange(date) {
    await this.setState({ endDate: date })
    this.updateSprintInfos()
  }

  updateProjectInfos(finished = this.props.disabled) {
    if (this.state.clientName.length > 50) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du client ne doit pas dépasser 50 caractères',
        'danger'
      )
      this.props.onInfoChange(false)
      return
    }
    if (this.state.projectName.length > 50) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du projet ne doit pas dépasser 50 caractères',
        'danger'
      )
      this.props.onInfoChange(false)
      return
    }
    if (this.state.clientName === '' || !this.state.clientName.trim()) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du client ne peut être vide',
        'danger'
      )
      this.props.onInfoChange(false)
      return
    } else if (
      this.state.projectName === '' ||
      !this.state.projectName.trim()
    ) {
      this.props.addNotification(
        'Opération échouée',
        'Le nom du projet ne peut être vide',
        'danger'
      )
      this.props.onInfoChange(false)
      return
    }

    const notificationMessage = 'Projet modifié'
    this.props
      .updateProject(
        this.props.project.id,
        {
          client_name: this.state.clientName,
          project_name: this.state.projectName,
          project_status: !isForfaitProjectType(this.props.project.project_type)
            ? null
            : this.props.projectStatuses.filter(
                (status) =>
                  status.name ===
                  document.getElementById('projectStatus').innerHTML
              )[0],
          finished,
        },
        (id, response) =>
          this.props.successNotification(id, response, notificationMessage),
        (id, error) => this.props.failureNotificationApi(id, error)
      )
      .then(() => {
        this.setState({
          clientNameInitial: this.props.project.client_name,
          projectNameInitial: this.props.project.project_name,
        })
      })
  }

  render() {
    if (this.state.isFirstRender) return null

    return isAgileProjectType(this.props.project.project_type) ? (
      <Card>
        <Section title="Général">
          {this.state.currentSprint && (
            <>
              <FormControl style={{ width: '100%', padding: '16px' }}>
                <FormGroup style={{ paddingBottom: '8px' }} row>
                  <TextField
                    label="Client"
                    type="text"
                    name="clientName"
                    id="clientName"
                    defaultValue={this.state.clientName}
                    disabled={this.props.disabled}
                    onChange={this.handleFormChanged('clientName')}
                    onBlur={() => {
                      this.state.clientName !== this.state.clientNameInitial &&
                        this.updateProjectInfos()
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
                <FormGroup style={{ paddingBottom: '8px' }} row>
                  <TextField
                    label="Nom"
                    type="text"
                    name="projectName"
                    id="projectName"
                    defaultValue={this.state.projectName}
                    disabled={this.props.disabled}
                    onChange={this.handleFormChanged('projectName')}
                    onBlur={() => {
                      this.state.projectName !==
                        this.state.projectNameInitial &&
                        this.updateProjectInfos()
                    }}
                    style={{ width: '100%' }}
                  />
                </FormGroup>
                <FormGroup style={{ paddingBottom: '8px' }} row>
                  <TextField
                    label="Sprint courant"
                    name="sprintCourant"
                    id="sprintCourant"
                    value={'Sprint ' + this.state.currentSprint.number}
                    disabled={true}
                    fullWidth
                  ></TextField>
                </FormGroup>
              </FormControl>
              <Grid container spacing={1}>
                <Grid item xs={6} className={'d-flex justify-content-center'}>
                  <FormGroup
                    style={{
                      paddingBottom: '8px',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'row',
                    }}
                  >
                    <FormLabel
                      htmlFor="sprint"
                      sm={2}
                      style={{ paddingRight: '1rem' }}
                    >
                      Début
                    </FormLabel>
                    <DatePicker
                      variant={'inline'}
                      inputVariant={'outlined'}
                      format={'DD-MM-yyyy'}
                      shouldDisableDate={isInvalidDate}
                      value={this.state.startDate}
                      maxDate={this.state.endDate || new Date('2100-12-31')}
                      onChange={(date) => this.handleStartDateChange(date)}
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                          height: '40px',
                          width: '120px',
                        },
                      }}
                      autoOk
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} className={'d-flex justify-content-center'}>
                  <FormGroup
                    style={{
                      paddingBottom: '8px',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'row',
                    }}
                  >
                    <FormLabel
                      htmlFor="sprint"
                      sm={2}
                      style={{ paddingRight: '1rem' }}
                    >
                      Fin
                    </FormLabel>
                    <DatePicker
                      variant={'inline'}
                      inputVariant={'outlined'}
                      format={'DD-MM-yyyy'}
                      shouldDisableDate={isInvalidDate}
                      minDate={this.state.startDate || new Date('1970-01-01')}
                      value={this.state.endDate}
                      onChange={(date) => this.handleEndDateChange(date)}
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                          height: '40px',
                          width: '120px',
                        },
                      }}
                      autoOk
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '1rem',
                    }}
                  >
                    <ProjectInfosNextSprintDialog
                      project={this.props.project}
                      disabled={this.props.disabled}
                      currentSprint={this.state.currentSprint}
                      currentSprintEndDate={this.state.endDate}
                      onNextSprint={this.handleNextSprint}
                    />
                    <ProjectInfosCloseDialog
                      project={this.props.project}
                      finished={this.props.disabled}
                      setFinished={(finished) => {
                        this.updateProjectInfos(finished)
                      }}
                    />
                    <ProjectInfosDeleteDialog project={this.props.project} />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Section>
      </Card>
    ) : (
      <Card>
        <Section title={'Général'}>
          {isForfaitProjectType(this.props.project.project_type) ? (
            <FormControl
              style={{ width: '100%', padding: '16px', height: '15rem' }}
            >
              <FormGroup style={{ paddingBottom: '8px' }}>
                <TextField
                  label="Client"
                  type="text"
                  name="clientName"
                  id="clientName"
                  defaultValue={this.state.clientName}
                  disabled={this.props.disabled}
                  onChange={this.handleFormChanged('clientName')}
                  onBlur={() => {
                    this.state.clientName !== this.state.clientNameInitial &&
                      this.updateProjectInfos()
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
              <FormGroup style={{ paddingBottom: '8px' }} row>
                <TextField
                  label="Nom"
                  type="text"
                  name="projectName"
                  id="projectName"
                  defaultValue={this.state.projectName}
                  disabled={this.props.disabled}
                  onChange={this.handleFormChanged('projectName')}
                  onBlur={() => {
                    this.state.projectName !== this.state.projectNameInitial &&
                      this.updateProjectInfos()
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
              <FormGroup style={{ paddingBottom: '8px' }} row>
                <label
                  className="small"
                  style={{
                    font: '12px Roboto,Helvetica,Arial,sans-serif',
                    height: '12',
                  }}
                >
                  Statut du projet
                </label>

                <Select
                  label="Statut"
                  name="projectStatus"
                  id="projectStatus"
                  defaultValue={this.props.project.project_status.id}
                  disabled={this.props.disabled}
                  onChange={this.handleSelectedProjectStatus}
                  style={{ width: '100%', marginTop: '-8px' }}
                >
                  {this.props.projectStatuses
                    .filter((status) => !status.is_sprint)
                    .map((status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '1rem',
                }}
              >
                <ProjectInfosCloseDialog
                  project={this.props.project}
                  finished={this.props.disabled}
                  setFinished={(finished) => {
                    this.updateProjectInfos(finished)
                  }}
                />
                <ProjectInfosDeleteDialog project={this.props.project} />
              </div>
            </FormControl>
          ) : (
            <FormControl
              style={{ width: '100%', padding: '16px', height: '15rem' }}
            >
              <FormGroup style={{ paddingBottom: '8px' }}>
                <TextField
                  label="Client"
                  type="text"
                  name="clientName"
                  id="clientName"
                  defaultValue={this.state.clientName}
                  disabled={this.props.disabled}
                  onChange={this.handleFormChanged('clientName')}
                  onBlur={() => {
                    this.state.clientName !== this.state.clientNameInitial &&
                      this.updateProjectInfos()
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
              <FormGroup style={{ paddingBottom: '8px' }} row>
                <TextField
                  label="Nom"
                  type="text"
                  name="projectName"
                  id="projectName"
                  defaultValue={this.state.projectName}
                  disabled={this.props.disabled}
                  onChange={this.handleFormChanged('projectName')}
                  onBlur={() => {
                    this.state.projectName !== this.state.projectNameInitial &&
                      this.updateProjectInfos()
                  }}
                  style={{ width: '100%' }}
                />
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '1rem',
                }}
              >
                <ProjectInfosCloseDialog
                  project={this.props.project}
                  finished={this.props.disabled}
                  setFinished={(finished) => {
                    this.updateProjectInfos(finished)
                  }}
                />
                <ProjectInfosDeleteDialog project={this.props.project} />
              </div>
            </FormControl>
          )}
        </Section>
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
  projectStatuses: getProjectStatuses(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateSprint: (id, projectId, body, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.sprints,
        id,
        body,
        successCallback,
        failureCallback,
        projectId
      )
    ),
  updateProject: (id, body, successCallback, failureCallback) =>
    dispatch(
      updateFormEntity(
        itemTypes.projects,
        id,
        body,
        successCallback,
        failureCallback
      )
    ),
  addNotification: (header, body, icon) =>
    dispatch(addNotification(header, body, icon)),
  successNotification: (id, response, message) =>
    dispatch(successNotification(id, response, message)),
  failureNotificationApi: (id, error) =>
    dispatch(failureNotificationApi(id, error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfosSection)
