import React from 'react'
import { createStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import { DatePicker } from '@material-ui/pickers'

const styles = createStyles((theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}))

class WeekPicker extends React.Component {
  formatWeekSelectLabel = (date, invalidLabel) => {
    const dateClone = moment(date)

    return dateClone && dateClone.isValid()
      ? `Semaine ${dateClone.week()}`
      : invalidLabel
  }

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props

    const dateClone = new Date(date.valueOf())
    const selectedDateClone = new Date(selectedDate.valueOf())
    const momentSelectedDate = moment(selectedDateClone)
    const momentDate = moment(dateClone)

    const start = momentSelectedDate.startOf('week').toDate()
    const end = momentSelectedDate.endOf('week').startOf('day').toDate()
    const isFirstDay = momentDate.isSame(start)
    const isLastDay = momentDate.isSame(end)
    const dayIsBetween = momentDate.isBetween(start, end, null, '[]')

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    })

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    })

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {momentDate.date()} </span>
        </IconButton>
      </div>
    )
  }

  render() {
    const { onChange, value } = this.props
    return (
      <DatePicker
        value={value}
        onChange={(date) => onChange(moment(date).startOf('week').toDate())}
        renderDay={this.renderWrappedWeekDay}
        labelFunc={this.formatWeekSelectLabel}
        autoOk
      />
    )
  }
}

export default withStyles(styles)(WeekPicker)
