import {
  clearErrors,
  deleteEntity,
  fetchEntity,
  postEntity,
  updateEntity,
} from '../globalActions'
import { itemTypes } from '../itemTypes'

export const fetchSkills = () => {
  return fetchEntity(itemTypes.skills)
}

export const updateSkills = (id, body, successCallback, failureCallback) => {
  return updateEntity(
    itemTypes.skills,
    id,
    body,
    successCallback,
    failureCallback
  )
}

export const deleteSkills = (id, successCallback, failureCallback) => {
  return deleteEntity(itemTypes.skills, id, successCallback, failureCallback)
}

export const postSkills = (body, successCallback, failureCallback) => {
  return postEntity(itemTypes.skills, body, successCallback, failureCallback)
}

export const clearErrorSkills = () => clearErrors(itemTypes.skills)
